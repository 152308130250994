import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Reveal from "../Reveal/Reveal";
import { fmDataContext } from "../../Context/FmData";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import userCan from "../../helpers/userCan";
import Swal from "sweetalert2/dist/sweetalert2.all.min";
import "@sweetalert2/theme-dark/dark.min.css";
import { authenticationContext } from "../../Context/AuthContext";
import { fmUsersContext } from "../../Context/UsersContext";

const UserRow = (props) => {
  let { meta, currentUser, getUsers, perPage, pageNum, orderBy, orderType } = props;
  let { baseUrl, navigate, editItemExistence, goToBlogDetails } = useContext(fmDataContext);
  let { filtrationDetails } = useContext(fmUsersContext);
  let { user } = useContext(authenticationContext);

  let [isSoftDeleteLoading, setIsSoftDeleteLoading] = useState(false);
  let [isForceDeleteLoading, setIsForceDeleteLoading] = useState(false);
  let [isRestoreLoading, setIsRestoreLoading] = useState(false);

  const softDeleteUser = (reqMethod, pathName, headers, setLoading) => {
    if (userCan("fm_user_soft_delete")) {
      Swal.fire({
        title: "Are you sure?",
        html: `<p class="mb-0">You will delete <span class="fu-text-light-gray">${currentUser.name}</span></p>`,
        icon: "warning",
        showCancelButton: true,
        customClass: {
          cancelButton: "popup-btn fu-btn-sm",
          confirmButton: "popup-btn fu-btn-sm",
        },
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              getUsers(
                "GET",
                `${baseUrl}fm-users`,
                {
                  page_num: meta.from === meta.to && meta.last_page > 1 ? meta.current_page - 1 : meta.last_page < pageNum ? 1 : pageNum,
                  per_page: perPage,
                  order_type: orderType,
                  order_by: orderBy,
                  ...filtrationDetails,
                },
                setLoading,
                response.data.msg && response.data.msg.includes("successfully") ? `${currentUser.name} Deleted Successfully` : response.data.msg && response.data.msg.includes("Unable") ? `Unable To Delete ${currentUser.name}` : null
              );
            })
            .catch((error) => {
              navigate("/not-found");
            });
        }
      });
    } else {
      navigate("/not-found");
    }
  };

  const restoreUser = (reqMethod, pathName, headers, setLoading) => {
    if (userCan("fm_user_restore_trashed")) {
      Swal.fire({
        title: "Are you sure?",
        html: `<p class="mb-0">You will restore <span class="fu-text-light-gray">${currentUser.name}</span></p>`,
        icon: "question",
        showCancelButton: true,
        customClass: {
          cancelButton: "popup-btn fu-btn-sm",
          confirmButton: "popup-btn fu-btn-sm",
        },
        confirmButtonText: "Yes, restore it!",
      }).then((result) => {
        if (result.isConfirmed) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              getUsers(
                "GET",
                `${baseUrl}fm-users`,
                {
                  page_num: meta.from === meta.to && meta.last_page > 1 ? meta.current_page - 1 : meta.last_page < pageNum ? 1 : pageNum,
                  per_page: perPage,
                  order_type: orderType,
                  order_by: orderBy,
                  ...filtrationDetails,
                },
                setLoading,
                `${currentUser.name} Restored Successfully`
              );
            })
            .catch((error) => {
              navigate("/not-found");
            });
        }
      });
    } else {
      navigate("/not-found");
    }
  };

  const forceDeleteUser = (reqMethod, pathName, headers, setLoading) => {
    if (userCan("fm_user_force_delete")) {
      Swal.fire({
        title: "Are you sure?",
        html: `<p class="mb-0">You will delete <span class="fu-text-light-gray">${currentUser.name}</span> permanently</p><p class="mb-0">You won't be able to revert this!</p>`,
        icon: "warning",
        showCancelButton: true,
        customClass: {
          cancelButton: "popup-btn fu-btn-sm",
          confirmButton: "popup-btn fu-btn-sm",
        },
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              getUsers(
                "GET",
                `${baseUrl}fm-users`,
                {
                  page_num: meta.from === meta.to && meta.last_page > 1 ? meta.current_page - 1 : meta.last_page < pageNum ? 1 : pageNum,
                  per_page: perPage,
                  order_type: orderType,
                  order_by: orderBy,
                  ...filtrationDetails,
                },
                setLoading,
                response.data.msg && response.data.msg.includes("successfully") ? `${currentUser.name} Deleted Permanently Successfully` : response.data.msg && response.data.msg.includes("Unable") ? `Unable To Delete ${currentUser.name}` : null
              );
            })
            .catch((error) => {
              navigate("/not-found");
            });
        }
      });
    } else {
      navigate("/not-found");
    }
  };

  return (
    <Reveal>
      <div className="row py-3 align-items-center row-brdr">
        {/* id */}
        <div className="col-12 col-xxl-1 order-2 order-xxl-first">
          <h4 className="h6 mb-0 my-xxl-0 fu-text-white row-info">
            <span className="fw-bold d-d-inline-block d-xxl-none me-2">#ID:</span>
            {currentUser._id}
          </h4>
        </div>
        {/* key */}
        <div className="col-12 col-xxl-4 order-3 order-xxl-1">
          <h4 className="h6 mb-0 my-xxl-0 fu-text-white row-info">
            <span className="fw-bold d-d-inline-block d-xxl-none me-2">KEY:</span>
            {currentUser.key}
          </h4>
        </div>
        {/* name */}
        <div className="col-12 col-xxl-3 order-first order-xxl-2">
          <div className="d-flex align-items-center mouse-pointer mb-3 mt-2 my-xxl-0" onClick={() => goToBlogDetails(`/fm-users/${currentUser.key}`, currentUser.deleted_at ? "1" : "0")}>
            <div className="d-inline-flex">{currentUser.is_logged_in_now ? <span className="d-inline-flex online-circle" data-title="online"></span> : <span className="d-inline-flex offline-circle" data-title="offline"></span>}</div>
            {currentUser.user_role === "super_admin" ? (
              <div className="super-admin-circle ms-2" data-title="super admin">
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="super-admin-crown">
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path d="M4 19H20M11.2929 5.70711L8.70711 8.2929C8.31658 8.68342 7.68342 8.68342 7.29289 8.2929L5.70711 6.70711C5.07714 6.07714 4 6.52331 4 7.41422V15C4 15.5523 4.44772 16 5 16H19C19.5523 16 20 15.5523 20 15V7.41421C20 6.52331 18.9229 6.07714 18.2929 6.70711L16.7071 8.2929C16.3166 8.68342 15.6834 8.68342 15.2929 8.2929L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711Z" stroke="#fdb915" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  </g>
                </svg>
              </div>
            ) : (
              ""
            )}
            {currentUser.user_status && currentUser.user_status !== "active" ? (
              <div className="blocked-user-circle ms-2" data-title="blocked user">
                <svg viewBox="0 0 24 24" id="meteor-icon-kit__regular-blocked-circle" fill="none" xmlns="http://www.w3.org/2000/svg" className="blocked-user">
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM16.2929 6.29289C16.6834 5.90237 17.3166 5.90237 17.7071 6.29289C18.0976 6.68342 18.0976 7.31658 17.7071 7.70711L7.70711 17.7071C7.31658 18.0976 6.68342 18.0976 6.29289 17.7071C5.90237 17.3166 5.90237 16.6834 6.29289 16.2929L16.2929 6.29289Z"
                      fill="#fdb915"></path>
                  </g>
                </svg>
              </div>
            ) : (
              ""
            )}
            <h4 className="h6 mb-0 px-2 fu-text-white line-clamp-1">{currentUser.name}</h4>
          </div>
        </div>
        {/* operation */}
        <div className="col-12 col-xxl-4 order-4 order-xxl-3">
          <div className="d-flex align-items-center flex-wrap justify-content-center justify-content-xxl-start mt-3 mt-xxl-0">
            <div className="me-2 mb-2 mb-xxl-0">
              <Link to={`/fm-users/${currentUser.key}?archived=${currentUser.deleted_at ? "1" : "0"}`} className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`} disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                <span className="btn-bg"></span>
                Show
              </Link>
            </div>
            {currentUser.deleted_at === null ? (
              userCan("fm_user_update") ? (
                <div className="me-2 mb-2 mb-xxl-0">
                  <Link to={`/fm-users/update/${currentUser.key}`} className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`} disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                    <span className="btn-bg"></span>
                    Edit
                  </Link>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {currentUser.deleted_at === null ? (
              userCan("fm_user_soft_delete") ? (
                <div className="me-2 mb-2 mb-xxl-0">
                  <button
                    className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`}
                    onClick={() =>
                      softDeleteUser(
                        "DELETE",
                        `${baseUrl}fm-users/delete/${currentUser.key}`,
                        {
                          Accept: "application/json",
                          "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                          Authorization: `Bearer ${user.accessToken}`,
                        },
                        setIsSoftDeleteLoading
                      )
                    }
                    disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                    <span className="btn-bg"></span>
                    Delete
                  </button>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {currentUser.deleted_at !== null ? (
              userCan("fm_user_restore_trashed") ? (
                <div className="me-2 mb-2 mb-xxl-0">
                  <button
                    className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`}
                    onClick={() =>
                      restoreUser(
                        "PATCH",
                        `${baseUrl}fm-users/restore/${currentUser.key}`,
                        {
                          Accept: "application/json",
                          "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                          Authorization: `Bearer ${user.accessToken}`,
                        },
                        setIsRestoreLoading
                      )
                    }
                    disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                    <span className="btn-bg"></span>
                    Restore
                  </button>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {userCan("fm_user_force_delete") ? (
              <div className="me-2 mb-2 mb-xxl-0">
                <button
                  className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`}
                  onClick={() =>
                    forceDeleteUser(
                      "DELETE",
                      `${baseUrl}fm-users/force-delete/${currentUser.key}`,
                      {
                        Accept: "application/json",
                        "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                        Authorization: `Bearer ${user.accessToken}`,
                      },
                      setIsForceDeleteLoading
                    )
                  }
                  disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                  <span className="btn-bg"></span>
                  Force Delete
                </button>
              </div>
            ) : (
              ""
            )}

            {isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? (
              <div className="position-relative ms-2 mb-2 mb-xxl-0">
                <LoadingCircle circleCont={28} icon={23} loader={28} dot={28} />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </Reveal>
  );
};

export default UserRow;
