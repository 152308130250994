import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { fmDataContext } from "../../Context/FmData";
import Joi from "joi";
import { useParams } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { authenticationContext } from "../../Context/AuthContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const UpdateRole = () => {
  let [mounted, setMounted] = useState(false);

  let { roleId } = useParams();
  let { baseUrl, navigate, showData, globalCrumbs, setGlobalCrumbs, getData } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  let [currentRole, setCurrentRole] = useState({});
  let [isRoleLoading, setIsRoleLoading] = useState(true);

  const animatedComponents = makeAnimated();

  let [joiErrorsList, setJoiErrorsList] = useState([]);

  let [roleData, setRoleData] = useState({
    role_name: "",
    guard_name: "web",
    permissions: [],
  });

  let [isUpdateLoading, setIsUpdateLoading] = useState(false);

  let [updateResponse, setUpdateResponse] = useState({});

  let permissionsRef = useRef();
  let [selectedPermissions, setSelectedPermissions] = useState([]);

  let [isGetPermissionsLoading, setIsGetPermissionsLoading] = useState(true);
  let [permissions, setPermissions] = useState([]);

  let [navigateTimeOut, setNavigateTimeOut] = useState("");

  let userToken = jwtDecode(localStorage.getItem("userToken")).accessToken;

  const getAllPermissions = (reqMethod, pathName, params, setLoading = setIsGetPermissionsLoading, headers) => {
    getData(reqMethod, pathName, params, setLoading, headers)
      .then((response) => {
        setLoading(false);
        setPermissions(response.data.data);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const getRole = (reqMethod, pathName, setLoading = setIsRoleLoading, headers) => {
    headers = {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
      Authorization: `Bearer ${jwtDecode(localStorage.getItem("userToken")).accessToken}`,
    };
    showData(reqMethod, pathName, setLoading, headers)
      .then((response) => {
        let roleResponse = response.data.data;
        if (response.data.data.name === "super_admin") {
          navigate("/not-found");
        }
        setCurrentRole(roleResponse);
        setRoleData({
          role_name: roleResponse.name ? roleResponse.name : "",
          guard_name: "web",
          permissions: roleResponse.role_permissions ? roleResponse.role_permissions.map((permission) => permission.name) : [],
        });

        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const getRoleData = (e) => {
    const role = { ...roleData };
    role[e.target.name] = e.target.value;
    setRoleData(role);
  };

  const handlePermissionsSelect = () => {
    const role = { ...roleData };
    role.permissions = selectedPermissions.map((permission) => permission.value);
    setRoleData(role);
  };

  const selectAllPermissions = () => {
    let allPermissions = permissions.map((permission) => ({ value: permission.name, label: permission.name.replaceAll("_", " ") }));
    permissionsRef.current.setValue(allPermissions);
    setSelectedPermissions(allPermissions);
  };

  const updateItem = async (e) => {
    e.preventDefault();
    if (userCan("fm_settings_update")) {
      const validationResult = validateUpdateRoleForm();
      setUpdateResponse({});
      if (validationResult.error) {
        validationResult.error ? setJoiErrorsList(validationResult.error.details) : setJoiErrorsList([]);
      } else {
        setIsUpdateLoading(true);
        setJoiErrorsList([]);

        await axios({
          method: "POST",
          url: `${baseUrl}fm-roles/update/${currentRole.key}`,
          data: roleData,
          params: {
            _method: "PUT",
          },
          headers: {
            Accept: "application/json",
            "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
            Authorization: `Bearer ${user.accessToken}`,
          },
        })
          .then((response) => {
            setUpdateResponse(response.data);
            setIsUpdateLoading(false);

            if (response.data.msg && response.data.msg.includes("successfully")) {
              let timeOut = setTimeout(() => {
                setUpdateResponse({});
                navigate(`/fm-roles/${currentRole.key}`);
              }, 4000);
              setNavigateTimeOut(timeOut);
              setRoleData({
                role_name: "",
                guard_name: "web",
                permissions: [],
              });
              permissionsRef.current.clearValue();
              e.target.reset();
              toast(`${response.data.msg}, navigating...`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                containerId: "update_done_toast",
              });
            } else if (response.data.msg && response.data.msg.includes("failed")) {
              toast(response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          })
          .catch((errors) => {
            navigate("/not-found");
          });
      }
    } else {
      navigate("/not-found");
    }
  };

  const validateUpdateRoleForm = () => {
    const scheme = Joi.object({
      role_name: Joi.string().min(1).max(255).required(),
      guard_name: Joi.string().min(1).max(255).allow(null, "").optional(),
      permissions: Joi.array().items(Joi.string().required()).required(),
    });
    return scheme.validate(roleData, { abortEarly: false });
  };

  useEffect(() => {
    getRole("GET", `${baseUrl}fm-role/${roleId}`, setIsRoleLoading);
    getAllPermissions("GET", `${baseUrl}fm-permissions`, null, setIsGetPermissionsLoading, {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
      Authorization: `Bearer ${userToken}`,
    });
    setMounted(true);
  }, []);

  useEffect(() => {
    return () => {
      if (mounted) {
        clearTimeout(navigateTimeOut);
      }
    };
  }, [navigateTimeOut]);

  useEffect(() => {
    if (mounted && currentRole) {
      let globalCrumbsUpdated = globalCrumbs.map((crumb) => {
        if (crumb.name === roleId.toUpperCase()) {
          crumb.name = currentRole.name.toUpperCase();
        }
        return crumb;
      });
      setGlobalCrumbs(globalCrumbsUpdated);
      if (permissionsRef.current && currentRole.role_permissions) {
        permissionsRef.current.setValue(currentRole.role_permissions.map((permission) => ({ value: permission.name, label: permission.name.replaceAll("_", " ") })));
      }
    }
  }, [currentRole]);

  useEffect(() => {
    handlePermissionsSelect();
  }, [selectedPermissions]);

  return (
    <>
      {isRoleLoading || isGetPermissionsLoading ? (
        <LoadingScreen />
      ) : (
        <div className="container position-relative pt-3 pt-md-4">
          <div className="mb-3 mb-md-4">
            <h1 className="fu-text-3-rem fu-text-3-rem-md fu-text-md-2-rem fu-text-sm-2-rem fu-text-1-8-rem-xs fu-text-18-px-xxs fu-text-16-px-xxs fu-text-white fu-fw-800 mb-0">Update Role</h1>
          </div>
          <div className="py-2 mb-4 iu-row-heading create-submit-row">
            <h2 className="mb-0 row-title">Edit ( {currentRole.name} ) data</h2>
          </div>
          <div className="row">
            <div>
              <div>
                <Form onSubmit={updateItem}>
                  <Row>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="roleName">
                        <Form.Label className="fu-text-light-gray" data-title="required">
                          Role Title
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Role Name" className="fu-input" name="role_name" value={roleData.role_name ? roleData.role_name : ""} onInput={getRoleData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "role_name" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("role_name", "role name")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <div className="mb-3">
                        <div className="fu-text-light-gray w-fit-content mb-2" data-title="required">
                          Role Permissions
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </div>
                        <div className="row align-items-center gy-2 g-md-0 justify-content-evenly">
                          <div className="col-sm-12 col-md-10 col-xxl-11">
                            <div className="select-options pe-md-2">
                              <Select
                                ref={permissionsRef}
                                classNames={{
                                  control: () => "select-filtration",
                                  valueContainer: ({ innerProps }) => {
                                    innerProps.style = { display: "flex", flexWrap: "nowrap" };
                                  },
                                }}
                                styles={{
                                  multiValue: () => ({ overflow: "visible", width: "max-content", display: "inline-flex", alignItems: "center", backgroundColor: "#2f2f2f", margin: "0 2px", borderRadius: "3px", fontSize: "14px", padding: "0 0 0 2px" }),
                                  multiValueLabel: () => ({ color: "#fdb915" }),
                                  multiValueRemove: () => ({ color: "#a5a5a5", marginLeft: "3px", padding: "2px", display: "inline-flex", alignItems: "center", transition: "all 0.3s", ":hover": { color: "#fdb915" } }),
                                }}
                                placeholder="Select Role Permissions...."
                                name="rolePermissions"
                                onChange={setSelectedPermissions}
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                options={permissions.map((permission) => ({ value: permission.name, label: permission.name.replaceAll("_", " ") }))}
                              />
                            </div>
                          </div>
                          <div className="col-sm-3 col-md-2 col-xxl-1">
                            <div className="w-100 text-center">
                              <button type="button" className="fu-btn-sm fu-btn-gray w-max" onClick={() => selectAllPermissions()}>
                                <span className="btn-bg"></span>
                                Select All
                              </button>
                            </div>
                          </div>
                        </div>

                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "permissions" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </div>
                    </Col>
                  </Row>

                  {updateResponse.errors
                    ? Object.keys(updateResponse.errors).map((key) =>
                        updateResponse.errors[key].map((error, index) => (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error}
                          </div>
                        ))
                      )
                    : ""}

                  <div className="d-flex align-items-center mt-4">
                    <div>
                      <Button className={`fu-btn fu-btn-gray ${isUpdateLoading ? "disabled" : ""}`} type="submit" disabled={isUpdateLoading ? true : false}>
                        <span className="btn-bg"></span>
                        Submit<i className="bi bi-send ms-2"></i>
                      </Button>
                    </div>
                    {isUpdateLoading ? (
                      <div className="position-relative ms-3">
                        <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateRole;
