import React, { useContext, useEffect, useRef, useState } from "react";
import { fmDataContext } from "../../Context/FmData";
import Pagination from "../Pagination/Pagination";
import { Link } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import UserRow from "./UserRow";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { fmUsersContext } from "../../Context/UsersContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";
import UsersFilration from "./UsersFilration";
import jwtDecode from "jwt-decode";

const Users = () => {
  const isCalledRef = useRef(false);

  let { baseUrl, navigate, getData } = useContext(fmDataContext);

  let [users, setUsers] = useState({});

  let [isGetUsersLoading, setIsGetUsersLoading] = useState(true);

  let [isPaginateLoading, setIsPaginateLoading] = useState(false);

  let [isGetRolesLoading, setIsGetRolesLoading] = useState(true);
  let [roles, setRoles] = useState([]);

  let [pageNum, setPageNum] = useState(1);

  let perPage = 20;
  let orderBy = "id";
  let orderType = "asc";

  let userToken = jwtDecode(localStorage.getItem("userToken")).accessToken;

  let { userMsg, setUserMsg, filtrationDetails, isFiltrationLoading, setFiltrationDetails } = useContext(fmUsersContext);

  const getAllRoles = (reqMethod, pathName, params = null, setLoading = setIsGetRolesLoading) => {
    getData(reqMethod, pathName, params, setLoading, {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
      Authorization: `Bearer ${userToken}`,
    })
      .then((response) => {
        setLoading(false);
        setRoles(response.data.data);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const getUsers = (reqMethod, pathName, params, setLoading = setIsGetUsersLoading, message = null) => {
    getData(reqMethod, pathName, params, setLoading, {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
      Authorization: `Bearer ${userToken}`,
    })
      .then((response) => {
        setUsers(response.data);
        setLoading(false);

        if (userMsg.length > 0) {
          toast(userMsg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
            onClose: () => setUserMsg(""),
          });
        }

        if (message) {
          toast(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    if (!isCalledRef.current) {
      getUsers(
        "GET",
        `${baseUrl}fm-users`,
        {
          page_num: pageNum,
          per_page: perPage,
          order_type: orderType,
          order_by: orderBy,
        },
        setIsGetUsersLoading
      );
      getAllRoles("GET", `${baseUrl}fm-all-roles`, null, setIsGetRolesLoading);
    }
    return () => {
      isCalledRef.current = true;
      setFiltrationDetails({
        search_query: "",
        only_trashed: 0,
        role_key: null,
        user_status: null,
      });
    };
  }, []);

  return (
    <>
      <div className="container">
        <div className={`${!isGetUsersLoading ? "min-vh-100" : ""}`}>
          <div className="mb-2 mb-sm-5">
            <div className="d-flex flex-wrap flex-sm-row align-items-center justify-content-between mb-3 mt-3">
              <h1 className="page-heading-title mb-0">USERS</h1>
              {userCan("fm_user_create") ? (
                <div className="me-3">
                  <Link to="/fm-users/create" className="fu-btn fu-btn-gray">
                    <span className="btn-bg"></span>
                    Create New
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
            {/* ----------------------------------------------------- */}
            <div className="mt-4">
              <UsersFilration getUsers={getUsers} roles={roles} perPage={perPage} orderBy={orderBy} orderType={orderType} />
            </div>
            {/* ----------------------------------------------------- */}
          </div>
          {!isGetUsersLoading || !isGetRolesLoading ? (
            <>
              <div className="row py-3 d-none d-xxl-flex table-heading-brdr">
                <div className="col-md-1">
                  <h3 className="h6 mb-0 fu-text-white">#ID</h3>
                </div>
                <div className="col-md-4">
                  <h3 className="h6 mb-0 fu-text-white">KEY</h3>
                </div>
                <div className="col-md-3">
                  <h3 className="h6 mb-0 fu-text-white">USER NAME</h3>
                </div>
                <div className="col-md-4">
                  <h3 className="h6 mb-0 fu-text-white">OPERATIONS</h3>
                </div>
              </div>
              <div className="table-heading-brdr mt-4 mt-sm-0 d-xxl-none"></div>
              {isPaginateLoading ? (
                <div className="d-flex justify-content-center mt-5">
                  <div className="position-relative mt-5">
                    <LoadingCircle circleCont={85} icon={80} loader={85} dot={85} />
                  </div>
                </div>
              ) : (
                <>
                  {users.data && users.data.length > 0 ? (
                    users.data.map((user, index) => <UserRow key={index} meta={users.meta} currentUser={user} index={index} getUsers={getUsers} perPage={perPage} pageNum={pageNum} orderBy={orderBy} orderType={orderType} />)
                  ) : users.data && users.data.length === 0 && filtrationDetails.search_query === "" && filtrationDetails.only_trashed === 0 && filtrationDetails.role_key === null && filtrationDetails.user_status === null && !isFiltrationLoading ? (
                    <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                      <div>
                        <h2 className="fu-text-3-rem fu-text-yellow">
                          <i className="bi bi-database-add"></i>
                        </h2>
                      </div>
                      <div>
                        <h3 className="h5 fu-text-light-gray">
                          There Are No users
                          {userCan("fm_user_create") ? (
                            <>
                              ,
                              <Link to="/fm-users/create" className="link-dashed ms-2">
                                Create New
                              </Link>
                            </>
                          ) : (
                            ""
                          )}
                        </h3>
                      </div>
                    </div>
                  ) : !isFiltrationLoading ? (
                    <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                      <div>
                        <h2 className="fu-text-3-rem fu-text-yellow">
                          <i className="bi bi-search"></i>
                        </h2>
                      </div>
                      <div>
                        <h3 className="h5 fu-text-light-gray">There are no users matching your search</h3>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="mt-5">{users ? users.meta && users.meta.last_page !== 1 ? <Pagination pages={users.meta.links} pagination={users.meta} getItems={getUsers} pathName={"fm-users"} filtrationDetails={filtrationDetails} setIsPaginateLoading={setIsPaginateLoading} setPageNum={setPageNum} per_page={perPage} order_by={orderBy} order_type={orderType} /> : "" : ""}</div>
                </>
              )}
            </>
          ) : (
            <LoadingScreen />
          )}
        </div>
      </div>
    </>
  );
};

export default Users;
