import React, { createContext, useState } from "react";

export let fmFuturisingDataContext = createContext(0);

const FuturisingDataContext = ({ children }) => {
  let [futurisingDataMsg, setFuturisingDataMsg] = useState("");

  let fmFuturisingDataLifting = {
    futurisingDataMsg,
    setFuturisingDataMsg,
  };

  return (
    <>
      <fmFuturisingDataContext.Provider value={{ ...fmFuturisingDataLifting }}>{children}</fmFuturisingDataContext.Provider>
    </>
  );
};

export default FuturisingDataContext;
