import React, { useContext, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { fmDataContext } from "../../Context/FmData";
import Joi from "joi";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { authenticationContext } from "../../Context/AuthContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";

const CreateGenre = () => {
  let { baseUrl, navigate } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  let [joiErrorsList, setJoiErrorsList] = useState([]);

  let [genreData, setGenreData] = useState({
    title: "",
    user_id: null,
  });

  let [isCreateLoading, setIsCreateLoading] = useState(false);

  let [createResponse, setCreateResponse] = useState({});

  const getGenreData = (e) => {
    const genre = { ...genreData };
    genre[e.target.name] = e.target.value;
    setGenreData(genre);
  };

  const createItem = async (e) => {
    e.preventDefault();

    if (userCan("fm_genre_create")) {
      genreData.user_id = user.currentUser._id;
      const validationResult = validateCreateGenreForm();
      setCreateResponse({});

      if (validationResult.error) {
        validationResult.error ? setJoiErrorsList(validationResult.error.details) : setJoiErrorsList([]);
      } else {
        setIsCreateLoading(true);
        setJoiErrorsList([]);
        await axios({
          method: "POST",
          url: `${baseUrl}fm-genres/create`,
          data: genreData,
          headers: {
            Accept: "application/json",
            "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
            Authorization: `Bearer ${user.accessToken}`,
          },
        })
          .then((response) => {
            setCreateResponse(response.data);
            setIsCreateLoading(false);
            if (response.data.msg && response.data.msg.includes("successfully")) {
              setGenreData({
                title: "",
                user_id: null,
              });
              e.target.reset();
              setCreateResponse({});
              toast(response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            } else if (response.data.msg && response.data.msg.includes("failed")) {
              toast(response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          })
          .catch((errors) => {
            navigate("/not-found");
          });
      }
    } else {
      navigate("/not-found");
    }
  };

  const validateCreateGenreForm = () => {
    const scheme = Joi.object({
      title: Joi.string().min(1).max(255).required(),
      user_id: Joi.number(),
    });
    return scheme.validate(genreData, { abortEarly: false });
  };

  return (
    <>
      <div className="container position-relative pt-3 pt-md-4">
        <div className="mb-3 mb-md-4">
          <h1 className="page-heading-title mb-0">Create Genre</h1>
        </div>
        <div className="py-2 mb-4 iu-row-heading create-submit-row">
          <h2 className="mb-0 row-title">Submit New Genre To Genres List</h2>
        </div>
        <div className="row">
          <div>
            <div>
              <Form onSubmit={createItem}>
                <Row>
                  <Col sm={12}>
                    <Form.Group className="mb-3" controlId="genreName">
                      <Form.Label className="fu-text-light-gray" data-title="required">
                        Genre Title
                        <span className="required-input mx-1" data-title="required">
                          *
                        </span>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Genre Title" className="fu-input" name="title" onInput={getGenreData} />
                      {joiErrorsList.map((error, index) =>
                        error.path[0] === "title" ? (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error.message.replace("t", "Genre t")}
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                {/* ------------------------------------ */}
                {createResponse.errors
                  ? Object.keys(createResponse.errors).map((key) =>
                      createResponse.errors[key].map((error, index) => (
                        <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                          {error}
                        </div>
                      ))
                    )
                  : ""}
                <div className="d-flex align-items-center mt-4">
                  <div>
                    <Button className={`fu-btn fu-btn-gray ${isCreateLoading ? "disabled" : ""}`} type="submit" disabled={isCreateLoading ? true : false}>
                      <span className="btn-bg"></span>
                      Submit<i className="bi bi-send ms-2"></i>
                    </Button>
                  </div>
                  {isCreateLoading ? (
                    <div className="position-relative ms-3">
                      <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateGenre;
