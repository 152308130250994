import React, { useContext, useEffect, useRef, useState } from "react";
import { fmDataContext } from "../../Context/FmData";
import { Link } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import FuturisingDataRow from "./FuturisingDataRow";
import { fmFuturisingDataContext } from "../../Context/FuturisingDataContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";

const FuturisingData = () => {
  const isCalledRef = useRef(false);

  let { baseUrl, navigate, getData } = useContext(fmDataContext);

  let [futurisingData, setFuturisingData] = useState({});

  let [isGetFuturisingDataLoading, setIsGetFuturisingDataLoading] = useState(true);

  let { futurisingDataMsg, setFuturisingDataMsg } = useContext(fmFuturisingDataContext);

  const getFuturisingData = (reqMethod, pathName, setLoading = setIsGetFuturisingDataLoading, message = null, params = null) => {
    getData(reqMethod, pathName, params, setLoading)
      .then((response) => {
        setFuturisingData(response.data);
        setLoading(false);

        if (futurisingDataMsg.length > 0) {
          toast(futurisingDataMsg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
            onClose: () => setFuturisingDataMsg(""),
          });
        }

        if (message) {
          toast(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    if (!isCalledRef.current) {
      getFuturisingData("GET", `${baseUrl}fm-futurising-data`, setIsGetFuturisingDataLoading);
    }

    return () => {
      isCalledRef.current = true;
    };
  }, []);

  return (
    <>
      <div className="container">
        <div className={`${!isGetFuturisingDataLoading ? "min-vh-100" : ""}`}>
          <div className="mb-4">
            <div className="d-flex flex-column align-items-center justify-content-between mb-3 mt-3">
              <h1 className="fu-text-3-rem fu-text-3-rem-md fu-text-md-2-rem fu-text-sm-2-rem fu-text-1-8-rem-xs fu-text-18-px-xxs fu-text-16-px-xxs fu-text-white fu-fw-800 mb-2">FUTURISING MUSIC DATA</h1>
              {userCan("fm_futurising_data_create") ? (
                <div className="me-3">
                  <Link to="/fm-futurising-data/create" className="fu-btn fu-btn-gray">
                    <span className="btn-bg"></span>
                    Create New
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {!isGetFuturisingDataLoading ? (
            <>
              <div className="row py-3 d-none d-xxl-flex table-heading-brdr">
                <div className="col-md-1">
                  <h3 className="h6 mb-0 fu-text-white">#ID</h3>
                </div>
                <div className="col-md-4">
                  <h3 className="h6 mb-0 fu-text-white">KEY</h3>
                </div>
                <div className="col-md-3">
                  <h3 className="h6 mb-0 fu-text-white">INFO</h3>
                </div>
                <div className="col-md-4">
                  <h3 className="h6 mb-0 fu-text-white">OPERATIONS</h3>
                </div>
              </div>
              <div className="table-heading-brdr mt-4 mt-sm-0 d-xxl-none"></div>
              {futurisingData.data && futurisingData.data.length > 0 ? (
                futurisingData.data.map((singleRecord, index) => <FuturisingDataRow key={index} futurisingData={singleRecord} index={index} getFuturisingData={getFuturisingData} />)
              ) : futurisingData.data && futurisingData.data.length === 0 ? (
                <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                  <div>
                    <h2 className="fu-text-3-rem fu-text-yellow">
                      <i className="bi bi-database-add"></i>
                    </h2>
                  </div>
                  <div>
                    <h3 className="h5 fu-text-light-gray">
                      Futurising Data is Empty
                      {userCan("fm_futurising_data_create") ? (
                        <>
                          ,
                          <Link to="/fm-futurising-data/create" className="link-dashed ms-2">
                            Create New
                          </Link>
                        </>
                      ) : (
                        ""
                      )}
                    </h3>
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <LoadingScreen />
          )}
        </div>
      </div>
    </>
  );
};

export default FuturisingData;
