import React, { useContext, useEffect, useRef, useState } from "react";
import { fmDataContext } from "../../Context/FmData";
import { Link } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import TfReleaseRow from "./TfReleaseRow";
import { fmTfReleasesContext } from "../../Context/TfReleasesContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";

const TFReleases = () => {
  const isCalledRef = useRef(false);

  let { baseUrl, navigate, getData } = useContext(fmDataContext);

  let { tfReleaseMsg, setTfReleaseMsg } = useContext(fmTfReleasesContext);

  let [tfReleases, setTfReleases] = useState({});

  let [isGetTfReleasesLoading, setIsGetTfReleasesLoading] = useState(true);

  let orderBy = "order";
  let orderType = "asc";

  const getTfReleases = (reqMethod, pathName, params, setLoading = setIsGetTfReleasesLoading, message = null) => {
    getData(reqMethod, pathName, params, setLoading)
      .then((response) => {
        if (response.data.data) {
          response.data.data.forEach((tfRelease) => {
            if (tfRelease.release_data.release_image.includes("via.placeholder.com")) {
              tfRelease.release_data.release_image = tfRelease.release_data.release_image.replace("http://127.0.0.1:8000/storage/", "");
            }
          });
        }
        setTfReleases(response.data);
        setLoading(false);
        if (tfReleaseMsg.length > 0) {
          toast(tfReleaseMsg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
            onClose: () => setTfReleaseMsg(""),
          });
        }

        if (message) {
          toast(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    if (!isCalledRef.current) {
      getTfReleases(
        "GET",
        `${baseUrl}fm-top-five-releases`,
        {
          order_type: orderType,
          order_by: orderBy,
        },
        setIsGetTfReleasesLoading
      );
    }
    return () => {
      isCalledRef.current = true;
    };
  }, []);

  return (
    <>
      <div className="container">
        <div className={`${!isGetTfReleasesLoading ? "min-vh-100" : ""}`}>
          <div className="mb-3 mb-sm-5 mt-4">
            <div className="d-flex align-items-center justify-content-between">
              <h1 className="fu-text-3-rem fu-text-sm-2-rem fu-text-1-8-rem-xs fu-text-18-px-xxs fu-text-16-px-xxs fu-text-white fu-fw-800 mb-0">TOP 5 RELEASES</h1>
              {userCan("fm_top_five_releases_create") ? (
                <div className="me-3">
                  <Link to="/fm-top-five-releases/create" className="fu-btn fu-btn-gray">
                    <span className="btn-bg"></span>
                    Create New
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {!isGetTfReleasesLoading ? (
            <>
              <div className="row py-3 d-none d-xxl-flex table-heading-brdr">
                <div className="col-md-1">
                  <h3 className="h6 mb-0 fu-text-white">#ID</h3>
                </div>
                <div className="col-md-4">
                  <h3 className="h6 mb-0 fu-text-white">KEY</h3>
                </div>
                <div className="col-md-3">
                  <h3 className="h6 mb-0 fu-text-white">RELEASE TITLE</h3>
                </div>
                <div className="col-md-1">
                  <h3 className="h6 mb-0 fu-text-white">ORDER</h3>
                </div>
                <div className="col-md-2">
                  <h3 className="h6 mb-0 fu-text-white">OPERATIONS</h3>
                </div>
              </div>
              <div className="table-heading-brdr mt-4 mt-sm-0 d-xxl-none"></div>
              {tfReleases.data && tfReleases.data.length > 0 ? (
                tfReleases.data.map((tfRelease, index) => <TfReleaseRow key={index} tfRelease={tfRelease} index={index} getTfReleases={getTfReleases} orderBy={orderBy} orderType={orderType} />)
              ) : tfReleases.data && tfReleases.data.length === 0 ? (
                <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                  <div>
                    <h2 className="fu-text-3-rem fu-text-yellow">
                      <i className="bi bi-database-add"></i>
                    </h2>
                  </div>
                  <div>
                    <h3 className="h5 fu-text-light-gray">
                      There Are No Top Five Releases
                      {userCan("fm_top_five_releases_create") ? (
                        <>
                          ,
                          <Link to="/fm-top-five-releases/create" className="link-dashed ms-2">
                            Create New
                          </Link>
                        </>
                      ) : (
                        ""
                      )}
                    </h3>
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <LoadingScreen />
          )}
        </div>
      </div>
    </>
  );
};

export default TFReleases;
