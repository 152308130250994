import React, { createContext, useState } from "react";

export let fmNewsContext = createContext(0);

const NewsContext = ({ children }) => {
  let [newBlogMsg, setNewBlogMsg] = useState("");

  let [filtrationDetails, setFiltrationDetails] = useState({
    search_query: "",
    only_trashed: 0,
  });

  let [isFiltrationLoading, setIsFiltrationLoading] = useState(false);

  let fmNewsLifting = {
    newBlogMsg,
    setNewBlogMsg,
    filtrationDetails,
    setFiltrationDetails,
    isFiltrationLoading,
    setIsFiltrationLoading,
  };

  return (
    <>
      <fmNewsContext.Provider value={{ ...fmNewsLifting }}>{children}</fmNewsContext.Provider>
    </>
  );
};

export default NewsContext;
