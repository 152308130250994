import React from "react";
import { Outlet } from "react-router-dom";
import ScrollLayoutTop from "../ScrollLayoutTop/ScrollLayoutTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LoginLayout = () => {
  return (
    <>
      <ScrollLayoutTop>
        <div className="min-vh-100 position-relative overflow-x-hidden">
          <div className="toast-msg-container-parent">
            <ToastContainer className="toast-msg-container" />
          </div>
          <Outlet></Outlet>
        </div>
      </ScrollLayoutTop>
    </>
  );
};

export default LoginLayout;
