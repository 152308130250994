import React, { useContext, useEffect, useRef, useState } from "react";
import { fmDataContext } from "../../Context/FmData";
import Pagination from "../Pagination/Pagination";
import { Link } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import RoleRow from "./RoleRow";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { fmRolesContext } from "../../Context/RolesContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";
import RolesFiltration from "./RolesFiltration";
import jwtDecode from "jwt-decode";

const Roles = () => {
  const isCalledRef = useRef(false);

  let { baseUrl, navigate, getData } = useContext(fmDataContext);

  let [roles, setRoles] = useState({});

  let [isGetRolesLoading, setIsGetRolesLoading] = useState(true);

  let [isPaginateLoading, setIsPaginateLoading] = useState(false);

  let [pageNum, setPageNum] = useState(1);

  let perPage = 20;
  let orderBy = "id";
  let orderType = "asc";

  let userToken = jwtDecode(localStorage.getItem("userToken")).accessToken;

  let { roleMsg, setRoleMsg, filtrationDetails, isFiltrationLoading, setFiltrationDetails } = useContext(fmRolesContext);

  const getRoles = (reqMethod, pathName, params, setLoading = setIsGetRolesLoading, message = null) => {
    getData(reqMethod, pathName, params, setLoading, {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
      Authorization: `Bearer ${userToken}`,
    })
      .then((response) => {
        setRoles(response.data);
        setLoading(false);

        if (roleMsg.length > 0) {
          toast(roleMsg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
            onClose: () => setRoleMsg(""),
          });
        }

        if (message) {
          toast(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    if (!isCalledRef.current) {
      getRoles(
        "GET",
        `${baseUrl}fm-roles`,
        {
          page_num: pageNum,
          per_page: perPage,
          order_type: orderType,
          order_by: orderBy,
        },
        setIsGetRolesLoading
      );
    }
    return () => {
      isCalledRef.current = true;
      setFiltrationDetails({
        search_query: "",
      });
    };
  }, []);

  return (
    <>
      <div className="container">
        <div className={`${!isGetRolesLoading ? "min-vh-100" : ""}`}>
          <div className="mb-2 mb-md-4 mb-lg-5">
            <div className="d-flex flex-wrap flex-sm-row align-items-center justify-content-between mb-3 mt-3">
              <h1 className="page-heading-title mb-0">ROLES</h1>
              {userCan("fm_settings_create") ? (
                <div className="me-3">
                  <Link to="/fm-roles/create" className="fu-btn fu-btn-gray">
                    <span className="btn-bg"></span>
                    Create New
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="mt-4">
              <RolesFiltration getRoles={getRoles} perPage={perPage} orderBy={orderBy} orderType={orderType} />
            </div>
          </div>
          {!isGetRolesLoading ? (
            <>
              <div className="row py-3 d-none d-xxl-flex table-heading-brdr">
                <div className="col-md-1">
                  <h3 className="h6 mb-0 fu-text-white">#ID</h3>
                </div>
                <div className="col-md-4">
                  <h3 className="h6 mb-0 fu-text-white">KEY</h3>
                </div>
                <div className="col-md-3">
                  <h3 className="h6 mb-0 fu-text-white">ROLE NAME</h3>
                </div>
                <div className="col-md-4">
                  <h3 className="h6 mb-0 fu-text-white">OPERATIONS</h3>
                </div>
              </div>
              <div className="table-heading-brdr mt-4 mt-md-4 mt-xl-4 d-xxl-none"></div>
              {isPaginateLoading ? (
                <div className="d-flex justify-content-center mt-5">
                  <div className="position-relative mt-5">
                    <LoadingCircle circleCont={85} icon={80} loader={85} dot={85} />
                  </div>
                </div>
              ) : (
                <>
                  {roles.data && roles.data.length > 0 ? (
                    roles.data.map((role, index) => <RoleRow key={index} meta={roles.meta} role={role} index={index} getRoles={getRoles} perPage={perPage} pageNum={pageNum} orderBy={orderBy} orderType={orderType} />)
                  ) : roles.data && roles.data.length === 0 && filtrationDetails.search_query === "" && !isFiltrationLoading ? (
                    <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                      <div>
                        <h2 className="fu-text-3-rem fu-text-yellow">
                          <i className="bi bi-database-add"></i>
                        </h2>
                      </div>
                      <div>
                        <h3 className="h5 fu-text-light-gray">
                          There Are No Roles
                          {userCan("fm_settings_create") ? (
                            <>
                              ,
                              <Link to="/fm-roles/create" className="link-dashed ms-2">
                                Create New
                              </Link>
                            </>
                          ) : (
                            ""
                          )}
                        </h3>
                      </div>
                    </div>
                  ) : !isFiltrationLoading ? (
                    <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                      <div>
                        <h2 className="fu-text-3-rem fu-text-yellow">
                          <i className="bi bi-search"></i>
                        </h2>
                      </div>
                      <div>
                        <h3 className="h5 fu-text-light-gray">There are no roles matching your search</h3>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="mt-5">{roles ? roles.meta && roles.meta.last_page !== 1 ? <Pagination pages={roles.meta.links} pagination={roles.meta} getItems={getRoles} pathName={"fm-roles"} filtrationDetails={filtrationDetails} setIsPaginateLoading={setIsPaginateLoading} setPageNum={setPageNum} per_page={perPage} order_by={orderBy} order_type={orderType} /> : "" : ""}</div>
                </>
              )}
            </>
          ) : (
            <LoadingScreen />
          )}
        </div>
      </div>
    </>
  );
};

export default Roles;
