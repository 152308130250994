import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { fmDataContext } from "../../Context/FmData";
import Joi from "joi";
import { useParams } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { authenticationContext } from "../../Context/AuthContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";

const UpdateGenre = () => {
  let [mounted, setMounted] = useState(false);

  let { genreId } = useParams();
  let { baseUrl, navigate, showData, globalCrumbs, setGlobalCrumbs } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  let [currentGenre, setCurrentGenre] = useState({});
  let [isGenreLoading, setIsGenreLoading] = useState(true);

  let [joiErrorsList, setJoiErrorsList] = useState([]);

  let [genreData, setGenreData] = useState({
    title: "",
    modify_user_id: null,
  });

  let [isUpdateLoading, setIsUpdateLoading] = useState(false);

  let [updateResponse, setUpdateResponse] = useState({});

  let [navigateTimeOut, setNavigateTimeOut] = useState("");

  const getGenre = (reqMethod, pathName, setLoading = setIsGenreLoading, headers) => {
    headers = {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
      Authorization: `Bearer ${jwtDecode(localStorage.getItem("userToken")).accessToken}`,
    };
    showData(reqMethod, pathName, setLoading, headers)
      .then((response) => {
        let genreResponse = response.data.data;
        setCurrentGenre(genreResponse);
        setGenreData({
          title: genreResponse.genre_title ? genreResponse.genre_title : "",
          modify_user_id: null,
        });
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const getGenreData = (e) => {
    const genre = { ...genreData };
    genre[e.target.name] = e.target.value;
    setGenreData(genre);
  };

  const updateItem = async (e) => {
    e.preventDefault();
    if (userCan("fm_genre_update")) {
      genreData.modify_user_id = user.currentUser._id;

      const validationResult = validateUpdateGenreForm();
      setUpdateResponse({});

      if (validationResult.error) {
        validationResult.error ? setJoiErrorsList(validationResult.error.details) : setJoiErrorsList([]);
      } else {
        setIsUpdateLoading(true);
        setJoiErrorsList([]);

        await axios({
          method: "POST",
          url: `${baseUrl}fm-genres/update/${currentGenre.genre_key}`,
          data: genreData,
          params: {
            _method: "PUT",
          },
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
            Authorization: `Bearer ${user.accessToken}`,
          },
        })
          .then((response) => {
            setUpdateResponse(response.data);
            setIsUpdateLoading(false);

            if (response.data.msg && response.data.msg.includes("successfully")) {
              let timeOut = setTimeout(() => {
                setUpdateResponse({});
                navigate(`/fm-genres/${currentGenre.genre_key}?archived=0`);
              }, 4000);
              setNavigateTimeOut(timeOut);
              setGenreData({
                title: "",
                modify_user_id: null,
              });
              e.target.reset();
              toast(`${response.data.msg}, navigating...`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                containerId: "update_done_toast",
              });
            } else if (response.data.msg && response.data.msg.includes("failed")) {
              toast(response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          })
          .catch((errors) => {
            navigate("/not-found");
          });
      }
    } else {
      navigate("/not-found");
    }
  };

  const validateUpdateGenreForm = () => {
    const scheme = Joi.object({
      title: Joi.string().min(1).max(255).required(),
      modify_user_id: Joi.number(),
    });
    return scheme.validate(genreData, { abortEarly: false });
  };

  useEffect(() => {
    getGenre("GET", `${baseUrl}fm-genre/${genreId}`, setIsGenreLoading);
    setMounted(true);
  }, []);

  useEffect(() => {
    return () => {
      if (mounted) {
        clearTimeout(navigateTimeOut);
      }
    };
  }, [navigateTimeOut]);

  useEffect(() => {
    if (mounted && currentGenre) {
      let globalCrumbsUpdated = globalCrumbs.map((crumb) => {
        if (crumb.name === genreId.toUpperCase()) {
          crumb.name = currentGenre.genre_title.toUpperCase();
        }
        return crumb;
      });
      setGlobalCrumbs(globalCrumbsUpdated);
    }
  }, [currentGenre]);

  return (
    <>
      {isGenreLoading ? (
        <LoadingScreen />
      ) : (
        <div className="container position-relative pt-3 pt-md-4">
          <div className="mb-3 mb-md-4">
            <h1 className="page-heading-title mb-0">Update Genre</h1>
          </div>
          <div className="py-2 mb-4 iu-row-heading create-submit-row">
            <h2 className="mb-0 row-title">Edit ( {currentGenre.genre_title} ) data</h2>
          </div>
          <div className="row">
            <div>
              <div>
                <Form onSubmit={updateItem}>
                  <Row>
                    <Col sm={12}>
                      <Form.Group className="mb-3" controlId="genreName">
                        <Form.Label className="fu-text-light-gray" data-title="required">
                          Genre Title
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Genre Name" className="fu-input" name="title" value={genreData.title ? genreData.title : ""} onInput={getGenreData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "title" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("t", "Genre t")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  {updateResponse.errors
                    ? Object.keys(updateResponse.errors).map((key) =>
                        updateResponse.errors[key].map((error, index) => (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error}
                          </div>
                        ))
                      )
                    : ""}

                  <div className="d-flex align-items-center mt-4">
                    <div>
                      <Button className={`fu-btn fu-btn-gray ${isUpdateLoading ? "disabled" : ""}`} type="submit" disabled={isUpdateLoading ? true : false}>
                        <span className="btn-bg"></span>
                        Submit<i className="bi bi-send ms-2"></i>
                      </Button>
                    </div>
                    {isUpdateLoading ? (
                      <div className="position-relative ms-3">
                        <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateGenre;
