import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { fmDataContext } from "../../Context/FmData";
import Joi from "joi";
import { useParams } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { authenticationContext } from "../../Context/AuthContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";

const UpdateLabel = () => {
  let [mounted, setMounted] = useState(false);

  let { labelId } = useParams();
  let { baseUrl, navigate, showData, globalCrumbs, setGlobalCrumbs } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  let [currentLabel, setCurrentLabel] = useState({});
  let [isLabelLoading, setIsLabelLoading] = useState(true);

  let [selectedImage, setSelectedImage] = useState({});

  let [joiErrorsList, setJoiErrorsList] = useState([]);
  let [imageInputError, setImageInputError] = useState(null);

  let [labelData, setLabelData] = useState({
    name: "",
    about: null,
    image: selectedImage,
    modify_user_id: null,
    beatport_url: null,
    spotify_url: null,
    sound_cloud_url: null,
    youtube_url: null,
    facebook_url: null,
    instagram_url: null,
    threads_url: null,
    x_url: null,
    tiktok_url: null,
    linkedin_url: null,
    discord_url: null,
    website_url: null,
  });

  let [isUpdateLoading, setIsUpdateLoading] = useState(false);

  let [updateResponse, setUpdateResponse] = useState({});

  let [navigateTimeOut, setNavigateTimeOut] = useState("");

  const getLabel = (reqMethod, pathName, setLoading = setIsLabelLoading) => {
    showData(reqMethod, pathName, setLoading)
      .then((response) => {
        // -------------------- REMOVE BRFORE PRODUCTION ------------------------------------
        if (response.data.data.label_image.includes("via.placeholder.com")) {
          response.data.data.label_image = response.data.data.label_image.replace("http://127.0.0.1:8000/storage/", "");
        }
        if (response.data.data.label_releases !== null) {
          response.data.data.label_releases.forEach((release) => {
            if (release.release_image.includes("via.placeholder.com")) {
              release.release_image = release.release_image.replace("http://127.0.0.1:8000/storage/", "");
            }
          });
        }
        if (response.data.data.label_artists !== null) {
          response.data.data.label_artists.forEach((artist) => {
            if (artist.artist_image.includes("via.placeholder.com")) {
              artist.artist_image = artist.artist_image.replace("http://127.0.0.1:8000/storage/", "");
            }
          });
        }
        // -------------------- REMOVE BRFORE PRODUCTION ------------------------------------
        let labelResponse = response.data.data;
        setCurrentLabel(labelResponse);
        setLabelData({
          name: labelResponse.label_name ? labelResponse.label_name : "",
          about: labelResponse.label_bio ? labelResponse.label_bio.replaceAll("</br>", "\n") : null,
          image: selectedImage,
          modify_user_id: null,
          beatport_url: labelResponse.label_streaming_platforms_links && labelResponse.label_streaming_platforms_links.beatport_url ? labelResponse.label_streaming_platforms_links.beatport_url : null,
          spotify_url: labelResponse.label_streaming_platforms_links && labelResponse.label_streaming_platforms_links.spotify_url ? labelResponse.label_streaming_platforms_links.spotify_url : null,
          sound_cloud_url: labelResponse.label_streaming_platforms_links && labelResponse.label_streaming_platforms_links.sound_cloud_url ? labelResponse.label_streaming_platforms_links.sound_cloud_url : null,
          youtube_url: labelResponse.label_streaming_platforms_links && labelResponse.label_streaming_platforms_links.youtube_url ? labelResponse.label_streaming_platforms_links.youtube_url : null,
          facebook_url: labelResponse.label_social_media_links && labelResponse.label_social_media_links.facebook_url ? labelResponse.label_social_media_links.facebook_url : null,
          instagram_url: labelResponse.label_social_media_links && labelResponse.label_social_media_links.instagram_url ? labelResponse.label_social_media_links.instagram_url : null,
          threads_url: labelResponse.label_social_media_links && labelResponse.label_social_media_links.threads_url ? labelResponse.label_social_media_links.threads_url : null,
          x_url: labelResponse.label_social_media_links && labelResponse.label_social_media_links.x_url ? labelResponse.label_social_media_links.x_url : null,
          tiktok_url: labelResponse.label_social_media_links && labelResponse.label_social_media_links.tiktok_url ? labelResponse.label_social_media_links.tiktok_url : null,
          linkedin_url: labelResponse.label_social_media_links && labelResponse.label_social_media_links.linkedin_url ? labelResponse.label_social_media_links.linkedin_url : null,
          discord_url: labelResponse.label_social_media_links && labelResponse.label_social_media_links.discord_url ? labelResponse.label_social_media_links.discord_url : null,
          website_url: labelResponse.label_social_media_links && labelResponse.label_social_media_links.website_url ? labelResponse.label_social_media_links.website_url : null,
        });
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const getLabelData = (e) => {
    const label = { ...labelData };
    label[e.target.name] = e.target.value;
    setLabelData(label);
  };

  const handleImageSelect = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const updateItem = async (e) => {
    e.preventDefault();
    if (userCan("fm_label_update")) {
      labelData.modify_user_id = user.currentUser._id;
      const validationResult = validateUpdateLabelForm();
      let imageError = null;
      setUpdateResponse({});
      if (selectedImage && selectedImage.name) {
        labelData.image = selectedImage;
        if (!["image/png", "image/jpg", "image/jpeg", "image/gif"].includes(labelData.image.type)) {
          imageError = "File must be an Image and from These extensions (png, jpg, jpeg, gif)";
        } else if (labelData.image.size > 5 * 1024 * 1024) {
          imageError = "Image Size Must Be Less Than 5 MB";
        }
      } else {
        labelData.image = null;
      }
      if (validationResult.error || imageError) {
        validationResult.error ? setJoiErrorsList(validationResult.error.details) : setJoiErrorsList([]);
        setImageInputError(imageError);
      } else {
        setIsUpdateLoading(true);
        setImageInputError(null);
        setJoiErrorsList([]);
        labelData.about = labelData.about ? labelData.about.replaceAll("\n", "</br>") : null;
        await axios({
          method: "POST",
          url: `${baseUrl}fm-labels/update/${currentLabel.label_key}`,
          data: labelData,
          params: {
            _method: "PUT",
          },
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
            Authorization: `Bearer ${user.accessToken}`,
          },
        })
          .then((response) => {
            labelData.about = labelData.about ? labelData.about.replaceAll("</br>", "\n") : null;
            setUpdateResponse(response.data);
            setIsUpdateLoading(false);
            if (response.data.msg && response.data.msg.includes("successfully")) {
              let timeOut = setTimeout(() => {
                setUpdateResponse({});
                navigate(`/fm-labels/${currentLabel.label_key}?archived=0`);
              }, 4000);
              setNavigateTimeOut(timeOut);
              setLabelData({
                name: "",
                about: null,
                image: {},
                modify_user_id: null,
                beatport_url: null,
                spotify_url: null,
                sound_cloud_url: null,
                youtube_url: null,
                facebook_url: null,
                instagram_url: null,
                threads_url: null,
                x_url: null,
                tiktok_url: null,
                linkedin_url: null,
                discord_url: null,
                website_url: null,
              });
              setSelectedImage({});
              e.target.reset();
              toast(`${response.data.msg}, navigating...`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                containerId: "update_done_toast",
              });
            } else if (response.data.msg && response.data.msg.includes("failed")) {
              toast(response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          })
          .catch((errors) => {
            navigate("/not-found");
          });
      }
    } else {
      navigate("/not-found");
    }
  };

  const validateUpdateLabelForm = () => {
    const scheme = Joi.object({
      name: Joi.string().min(1).max(255).required(),
      about: Joi.string().min(1).max(1500).allow(null, "").optional(),
      image: Joi.any(),
      modify_user_id: Joi.number(),
      beatport_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Beatport URL is not valid").allow(null, "").optional(),
      spotify_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Spotify URL is not valid").allow(null, "").optional(),
      sound_cloud_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Sound cloud URL is not valid").allow(null, "").optional(),
      youtube_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Youtube URL is not valid").allow(null, "").optional(),
      facebook_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Facebook URL is not valid").allow(null, "").optional(),
      instagram_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Instagram URL is not valid").allow(null, "").optional(),
      threads_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("threads URL is not valid").allow(null, "").optional(),
      x_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("X URL is not valid").allow(null, "").optional(),
      tiktok_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Tiktok URL is not valid").allow(null, "").optional(),
      linkedin_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Linkedin URL is not valid").allow(null, "").optional(),
      discord_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Discord URL is not valid").allow(null, "").optional(),
      website_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Website URL is not valid").allow(null, "").optional(),
    });
    return scheme.validate(labelData, { abortEarly: false });
  };
  useEffect(() => {
    getLabel("GET", `${baseUrl}fm-label/${labelId}`, setIsLabelLoading);
    setMounted(true);
  }, []);

  useEffect(() => {
    return () => {
      if (mounted) {
        clearTimeout(navigateTimeOut);
      }
    };
  }, [navigateTimeOut]);

  useEffect(() => {
    if (mounted && currentLabel) {
      let globalCrumbsUpdated = globalCrumbs.map((crumb) => {
        if (crumb.name === labelId.toUpperCase()) {
          crumb.name = currentLabel.label_name.toUpperCase();
        }
        return crumb;
      });
      setGlobalCrumbs(globalCrumbsUpdated);
    }
  }, [currentLabel]);

  return (
    <>
      {isLabelLoading ? (
        <LoadingScreen />
      ) : (
        <div className="container position-relative pt-3 pt-md-4">
          <div className="mb-3 mb-md-4">
            <h1 className="page-heading-title mb-0">Update Label</h1>
          </div>
          <div className="py-2 mb-4 iu-row-heading create-submit-row">
            <h2 className="mb-0 row-title">Edit ( {currentLabel.label_name} ) data</h2>
          </div>
          <div className="row">
            <div>
              <div>
                <Form onSubmit={updateItem}>
                  <Row>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="labelName">
                        <Form.Label className="fu-text-light-gray" data-title="required">
                          Label Name
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Label Name" className="fu-input" name="name" value={labelData.name ? labelData.name : ""} onInput={getLabelData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "name" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("n", "Label n")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <Form.Group className="mb-3" controlId="labelImage">
                            <Form.Label className="fu-text-light-gray" data-title="required">
                              Label Image
                              <span className="required-input mx-1" data-title="required">
                                *
                              </span>
                            </Form.Label>
                            <Form.Control type="file" placeholder="choose Label Image" className="fu-input" name="image" onInput={handleImageSelect} />
                            {imageInputError !== null ? <div className="alert alert-danger py-1 px-2 mt-2 mb-0">{imageInputError}</div> : ""}
                          </Form.Group>
                        </div>
                        <div className="ms-2 align-self-center align-self-lg-center align-self-xl-baseline">
                          <div className="table-item-img-container update-img-show">
                            <img src={currentLabel.label_image} alt="artist" className="w-100 rounded-circle" />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Form.Group className="mb-3" controlId="aboutLabel">
                    <Form.Label className="fu-text-light-gray" data-title="optional">
                      About Label
                      <span className="optional-input mx-1" data-title="optional">
                        *
                      </span>
                    </Form.Label>
                    <div className="textarea-container">
                      <Form.Control as="textarea" rows={4} className="fu-input" placeholder="Enter your label bio" name="about" value={labelData.about ? labelData.about.replaceAll("</br>", "\n") : ""} onInput={getLabelData} autoComplete="on" />
                    </div>
                    {joiErrorsList.map((error, index) =>
                      error.path[0] === "about" ? (
                        <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                          {error.message}
                        </div>
                      ) : (
                        ""
                      )
                    )}
                  </Form.Group>
                  <Row>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="beatportUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Beatport URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Label Beatport URL" className="fu-input" name="beatport_url" value={labelData.beatport_url ? labelData.beatport_url : ""} onInput={getLabelData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "beatport_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="spotifyUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Spotify URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Label Spotify URL" className="fu-input" name="spotify_url" value={labelData.spotify_url ? labelData.spotify_url : ""} onInput={getLabelData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "spotify_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="soundCloudUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Sound Cloud URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Label Sound Cloud URL" className="fu-input" name="sound_cloud_url" value={labelData.sound_cloud_url ? labelData.sound_cloud_url : ""} onInput={getLabelData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "sound_cloud_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="youtubeUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          YouTube URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Label YouTube URL" className="fu-input" name="youtube_url" value={labelData.youtube_url ? labelData.youtube_url : ""} onInput={getLabelData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "youtube_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="facebookUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          FaceBook URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Label FaceBook URL" className="fu-input" name="facebook_url" value={labelData.facebook_url ? labelData.facebook_url : ""} onInput={getLabelData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "facebook_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="instagramUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Instagram URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Label Instagram URL" className="fu-input" name="instagram_url" value={labelData.instagram_url ? labelData.instagram_url : ""} onInput={getLabelData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "instagram_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="threadsUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Threads URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Label Threads URL" className="fu-input" name="threads_url" value={labelData.threads_url ? labelData.threads_url : ""} onInput={getLabelData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "threads_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="xUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          X URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Label X URL" className="fu-input" name="x_url" value={labelData.x_url ? labelData.x_url : ""} onInput={getLabelData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "x_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="tiktokUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          TikTok URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Label TikTok URL" className="fu-input" name="tiktok_url" value={labelData.tiktok_url ? labelData.tiktok_url : ""} onInput={getLabelData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "tiktok_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="linkedInUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          LinkedIn URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Label LinkedIn URL" className="fu-input" name="linkedin_url" value={labelData.linkedin_url ? labelData.linkedin_url : ""} onInput={getLabelData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "linkedin_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="discordUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Discord URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Label Discord URL" className="fu-input" name="discord_url" value={labelData.discord_url ? labelData.discord_url : ""} onInput={getLabelData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "discord_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="websiteUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Website URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Label Website URL" className="fu-input" name="website_url" value={labelData.website_url ? labelData.website_url : ""} onInput={getLabelData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "website_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  {updateResponse.errors
                    ? Object.keys(updateResponse.errors).map((key) =>
                        updateResponse.errors[key].map((error, index) => (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error}
                          </div>
                        ))
                      )
                    : ""}
                  <div className="d-flex align-items-center mt-4">
                    <div>
                      <Button className={`fu-btn fu-btn-gray ${isUpdateLoading ? "disabled" : ""}`} type="submit" disabled={isUpdateLoading ? true : false}>
                        <span className="btn-bg"></span>
                        Submit<i className="bi bi-send ms-2"></i>
                      </Button>
                    </div>
                    {isUpdateLoading ? (
                      <div className="position-relative ms-3">
                        <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateLabel;
