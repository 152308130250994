import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import MasterLayout from "./components/MasterLayout/MasterLayout";
import Home from "./components/Home/Home";
import News from "./components/News/News";
import NewDetails from "./components/NewDetails/NewDetails";
import Artists from "./components/Artists/Artists";
import ArtistDetails from "./components/ArtistDetails/ArtistDetails";
import Releases from "./components/Releases/Releases";
import ReleaseDetails from "./components/ReleaseDetails/ReleaseDetails";
import Labels from "./components/Labels/Labels";
import LabelDetails from "./components/LabelDetails/LabelDetails";
import Videos from "./components/Videos/Videos";
import VideoDetails from "./components/VideoDetails/VideoDetails";
import Partnerships from "./components/Partnerships/Partnerships";
import PartnershipDetails from "./components/PartnershipDetails/PartnershipDetails";
import Genres from "./components/Genres/Genres";
import GenreDetails from "./components/GenreDetails/GenreDetails";
import TeamMembers from "./components/TeamMembers/TeamMembers";
import TeamMemberDetails from "./components/TeamMemberDetails/TeamMemberDetails";
import PageHeaders from "./components/PageHeaders/PageHeaders";
import PageHeaderDetails from "./components/PageHeaderDetails/PageHeaderDetails";
import HomeSlides from "./components/HomeSlides/HomeSlides";
import HomeSlideDetails from "./components/HomeSlideDetails/HomeSlideDetails";
import FuturisingData from "./components/FuturisingData/FuturisingData";
import FuturisingDataDetails from "./components/FuturisingDataDetails/FuturisingDataDetails";
import PrivacyPolicies from "./components/PrivacyPolicies/PrivacyPolicies";
import PrivacyPolicyDetails from "./components/PrivacyPolicyDetails/PrivacyPolicyDetails";
import Users from "./components/Users/Users";
import UserDetails from "./components/UserDetails/UserDetails";
import Roles from "./components/Roles/Roles";
import RoleDetails from "./components/RoleDetails/RoleDetails";
import SubscribedEmails from "./components/SubscribedEmails/SubscribedEmails";
import ContactMsgs from "./components/ContactMsgs/ContactMsgs";
import ContactMsgDetails from "./components/ContactMsgDetails/ContactMsgDetails";
import TrackDemos from "./components/TrackDemos/TrackDemos";
import TrackDemoDetails from "./components/TrackDemoDetails/TrackDemoDetails";
import NotFound from "./components/NotFound/NotFound";
import FmData from "./Context/FmData";
import CreateLabel from "./components/CreateLabel/CreateLabel";
import UpdateLabel from "./components/UpdateLabel/UpdateLabel";
import LabelsContext from "./Context/LabelsContext";
import LoginLayout from "./components/LoginLayout/LoginLayout";
import LoginPage from "./components/LoginPage/LoginPage";
import AuthContext from "./Context/AuthContext";
import RouteGuard from "./components/RouteGuard/RouteGuard";
import LoginGuard from "./components/LoginGuard/LoginGuard";
import PermissionGuard from "./components/PermissionGuard/PermissionGuard";
import ValidateUser from "./components/ValidateUser/ValidateUser";
import GenresContext from "./Context/GenresContext";
import CreateGenre from "./components/CreateGenre/CreateGenre";
import UpdateGenre from "./components/UpdateGenre/UpdateGenre";
import ArtistsContext from "./Context/ArtistsContext";
import CreateArtist from "./components/CreateArtist/CreateArtist";
import UpdateArtist from "./components/UpdateArtist/UpdateArtist";
import ReleasesContext from "./Context/ReleasesContext";
import CreateRelease from "./components/CreateRelease/CreateRelease";
import UpdateRelease from "./components/UpdateRelease/UpdateRelease";
import VideosContext from "./Context/VideosContext";
import CreateVideo from "./components/CreateVideo/CreateVideo";
import UpdateVideo from "./components/UpdateVideo/UpdateVideo";
import NewsContext from "./Context/NewsContext";
import CreateNewBlog from "./components/CreateNewBlog/CreateNewBlog";
import UpdateNewBlog from "./components/UpdateNewBlog/UpdateNewBlog";
import TfArtistsContext from "./Context/TfArtistsContext";
import TfArtists from "./components/TfArtists/TfArtists";
import TfArtistDetails from "./components/TfArtistDetails/TfArtistDetails";
import CreateTfArtist from "./components/CreateTfArtist/CreateTfArtist";
import UpdateTfArtist from "./components/UpdateTfArtist/UpdateTfArtist";
import TfReleasesContext from "./Context/TfReleasesContext";
import TfReleases from "./components/TfReleases/TfReleases";
import TfReleaseDetails from "./components/TfReleaseDetails/TfReleaseDetails";
import CreateTfRelease from "./components/CreateTfRelease/CreateTfRelease";
import UpdateTfRelease from "./components/UpdateTfRelease/UpdateTfRelease";
import PartnershipsContext from "./Context/PartnershipsContext";
import CreatePartnership from "./components/CreatePartnership/CreatePartnership";
import UpdatePartnership from "./components/UpdatePartnership/UpdatePartnership";
import TeamMembersContext from "./Context/TeamMembersContext";
import CreateTeamMember from "./components/CreateTeamMember/CreateTeamMember";
import UpdateTeamMember from "./components/UpdateTeamMember/UpdateTeamMember";
import SubscribedEmailsContext from "./Context/SubscribedEmailsContext";
import SendPost from "./components/SendPost/SendPost";
import ContactMsgsContext from "./Context/ContactMsgsContext";
import TrackDemosContext from "./Context/TrackDemosContext";
import PageHeadersContext from "./Context/PageHeadersContext";
import CreatePageHeader from "./components/CreatePageHeader/CreatePageHeader";
import UpdatePageHeader from "./components/UpdatePageHeader/UpdatePageHeader";
import CreateHomeSlide from "./components/CreateHomeSlide/CreateHomeSlide";
import UpdateHomeSlide from "./components/UpdateHomeSlide/UpdateHomeSlide";
import HomeSlidesContext from "./Context/HomeSlidesContext";
import FuturisingDataContext from "./Context/FuturisingDataContext";
import CreateFuturisingData from "./components/CreateFuturisingData/CreateFuturisingData";
import UpdateFuturisingData from "./components/UpdateFuturisingData/UpdateFuturisingData";
import PrivacyPoliciesContext from "./Context/PrivacyPoliciesContext";
import CreatePrivacyPolicy from "./components/CreatePrivacyPolicy/CreatePrivacyPolicy";
import UpdatePrivacyPolicy from "./components/UpdatePrivacyPolicy/UpdatePrivacyPolicy";
import RolesContext from "./Context/RolesContext";
import CreateRole from "./components/CreateRole/CreateRole";
import UpdateRole from "./components/UpdateRole/UpdateRole";
import UsersContext from "./Context/UsersContext";
import CreateUser from "./components/CreateUser/CreateUser";
import UpdateUser from "./components/UpdateUser/UpdateUser";

function App() {
  const routes = createBrowserRouter([
    {
      path: "/",
      element: (
        <AuthContext>
          <RouteGuard>
            <ValidateUser>
              <FmData>
                <MasterLayout />
              </FmData>
            </ValidateUser>
          </RouteGuard>
        </AuthContext>
      ),
      children: [
        // ------------------ index
        {
          index: true,
          element: <Home />,
          // <Home />
        },
        // ------------------ news
        {
          path: "fm-news",
          element: (
            <NewsContext>
              <News />
            </NewsContext>
          ),
        },
        {
          path: "fm-news/:newBlogId",
          element: (
            <NewsContext>
              <NewDetails />
            </NewsContext>
          ),
        },
        {
          path: "fm-news/create",
          element: (
            <PermissionGuard permission={"fm_new_blog_create"}>
              <CreateNewBlog />
            </PermissionGuard>
          ),
        },
        {
          path: "fm-news/update/:newBlogId",
          element: (
            <PermissionGuard permission={"fm_new_blog_update"}>
              <UpdateNewBlog />
            </PermissionGuard>
          ),
        },
        // ------------------ artists
        {
          path: "fm-artists",
          element: (
            <ArtistsContext>
              <Artists />
            </ArtistsContext>
          ),
        },
        {
          path: "fm-artists/:artistId",
          element: (
            <ArtistsContext>
              <ArtistDetails />
            </ArtistsContext>
          ),
        },
        {
          path: "fm-artists/create",
          element: (
            <PermissionGuard permission={"fm_artist_create"}>
              <CreateArtist />
            </PermissionGuard>
          ),
        },
        {
          path: "fm-artists/update/:artistId",
          element: (
            <PermissionGuard permission={"fm_artist_update"}>
              <UpdateArtist />
            </PermissionGuard>
          ),
        },
        // ------------------ labels
        {
          path: "fm-labels",
          element: (
            <LabelsContext>
              <Labels />
            </LabelsContext>
          ),
        },
        {
          path: "fm-labels/:labelId",
          element: (
            <LabelsContext>
              <LabelDetails />
            </LabelsContext>
          ),
        },
        {
          path: "fm-labels/create",
          element: (
            <PermissionGuard permission={"fm_label_create"}>
              <CreateLabel />
            </PermissionGuard>
          ),
        },
        {
          path: "fm-labels/update/:labelId",
          element: (
            <PermissionGuard permission={"fm_label_update"}>
              <UpdateLabel />
            </PermissionGuard>
          ),
        },
        // ------------------ releases
        {
          path: "fm-releases",
          element: (
            <ReleasesContext>
              <Releases />
            </ReleasesContext>
          ),
        },
        {
          path: "fm-releases/:releaseId",
          element: (
            <ReleasesContext>
              <ReleaseDetails />
            </ReleasesContext>
          ),
        },
        {
          path: "fm-releases/create",
          element: (
            <PermissionGuard permission={"fm_release_create"}>
              <CreateRelease />
            </PermissionGuard>
          ),
        },
        {
          path: "fm-releases/update/:releaseId",
          element: (
            <PermissionGuard permission={"fm_release_update"}>
              <UpdateRelease />
            </PermissionGuard>
          ),
        },
        // ------------------ genres
        {
          path: "fm-genres",
          element: (
            <GenresContext>
              <Genres />
            </GenresContext>
          ),
        },
        {
          path: "fm-genres/:genreId",
          element: (
            <GenresContext>
              <GenreDetails />
            </GenresContext>
          ),
        },
        {
          path: "fm-genres/create",
          element: (
            <PermissionGuard permission={"fm_genre_create"}>
              <CreateGenre />
            </PermissionGuard>
          ),
        },
        {
          path: "fm-genres/update/:genreId",
          element: (
            <PermissionGuard permission={"fm_genre_update"}>
              <UpdateGenre />
            </PermissionGuard>
          ),
        },
        // ------------------ videos
        {
          path: "fm-videos",
          element: (
            <VideosContext>
              <Videos />
            </VideosContext>
          ),
        },
        {
          path: "fm-videos/:videoId",
          element: (
            <VideosContext>
              <VideoDetails />
            </VideosContext>
          ),
        },
        {
          path: "fm-videos/create",
          element: (
            <PermissionGuard permission={"fm_video_create"}>
              <CreateVideo />
            </PermissionGuard>
          ),
        },
        {
          path: "fm-videos/update/:videoId",
          element: (
            <PermissionGuard permission={"fm_video_update"}>
              <UpdateVideo />
            </PermissionGuard>
          ),
        },
        // ------------------ partnerships
        {
          path: "fm-partnerships",
          element: (
            <PartnershipsContext>
              <Partnerships />
            </PartnershipsContext>
          ),
        },
        {
          path: "fm-partnerships/:partnershipId",
          element: (
            <PartnershipsContext>
              <PartnershipDetails />
            </PartnershipsContext>
          ),
        },
        {
          path: "fm-partnerships/create",
          element: (
            <PermissionGuard permission={"fm_partnership_create"}>
              <CreatePartnership />
            </PermissionGuard>
          ),
        },
        {
          path: "fm-partnerships/update/:partnershipId",
          element: (
            <PermissionGuard permission={"fm_partnership_update"}>
              <UpdatePartnership />
            </PermissionGuard>
          ),
        },
        // ------------------ team members
        {
          path: "fm-team-members",
          element: (
            <TeamMembersContext>
              <TeamMembers />
            </TeamMembersContext>
          ),
        },
        {
          path: "fm-team-members/:teamMemberId",
          element: (
            <TeamMembersContext>
              <TeamMemberDetails />
            </TeamMembersContext>
          ),
        },
        {
          path: "fm-team-members/create",
          element: (
            <PermissionGuard permission={"fm_team_member_create"}>
              <CreateTeamMember />
            </PermissionGuard>
          ),
        },
        {
          path: "fm-team-members/update/:teamMemberId",
          element: (
            <PermissionGuard permission={"fm_team_member_update"}>
              <UpdateTeamMember />
            </PermissionGuard>
          ),
        },
        // ------------------ page headers
        {
          path: "fm-page-headers",
          element: (
            <PageHeadersContext>
              <PageHeaders />
            </PageHeadersContext>
          ),
        },
        {
          path: "fm-page-headers/:pageHeaderId",
          element: (
            <PageHeadersContext>
              <PageHeaderDetails />
            </PageHeadersContext>
          ),
        },
        {
          path: "fm-page-headers/create",
          element: (
            <PermissionGuard permission={"fm_page_header_create"}>
              <CreatePageHeader />
            </PermissionGuard>
          ),
        },
        {
          path: "fm-page-headers/update/:pageHeaderId",
          element: (
            <PermissionGuard permission={"fm_page_header_update"}>
              <UpdatePageHeader />
            </PermissionGuard>
          ),
        },
        // ------------------ home slides
        {
          path: "fm-home-slides",
          element: (
            <HomeSlidesContext>
              <HomeSlides />
            </HomeSlidesContext>
          ),
        },
        {
          path: "fm-home-slides/:homeSlideId",
          element: (
            <HomeSlidesContext>
              <HomeSlideDetails />
            </HomeSlidesContext>
          ),
        },
        {
          path: "fm-home-slides/create",
          element: (
            <PermissionGuard permission={"fm_home_carousel_slide_create"}>
              <CreateHomeSlide />
            </PermissionGuard>
          ),
        },
        {
          path: "fm-home-slides/update/:homeSlideId",
          element: (
            <PermissionGuard permission={"fm_home_carousel_slide_update"}>
              <UpdateHomeSlide />
            </PermissionGuard>
          ),
        },
        // ------------------ top five releases
        {
          path: "fm-top-five-releases",
          element: (
            <TfReleasesContext>
              <TfReleases />
            </TfReleasesContext>
          ),
        },
        {
          path: "fm-top-five-releases/:tfReleaseId",
          element: (
            <TfReleasesContext>
              <TfReleaseDetails />
            </TfReleasesContext>
          ),
        },
        {
          path: "fm-top-five-releases/create",
          element: (
            <PermissionGuard permission={"fm_top_five_releases_create"}>
              <CreateTfRelease />
            </PermissionGuard>
          ),
        },
        {
          path: "fm-top-five-releases/update/:tfReleaseId",
          element: (
            <PermissionGuard permission={"fm_top_five_releases_update"}>
              <UpdateTfRelease />
            </PermissionGuard>
          ),
        },
        // ------------------ top five artists
        {
          path: "fm-top-five-artists",
          element: (
            <TfArtistsContext>
              <TfArtists />
            </TfArtistsContext>
          ),
        },
        {
          path: "fm-top-five-artists/:tfArtistId",
          element: (
            <TfArtistsContext>
              <TfArtistDetails />
            </TfArtistsContext>
          ),
        },
        {
          path: "fm-top-five-artists/create",
          element: (
            <PermissionGuard permission={"fm_top_five_artists_create"}>
              <CreateTfArtist />
            </PermissionGuard>
          ),
        },
        {
          path: "fm-top-five-artists/update/:tfArtistId",
          element: (
            <PermissionGuard permission={"fm_top_five_artists_update"}>
              <UpdateTfArtist />
            </PermissionGuard>
          ),
        },
        // ------------------ futurising data
        {
          path: "fm-futurising-data",
          element: (
            <FuturisingDataContext>
              <FuturisingData />
            </FuturisingDataContext>
          ),
        },
        {
          path: "fm-futurising-data/:futurisingDataId",
          element: (
            <FuturisingDataContext>
              <FuturisingDataDetails />
            </FuturisingDataContext>
          ),
        },
        {
          path: "fm-futurising-data/create",
          element: (
            <PermissionGuard permission={"fm_futurising_data_create"}>
              <CreateFuturisingData />
            </PermissionGuard>
          ),
        },
        {
          path: "fm-futurising-data/update/:futurisingDataId",
          element: (
            <PermissionGuard permission={"fm_futurising_data_update"}>
              <UpdateFuturisingData />
            </PermissionGuard>
          ),
        },
        // ------------------ privacy policy
        {
          path: "fm-privacy-policies",
          element: (
            <PrivacyPoliciesContext>
              <PrivacyPolicies />
            </PrivacyPoliciesContext>
          ),
        },
        {
          path: "fm-privacy-policies/:privacyPolicyId",
          element: (
            <PrivacyPoliciesContext>
              <PrivacyPolicyDetails />
            </PrivacyPoliciesContext>
          ),
        },
        {
          path: "fm-privacy-policies/create",
          element: (
            <PermissionGuard permission={"fm_privacy_policy_create"}>
              <CreatePrivacyPolicy />
            </PermissionGuard>
          ),
        },
        {
          path: "fm-privacy-policies/update/:privacyPolicyId",
          element: (
            <PermissionGuard permission={"fm_privacy_policy_update"}>
              <UpdatePrivacyPolicy />
            </PermissionGuard>
          ),
        },
        // ------------------ users
        {
          path: "fm-users",
          element: (
            <PermissionGuard permission={"fm_user_show_all"}>
              <UsersContext>
                <Users />
              </UsersContext>
            </PermissionGuard>
          ),
        },
        {
          path: "fm-users/:userId",
          element: (
            <PermissionGuard permission={"fm_user_show_one"}>
              <UsersContext>
                <UserDetails />
              </UsersContext>
            </PermissionGuard>
          ),
        },
        {
          path: "fm-users/create",
          element: (
            <PermissionGuard permission={"fm_user_create"}>
              <CreateUser />
            </PermissionGuard>
          ),
        },
        {
          path: "fm-users/update/:userId",
          element: (
            <PermissionGuard permission={"fm_user_update"}>
              <UpdateUser />
            </PermissionGuard>
          ),
        },
        // ------------------ roles
        {
          path: "fm-roles",
          element: (
            <PermissionGuard permission={"fm_settings_show"}>
              <RolesContext>
                <Roles />
              </RolesContext>
            </PermissionGuard>
          ),
        },
        {
          path: "fm-roles/:roleId",
          element: (
            <PermissionGuard permission={"fm_settings_show"}>
              <RolesContext>
                <RoleDetails />
              </RolesContext>
            </PermissionGuard>
          ),
        },
        {
          path: "fm-roles/create",
          element: (
            <PermissionGuard permission={"fm_settings_create"}>
              <CreateRole />
            </PermissionGuard>
          ),
        },
        {
          path: "fm-roles/update/:roleId",
          element: (
            <PermissionGuard permission={"fm_settings_update"}>
              <UpdateRole />
            </PermissionGuard>
          ),
        },
        // ------------------ subscribed email
        {
          path: "fm-subscribed-emails",
          element: (
            <PermissionGuard permission={"fm_subscribed_email_show"}>
              <SubscribedEmailsContext>
                <SubscribedEmails />
              </SubscribedEmailsContext>
            </PermissionGuard>
          ),
        },
        {
          path: "fm-subscribed-emails/send-post",
          element: (
            <PermissionGuard permission={"send_blog_mail"}>
              <SendPost />
            </PermissionGuard>
          ),
        },
        // ------------------ contact messages
        {
          path: "fm-contact-messages",
          element: (
            <PermissionGuard permission={"fm_contact_message_show_all"}>
              <ContactMsgsContext>
                <ContactMsgs />
              </ContactMsgsContext>
            </PermissionGuard>
          ),
        },
        {
          path: "fm-contact-messages/:contactMsgId",
          element: (
            <PermissionGuard permission={"fm_contact_message_show_one"}>
              <ContactMsgsContext>
                <ContactMsgDetails />
              </ContactMsgsContext>
            </PermissionGuard>
          ),
        },
        // ------------------ track demos
        {
          path: "fm-track-demos",
          element: (
            <PermissionGuard permission={"fm_track_demo_show_all"}>
              <TrackDemosContext>
                <TrackDemos />
              </TrackDemosContext>
            </PermissionGuard>
          ),
        },
        {
          path: "fm-track-demos/:trackDemoId",
          element: (
            <PermissionGuard permission={"fm_track_demo_show_one"}>
              <TrackDemosContext>
                <TrackDemoDetails />
              </TrackDemosContext>
            </PermissionGuard>
          ),
        },
        // not found
        { path: "*", element: <NotFound /> },
      ],
    },
    {
      path: "/login",
      element: (
        <AuthContext>
          <LoginGuard>
            <LoginLayout />
          </LoginGuard>
        </AuthContext>
      ),
      children: [
        // ------------------ login
        { index: true, element: <LoginPage /> },
      ],
    },
  ]);
  return (
    <>
      <RouterProvider router={routes} />
    </>
  );
}

export default App;
