import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fmDataContext } from "../../Context/FmData";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { fmTfArtistsContext } from "../../Context/TfArtistsContext";
import userCan from "../../helpers/userCan";
import "swiper/css";
import "swiper/css/pagination";
import Swal from "sweetalert2/dist/sweetalert2.all.min";
import "@sweetalert2/theme-dark/dark.min.css";
import jwtDecode from "jwt-decode";
import { authenticationContext } from "../../Context/AuthContext";
import ArtistCard from "../ArtistCard/ArtistCard";

const TfArtistDetails = () => {
  let [mounted, setMounted] = useState(false);

  let { tfArtistId } = useParams();

  let { baseUrl, navigate, showData, editItemExistence, globalCrumbs, setGlobalCrumbs } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  let [currentTfArtist, setCurrentTfArtist] = useState({});

  let [isCurrentTfArtistLoading, setIsCurrentTfArtistLoading] = useState(true);

  let [isForceDeleteLoading, setIsForceDeleteLoading] = useState(false);

  let { setTfArtistMsg } = useContext(fmTfArtistsContext);

  let userToken = jwtDecode(localStorage.getItem("userToken")).accessToken;

  const removeTfArtist = (reqMethod, pathName, headers, setLoading) => {
    Swal.fire({
      title: "Are you sure?",
      html: `<p class="mb-0">You will remove <span class="fu-text-light-gray">${currentTfArtist.data.additional_artist_name ? currentTfArtist.data.additional_artist_name : currentTfArtist.data.artist_data.artist_name}</span> </p><p class="mb-0">from top five artists list!</p>`,
      icon: "warning",
      showCancelButton: true,
      customClass: {
        cancelButton: "popup-btn",
        confirmButton: "popup-btn",
      },
      confirmButtonText: "Yes, remove!",
    }).then((result) => {
      if (result.isConfirmed) {
        if (userCan("fm_top_five_artists_force_delete")) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              setTfArtistMsg(`${currentTfArtist.data.additional_artist_name ? currentTfArtist.data.additional_artist_name : currentTfArtist.data.artist_data.artist_name} Removed Successfully`);
              navigate("/fm-top-five-artists");
            })
            .catch((error) => {
              navigate("/not-found");
            });
        } else {
          navigate("/not-found");
        }
      }
    });
  };

  const getTfArtist = (reqMethod, pathName, setLoading = setIsCurrentTfArtistLoading, headers) => {
    showData(reqMethod, pathName, setLoading, headers)
      .then((response) => {
        if (response.data.data.artist_data !== null) {
          if (response.data.data.artist_data.artist_image.includes("via.placeholder.com")) {
            response.data.data.artist_data.artist_image = response.data.data.artist_data.artist_image.replace("http://127.0.0.1:8000/storage/", "");
          }
        }

        setCurrentTfArtist(response.data);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    getTfArtist("GET", `${baseUrl}fm-one-from-top-five-artists/${tfArtistId}`, setIsCurrentTfArtistLoading, {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
      Authorization: `Bearer ${userToken}`,
    });
    setMounted(true);
  }, []);

  useEffect(() => {
    getTfArtist("GET", `${baseUrl}fm-one-from-top-five-artists/${tfArtistId}`, setIsCurrentTfArtistLoading, {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
      Authorization: `Bearer ${userToken}`,
    });
    setMounted(true);
  }, [tfArtistId]);

  useEffect(() => {
    if (mounted && currentTfArtist.data) {
      let globalCrumbsUpdated = globalCrumbs.map((crumb) => {
        if (crumb.name === tfArtistId.toUpperCase()) {
          crumb.name = currentTfArtist.data.additional_artist_name ? currentTfArtist.data.additional_artist_name.toUpperCase() : currentTfArtist.data.artist_data.artist_name.toUpperCase();
        }
        return crumb;
      });
      setGlobalCrumbs(globalCrumbsUpdated);
    }
  }, [currentTfArtist]);

  return (
    <>
      {!isCurrentTfArtistLoading ? (
        <>
          <div className="min-vh-100">
            <div className="container">
              <h1 className="fu-text-3-rem page-heading-title-sm-2-rem fu-fw-800 fu-text-white mb-3 mt-3">{currentTfArtist.data.additional_artist_name ? currentTfArtist.data.additional_artist_name : currentTfArtist.data.artist_data.artist_name}</h1>

              <div className="py-0 py-sm-2 mt-2 mt-sm-3">
                <div className="d-flex flex-wrap align-items-center mb-3 mb-sm-4">
                  {userCan("fm_top_five_artists_update") ? (
                    <div className="mx-2 my-1 my-sm-0">
                      <Link to={`/fm-top-five-artists/update/${currentTfArtist.data.key}`} className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading ? "disabled" : ""}`} disabled={isForceDeleteLoading ? true : false}>
                        <span className="btn-bg"></span>
                        Edit
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}

                  {userCan("fm_top_five_artists_force_delete") ? (
                    <div className="ms-2 my-1 my-sm-0">
                      <button
                        className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading ? "disabled" : ""}`}
                        onClick={() =>
                          removeTfArtist(
                            "DELETE",
                            `${baseUrl}fm-top-five-artists/delete/${currentTfArtist.data.key}`,
                            {
                              Accept: "application/json",
                              "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                              Authorization: `Bearer ${user.accessToken}`,
                            },
                            setIsForceDeleteLoading
                          )
                        }
                        disabled={isForceDeleteLoading ? true : false}>
                        <span className="btn-bg"></span>
                        Remove
                      </button>
                    </div>
                  ) : (
                    ""
                  )}

                  {isForceDeleteLoading ? (
                    <div className="position-relative ms-4">
                      <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="tf-artists-tags">
                  <div className="mb-4 info-tags-container justify-content-start">
                    {currentTfArtist.data._id ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">#ID: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentTfArtist.data._id}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentTfArtist.data.key ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">#KEY: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentTfArtist.data.key}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentTfArtist.data.artist_order ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">ORDER IN TOP 5: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">#{currentTfArtist.data.artist_order}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {currentTfArtist.data.created_at ? (
                      userCan("created_at_show") ? (
                        <>
                          <div className="mx-1 my-1 info-tag">
                            <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED AT: </span>
                            <span className="d-inline-flex justify-content-center align-items-center">{new Date(currentTfArtist.data.created_at).toLocaleString("en-US", { timeZone: "Africa/Cairo", hour12: true })}</span>
                          </div>
                        </>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {currentTfArtist.data.updated_at ? (
                      userCan("updated_at_show") ? (
                        <>
                          <div className="mx-1 my-1 info-tag">
                            <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED AT: </span>
                            <span className="d-inline-flex justify-content-center align-items-center">{new Date(currentTfArtist.data.updated_at).toLocaleString("en-US", { timeZone: "Africa/Cairo", hour12: true })}</span>
                          </div>
                        </>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}

                    {currentTfArtist.data.created_by ? (
                      userCan("created_by_show") ? (
                        <>
                          <div className="mx-1 my-1 info-tag">
                            <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED BY, NAME: </span>
                            <span className="d-inline-flex justify-content-center align-items-center">{currentTfArtist.data.created_by.name}</span>
                          </div>
                        </>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {currentTfArtist.data.created_by ? (
                      userCan("created_by_show") ? (
                        <>
                          <div className="mx-1 my-1 info-tag">
                            <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED BY, #ID: </span>
                            <span className="d-inline-flex justify-content-center align-items-center">{currentTfArtist.data.created_by._id}</span>
                          </div>
                        </>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {currentTfArtist.data.created_by ? (
                      userCan("created_by_show") ? (
                        <>
                          <div className="mx-1 my-1 info-tag">
                            <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED BY, KEY: </span>
                            <span className="d-inline-flex justify-content-center align-items-center">{currentTfArtist.data.created_by.key}</span>
                          </div>
                        </>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {currentTfArtist.data.updated_by ? (
                      userCan("updated_by_show") ? (
                        <>
                          <div className="mx-1 my-1 info-tag">
                            <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED BY, NAME: </span>
                            <span className="d-inline-flex justify-content-center align-items-center">{currentTfArtist.data.updated_by.name}</span>
                          </div>
                        </>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {currentTfArtist.data.updated_by ? (
                      userCan("updated_by_show") ? (
                        <>
                          <div className="mx-1 my-1 info-tag">
                            <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED BY, #ID: </span>
                            <span className="d-inline-flex justify-content-center align-items-center">{currentTfArtist.data.updated_by._id}</span>
                          </div>
                        </>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {currentTfArtist.data.updated_by ? (
                      userCan("updated_by_show") ? (
                        <>
                          <div className="mx-1 my-1 info-tag">
                            <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED BY, KEY: </span>
                            <span className="d-inline-flex justify-content-center align-items-center">{currentTfArtist.data.updated_by.key}</span>
                          </div>
                        </>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/* Artist */}
                {currentTfArtist.data.artist_data !== null ? (
                  <div className="mb-4">
                    <div className="d-flex align-items-center mb-4">
                      <div className="flex-grow-1 iu-row-heading">
                        <h2 className="mb-0 row-title">ARTIST</h2>
                      </div>
                    </div>
                    <div className="row gx-3">
                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2">
                        <ArtistCard artist={currentTfArtist.data.artist_data} />
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
};

export default TfArtistDetails;
