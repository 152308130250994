import React, { createContext, useState } from "react";

export let fmPrivacyPoliciesContext = createContext(0);

const PrivacyPoliciesContext = ({ children }) => {
  let [privacyPoliciesMsg, setPrivacyPoliciesMsg] = useState("");

  let fmPrivacyPoliciesLifting = {
    privacyPoliciesMsg,
    setPrivacyPoliciesMsg,
  };

  return (
    <>
      <fmPrivacyPoliciesContext.Provider value={{ ...fmPrivacyPoliciesLifting }}>{children}</fmPrivacyPoliciesContext.Provider>
    </>
  );
};

export default PrivacyPoliciesContext;
