import React, { createContext, useState } from "react";

export let fmPageHeadersContext = createContext(0);

const PageHeadersContext = ({ children }) => {
  let [pageHeaderMsg, setPageHeaderMsg] = useState("");

  let [filtrationDetails, setFiltrationDetails] = useState({
    search_query: "",
    only_trashed: 0,
  });

  let [isFiltrationLoading, setIsFiltrationLoading] = useState(false);

  let fmPageHeadersLifting = {
    pageHeaderMsg,
    setPageHeaderMsg,
    filtrationDetails,
    setFiltrationDetails,
    isFiltrationLoading,
    setIsFiltrationLoading,
  };

  return (
    <>
      <fmPageHeadersContext.Provider value={{ ...fmPageHeadersLifting }}>{children}</fmPageHeadersContext.Provider>
    </>
  );
};

export default PageHeadersContext;
