import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { fmDataContext } from "../../Context/FmData";
import Joi from "joi";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { authenticationContext } from "../../Context/AuthContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";
import Reveal from "../Reveal/Reveal";
import LoadingScreen from "../LoadingScreen/LoadingScreen";

const CreatePrivacyPolicy = () => {
  let { baseUrl, navigate, getData } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  let [joiErrorsList, setJoiErrorsList] = useState([]);

  let [privacyPolicyData, setPrivacyPolicyData] = useState({
    description: "",
    user_id: null,
  });

  let [isCreateLoading, setIsCreateLoading] = useState(false);

  let [createResponse, setCreateResponse] = useState({});

  let [privacyPolicyRows, setPrivacyPolicyRows] = useState({});

  let [isGetPrivacyPolicyLoading, setIsGetPrivacyPolicyLoading] = useState(true);

  const getPrivacyPolicyData = (e) => {
    const privacyPolicy = { ...privacyPolicyData };
    privacyPolicy[e.target.name] = e.target.value;
    setPrivacyPolicyData(privacyPolicy);
  };

  const getPrivacyPolicyRows = (reqMethod, pathName, setLoading = setIsGetPrivacyPolicyLoading, params = null) => {
    getData(reqMethod, pathName, params, setLoading)
      .then((response) => {
        setPrivacyPolicyRows(response.data);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const createItem = async (e) => {
    e.preventDefault();

    if (userCan("fm_privacy_policy_create")) {
      privacyPolicyData.user_id = user.currentUser._id;
      privacyPolicyData.description = privacyPolicyData.description ? privacyPolicyData.description.replaceAll("\n", "</br>") : "";
      const validationResult = validateCreatePrivacyPolicyForm();

      setCreateResponse({});

      if (validationResult.error) {
        validationResult.error ? setJoiErrorsList(validationResult.error.details) : setJoiErrorsList([]);
      } else {
        setJoiErrorsList([]);
        setIsCreateLoading(true);
        await axios({
          method: "POST",
          url: `${baseUrl}fm-privacy-policies/create`,
          data: privacyPolicyData,
          headers: {
            Accept: "application/json",
            "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
            Authorization: `Bearer ${user.accessToken}`,
          },
        })
          .then((response) => {
            setCreateResponse(response.data);
            if (response.data.msg && response.data.msg.includes("successfully")) {
              getData("GET", `${baseUrl}fm-privacy-policies`, null, setIsCreateLoading)
                .then((fmRowsResponse) => {
                  setPrivacyPolicyRows(fmRowsResponse.data);
                  setPrivacyPolicyData({
                    description: "",
                    user_id: null,
                  });
                  e.target.reset();
                  setCreateResponse({});
                  setIsCreateLoading(false);
                  toast(response.data.msg, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    progressStyle: { backgroundColor: "#fdb915" },
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                  });
                })
                .catch((error) => {
                  navigate("/not-found");
                });
            } else if (response.data.msg && response.data.msg.includes("failed")) {
              setIsCreateLoading(false);
              toast(response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          })
          .catch((errors) => {
            navigate("/not-found");
          });
      }
    } else {
      navigate("/not-found");
    }
  };

  const validateCreatePrivacyPolicyForm = () => {
    const scheme = Joi.object({
      description: Joi.string().min(1).required(),
      user_id: Joi.number(),
    });
    return scheme.validate(privacyPolicyData, { abortEarly: false });
  };

  useEffect(() => {
    getPrivacyPolicyRows("GET", `${baseUrl}fm-privacy-policies`, setIsGetPrivacyPolicyLoading);
  }, []);

  return (
    <>
      {isGetPrivacyPolicyLoading ? (
        <LoadingScreen />
      ) : (
        <>
          {privacyPolicyRows.data && privacyPolicyRows.data.length >= 1 ? (
            <Reveal>
              <div className="custom-height d-flex flex-column justify-content-center align-items-center limitation-msg">
                <div className="mb-1 mb-sm-2 mb-lg-3 fu-text-4-rem fu-text-yellow">
                  <i className="bi bi-info-circle"></i>
                </div>
                <h1 className="fu-text-2-rem fu-text-white fu-fw-800 mb-0">Privacy Policies is already exists</h1>
                <h2 className="fu-text-1-rem fu-text-yellow fu-fw-800 mb-0">You can't add another</h2>
              </div>
            </Reveal>
          ) : (
            <div className="container position-relative pt-3 pt-md-4">
              <div className="mb-3 mb-md-4">
                <h1 className="fu-fw-800 fu-text-white fu-text-3-rem fu-text-3-rem-md fu-text-md-2-rem fu-text-sm-2-rem fu-text-1-8-rem-xs fu-text-18-px-xxs fu-text-16-px-xxs mb-0">Create Privacy Policy</h1>
              </div>
              <div className="py-2 mb-4 iu-row-heading create-submit-row">
                <h2 className="mb-0 row-title">Submit Privacy Policies Description</h2>
              </div>
              <div className="row">
                <div>
                  <div>
                    <Form onSubmit={createItem}>
                      <Row>
                        <Col md={12}>
                          <Form.Group className="mb-3" controlId="aboutCompany">
                            <Form.Label className="fu-text-light-gray" data-title="required">
                              Privacy Policy Description
                              <span className="required-input mx-1" data-title="required">
                                *
                              </span>
                            </Form.Label>
                            <div className="textarea-container">
                              <Form.Control as="textarea" rows={4} className="fu-input" placeholder="Enter Privacy Policy Description" name="description" onInput={getPrivacyPolicyData} />
                            </div>
                            {joiErrorsList.map((error, index) =>
                              error.path[0] === "description" ? (
                                <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                                  {error.message.replace("description", "Privacy Policy Description")}
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </Form.Group>
                        </Col>
                      </Row>

                      {/* ------------------------------------ */}

                      {createResponse.errors
                        ? Object.keys(createResponse.errors).map((key) =>
                            createResponse.errors[key].map((error, index) => (
                              <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                                {error}
                              </div>
                            ))
                          )
                        : ""}
                      <div className="d-flex align-items-center mt-4">
                        <div>
                          <Button className={`fu-btn fu-btn-gray ${isCreateLoading ? "disabled" : ""}`} type="submit" disabled={isCreateLoading ? true : false}>
                            <span className="btn-bg"></span>
                            Submit<i className="bi bi-send ms-2"></i>
                          </Button>
                        </div>
                        {isCreateLoading ? (
                          <div className="position-relative ms-3">
                            <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CreatePrivacyPolicy;
