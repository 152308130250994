import React, { createContext, useState } from "react";

export let fmTrackDemosContext = createContext(0);

const TrackDemosContext = ({ children }) => {
  let [trackDemoMsg, setTrackDemoMsg] = useState("");

  let fmTrackDemosLifting = {
    trackDemoMsg,
    setTrackDemoMsg,
  };

  return (
    <>
      <fmTrackDemosContext.Provider value={{ ...fmTrackDemosLifting }}>{children}</fmTrackDemosContext.Provider>
    </>
  );
};

export default TrackDemosContext;
