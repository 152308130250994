import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import { Outlet } from "react-router-dom";
import Footer from "../Footer/Footer";
import ScrollLayoutTop from "../ScrollLayoutTop/ScrollLayoutTop";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import SearchBar from "../SearchBar/SearchBar";
import MobileNavBar from "../MobileNavbar/MobileNavBar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MasterLayout = () => {
  let [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener("resize", function () {
      setWindowWidth(window.innerWidth);
    });
  }, []);

  return (
    <>
      <ScrollLayoutTop>
        {windowWidth >= 1250 ? <Navbar /> : ""}
        <div>
          <div className="layout-container position-relative">
            <div className="toast-msg-container-parent">
              <ToastContainer className="toast-msg-container" />
            </div>
            {windowWidth >= 1250 ? (
              <div>
                <div className="virtual-nav-width"></div>
              </div>
            ) : windowWidth !== 0 ? (
              <MobileNavBar />
            ) : (
              ""
            )}

            <div className="flex-grow-1 test-center outlet">
              <div className="min-vh-100">
                <div className="fu-bg-black">
                  <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                      <Breadcrumbs />
                      <div className="ms-auto">
                        <SearchBar />
                      </div>
                    </div>
                  </div>
                </div>

                <Outlet></Outlet>

                <div className="virtual-mobile-bar-height"></div>

                <div className="position-relateive mt-4 py-4 z-index-high">
                  <div className="position-absolute bottom-0 start-0 end-0">
                    <Footer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollLayoutTop>
    </>
  );
};

export default MasterLayout;
