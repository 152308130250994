import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { fmDataContext } from "../../Context/FmData";
import Joi from "joi";
import { useParams } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { authenticationContext } from "../../Context/AuthContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const UpdateUser = () => {
  let [mounted, setMounted] = useState(false);

  let { userId } = useParams();
  let { baseUrl, navigate, showData, globalCrumbs, setGlobalCrumbs, getData } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  let [currentUser, setCurrentUser] = useState({});

  let [isUserLoading, setIsUserLoading] = useState(true);

  const animatedComponents = makeAnimated();

  let roleRef = useRef();
  let userStatusRef = useRef();

  let [selectedRole, setSelectedRole] = useState(null);
  let [selectedUserStatus, setSelectedUserStatus] = useState(null);

  let [isGetRolesLoading, setIsGetRolesLoading] = useState(true);
  let [roles, setRoles] = useState([]);

  let [joiErrorsList, setJoiErrorsList] = useState([]);

  let [userData, setUserData] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    role: "",
    status: "",
  });

  let [isUpdateLoading, setIsUpdateLoading] = useState(false);

  let [updateResponse, setUpdateResponse] = useState({});

  let [navigateTimeOut, setNavigateTimeOut] = useState("");

  let userToken = jwtDecode(localStorage.getItem("userToken")).accessToken;

  const getUser = (reqMethod, pathName, setLoading = setIsUserLoading, headers) => {
    headers = {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
      Authorization: `Bearer ${jwtDecode(localStorage.getItem("userToken")).accessToken}`,
    };
    showData(reqMethod, pathName, setLoading, headers)
      .then((response) => {
        let userResponse = response.data.data;
        setCurrentUser(userResponse);

        setUserData({
          name: userResponse.name ? userResponse.name : "",
          email: userResponse.email_address ? userResponse.email_address : "",
          password: "",
          password_confirmation: "",
          role: userResponse.user_role ? userResponse.user_role : "",
          status: userResponse.user_status ? userResponse.user_status : "",
        });

        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const getAllRoles = (reqMethod, pathName, params = null, setLoading = setIsGetRolesLoading) => {
    getData(reqMethod, pathName, params, setLoading, {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
      Authorization: `Bearer ${userToken}`,
    })
      .then((response) => {
        setLoading(false);
        setRoles(response.data.data);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const getUserData = (e) => {
    const user = { ...userData };
    user[e.target.name] = e.target.value;
    setUserData(user);
  };

  const handleUserStatus = () => {
    const user = { ...userData };
    user.status = selectedUserStatus !== null && selectedUserStatus.value !== null ? selectedUserStatus.value : null;
    setUserData(user);
  };

  const handleRoleSelect = () => {
    const user = { ...userData };
    user.role = selectedRole !== null && selectedRole.value !== null ? selectedRole.value : null;
    setUserData(user);
  };

  const updateItem = async (e) => {
    e.preventDefault();
    if (userCan("fm_user_update")) {
      const validationResult = validateUpdateUserForm();
      setUpdateResponse({});
      if (validationResult.error) {
        validationResult.error ? setJoiErrorsList(validationResult.error.details) : setJoiErrorsList([]);
      } else {
        setIsUpdateLoading(true);
        setJoiErrorsList([]);

        await axios({
          method: "POST",
          url: `${baseUrl}fm-users/update/${currentUser.key}`,
          data: userData,
          params: {
            _method: "PUT",
          },
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
            Authorization: `Bearer ${user.accessToken}`,
          },
        })
          .then((response) => {
            setUpdateResponse(response.data);
            setIsUpdateLoading(false);

            if (response.data.msg && response.data.msg.includes("successfully")) {
              let timeOut = setTimeout(() => {
                setUpdateResponse({});
                navigate(`/fm-users/${currentUser.key}?archived=0`);
              }, 4000);
              setNavigateTimeOut(timeOut);
              setUserData({
                name: "",
                email: "",
                password: "",
                password_confirmation: "",
                role: "",
                status: "",
              });
              roleRef.current.clearValue();
              userStatusRef.current.clearValue();

              e.target.reset();
              toast(`${response.data.msg}, navigating...`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                containerId: "update_done_toast",
              });
            } else if (response.data.msg && response.data.msg.includes("failed")) {
              toast(response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          })
          .catch((errors) => {
            navigate("/not-found");
          });
      }
    } else {
      navigate("/not-found");
    }
  };

  const validateUpdateUserForm = () => {
    const scheme = Joi.object({
      name: Joi.string().min(1).max(255).required(),
      email: Joi.string()
        .email({ tlds: { allow: ["com", "net", "org", "eg"] } })
        .required(),
      password: Joi.string().pattern(new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@#$!%*?&])[A-Za-z\\d@#$!%*?&]{8,255}$")).message("Password Must Be Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character").allow(null, "").optional(),
      password_confirmation: Joi.any()
        .equal(Joi.ref("password"))
        .label("Confirm password")
        .options({ messages: { "any.only": "{{#label}} does not match" } }),
      role: Joi.string().min(1).max(255).required(),
      status: Joi.string().min(1).max(255).required(),
    });
    return scheme.validate(userData, { abortEarly: false });
  };

  useEffect(() => {
    getAllRoles("GET", `${baseUrl}fm-all-roles`, null, setIsGetRolesLoading);
    getUser("GET", `${baseUrl}fm-user/${userId}`, setIsUserLoading);
    setMounted(true);
  }, []);

  useEffect(() => {
    return () => {
      if (mounted) {
        clearTimeout(navigateTimeOut);
      }
    };
  }, [navigateTimeOut]);

  useEffect(() => {
    if (mounted && currentUser) {
      let globalCrumbsUpdated = globalCrumbs.map((crumb) => {
        if (crumb.name === userId.toUpperCase()) {
          crumb.name = currentUser.name.toUpperCase();
        }
        return crumb;
      });
      setGlobalCrumbs(globalCrumbsUpdated);
      if (roleRef.current && currentUser.user_role && currentUser.user_role !== "None") {
        roleRef.current.setValue(currentUser.user_role ? { value: currentUser.user_role, label: currentUser.user_role } : null);
      }
      if (userStatusRef.current) {
        userStatusRef.current.setValue(currentUser.user_status ? { value: currentUser.user_status, label: currentUser.user_status } : null);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    handleRoleSelect();
  }, [selectedRole]);

  useEffect(() => {
    handleUserStatus();
  }, [selectedUserStatus]);

  return (
    <>
      {isUserLoading || isGetRolesLoading ? (
        <LoadingScreen />
      ) : (
        <div className="container position-relative pt-3 pt-md-4">
          <div className="mb-3 mb-md-4">
            <h1 className="fu-text-3-rem fu-text-3-rem-md fu-text-md-2-rem fu-text-sm-2-rem fu-text-1-8-rem-xs fu-text-18-px-xxs fu-text-16-px-xxs fu-text-white fu-fw-800 mb-0">Update User</h1>
          </div>
          <div className="py-2 mb-4 iu-row-heading create-submit-row">
            <h2 className="mb-0 row-title">Edit ( {currentUser.name} ) data</h2>
          </div>
          <div className="row">
            <div>
              <div>
                <Form onSubmit={updateItem}>
                  <Row>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="userName">
                        <Form.Label className="fu-text-light-gray" data-title="required">
                          Name
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter User Name" className="fu-input" name="name" value={userData.name ? userData.name : ""} onInput={getUserData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "name" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("name", "User Name")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="userEmail">
                        <Form.Label className="fu-text-light-gray" data-title="required">
                          Email Address
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Email Address" className="fu-input" name="email" value={userData.email ? userData.email : ""} onInput={getUserData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "email" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("email", "Email Address")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="emailPassword">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Password
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="password" placeholder="Enter Password" className="fu-input" name="password" onInput={getUserData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "password" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("password", "Password")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="emailPasswordConfirmation">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Password Confirmation
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="password" placeholder="Enter Password Again" className="fu-input" name="password_confirmation" onInput={getUserData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "password_confirmation" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("password_confirmation", "Password Confirmation")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>

                    <Col lg={6}>
                      <div className="mb-3">
                        <div className="fu-text-light-gray w-fit-content mb-2" data-title="required">
                          User Role
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </div>
                        <div className="select-options">
                          <Select
                            ref={roleRef}
                            classNames={{
                              control: () => "select-filtration",
                              option: ({ isSelected, innerProps }) => (isSelected ? (innerProps.style = { backgroundColor: "#fdb915", color: "#191919" }) : (innerProps.style = { backgroundColor: "#ffffff" })),
                            }}
                            placeholder="Select User Role...."
                            name="verified"
                            onChange={setSelectedRole}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            isClearable
                            options={roles.map((role) => ({ value: role.name, label: role.name }))}
                          />
                        </div>
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "role" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("role", "Role")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <div className="fu-text-light-gray w-fit-content mb-2" data-title="required">
                          User Status
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </div>
                        <div className="select-options">
                          <Select
                            ref={userStatusRef}
                            classNames={{
                              control: () => "select-filtration",
                              option: ({ isSelected, innerProps }) => (isSelected ? (innerProps.style = { backgroundColor: "#fdb915", color: "#191919" }) : (innerProps.style = { backgroundColor: "#ffffff" })),
                            }}
                            placeholder="Select User Status...."
                            name="userStatus"
                            onChange={setSelectedUserStatus}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            isClearable
                            options={[
                              { value: "active", label: "Active" },
                              { value: "inactive", label: "Inactive" },
                            ]}
                          />
                        </div>
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "status" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("status", "User Status")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </div>
                    </Col>
                  </Row>

                  {updateResponse.errors
                    ? Object.keys(updateResponse.errors).map((key) =>
                        updateResponse.errors[key].map((error, index) => (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error}
                          </div>
                        ))
                      )
                    : ""}

                  <div className="d-flex align-items-center mt-4">
                    <div>
                      <Button className={`fu-btn fu-btn-gray ${isUpdateLoading ? "disabled" : ""}`} type="submit" disabled={isUpdateLoading ? true : false}>
                        <span className="btn-bg"></span>
                        Submit<i className="bi bi-send ms-2"></i>
                      </Button>
                    </div>
                    {isUpdateLoading ? (
                      <div className="position-relative ms-3">
                        <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateUser;
