import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { fmDataContext } from "../../Context/FmData";
import Joi from "joi";
import { useParams } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { authenticationContext } from "../../Context/AuthContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import jwtDecode from "jwt-decode";

const UpdateTfArtist = () => {
  let [mounted, setMounted] = useState(false);

  let { tfArtistId } = useParams();
  let { baseUrl, navigate, showData, globalCrumbs, setGlobalCrumbs, getAllDataWithoutPaginate } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  let [currentTfArtist, setCurrentTfArtist] = useState({});
  let [isTfArtistLoading, setIsTfArtistLoading] = useState(true);

  let [joiErrorsList, setJoiErrorsList] = useState([]);

  let [tfArtistData, setTfArtistData] = useState({
    name: "",
    order: null,
    artist_id: null,
    modify_user_id: null,
  });

  let [isUpdateLoading, setIsUpdateLoading] = useState(false);

  let [updateResponse, setUpdateResponse] = useState({});

  const animatedComponents = makeAnimated();

  let artistRef = useRef();
  let artistOrderRef = useRef();

  let [selectedArtist, setSelectedArtist] = useState(null);
  let [selectedArtistOrder, setSelectedArtistOrder] = useState(null);

  let [isGetArtistsLoading, setIsGetArtistsLoading] = useState(true);
  let [artists, setArtists] = useState([]);

  let [navigateTimeOut, setNavigateTimeOut] = useState("");

  let userToken = jwtDecode(localStorage.getItem("userToken")).accessToken;

  const getTfArtist = (reqMethod, pathName, setLoading = setIsTfArtistLoading, headers) => {
    showData(reqMethod, pathName, setLoading, headers)
      .then((response) => {
        let tfArtistResponse = response.data.data;

        setCurrentTfArtist(tfArtistResponse);
        setTfArtistData({
          name: tfArtistResponse.additional_artist_name ? tfArtistResponse.additional_artist_name : "",
          order: tfArtistResponse.artist_order ? tfArtistResponse.artist_order : null,
          artist_id: tfArtistResponse.artist_data._id ? tfArtistResponse.artist_data._id : null,
          modify_user_id: null,
        });

        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const getTfArtistData = (e) => {
    const tfArtist = { ...tfArtistData };
    tfArtist[e.target.name] = e.target.value;
    setTfArtistData(tfArtist);
  };

  const handleArtistOrder = () => {
    const tfArtist = { ...tfArtistData };
    tfArtist.order = selectedArtistOrder !== null && selectedArtistOrder.value !== null ? selectedArtistOrder.value : null;
    setTfArtistData(tfArtist);
  };

  const handleArtistSelect = () => {
    const tfArtist = { ...tfArtistData };
    tfArtist.artist_id = selectedArtist !== null && selectedArtist.value !== null ? selectedArtist.value : null;
    setTfArtistData(tfArtist);
  };

  const getAllArtists = (reqMethod, pathName, params, setLoading = setIsGetArtistsLoading) => {
    getAllDataWithoutPaginate(reqMethod, pathName, params, setLoading)
      .then((response) => {
        setArtists(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const updateItem = async (e) => {
    e.preventDefault();
    if (userCan("fm_top_five_artists_update")) {
      tfArtistData.modify_user_id = user.currentUser._id;

      const validationResult = validateUpdateTfArtistForm();
      setUpdateResponse({});

      if (validationResult.error) {
        validationResult.error ? setJoiErrorsList(validationResult.error.details) : setJoiErrorsList([]);
      } else {
        setIsUpdateLoading(true);
        setJoiErrorsList([]);

        await axios({
          method: "POST",
          url: `${baseUrl}fm-top-five-artists/update/${currentTfArtist.key}`,
          data: tfArtistData,
          params: {
            _method: "PUT",
          },
          headers: {
            Accept: "application/json",
            "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
            Authorization: `Bearer ${user.accessToken}`,
          },
        })
          .then((response) => {
            setUpdateResponse(response.data);
            setIsUpdateLoading(false);
            if (response.data.msg && response.data.msg.includes("successfully")) {
              let timeOut = setTimeout(() => {
                setUpdateResponse({});
                navigate(`/fm-top-five-artists/${currentTfArtist.key}`);
              }, 4000);
              setNavigateTimeOut(timeOut);
              setTfArtistData({
                name: "",
                order: null,
                artist_id: null,
                modify_user_id: null,
              });
              e.target.reset();
              artistRef.current.clearValue();
              artistOrderRef.current.clearValue();
              toast(`${response.data.msg}, navigating...`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                containerId: "update_done_toast",
              });
            } else if (response.data.msg && response.data.msg.includes("failed")) {
              toast(response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          })
          .catch((errors) => {
            navigate("/not-found");
          });
      }
    } else {
      navigate("/not-found");
    }
  };

  const validateUpdateTfArtistForm = () => {
    const scheme = Joi.object({
      name: Joi.string().min(1).max(255).allow(null, "").optional(),
      order: Joi.number().min(1).max(5).required().messages({
        "number.base": `"Artist Order" is a required field`,
        "number.empty": `"Artist Order" is a required field`,
        "any.required": `"Artist Order" is a required field`,
      }),
      artist_id: Joi.number().required().messages({
        "number.base": `"Artist" is a required field`,
        "number.empty": `"Artist" is a required field`,
        "any.required": `"Artist" is a required field`,
      }),
      modify_user_id: Joi.number(),
    });
    return scheme.validate(tfArtistData, { abortEarly: false });
  };

  useEffect(() => {
    getTfArtist("GET", `${baseUrl}fm-one-from-top-five-artists/${tfArtistId}`, setIsTfArtistLoading, {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
      Authorization: `Bearer ${userToken}`,
    });
    getAllArtists("GET", `${baseUrl}fm-all-artists`, null, setIsGetArtistsLoading);
    setMounted(true);
  }, []);

  useEffect(() => {
    return () => {
      if (mounted) {
        clearTimeout(navigateTimeOut);
      }
    };
  }, [navigateTimeOut]);

  useEffect(() => {
    if (mounted && currentTfArtist) {
      let globalCrumbsUpdated = globalCrumbs.map((crumb) => {
        if (crumb.name === tfArtistId.toUpperCase()) {
          crumb.name = currentTfArtist.additional_artist_name ? currentTfArtist.additional_artist_name.toUpperCase() : currentTfArtist.artist_data.artist_name.toUpperCase();
        }
        return crumb;
      });
      setGlobalCrumbs(globalCrumbsUpdated);

      if (artistRef.current && currentTfArtist.artist_data) {
        artistRef.current.setValue({ value: currentTfArtist.artist_data._id, label: currentTfArtist.artist_data.artist_name });
      }

      if (artistOrderRef.current && currentTfArtist.artist_order) {
        artistOrderRef.current.setValue({ value: currentTfArtist.artist_order, label: currentTfArtist.artist_order });
      }
    }
  }, [currentTfArtist]);

  useEffect(() => {
    handleArtistSelect();
  }, [selectedArtist]);

  useEffect(() => {
    handleArtistOrder();
  }, [selectedArtistOrder]);

  return (
    <>
      {isTfArtistLoading || isGetArtistsLoading ? (
        <LoadingScreen />
      ) : (
        <div className="container position-relative pt-3 pt-md-4">
          <div className="mb-3 mb-md-4">
            <h1 className="fu-text-3-rem page-heading-title-sm-2-rem fu-text-white fu-fw-800 mb-0">Update Artist</h1>
          </div>
          <div className="py-2 mb-4 iu-row-heading create-submit-row">
            <h2 className="mb-0 row-title">Edit ( {currentTfArtist.additional_artist_name ? currentTfArtist.additional_artist_name : currentTfArtist.artist_data.artist_name} ) record in top 5 records</h2>
          </div>
          <div className="row">
            <div>
              <div>
                <Form onSubmit={updateItem}>
                  <Row>
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="additionalArtistName">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Additional Artist Name
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Additional Artist Name" className="fu-input" name="name" value={tfArtistData.name ? tfArtistData.name : ""} onInput={getTfArtistData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "name" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("name", "Additional Artist Name")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>

                    <Col xl={6}>
                      <div className="mb-3">
                        <div className="fu-text-light-gray w-fit-content mb-2" data-title="required">
                          Select Artist
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </div>
                        <div className="select-options">
                          <Select
                            ref={artistRef}
                            classNames={{
                              control: () => "select-filtration",
                              option: ({ isSelected, innerProps }) => (isSelected ? (innerProps.style = { backgroundColor: "#fdb915", color: "#191919" }) : (innerProps.style = { backgroundColor: "#ffffff" })),
                            }}
                            placeholder="Select Artist...."
                            name="artist"
                            onChange={setSelectedArtist}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            isClearable
                            options={artists.map((artist) => ({ value: artist.id, label: artist.name }))}
                          />
                        </div>
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "artist_id" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("artist_id", "Artist")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </div>
                    </Col>
                    <Col xl={6}>
                      <div className="mb-3">
                        <div className="fu-text-light-gray w-fit-content mb-2" data-title="required">
                          Artist Order
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </div>
                        <div className="select-options">
                          <Select
                            ref={artistOrderRef}
                            classNames={{
                              control: () => "select-filtration",
                              option: ({ isSelected, innerProps }) => (isSelected ? (innerProps.style = { backgroundColor: "#fdb915", color: "#191919" }) : (innerProps.style = { backgroundColor: "#ffffff" })),
                            }}
                            placeholder="Select Artist Order...."
                            name="artistOrder"
                            onChange={setSelectedArtistOrder}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            isClearable
                            options={[
                              { value: 1, label: 1 },
                              { value: 2, label: 2 },
                              { value: 3, label: 3 },
                              { value: 4, label: 4 },
                              { value: 5, label: 5 },
                            ]}
                          />
                        </div>
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "order" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("order", "Artist Order")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </div>
                    </Col>
                  </Row>

                  {updateResponse.errors
                    ? Object.keys(updateResponse.errors).map((key) =>
                        updateResponse.errors[key].map((error, index) => (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error}
                          </div>
                        ))
                      )
                    : ""}

                  <div className="d-flex align-items-center mt-4">
                    <div>
                      <Button className={`fu-btn fu-btn-gray ${isUpdateLoading ? "disabled" : ""}`} type="submit" disabled={isUpdateLoading ? true : false}>
                        <span className="btn-bg"></span>
                        Submit<i className="bi bi-send ms-2"></i>
                      </Button>
                    </div>
                    {isUpdateLoading ? (
                      <div className="position-relative ms-3">
                        <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateTfArtist;
