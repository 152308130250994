import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Reveal from "../Reveal/Reveal";
import { fmDataContext } from "../../Context/FmData";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import userCan from "../../helpers/userCan";
import Swal from "sweetalert2/dist/sweetalert2.all.min";
import "@sweetalert2/theme-dark/dark.min.css";
import { authenticationContext } from "../../Context/AuthContext";
import { fmPageHeadersContext } from "../../Context/PageHeadersContext";

const PageHeaderRow = (props) => {
  let { pageHeader, getPageHeaders, orderBy, orderType } = props;
  let { baseUrl, navigate, editItemExistence, goToBlogDetails } = useContext(fmDataContext);
  let { filtrationDetails } = useContext(fmPageHeadersContext);
  let { user } = useContext(authenticationContext);

  let [isSoftDeleteLoading, setIsSoftDeleteLoading] = useState(false);
  let [isForceDeleteLoading, setIsForceDeleteLoading] = useState(false);
  let [isRestoreLoading, setIsRestoreLoading] = useState(false);

  const softDeletePageHeader = (reqMethod, pathName, headers, setLoading) => {
    if (userCan("fm_page_header_soft_delete")) {
      Swal.fire({
        title: "Are you sure?",
        html: `<p class="mb-0 line-clamp-2">You will delete <span class="fu-text-light-gray">${pageHeader.page_title} header image</span></p>`,
        icon: "warning",
        showCancelButton: true,
        customClass: {
          cancelButton: "popup-btn",
          confirmButton: "popup-btn",
        },
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              getPageHeaders(
                "GET",
                `${baseUrl}fm-page-headers`,
                {
                  order_type: orderType,
                  order_by: orderBy,
                  ...filtrationDetails,
                },
                setLoading,
                `${pageHeader.page_title} header image Deleted Successfully`
              );
            })
            .catch((error) => {
              navigate("/not-found");
            });
        }
      });
    } else {
      navigate("/not-found");
    }
  };

  const restorePageHeader = (reqMethod, pathName, headers, setLoading) => {
    if (userCan("fm_page_header_restore_trashed")) {
      Swal.fire({
        title: "Are you sure?",
        html: `<p class="mb-0 line-clamp-2">You will restore <span class="fu-text-light-gray">${pageHeader.page_title} header image</span></p>`,
        icon: "question",
        showCancelButton: true,
        customClass: {
          cancelButton: "popup-btn",
          confirmButton: "popup-btn",
        },
        confirmButtonText: "Yes, restore it!",
      }).then((result) => {
        if (result.isConfirmed) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              getPageHeaders(
                "GET",
                `${baseUrl}fm-page-headers`,
                {
                  order_type: orderType,
                  order_by: orderBy,
                  ...filtrationDetails,
                },
                setLoading,
                `${pageHeader.page_title} header image Restored Successfully`
              );
            })
            .catch((error) => {
              navigate("/not-found");
            });
        }
      });
    } else {
      navigate("/not-found");
    }
  };

  const forceDeletePageHeader = (reqMethod, pathName, headers, setLoading) => {
    if (userCan("fm_page_header_force_delete")) {
      Swal.fire({
        title: "Are you sure?",
        html: `<p class="mb-0 line-clamp-2">You will delete <span class="fu-text-light-gray">${pageHeader.page_title} header image</span> permanently</p><p class="mb-0">You won't be able to revert this!</p>`,
        icon: "warning",
        showCancelButton: true,
        customClass: {
          cancelButton: "popup-btn",
          confirmButton: "popup-btn",
        },
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              getPageHeaders(
                "GET",
                `${baseUrl}fm-page-headers`,
                {
                  order_type: orderType,
                  order_by: orderBy,
                  ...filtrationDetails,
                },
                setLoading,
                `${pageHeader.page_title} header image Deleted Permanently Successfully`
              );
            })
            .catch((error) => {
              navigate("/not-found");
            });
        }
      });
    } else {
      navigate("/not-found");
    }
  };

  return (
    <Reveal>
      <div className="row py-3 align-items-center row-brdr">
        {/* id */}
        <div className="col-12 col-xxl-1 order-2 order-xxl-first">
          <h4 className="h6 mb-0 my-xxl-0 fu-text-white row-info">
            <span className="fw-bold d-d-inline-block d-xxl-none me-2">#ID:</span>
            {pageHeader._id}
          </h4>
        </div>
        {/* key */}
        <div className="col-12 col-xxl-4 order-3 order-xxl-1">
          <h4 className="h6 mb-0 my-xxl-0 fu-text-white row-info">
            <span className="fw-bold d-d-inline-block d-xxl-none me-2">KEY:</span>
            {pageHeader.key}
          </h4>
        </div>
        {/* image & name */}
        <div className="col-12 col-xxl-3 order-first order-xxl-2">
          <div className="d-flex align-items-center mouse-pointer mb-3 mt-2 my-xxl-0" onClick={() => goToBlogDetails(`/fm-page-headers/${pageHeader.key}`, pageHeader.deleted_at ? "1" : "0")}>
            <div className="rectangle-table-item-img-container d-inline-flex align-items-center">
              <img src={pageHeader.image} alt="page header" className="w-100" />
            </div>
            <h4 className="h6 mb-0 ms-2 px-2 fu-text-white line-clamp-1">{pageHeader.page_title}</h4>
          </div>
        </div>
        {/* operation */}
        <div className="col-12 col-xxl-4 order-4 order-xxl-3">
          <div className="d-flex align-items-center flex-wrap justify-content-center justify-content-xxl-start mt-3 mt-xxl-0">
            <div className="me-2 mb-2 mb-xxl-0">
              <Link to={`/fm-page-headers/${pageHeader.key}?archived=${pageHeader.deleted_at ? "1" : "0"}`} className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`} disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                <span className="btn-bg"></span>
                Show
              </Link>
            </div>
            {pageHeader.deleted_at === null ? (
              userCan("fm_page_header_update") ? (
                <div className="me-2 mb-2 mb-xxl-0">
                  <Link to={`/fm-page-headers/update/${pageHeader.key}`} className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`} disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                    <span className="btn-bg"></span>
                    Edit
                  </Link>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {pageHeader.deleted_at === null ? (
              userCan("fm_page_header_soft_delete") ? (
                <div className="me-2 mb-2 mb-xxl-0">
                  <button
                    className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`}
                    onClick={() =>
                      softDeletePageHeader(
                        "DELETE",
                        `${baseUrl}fm-page-headers/delete/${pageHeader.key}`,
                        {
                          Accept: "application/json",
                          "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                          Authorization: `Bearer ${user.accessToken}`,
                        },
                        setIsSoftDeleteLoading
                      )
                    }
                    disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                    <span className="btn-bg"></span>
                    Delete
                  </button>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {pageHeader.deleted_at !== null ? (
              userCan("fm_page_header_restore_trashed") ? (
                <div className="me-2 mb-2 mb-xxl-0">
                  <button
                    className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`}
                    onClick={() =>
                      restorePageHeader(
                        "PATCH",
                        `${baseUrl}fm-page-headers/restore/${pageHeader.key}`,
                        {
                          Accept: "application/json",
                          "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                          Authorization: `Bearer ${user.accessToken}`,
                        },
                        setIsRestoreLoading
                      )
                    }
                    disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                    <span className="btn-bg"></span>
                    Restore
                  </button>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {userCan("fm_page_header_force_delete") ? (
              <div className="me-2 mb-2 mb-xxl-0">
                <button
                  className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`}
                  onClick={() =>
                    forceDeletePageHeader(
                      "DELETE",
                      `${baseUrl}fm-page-headers/force-delete/${pageHeader.key}`,
                      {
                        Accept: "application/json",
                        "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                        Authorization: `Bearer ${user.accessToken}`,
                      },
                      setIsForceDeleteLoading
                    )
                  }
                  disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                  <span className="btn-bg"></span>
                  Force Delete
                </button>
              </div>
            ) : (
              ""
            )}

            {isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? (
              <div className="position-relative ms-2 mb-2 mb-xxl-0">
                <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </Reveal>
  );
};

export default PageHeaderRow;
