import React, { useContext, useEffect, useRef, useState } from "react";
import { fmDataContext } from "../../Context/FmData";
import Pagination from "../Pagination/Pagination";
import { Link } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import ArtistRow from "./ArtistRow";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { fmArtistsContext } from "../../Context/ArtistsContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";
import ArtistsFiltration from "./ArtistsFiltration";

const Artists = () => {
  const isCalledRef = useRef(false);

  let { baseUrl, navigate, getData, getAllDataWithoutPaginate } = useContext(fmDataContext);

  let { artistMsg, setArtistMsg, filtrationDetails, isFiltrationLoading, setFiltrationDetails } = useContext(fmArtistsContext);

  let [artists, setArtists] = useState({});
  let [labels, setLabels] = useState([]);

  let [isGetArtistsLoading, setIsGetArtistsLoading] = useState(true);
  let [isGetLabelsLoading, setIsGetLabelsLoading] = useState(true);

  let [isPaginateLoading, setIsPaginateLoading] = useState(false);

  let [pageNum, setPageNum] = useState(1);

  let perPage = 20;
  let orderBy = "id";
  let orderType = "asc";

  const getArtists = (reqMethod, pathName, params, setLoading = setIsGetArtistsLoading, message = null) => {
    getData(reqMethod, pathName, params, setLoading)
      .then((response) => {
        // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
        // if (response.data.data) {
        //   response.data.data.forEach((artist) => {
        //     if (artist.artist_image.includes("via.placeholder.com")) {
        //       artist.artist_image = artist.artist_image.replace("http://127.0.0.1:8000/storage/", "");
        //     }
        //   });
        // }
        // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
        setArtists(response.data);
        setLoading(false);

        if (artistMsg.length > 0) {
          toast(artistMsg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
            onClose: () => setArtistMsg(""),
          });
        }

        if (message) {
          toast(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const getAllLabels = (reqMethod, pathName, params, setLoading = setIsGetLabelsLoading) => {
    getAllDataWithoutPaginate(reqMethod, pathName, params, setLoading)
      .then((response) => {
        setLoading(false);
        setLabels(response.data.data);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    if (!isCalledRef.current) {
      getArtists(
        "GET",
        `${baseUrl}fm-artists`,
        {
          page_num: pageNum,
          per_page: perPage,
          order_type: orderType,
          order_by: orderBy,
        },
        setIsGetArtistsLoading
      );
      getAllLabels("GET", `${baseUrl}fm-all-labels`, null, setIsGetLabelsLoading);
    }

    return () => {
      isCalledRef.current = true;
      setFiltrationDetails({
        search_query: "",
        label_keys: [],
        main_artist: null,
        top_five_artists: 0,
        only_trashed: 0,
      });
    };
  }, []);

  return (
    <>
      <div className="container">
        <div className={`${!isGetArtistsLoading && !isGetLabelsLoading ? "min-vh-100" : ""}`}>
          <div className="mb-2 mb-sm-5">
            <div className="d-flex align-items-center justify-content-between mb-3 mt-3">
              <h1 className="page-heading-title mb-0">ARTISTS</h1>
              {userCan("fm_artist_create") ? (
                <div className="me-3">
                  <Link to="/fm-artists/create" className="fu-btn fu-btn-gray">
                    <span className="btn-bg"></span>
                    Create New
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
            {/* ----------------------------------------------------- */}
            <div className="mt-4">
              <ArtistsFiltration labels={labels} getArtists={getArtists} perPage={perPage} orderBy={orderBy} orderType={orderType} />
            </div>
            {/* ----------------------------------------------------- */}
          </div>
          {!isGetArtistsLoading && !isGetLabelsLoading ? (
            <>
              <div className="row py-3 d-none d-xxl-flex table-heading-brdr">
                <div className="col-md-1">
                  <h3 className="h6 mb-0 fu-text-white">#ID</h3>
                </div>
                <div className="col-md-4">
                  <h3 className="h6 mb-0 fu-text-white">KEY</h3>
                </div>
                <div className="col-md-3">
                  <h3 className="h6 mb-0 fu-text-white">ARTIST NAME</h3>
                </div>
                <div className="col-md-4">
                  <h3 className="h6 mb-0 fu-text-white">OPERATIONS</h3>
                </div>
              </div>
              <div className="table-heading-brdr mt-4 mt-sm-0 d-xxl-none"></div>
              {isPaginateLoading ? (
                <div className="d-flex justify-content-center mt-5">
                  <div className="position-relative mt-5">
                    <LoadingCircle circleCont={85} icon={80} loader={85} dot={85} />
                  </div>
                </div>
              ) : (
                <>
                  {artists.data && artists.data.length > 0 ? (
                    artists.data.map((artist, index) => <ArtistRow key={index} meta={artists.meta} artist={artist} index={index} getArtists={getArtists} perPage={perPage} pageNum={pageNum} orderBy={orderBy} orderType={orderType} />)
                  ) : artists.data && artists.data.length === 0 && filtrationDetails.search_query === "" && filtrationDetails.label_keys.length === 0 && filtrationDetails.main_artist === null && filtrationDetails.top_five_artists === 0 && filtrationDetails.only_trashed === 0 && !isFiltrationLoading ? (
                    <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                      <div>
                        <h2 className="fu-text-3-rem fu-text-yellow">
                          <i className="bi bi-database-add"></i>
                        </h2>
                      </div>
                      <div>
                        <h3 className="h5 fu-text-light-gray">
                          There Are No Artists
                          {userCan("fm_artist_create") ? (
                            <>
                              ,
                              <Link to="/fm-artists/create" className="link-dashed ms-2">
                                Create New
                              </Link>
                            </>
                          ) : (
                            ""
                          )}
                        </h3>
                      </div>
                    </div>
                  ) : !isFiltrationLoading ? (
                    <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                      <div>
                        <h2 className="fu-text-3-rem fu-text-yellow">
                          <i className="bi bi-search"></i>
                        </h2>
                      </div>
                      <div>
                        <h3 className="h5 fu-text-light-gray">There are no artists matching your search</h3>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="mt-5">{artists ? artists.meta && artists.meta.last_page !== 1 ? <Pagination pages={artists.meta.links} pagination={artists.meta} getItems={getArtists} pathName={"fm-artists"} filtrationDetails={filtrationDetails} setIsPaginateLoading={setIsPaginateLoading} setPageNum={setPageNum} per_page={perPage} order_by={orderBy} order_type={orderType} /> : "" : ""}</div>
                </>
              )}
            </>
          ) : (
            <LoadingScreen />
          )}
        </div>
      </div>
    </>
  );
};

export default Artists;
