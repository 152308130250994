import React from "react";

const Footer = () => {
  return (
    <>
      <div className="d-flex justify-content-center">
        <h2 className="h6 fu-text-light-gray fu-fw-400 mb-0 pb-4 footer-text">
          Copyright<span className="fu-text-yellow mx-2">©️</span>2023 Futuring Music All Rights Reserved. — Website by AAK
        </h2>
      </div>
      <div className="virtual-mobile-bar-height"></div>
    </>
  );
};

export default Footer;
