import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { fmDataContext } from "../../Context/FmData";
import Joi from "joi";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { authenticationContext } from "../../Context/AuthContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const CreateRelease = () => {
  let { baseUrl, navigate, getAllDataWithoutPaginate } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  const animatedComponents = makeAnimated();
  let labelKeyRef = useRef();
  let artistKeysRef = useRef();
  let genreKeyRef = useRef();
  let releaseStatusRef = useRef();
  let releaseKeysRef = useRef();

  let [selectedImage, setSelectedImage] = useState({});

  let [joiErrorsList, setJoiErrorsList] = useState([]);
  let [imageInputError, setImageInputError] = useState(null);

  let [selectedLabelKey, setSelectedLabelKey] = useState(null);
  let [selectedArtistKeys, setSelectedArtistKeys] = useState([]);
  let [selectedGenreKey, setSelectedGenreKey] = useState(null);
  let [selectedReleaseStatus, setSelectedReleaseStatus] = useState(null);
  let [selectedReleaseKeys, setSelectedReleaseKeys] = useState([]);

  let [releaseData, setReleaseData] = useState({
    title: "",
    image: selectedImage,
    description: null,
    release_date: null,
    catalog: null,
    pre_order: null,
    spotify_id: null,
    beatport_url: null,
    spotify_url: null,
    sound_cloud_url: null,
    youtube_url: null,
    youtube_music_url: null,
    apple_music_url: null,
    deezer_music_url: null,
    amazon_music_url: null,
    user_id: null,
    label_id: null,
    genre_id: null,
    artists_ids: [],
    releases_ids: [],
  });

  let [isCreateLoading, setIsCreateLoading] = useState(false);

  let [createResponse, setCreateResponse] = useState({});

  let [isGetMediaBasiesLoading, setIsGetMediaBasiesLoading] = useState(true);

  let [labels, setLabels] = useState([]);

  let [artists, setArtists] = useState([]);

  let [genres, setGenres] = useState([]);

  let [releases, setReleases] = useState([]);

  const getReleaseData = (e) => {
    const release = { ...releaseData };
    release[e.target.name] = e.target.value;
    setReleaseData(release);
  };

  const handleReleaseStatus = () => {
    const release = { ...releaseData };
    release.pre_order = selectedReleaseStatus !== null && selectedReleaseStatus.value !== null ? selectedReleaseStatus.value : null;
    setReleaseData(release);
  };

  const handleLabelSelect = () => {
    const release = { ...releaseData };
    release.label_id = selectedLabelKey !== null && selectedLabelKey.value !== null ? selectedLabelKey.value : null;
    setReleaseData(release);
  };

  const handleArtistsSelect = () => {
    const release = { ...releaseData };
    release.artists_ids = selectedArtistKeys.map((artist) => artist.value);
    setReleaseData(release);
  };

  const handleGenreSelect = () => {
    const release = { ...releaseData };
    release.genre_id = selectedGenreKey !== null && selectedGenreKey.value !== null ? selectedGenreKey.value : null;
    setReleaseData(release);
  };

  const handleReleasesSelect = () => {
    const release = { ...releaseData };
    release.releases_ids = selectedReleaseKeys.map((release) => release.value);
    setReleaseData(release);
  };

  const getMediaBasies = (reqMethod, pathName, params, setLoading = setIsGetMediaBasiesLoading) => {
    getAllDataWithoutPaginate(reqMethod, pathName, params, setLoading)
      .then((response) => {
        setLabels(response.data.labels);
        setArtists(response.data.artists);
        setGenres(response.data.genres);
        setReleases(response.data.releases);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const handleImageSelect = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const createItem = async (e) => {
    e.preventDefault();

    if (userCan("fm_release_create")) {
      releaseData.user_id = user.currentUser._id;
      releaseData.description = releaseData.description ? releaseData.description.replaceAll("\n", "</br>") : null;
      const validationResult = validateCreateReleaseForm();
      let imageError = null;
      setCreateResponse({});

      if (selectedImage) {
        releaseData.image = selectedImage;
        if (!releaseData.image.name) {
          imageError = "Image Input Is Required";
        } else if (!["image/png", "image/jpg", "image/jpeg", "image/gif"].includes(releaseData.image.type)) {
          imageError = "File must be an Image and from These extensions (png, jpg, jpeg, gif)";
        } else if (releaseData.image.size > 5 * 1024 * 1024) {
          imageError = "Image Size Must Be Less Than 5 MB";
        }
      } else {
        imageError = "Image Input Is Required";
        releaseData.image = {};
      }

      if (validationResult.error || imageError) {
        validationResult.error ? setJoiErrorsList(validationResult.error.details) : setJoiErrorsList([]);
        setImageInputError(imageError);
      } else {
        setIsCreateLoading(true);
        setImageInputError(null);
        setJoiErrorsList([]);
        await axios({
          method: "POST",
          url: `${baseUrl}fm-releases/create`,
          data: releaseData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
            Authorization: `Bearer ${user.accessToken}`,
          },
        })
          .then((response) => {
            setCreateResponse(response.data);
            setIsCreateLoading(false);
            if (response.data.msg && response.data.msg.includes("successfully")) {
              setReleaseData({
                title: "",
                image: {},
                description: null,
                release_date: null,
                catalog: null,
                pre_order: null,
                spotify_id: null,
                beatport_url: null,
                spotify_url: null,
                sound_cloud_url: null,
                youtube_url: null,
                youtube_music_url: null,
                apple_music_url: null,
                deezer_music_url: null,
                amazon_music_url: null,
                user_id: null,
                label_id: null,
                genre_id: null,
                artists_ids: [],
                releases_ids: [],
              });
              setSelectedImage({});
              e.target.reset();
              labelKeyRef.current.clearValue();
              artistKeysRef.current.clearValue();
              genreKeyRef.current.clearValue();
              releaseKeysRef.current.clearValue();
              releaseStatusRef.current.clearValue();
              setCreateResponse({});
              toast(response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            } else if (response.data.msg && response.data.msg.includes("failed")) {
              toast(response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          })
          .catch((errors) => {
            navigate("/not-found");
          });
      }
    } else {
      navigate("/not-found");
    }
  };

  const validateCreateReleaseForm = () => {
    const scheme = Joi.object({
      title: Joi.string().min(1).max(255).required(),
      description: Joi.string().min(1).max(1500).required(),
      release_date: Joi.string().min(1).max(255).required(),
      image: Joi.any(),
      catalog: Joi.string().min(1).max(255).required(),
      user_id: Joi.number(),
      pre_order: Joi.number().required().messages({
        "number.base": `"Release Status" is a required field`,
        "number.empty": `"Release Status" is a required field`,
        "any.required": `"Release Status" is a required field`,
      }),
      spotify_id: Joi.string().max(255).optional().allow(null, ""),
      beatport_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Beatport URL is not valid").allow(null, "").optional(),
      spotify_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Spotify URL is not valid").allow(null, "").optional(),
      sound_cloud_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Sound cloud URL is not valid").allow(null, "").optional(),
      youtube_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Youtube URL is not valid").allow(null, "").optional(),
      youtube_music_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Youtube Music URL is not valid").allow(null, "").optional(),
      apple_music_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Apple Music URL is not valid").allow(null, "").optional(),
      deezer_music_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Deezer URL is not valid").allow(null, "").optional(),
      amazon_music_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Amazon Music URL is not valid").allow(null, "").optional(),
      label_id: Joi.number().allow(null, "").optional(),
      genre_id: Joi.number().allow(null, "").optional(),
      artists_ids: Joi.array().items(Joi.number()).allow(null).optional(),
      releases_ids: Joi.array().items(Joi.number()).allow(null).optional(),
    });
    return scheme.validate(releaseData, { abortEarly: false });
  };

  useEffect(() => {
    getMediaBasies("GET", `${baseUrl}fm-get-tracks-data`, null, setIsGetMediaBasiesLoading);
  }, []);

  useEffect(() => {
    handleLabelSelect();
  }, [selectedLabelKey]);

  useEffect(() => {
    handleArtistsSelect();
  }, [selectedArtistKeys]);

  useEffect(() => {
    handleGenreSelect();
  }, [selectedGenreKey]);

  useEffect(() => {
    handleReleasesSelect();
  }, [selectedReleaseKeys]);

  useEffect(() => {
    handleReleaseStatus();
  }, [selectedReleaseStatus]);

  return (
    <>
      {isGetMediaBasiesLoading ? (
        <LoadingScreen />
      ) : (
        <div className="container position-relative pt-3 pt-md-4">
          <div className="mb-3 mb-md-4">
            <h1 className="page-heading-title mb-0">Create Release</h1>
          </div>
          <div className="py-2 mb-4 iu-row-heading create-submit-row">
            <h2 className="mb-0 row-title">Submit New Release To Releases List</h2>
          </div>
          <div className="row">
            <div>
              <div>
                <Form onSubmit={createItem}>
                  <Row>
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="releaseTitle">
                        <Form.Label className="fu-text-light-gray" data-title="required">
                          Release Title
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Release Title" className="fu-input" name="title" onInput={getReleaseData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "title" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("t", "Release t")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="releaseImage">
                        <Form.Label className="fu-text-light-gray" data-title="required">
                          Release Image
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="file" placeholder="choose Release Image" className="fu-input" name="image" onInput={handleImageSelect} />
                        {imageInputError !== null ? <div className="alert alert-danger py-1 px-2 mt-2 mb-0">{imageInputError}</div> : ""}
                      </Form.Group>
                    </Col>
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="releaseDate">
                        <Form.Label className="fu-text-light-gray" data-title="required">
                          Release Date
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Release Date, EX: DD-MM-YYYY" className="fu-input" name="release_date" onInput={getReleaseData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "release_date" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("release_date", "Release date")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="releaseCatalog">
                        <Form.Label className="fu-text-light-gray" data-title="required">
                          Release Catalog
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Release Catalog" className="fu-input" name="catalog" onInput={getReleaseData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "catalog" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("c", "Release c")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="releaseDiscription">
                        <Form.Label className="fu-text-light-gray" data-title="required">
                          Release Discription
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </Form.Label>
                        <div className="textarea-container">
                          <Form.Control as="textarea" rows={4} className="fu-input" placeholder="Enter your release discription" name="description" onInput={getReleaseData} />
                        </div>
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "description" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("d", "Release d")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="releaseSpotifyId">
                        <div className="d-flex flex-wrap align-items-center">
                          <Form.Label className="fu-text-light-gray me-2" data-title="optional but required if release status is out now">
                            Release Spotify ID
                            <span className="optional-input mx-1" data-title="optional but required if release status is out now">
                              *
                            </span>
                          </Form.Label>
                          <span className="fu-text-light-gray d-inline-flex mb-2 form-explain">
                            <i className="me-2">
                              <strong>EX:</strong>
                            </i>
                            <span className="text-lined-through">https://open.spotify.com/</span>
                            <span className="fu-text-yellow">track/3GTFPGj0JwvD1Q5PJNr13k</span>
                          </span>
                        </div>

                        <Form.Control type="text" placeholder="Enter Release Spotify Id" className="fu-input" name="spotify_id" onInput={getReleaseData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "spotify_id" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("spotify_id", "Release spotify id")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>

                    {/* Release status req*/}
                    <Col xl={6}>
                      <div className="mb-3">
                        <div className="fu-text-light-gray w-fit-content mb-2" data-title="required">
                          Release Status
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </div>
                        <div className="select-options">
                          <Select
                            ref={releaseStatusRef}
                            classNames={{
                              control: () => "select-filtration",
                              option: ({ isSelected, innerProps }) => (isSelected ? (innerProps.style = { backgroundColor: "#fdb915", color: "#191919" }) : (innerProps.style = { backgroundColor: "#ffffff" })),
                            }}
                            placeholder="Select Artist Status...."
                            name="releaseStatus"
                            onChange={setSelectedReleaseStatus}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            isClearable
                            options={[
                              { value: 0, label: "Out Now" },
                              { value: 1, label: "Out Soon" },
                            ]}
                          />
                        </div>
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "pre_order" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("pre_order", "Pre Order")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </div>
                    </Col>

                    {/* release artists optional*/}
                    <Col xl={6}>
                      <div className="mb-3">
                        <div className="fu-text-light-gray w-fit-content mb-2" data-title="optional">
                          Release Artists
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </div>
                        <div className="select-options">
                          <Select
                            ref={artistKeysRef}
                            classNames={{
                              control: () => "select-filtration",
                              valueContainer: ({ innerProps }) => {
                                innerProps.style = { display: "flex", flexWrap: "nowrap" };
                              },
                            }}
                            styles={{
                              multiValue: () => ({ overflow: "visible", width: "max-content", display: "inline-flex", alignItems: "center", backgroundColor: "#2f2f2f", margin: "0 2px", borderRadius: "3px", fontSize: "14px", padding: "0 0 0 2px" }),
                              multiValueLabel: () => ({ color: "#fdb915" }),
                              multiValueRemove: () => ({ color: "#a5a5a5", marginLeft: "3px", padding: "2px", display: "inline-flex", alignItems: "center", transition: "all 0.3s", ":hover": { color: "#fdb915" } }),
                            }}
                            placeholder="Select Release Artists...."
                            name="releaseArtist"
                            onChange={setSelectedArtistKeys}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={artists.map((artist) => ({ value: artist.id, label: artist.name }))}
                          />
                        </div>
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "artists_ids" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("_ids", "")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </div>
                    </Col>

                    {/* release genre optional*/}
                    <Col xl={6}>
                      <div className="mb-3">
                        <div className="fu-text-light-gray w-fit-content mb-2" data-title="optional">
                          Release Genre
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </div>
                        <div className="select-options">
                          <Select
                            ref={genreKeyRef}
                            classNames={{
                              control: () => "select-filtration",
                              option: ({ isSelected, innerProps }) => (isSelected ? (innerProps.style = { backgroundColor: "#fdb915", color: "#191919" }) : (innerProps.style = { backgroundColor: "#ffffff" })),
                            }}
                            placeholder="Select Release Genre...."
                            name="releaseGenre"
                            onChange={setSelectedGenreKey}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            isClearable
                            options={genres.map((genre) => ({ value: genre.id, label: genre.title }))}
                          />
                        </div>
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "genre_id" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("_id", "")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </div>
                    </Col>

                    {/* releases like this optional*/}
                    <Col xl={6}>
                      <div className="mb-3">
                        <div className="fu-text-light-gray w-fit-content mb-2" data-title="optional">
                          Releases Like This
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </div>
                        <div className="select-options">
                          <Select
                            ref={releaseKeysRef}
                            classNames={{
                              control: () => "select-filtration",
                              valueContainer: ({ innerProps }) => {
                                innerProps.style = { display: "flex", flexWrap: "nowrap" };
                              },
                            }}
                            styles={{
                              multiValue: () => ({ overflow: "visible", width: "max-content", display: "inline-flex", alignItems: "center", backgroundColor: "#2f2f2f", margin: "0 2px", borderRadius: "3px", fontSize: "14px", padding: "0 0 0 2px" }),
                              multiValueLabel: () => ({ color: "#fdb915" }),
                              multiValueRemove: () => ({ color: "#a5a5a5", marginLeft: "3px", padding: "2px", display: "inline-flex", alignItems: "center", transition: "all 0.3s", ":hover": { color: "#fdb915" } }),
                            }}
                            placeholder="Select Releases Like This...."
                            name="relatedReleases"
                            onChange={setSelectedReleaseKeys}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={releases.map((release) => ({ value: release.id, label: release.title }))}
                          />
                        </div>
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "releases_ids" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("_ids", "")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </div>
                    </Col>

                    {/* release label optional*/}
                    <Col xl={6}>
                      <div className="mb-3">
                        <div className="fu-text-light-gray w-fit-content mb-2" data-title="optional">
                          Release Label
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </div>
                        <div className="select-options">
                          <Select
                            ref={labelKeyRef}
                            classNames={{
                              control: () => "select-filtration",
                              option: ({ isSelected, innerProps }) => (isSelected ? (innerProps.style = { backgroundColor: "#fdb915", color: "#191919" }) : (innerProps.style = { backgroundColor: "#ffffff" })),
                            }}
                            placeholder="Select Release Label...."
                            name="releaseLabel"
                            onChange={setSelectedLabelKey}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            isClearable
                            options={labels.map((label) => ({ value: label.id, label: label.name }))}
                          />
                        </div>
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "label_id" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("_id", "")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </div>
                    </Col>
                  </Row>
                  {/* ------------------------------------ */}
                  <Row>
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="beatportUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional but required if release status is out soon">
                          Release Beatport URL
                          <span className="optional-input mx-1" data-title="optional but required if release status is out soon">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Release Beatport URL" className="fu-input" name="beatport_url" onInput={getReleaseData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "beatport_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="spotifyUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Release Spotify URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Release Spotify URL" className="fu-input" name="spotify_url" onInput={getReleaseData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "spotify_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="soundCloudUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Release Sound Cloud URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Release Sound Cloud URL" className="fu-input" name="sound_cloud_url" onInput={getReleaseData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "sound_cloud_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="youtubeUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Release YouTube URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Release YouTube URL" className="fu-input" name="youtube_url" onInput={getReleaseData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "youtube_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    {/* ------------------ */}
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="youTubeMusicUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Release YouTube Music URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Release YouTube Music URL" className="fu-input" name="youtube_music_url" onInput={getReleaseData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "youtube_music_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="appleMusicUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Release Apple Music URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Release Apple Music URL" className="fu-input" name="apple_music_url" onInput={getReleaseData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "apple_music_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="deezerUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Release Deezer URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Release Deezer URL" className="fu-input" name="deezer_music_url" onInput={getReleaseData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "deezer_music_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="amazonMusicUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Release Amazon Music URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Release Amazon Music URL" className="fu-input" name="amazon_music_url" onInput={getReleaseData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "amazon_music_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    {/* ------------------------------------ */}
                  </Row>
                  {createResponse.errors
                    ? Object.keys(createResponse.errors).map((key) =>
                        createResponse.errors[key].map((error, index) => (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error.includes("The spotify id field is required when pre order is 0.") ? error.replace("The spotify id field is required when pre order is 0.", "Spotify id is required if release status is out now") : error.includes("The beatport url field is required when pre order is 1.") ? error.replace("The beatport url field is required when pre order is 1.", "Beatport Url is required if release status is out soon") : error}
                          </div>
                        ))
                      )
                    : ""}
                  {/* The beatport url field is required when pre order is 1. */}
                  {/* Spotify id is required if release status is out now */}
                  <div className="d-flex align-items-center mt-4">
                    <div>
                      <Button className={`fu-btn fu-btn-gray ${isCreateLoading ? "disabled" : ""}`} type="submit" disabled={isCreateLoading ? true : false}>
                        <span className="btn-bg"></span>
                        Submit<i className="bi bi-send ms-2"></i>
                      </Button>
                    </div>
                    {isCreateLoading ? (
                      <div className="position-relative ms-3">
                        <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateRelease;
