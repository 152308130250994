import React, { useContext, useEffect, useRef, useState } from "react";
import { fmDataContext } from "../../Context/FmData";
import Pagination from "../Pagination/Pagination";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import ContactMsgRow from "./ContactMsgRow";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { fmContactMsgsContext } from "../../Context/ContactMsgsContext";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";

const ContactMsgs = () => {
  const isCalledRef = useRef(false);

  let { baseUrl, navigate, getData } = useContext(fmDataContext);

  let [contactMsgs, setContactMsgs] = useState({});

  let [isGetContactMsgsLoading, setIsGetContactMsgsLoading] = useState(true);

  let [isPaginateLoading, setIsPaginateLoading] = useState(false);

  let [pageNum, setPageNum] = useState(1);

  let perPage = 20;
  let orderBy = "id";
  let orderType = "desc";

  let { contactMsg, setContactMsg } = useContext(fmContactMsgsContext);

  let userToken = jwtDecode(localStorage.getItem("userToken")).accessToken;

  const getContactMsgs = (
    reqMethod,
    pathName,
    params,
    setLoading = setIsGetContactMsgsLoading,
    message = null,
    headers = {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
      Authorization: `Bearer ${userToken}`,
    }
  ) => {
    getData(reqMethod, pathName, params, setLoading, headers)
      .then((response) => {
        setContactMsgs(response.data);
        setLoading(false);

        if (contactMsg.length > 0) {
          toast(contactMsg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
            onClose: () => setContactMsg(""),
          });
        }

        if (message) {
          toast(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    if (!isCalledRef.current) {
      getContactMsgs(
        "GET",
        `${baseUrl}fm-contact-messages`,
        {
          page_num: pageNum,
          per_page: perPage,
          order_type: orderType,
          order_by: orderBy,
        },
        setIsGetContactMsgsLoading
      );
    }
    return () => {
      isCalledRef.current = true;
    };
  }, []);

  return (
    <>
      <div className="container">
        <div className={`${!isGetContactMsgsLoading ? "min-vh-100" : ""}`}>
          <div className="mb-2 mb-sm-4 mb-lg-4 mt-3">
            <div className="d-flex align-items-center justify-content-between mb-0">
              <h1 className="fu-text-3-rem fu-text-3-rem-md fu-text-md-2-rem fu-text-sm-2-rem fu-text-1-8-rem-xs fu-text-18-px-xxs fu-text-16-px-xxs fu-fw-800 fu-text-white mb-0">CONTACT MESSAGES</h1>
            </div>
          </div>
          {!isGetContactMsgsLoading ? (
            <>
              <div className="row py-3 d-none d-xxl-flex table-heading-brdr">
                <div className="col-md-1">
                  <h3 className="h6 mb-0 fu-text-white">#ID</h3>
                </div>
                <div className="col-md-2">
                  <h3 className="h6 mb-0 fu-text-white">SENDER NAME</h3>
                </div>
                <div className="col-md-3">
                  <h3 className="h6 mb-0 fu-text-white">SENDER EMAIL</h3>
                </div>
                <div className="col-md-3">
                  <h3 className="h6 mb-0 fu-text-white">SENT AT</h3>
                </div>
                <div className="col-md-3">
                  <h3 className="h6 mb-0 fu-text-white">OPERATIONS</h3>
                </div>
              </div>
              <div className="table-heading-brdr mt-4 mt-sm-0 d-xxl-none"></div>
              {isPaginateLoading ? (
                <div className="d-flex justify-content-center mt-5">
                  <div className="position-relative mt-5">
                    <LoadingCircle circleCont={85} icon={80} loader={85} dot={85} />
                  </div>
                </div>
              ) : (
                <>
                  {contactMsgs.data && contactMsgs.data.length > 0 ? (
                    contactMsgs.data.map((contactMsg, index) => <ContactMsgRow key={index} meta={contactMsgs.meta} contactMsg={contactMsg} index={index} getContactMsgs={getContactMsgs} perPage={perPage} pageNum={pageNum} orderBy={orderBy} orderType={orderType} />)
                  ) : (
                    <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                      <div>
                        <h2 className="fu-text-3-rem fu-text-yellow">
                          <i className="bi bi-database-add"></i>
                        </h2>
                      </div>
                      <div>
                        <h3 className="h5 fu-text-light-gray">There Are No Contact Messages</h3>
                      </div>
                    </div>
                  )}
                  <div className="mt-5">{contactMsgs ? contactMsgs.meta && contactMsgs.meta.last_page !== 1 ? <Pagination pages={contactMsgs.meta.links} pagination={contactMsgs.meta} getItems={getContactMsgs} pathName={"fm-contact-messages"} filtrationDetails={{}} setIsPaginateLoading={setIsPaginateLoading} setPageNum={setPageNum} per_page={perPage} order_by={orderBy} order_type={orderType} /> : "" : ""}</div>
                </>
              )}
            </>
          ) : (
            <LoadingScreen />
          )}
        </div>
      </div>
    </>
  );
};

export default ContactMsgs;
