import React, { useContext, useState } from "react";
import styles from "./SearchBar.module.scss";
import { authenticationContext } from "../../Context/AuthContext";
import Swal from "sweetalert2/dist/sweetalert2.all.min";
import "@sweetalert2/theme-dark/dark.min.css";
import { fmDataContext } from "../../Context/FmData";
import axios from "axios";
import LoadingCircle from "../LoadingCircle/LoadingCircle";

const SearchBar = () => {
  let { navigate } = useContext(fmDataContext);
  let { user, setUser, setUserPermissions, authApiBaseUrl } = useContext(authenticationContext);
  let [isLogoutLoading, setIsLogoutLoading] = useState(false);

  let logoutUser = (e) => {
    if (e.target.innerHTML === "Logout From All devices" || e.target.innerHTML === "Logout") {
      Swal.fire({
        title: "Are you sure?",
        html: `<p class="mb-0">You want to logout from ${e.target.innerHTML === "Logout From All devices" ? "all" : "this"} device${e.target.innerHTML === "Logout From All devices" ? "s" : ""}</p>`,
        icon: "warning",
        showCancelButton: true,
        customClass: {
          cancelButton: "popup-btn",
          confirmButton: "popup-btn",
        },
        confirmButtonText: "Yes, logout!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setIsLogoutLoading(true);
          try {
            await axios({
              method: "POST",
              url: `${authApiBaseUrl}${e.target.innerHTML === "Logout From All devices" ? "fm-logout-all-devices" : "fm-logout"}`,
              headers: {
                Accept: "application/json",
                "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                Authorization: `Bearer ${user.accessToken}`,
              },
            })
              .then(() => {
                localStorage.removeItem("userToken");
                navigate("/login");
                setUser(null);
                setUserPermissions([]);
                setIsLogoutLoading(false);
              })
              .catch((error) => {
                setIsLogoutLoading(false);
                localStorage.removeItem("userToken");
                navigate("/login");
                setUser(null);
                setUserPermissions([]);
                setIsLogoutLoading(false);
              });
          } catch (error) {
            navigate("/not-found");
          }
        }
      });
    }
  };

  const changeBgOnMouseDown = (e) => {
    e.target.style.cssText = "background-color:#fdb915 !important";
  };

  const changeBgOnMouseUp = (e) => {
    e.target.style.cssText = "background-color:transparent !important";
  };

  const changeBgOnMouseEnter = (e) => {
    e.target.style.cssText = "background-color:#ffffff !important";
  };

  const changeBgOnMouseLeave = (e) => {
    e.target.style.cssText = "background-color:transparent !important";
  };

  return (
    <>
      <div className="fu-bg-black">
        <div>
          <div className="py-0 py-md-1 d-flex align-items-center">
            <div>
              <div className="dropdown">
                <button className={`btn btn-secondary btn-sm dropdown-toggle fu-text-12-px-xxs fu-text-12-px-xs fu-text-12-px-sm logout-dropdown-btn px-0 ${styles.logout_btn}`} type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <span className="d-inline-flex justify-content-center align-items-center">
                    {isLogoutLoading ? (
                      <span className="d-inline-flex  justify-content-center align-items-center position-relative me-2">
                        <LoadingCircle circleCont={20} icon={0} loader={20} dot={20} />
                      </span>
                    ) : (
                      ""
                    )}
                    {user && user.currentUser.name}
                  </span>
                  <span className="ms-2">
                    <i className="bi bi-caret-down"></i>
                  </span>
                </button>
                <ul className={`dropdown-menu ${styles.dropdown_menu}`}>
                  <li>
                    <button
                      className={`dropdown-item ${styles.dropdown_item}`}
                      onClick={logoutUser}
                      onMouseDown={(e) => {
                        changeBgOnMouseDown(e);
                      }}
                      onMouseUp={(e) => {
                        changeBgOnMouseUp(e);
                      }}
                      onMouseEnter={(e) => {
                        changeBgOnMouseEnter(e);
                      }}
                      onMouseLeave={(e) => {
                        changeBgOnMouseLeave(e);
                      }}
                      disabled={isLogoutLoading ? true : false}>
                      Logout
                    </button>
                  </li>
                  <li>
                    <button
                      className={`dropdown-item ${styles.dropdown_item}`}
                      onClick={logoutUser}
                      onMouseDown={(e) => {
                        changeBgOnMouseDown(e);
                      }}
                      onMouseUp={(e) => {
                        changeBgOnMouseUp(e);
                      }}
                      onMouseEnter={(e) => {
                        changeBgOnMouseEnter(e);
                      }}
                      onMouseLeave={(e) => {
                        changeBgOnMouseLeave(e);
                      }}
                      disabled={isLogoutLoading ? true : false}>
                      Logout From All devices
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchBar;
