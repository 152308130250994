import jwtDecode from "jwt-decode";
import React from "react";
import { Navigate } from "react-router-dom";

const LoginGuard = ({ children }) => {
  let currentUserEncoded = localStorage.getItem("userToken");

  if (currentUserEncoded) {
    try {
      let currentUser = jwtDecode(currentUserEncoded);
      if (!currentUser.accessToken || !currentUser.currentUser || !currentUser.currentUser._id || !currentUser.currentUser.key || !currentUser.currentUser.name || !currentUser.currentUser.email || !currentUser.currentUser.role || !currentUser.currentUser.role_name || !currentUser.currentUser.permissions || !currentUser.currentUser.status) {
        localStorage.removeItem("userToken");
        return <Navigate to="/login" />;
      }
      return <Navigate to="/" />;
    } catch (error) {
      localStorage.removeItem("userToken");
      return <Navigate to="/login" />;
    }
  } else {
    return children;
  }
};

export default LoginGuard;
