import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { fmDataContext } from "../../Context/FmData";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { fmVideosContext } from "../../Context/VideosContext";
import userCan from "../../helpers/userCan";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import Swal from "sweetalert2/dist/sweetalert2.all.min";
import "@sweetalert2/theme-dark/dark.min.css";
import jwtDecode from "jwt-decode";
import styles from "./VideoDetails.module.scss";
import { authenticationContext } from "../../Context/AuthContext";
import LabelCard from "../LabelCard/LabelCard";
import ArtistCard from "../ArtistCard/ArtistCard";
import ReactPlayer from "react-player";

const VideoDetails = () => {
  let [mounted, setMounted] = useState(false);

  let { videoId } = useParams();
  let [params, setParams] = useSearchParams();

  let { baseUrl, navigate, showData, editItemExistence, getData, globalCrumbs, setGlobalCrumbs } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  let [currentVideo, setCurrentVideo] = useState({});
  let onlyTrashed = params.get("archived");
  let [isCurrentVideoLoading, setIsCurrentVideoLoading] = useState(true);

  let [isSoftDeleteLoading, setIsSoftDeleteLoading] = useState(false);
  let [isForceDeleteLoading, setIsForceDeleteLoading] = useState(false);
  let [isRestoreLoading, setIsRestoreLoading] = useState(false);

  let { setVideoMsg } = useContext(fmVideosContext);

  let userToken = jwtDecode(localStorage.getItem("userToken")).accessToken;

  let artistsSearchInput = useRef();

  let [artistsSwiperCount, setArtistsSwiperCount] = useState(6);

  const setArtistsSlidesCount = () => {
    if (window.innerWidth > 1400) {
      setArtistsSwiperCount(6);
    } else if (window.innerWidth < 1400 && window.innerWidth >= 1200) {
      setArtistsSwiperCount(5);
    } else if (window.innerWidth < 1200 && window.innerWidth > 991.9) {
      setArtistsSwiperCount(4);
    } else if (window.innerWidth < 992 && window.innerWidth > 767.9) {
      setArtistsSwiperCount(3);
    } else if (window.innerWidth < 768 && window.innerWidth > 575.9) {
      setArtistsSwiperCount(2);
    } else if (window.innerWidth < 576 && window.innerWidth > 0) {
      setArtistsSwiperCount(1);
    }
  };

  const softDeleteVideo = (reqMethod, pathName, headers, setLoading) => {
    if (userCan("fm_video_soft_delete")) {
      Swal.fire({
        title: "Are you sure?",
        html: `<p class="mb-0">You will delete <span class="fu-text-light-gray">${currentVideo.data.Video_title}</span></p>`,
        icon: "warning",
        showCancelButton: true,
        customClass: {
          cancelButton: "popup-btn",
          confirmButton: "popup-btn",
        },
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              setVideoMsg(`${currentVideo.data.video_title} Deleted Successfully`);
              navigate("/fm-videos");
            })
            .catch((error) => {
              navigate("/not-found");
            });
        }
      });
    } else {
      navigate("/not-found");
    }
  };

  const restoreVideo = (reqMethod, pathName, headers, setLoading) => {
    if (userCan("fm_video_restore_trashed")) {
      Swal.fire({
        title: "Are you sure?",
        html: `<p class="mb-0">You will restore <span class="fu-text-light-gray">${currentVideo.data.video_title}</span></p>`,
        icon: "question",
        showCancelButton: true,
        customClass: {
          cancelButton: "popup-btn",
          confirmButton: "popup-btn",
        },
        confirmButtonText: "Yes, restore it!",
      }).then((result) => {
        if (result.isConfirmed) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              setVideoMsg(`${currentVideo.data.video_title} Restored Successfully`);
              navigate("/fm-videos");
            })
            .catch((error) => {
              navigate("/not-found");
            });
        }
      });
    } else {
      navigate("/not-found");
    }
  };

  const forceDeleteVideo = (reqMethod, pathName, headers, setLoading) => {
    Swal.fire({
      title: "Are you sure?",
      html: `<p class="mb-0">You will delete <span class="fu-text-light-gray">${currentVideo.data.video_title}</span> permanently</p><p class="mb-0">You won't be able to revert this!</p>`,
      icon: "warning",
      showCancelButton: true,
      customClass: {
        cancelButton: "popup-btn",
        confirmButton: "popup-btn",
      },
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        if (userCan("fm_video_force_delete")) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              setVideoMsg(`${currentVideo.data.video_title} Deleted Permanently Successfully`);
              navigate("/fm-videos");
            })
            .catch((error) => {
              navigate("/not-found");
            });
        } else {
          navigate("/not-found");
        }
      }
    });
  };

  const getVideo = (reqMethod, pathName, setLoading = setIsCurrentVideoLoading, headers, params) => {
    showData(reqMethod, pathName, setLoading, headers, params)
      .then((response) => {
        // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
        // if (response.data.data.video_label && response.data.data.video_label.label_image.includes("via.placeholder.com")) {
        //   response.data.data.video_label.label_image = response.data.data.video_label.label_image.replace("http://127.0.0.1:8000/storage/", "");
        // }
        // if (response.data.data.video_artists !== null) {
        //   response.data.data.video_artists.forEach((artist) => {
        //     if (artist.artist_image.includes("via.placeholder.com")) {
        //       artist.artist_image = artist.artist_image.replace("http://127.0.0.1:8000/storage/", "");
        //     }
        //   });
        // }
        // ------------------ REMOVE BEFORE PRODUCTION --------------------------------

        setCurrentVideo(response.data);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    getVideo(
      "GET",
      `${baseUrl}fm-video/${videoId}`,
      setIsCurrentVideoLoading,
      {
        Accept: "application/json",
        "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
        Authorization: `Bearer ${userToken}`,
      },
      {
        only_trashed: onlyTrashed,
      }
    );
    setMounted(true);

    setArtistsSlidesCount();
    window.addEventListener("resize", function () {
      setArtistsSlidesCount();
    });
  }, []);

  useEffect(() => {
    if (mounted && currentVideo.data) {
      let globalCrumbsUpdated = globalCrumbs.map((crumb) => {
        if (crumb.name === videoId.toUpperCase()) {
          crumb.name = currentVideo.data.video_title.toUpperCase();
        }
        return crumb;
      });
      setGlobalCrumbs(globalCrumbsUpdated);
    }
  }, [currentVideo]);

  let [artistsSearchTimeOut, setArtistsSearchTimeOut] = useState("");
  let [artistsSearchText, setArtistsSearchText] = useState("");
  let [isArtistsSearchLoading, setIsArtistsSearchLoading] = useState(false);

  const getSearchArtists = () => {
    setIsArtistsSearchLoading(true);
    if (artistsSearchTimeOut) {
      clearTimeout(artistsSearchTimeOut);
    }
    let getArtistsSearchTimeOut = setTimeout(() => {
      getData(
        "GET",
        `${baseUrl}fm-artists`,
        {
          search_query: artistsSearchText,
          video_keys: [currentVideo.data.video_key],
        },
        setIsArtistsSearchLoading
      )
        .then((response) => {
          // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
          // if (response.data.data !== null && response.data.data.length > 0) {
          //   response.data.data.forEach((artist) => {
          //     if (artist.artist_image.includes("via.placeholder.com")) {
          //       artist.artist_image = artist.artist_image.replace("http://127.0.0.1:8000/storage/", "");
          //     }
          //   });
          // }
          // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
          currentVideo.data.video_artists = response.data.data;
          setIsArtistsSearchLoading(false);
        })
        .catch((error) => {
          navigate("/not-found");
        });
    }, 1500);
    setArtistsSearchTimeOut(getArtistsSearchTimeOut);
  };

  useEffect(() => {
    if (mounted) {
      getSearchArtists();
    }
  }, [artistsSearchText]);

  return (
    <>
      {!isCurrentVideoLoading ? (
        <>
          <div className={styles.video_container}>
            <div className={`pt-4 ${styles.video_info_section}`}>
              <div className="container">
                <div className="row g-3 justify-content-center">
                  {currentVideo.data.youtube_video_id && (
                    <div className="col-lg-9 col-xl-8 col-xxl-6">
                      <div className="yt-iframe-box">
                        <div className="i-frame-container mb-2">
                          <div className="i-frame-release-poster">
                            <img src={`https://img.youtube.com/vi/${currentVideo.data.youtube_video_id}/sddefault.jpg`} alt="release poster" className="w-100" />
                          </div>
                          <ReactPlayer
                            url={`https://www.youtube-nocookie.com/embed/${currentVideo.data.youtube_video_id}?autoplay=0&disablekb=1&playsinline=1&cc_load_policy=0&cc_lang_pref=auto&widget_referrer=https%3A%2F%2Ffmcp.futurisingmusic.com%2F&&rel=0&showinfo=0&iv_load_policy=3&modestbranding=1&customControls=true&noCookie=true&origin=https%3A%2F%2Ffmcp.futurisingmusic.com%2F&enablejsapi=1&widgetid=1`}
                            playIcon={
                              <div className="i-frame-play-icon">
                                <i className="bi bi-play-fill play-icon"></i>
                              </div>
                            }
                            light={true}
                            controls
                            playing={true}
                            width={"100%"}
                            height={"100%"}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-12 col-xxl-6">
                    <div>
                      <div className={styles.video_info}>
                        <h2 className={`mb-0 ${styles.video_title}`}>{currentVideo.data.video_title}</h2>
                        {currentVideo.data.video_artists ? <span className={`mb-3 ${styles.video_artist}`}>{currentVideo.data.video_artists.map((artist, index) => `${artist.artist_name}${index === currentVideo.data.video_artists.length - 1 ? "" : ", "}`)}</span> : ""}
                      </div>
                    </div>
                    {/* info tags */}
                    <div className="video-tags">
                      <div className="mb-4 info-tags-container justify-content-start">
                        {currentVideo.data._id ? (
                          <>
                            <div className="mx-1 my-1 info-tag">
                              <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">#ID: </span>
                              <span className="d-inline-flex justify-content-center align-items-center">{currentVideo.data._id}</span>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {currentVideo.data.video_key ? (
                          <>
                            <div className="mx-1 my-1 info-tag">
                              <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">#KEY: </span>
                              <span className="d-inline-flex justify-content-center align-items-center">{currentVideo.data.video_key}</span>
                            </div>
                          </>
                        ) : (
                          ""
                        )}

                        {currentVideo.data.video_genre ? (
                          <>
                            <div className="mx-1 my-1 info-tag">
                              <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">VIDEO GENRE: </span>
                              <span className="d-inline-flex justify-content-center align-items-center">{currentVideo.data.video_genre.genre_title}</span>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {currentVideo.data.video_artists_count !== null ? (
                          <>
                            <div className="mx-1 my-1 info-tag">
                              <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">VIDEO ARTISTS COUNT: </span>
                              <span className="d-inline-flex justify-content-center align-items-center">{currentVideo.data.video_artists_count}</span>
                            </div>
                          </>
                        ) : (
                          ""
                        )}

                        {currentVideo.data.video_created_at ? (
                          userCan("created_at_show") ? (
                            <>
                              <div className="mx-1 my-1 info-tag">
                                <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED AT: </span>
                                <span className="d-inline-flex justify-content-center align-items-center">{new Date(currentVideo.data.video_created_at).toLocaleString("en-US", { timeZone: "Africa/Cairo", hour12: true })}</span>
                              </div>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {currentVideo.data.video_updated_at ? (
                          userCan("updated_at_show") ? (
                            <>
                              <div className="mx-1 my-1 info-tag">
                                <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED AT: </span>
                                <span className="d-inline-flex justify-content-center align-items-center">{new Date(currentVideo.data.video_updated_at).toLocaleString("en-US", { timeZone: "Africa/Cairo", hour12: true })}</span>
                              </div>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {currentVideo.data.video_deleted_at ? (
                          userCan("deleted_at_show") ? (
                            <>
                              <div className="mx-1 my-1 info-tag">
                                <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">DELETED AT: </span>
                                <span className="d-inline-flex justify-content-center align-items-center">{new Date(currentVideo.data.video_deleted_at).toLocaleString("en-US", { timeZone: "Africa/Cairo", hour12: true })}</span>
                              </div>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {currentVideo.data.video_created_by ? (
                          userCan("created_by_show") ? (
                            <>
                              <div className="mx-1 my-1 info-tag">
                                <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED BY, NAME: </span>
                                <span className="d-inline-flex justify-content-center align-items-center">{currentVideo.data.video_created_by.name}</span>
                              </div>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {currentVideo.data.video_created_by ? (
                          userCan("created_by_show") ? (
                            <>
                              <div className="mx-1 my-1 info-tag">
                                <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED BY, #ID: </span>
                                <span className="d-inline-flex justify-content-center align-items-center">{currentVideo.data.video_created_by._id}</span>
                              </div>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {currentVideo.data.video_created_by ? (
                          userCan("created_by_show") ? (
                            <>
                              <div className="mx-1 my-1 info-tag">
                                <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED BY, KEY: </span>
                                <span className="d-inline-flex justify-content-center align-items-center">{currentVideo.data.video_created_by.key}</span>
                              </div>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {currentVideo.data.video_updated_by ? (
                          userCan("updated_by_show") ? (
                            <>
                              <div className="mx-1 my-1 info-tag">
                                <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED BY, NAME: </span>
                                <span className="d-inline-flex justify-content-center align-items-center">{currentVideo.data.video_updated_by.name}</span>
                              </div>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {currentVideo.data.video_updated_by ? (
                          userCan("updated_by_show") ? (
                            <>
                              <div className="mx-1 my-1 info-tag">
                                <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED BY, #ID: </span>
                                <span className="d-inline-flex justify-content-center align-items-center">{currentVideo.data.video_updated_by._id}</span>
                              </div>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {currentVideo.data.video_updated_by ? (
                          userCan("updated_by_show") ? (
                            <>
                              <div className="mx-1 my-1 info-tag">
                                <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED BY, KEY: </span>
                                <span className="d-inline-flex justify-content-center align-items-center">{currentVideo.data.video_updated_by.key}</span>
                              </div>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.bg_blur} style={{ backgroundImage: `url(https://img.youtube.com/vi/${currentVideo.data.youtube_video_id}/sddefault.jpg)` }}></div>
            <div className={styles.gradient}></div>
          </div>
          <div className="position-relative mt-2 mt-xxl-4 z-index-high">
            <div className="container">
              {/* edit restore delete */}
              <div className="d-flex flex-wrap align-items-center justify-content-center mb-3 mb-xxl-4">
                {currentVideo.data.video_deleted_at === null ? (
                  userCan("fm_video_update") ? (
                    <div className="mx-2 my-1 my-sm-0">
                      <Link to={`/fm-videos/update/${currentVideo.data.video_key}`} className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`} disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                        <span className="btn-bg"></span>
                        Edit
                      </Link>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {currentVideo.data.video_deleted_at === null ? (
                  userCan("fm_video_soft_delete") ? (
                    <div className="mx-2 my-1 my-sm-0">
                      <button
                        className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`}
                        onClick={() =>
                          softDeleteVideo(
                            "DELETE",
                            `${baseUrl}fm-videos/delete/${currentVideo.data.video_key}`,
                            {
                              Accept: "application/json",
                              "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                              Authorization: `Bearer ${user.accessToken}`,
                            },
                            setIsSoftDeleteLoading
                          )
                        }
                        disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                        <span className="btn-bg"></span>
                        Delete
                      </button>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {currentVideo.data.video_deleted_at !== null ? (
                  userCan("fm_video_restore_trashed") ? (
                    <div className="mx-2 my-1 my-sm-0">
                      <button
                        className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`}
                        onClick={() =>
                          restoreVideo(
                            "PATCH",
                            `${baseUrl}fm-videos/restore/${currentVideo.data.video_key}`,
                            {
                              Accept: "application/json",
                              "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                              Authorization: `Bearer ${user.accessToken}`,
                            },
                            setIsRestoreLoading
                          )
                        }
                        disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                        <span className="btn-bg"></span>
                        Restore
                      </button>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {userCan("fm_video_force_delete") ? (
                  <div className="ms-2 my-1 my-sm-0">
                    <button
                      className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`}
                      onClick={() =>
                        forceDeleteVideo(
                          "DELETE",
                          `${baseUrl}fm-videos/force-delete/${currentVideo.data.video_key}`,
                          {
                            Accept: "application/json",
                            "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                            Authorization: `Bearer ${user.accessToken}`,
                          },
                          setIsForceDeleteLoading
                        )
                      }
                      disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                      <span className="btn-bg"></span>
                      Force Delete
                    </button>
                  </div>
                ) : (
                  ""
                )}

                {isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? (
                  <div className="position-relative ms-4">
                    <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
                  </div>
                ) : (
                  ""
                )}
              </div>

              {/* video Label */}
              {currentVideo.data.video_label ? (
                <div className="mb-4">
                  <div className="py-2 mb-4 iu-row-heading">
                    <h2 className="mb-0 row-title">LABEL</h2>
                  </div>
                  <div className="row g-0">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-2">
                      <LabelCard label={currentVideo.data.video_label} />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* video artists */}
              {currentVideo.data.video_artists ? (
                <div className="mb-4">
                  <div className="d-flex flex-column flex-lg-row align-items-center mb-4">
                    <div className="flex-grow-1 iu-row-heading mb-2 mb-lg-0">
                      <h2 className="mb-0 row-title">ARTISTS</h2>
                    </div>
                    <div className="d-flex justify-content-between align-items-center row-search-container ms-0 ms-lg-2">
                      <div className="item-search-box-container flex-grow-1">
                        <div className="loading-fixed-dimensions item-search-box-loading">
                          {isArtistsSearchLoading ? (
                            <div className="position-relative">
                              <LoadingCircle circleCont={20} icon={15} loader={20} dot={20} />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="item-search-box">
                          <input
                            type="text"
                            className="form-control shadow-none"
                            id="artist_search_query"
                            placeholder={`${currentVideo.data.video_title} Artists Search...`}
                            onInput={(e) => {
                              setArtistsSearchText(e.target.value);
                              clearTimeout(artistsSearchTimeOut);
                            }}
                            ref={artistsSearchInput}
                          />
                        </div>
                      </div>
                      <div className="ms-2">
                        <button
                          className="fu-btn fu-btn-gray"
                          onClick={() => {
                            setArtistsSearchText("");
                            clearTimeout(artistsSearchTimeOut);
                            artistsSearchInput.current.value = "";
                          }}>
                          <span className="btn-bg"></span>
                          Clear Search
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="item-artists-search-row-height">
                    {artistsSearchText.length > 0 && !isArtistsSearchLoading && currentVideo.data.video_artists.length === 0 ? (
                      <div className="d-flex flex-column justify-content-center align-items-center item-artists-search-msg-height">
                        <div>
                          <h2 className="fu-text-3-rem fu-text-yellow">
                            <i className="bi bi-search"></i>
                          </h2>
                        </div>
                        <div>
                          <h3 className="h5 fu-text-light-gray line-clamp-2">
                            There Are No Artists Related To<span className="d-inline-flex fu-text-yellow mx-2">{currentVideo.data.video_title}</span>With This Text<span className="d-inline-flex fu-text-yellow mx-2">"{artistsSearchText}"</span>
                          </h3>
                        </div>
                      </div>
                    ) : (
                      <div className="row gx-3">
                        <Swiper className="myswiper py-3 px-2" slidesPerView={artistsSwiperCount} spaceBetween={10} keyboard={true} navigation={true} modules={[Navigation, Keyboard]}>
                          {currentVideo.data.video_artists.map((artist, index) => (
                            <SwiperSlide key={index}>
                              <ArtistCard artist={artist} />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
};

export default VideoDetails;
