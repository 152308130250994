import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fmDataContext } from "../../Context/FmData";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { fmContactMsgsContext } from "../../Context/ContactMsgsContext";
import userCan from "../../helpers/userCan";
import Swal from "sweetalert2/dist/sweetalert2.all.min";
import "@sweetalert2/theme-dark/dark.min.css";
import jwtDecode from "jwt-decode";
import styles from "./ContactMsgDetails.module.scss";
import { authenticationContext } from "../../Context/AuthContext";

const ContactMsgDetails = () => {
  let [mounted, setMounted] = useState(false);

  let { contactMsgId } = useParams();

  let { baseUrl, navigate, showData, editItemExistence, globalCrumbs, setGlobalCrumbs } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  let [currentContactMsg, setCurrentContactMsg] = useState({});

  let [isCurrentContactMsgLoading, setIsCurrentContactMsgLoading] = useState(true);

  let [isForceDeleteLoading, setIsForceDeleteLoading] = useState(false);

  let { setContactMsg } = useContext(fmContactMsgsContext);

  let userToken = jwtDecode(localStorage.getItem("userToken")).accessToken;

  const forceDeleteContactMsg = (reqMethod, pathName, headers, setLoading) => {
    Swal.fire({
      title: "Are you sure?",
      html: `<p class="mb-0">You will delete <span class="fu-text-light-gray">${currentContactMsg.data.user_first_name} ${currentContactMsg.data.user_last_name}</span> message permanently</p><p class="mb-0">You won't be able to revert this!</p>`,
      icon: "warning",
      showCancelButton: true,
      customClass: {
        cancelButton: "popup-btn",
        confirmButton: "popup-btn",
      },
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        if (userCan("fm_contact_message_force_delete")) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              setContactMsg(`${currentContactMsg.data.user_first_name} ${currentContactMsg.data.user_last_name} Message Deleted Permanently Successfully`);
              navigate("/fm-contact-messages");
            })
            .catch((error) => {
              navigate("/not-found");
            });
        } else {
          navigate("/not-found");
        }
      }
    });
  };

  const getContactMsg = (reqMethod, pathName, setLoading = setIsCurrentContactMsgLoading, headers) => {
    showData(reqMethod, pathName, setLoading, headers)
      .then((response) => {
        setCurrentContactMsg(response.data);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    getContactMsg("GET", `${baseUrl}fm-contact-message/${contactMsgId}`, setIsCurrentContactMsgLoading, {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
      Authorization: `Bearer ${userToken}`,
    });
    setMounted(true);
  }, []);

  useEffect(() => {
    getContactMsg("GET", `${baseUrl}fm-contact-message/${contactMsgId}`, setIsCurrentContactMsgLoading, {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
      Authorization: `Bearer ${userToken}`,
    });
    setMounted(true);
  }, [contactMsgId]);

  useEffect(() => {
    if (mounted && currentContactMsg.data) {
      let globalCrumbsUpdated = globalCrumbs.map((crumb) => {
        if (crumb.name === contactMsgId.toUpperCase()) {
          crumb.name = `${currentContactMsg.data.user_first_name.toUpperCase()} ${currentContactMsg.data.user_last_name.toUpperCase()} MESSAGE`;
        }
        return crumb;
      });
      setGlobalCrumbs(globalCrumbsUpdated);
    }
  }, [currentContactMsg]);

  return (
    <>
      {!isCurrentContactMsgLoading ? (
        <>
          <div className="container">
            <h1 className="fu-text-2-rem fu-text-1-8-rem-xs fu-text-18-px-xxs fu-text-16-px-xxs fu-fw-800 fu-text-white mb-3 mt-3">
              {currentContactMsg.data.user_first_name} {currentContactMsg.data.user_last_name} Message
            </h1>
            <div className="py-1 py-md-2 mt-2 mt-md-3">
              <div className="d-flex align-items-center mb-3 mb-md-4">
                {userCan("fm_contact_message_force_delete") ? (
                  <div>
                    <button
                      className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading ? "disabled" : ""}`}
                      onClick={() =>
                        forceDeleteContactMsg(
                          "DELETE",
                          `${baseUrl}fm-contact-messages/force-delete/${currentContactMsg.data.key}`,
                          {
                            Accept: "application/json",
                            "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                            Authorization: `Bearer ${user.accessToken}`,
                          },
                          setIsForceDeleteLoading
                        )
                      }
                      disabled={isForceDeleteLoading ? true : false}>
                      <span className="btn-bg"></span>
                      Delete
                    </button>
                  </div>
                ) : (
                  ""
                )}

                {isForceDeleteLoading ? (
                  <div className="position-relative ms-4">
                    <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="contact-message-tags">
                <div className="mb-4 info-tags-container justify-content-start">
                  {currentContactMsg.data._id ? (
                    <>
                      <div className="mx-1 my-1 info-tag">
                        <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">#ID: </span>
                        <span className="d-inline-flex justify-content-center align-items-center">{currentContactMsg.data._id}</span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {currentContactMsg.data.key ? (
                    <>
                      <div className="mx-1 my-1 info-tag">
                        <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">#KEY: </span>
                        <span className="d-inline-flex justify-content-center align-items-center">{currentContactMsg.data.key}</span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {currentContactMsg.data.sent_at ? (
                    <>
                      <div className="mx-1 my-1 info-tag">
                        <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">MESSAGE SENT AT: </span>
                        <span className="d-inline-flex justify-content-center align-items-center">{new Date(currentContactMsg.data.sent_at).toLocaleString("en-US", { timeZone: "Africa/Cairo", hour12: true })}</span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {currentContactMsg.data.user_email_address ? (
                    <>
                      <div className="mx-1 my-1 info-tag">
                        <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">SENDER EMAIL ADDRESS: </span>
                        <span className="d-inline-flex justify-content-center align-items-center">{currentContactMsg.data.user_email_address}</span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* Message content */}
              {currentContactMsg.data.user_message !== null ? (
                <div className="mb-2 mb-md-4">
                  <div className="d-flex align-items-center mb-2 mb-md-4">
                    <div className="flex-grow-1 iu-row-heading">
                      <h2 className="mb-0 row-title">MESSAGE CONTENT</h2>
                    </div>
                  </div>
                  <div>
                    <p className={styles.contact_msg_content} dangerouslySetInnerHTML={{ __html: currentContactMsg.data.user_message }} />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
};

export default ContactMsgDetails;
