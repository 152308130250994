import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { fmDataContext } from "../../Context/FmData";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { fmGenresContext } from "../../Context/GenresContext";
import userCan from "../../helpers/userCan";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import Swal from "sweetalert2/dist/sweetalert2.all.min";
import "@sweetalert2/theme-dark/dark.min.css";
import jwtDecode from "jwt-decode";
import ReleaseCard from "../ReleaseCard/ReleaseCard";
import VideoCard from "../VideoCard/VideoCard";
import { authenticationContext } from "../../Context/AuthContext";

const GenreDetails = () => {
  let [mounted, setMounted] = useState(false);

  let { genreId } = useParams();
  let [params, setParams] = useSearchParams();

  let { baseUrl, navigate, showData, editItemExistence, getData, globalCrumbs, setGlobalCrumbs } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  let [currentGenre, setCurrentGenre] = useState({});
  let onlyTrashed = params.get("archived");
  let [isCurrentGenreLoading, setIsCurrentGenreLoading] = useState(true);

  let [isSoftDeleteLoading, setIsSoftDeleteLoading] = useState(false);
  let [isForceDeleteLoading, setIsForceDeleteLoading] = useState(false);
  let [isRestoreLoading, setIsRestoreLoading] = useState(false);

  let { setGenreMsg } = useContext(fmGenresContext);

  let userToken = jwtDecode(localStorage.getItem("userToken")).accessToken;

  let releasesSearchInput = useRef();
  let videosSearchInput = useRef();

  let [releasesSwiperCount, setReleasesSwiperCount] = useState(4);
  let [videosSwiperCount, setVideosSwiperCount] = useState(2);

  const softDeleteGenre = (reqMethod, pathName, headers, setLoading) => {
    if (userCan("fm_genre_soft_delete")) {
      Swal.fire({
        title: "Are you sure?",
        html: `<p class="mb-0">You will delete <span class="fu-text-light-gray">${currentGenre.data.genre_title}</span></p>`,
        icon: "warning",
        showCancelButton: true,
        customClass: {
          cancelButton: "popup-btn",
          confirmButton: "popup-btn",
        },
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              setGenreMsg(`${currentGenre.data.genre_title} Deleted Successfully`);
              navigate("/fm-genres");
            })
            .catch((error) => {
              navigate("/not-found");
            });
        }
      });
    } else {
      navigate("/not-found");
    }
  };

  const restoreGenre = (reqMethod, pathName, headers, setLoading) => {
    if (userCan("fm_genre_restore_trashed")) {
      Swal.fire({
        title: "Are you sure?",
        html: `<p class="mb-0">You will restore <span class="fu-text-light-gray">${currentGenre.data.genre_title}</span></p>`,
        icon: "question",
        showCancelButton: true,
        customClass: {
          cancelButton: "popup-btn",
          confirmButton: "popup-btn",
        },
        confirmButtonText: "Yes, restore it!",
      }).then((result) => {
        if (result.isConfirmed) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              setGenreMsg(`${currentGenre.data.genre_title} Restored Successfully`);
              navigate("/fm-genres");
            })
            .catch((error) => {
              navigate("/not-found");
            });
        }
      });
    } else {
      navigate("/not-found");
    }
  };

  const forceDeleteGenre = (reqMethod, pathName, headers, setLoading) => {
    Swal.fire({
      title: "Are you sure?",
      html: `<p class="mb-0">You will delete <span class="fu-text-light-gray">${currentGenre.data.genre_title}</span> permanently</p><p class="mb-0">You won't be able to revert this!</p>`,
      icon: "warning",
      showCancelButton: true,
      customClass: {
        cancelButton: "popup-btn",
        confirmButton: "popup-btn",
      },
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        if (userCan("fm_genre_force_delete")) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              setGenreMsg(`${currentGenre.data.genre_title} Deleted Permanently Successfully`);
              navigate("/fm-genres");
            })
            .catch((error) => {
              navigate("/not-found");
            });
        } else {
          navigate("/not-found");
        }
      }
    });
  };

  const setReleasesSlidesCount = () => {
    if (window.innerWidth >= 1400) {
      setReleasesSwiperCount(4);
    } else if (window.innerWidth < 1400 && window.innerWidth > 1199.9) {
      setReleasesSwiperCount(3);
    } else if (window.innerWidth < 1200 && window.innerWidth > 991.9) {
      setReleasesSwiperCount(3);
    } else if (window.innerWidth < 992 && window.innerWidth > 767.9) {
      setReleasesSwiperCount(2);
    } else if (window.innerWidth < 768 && window.innerWidth > 0) {
      setReleasesSwiperCount(1);
    }
  };

  const setVideosSlidesCount = () => {
    if (window.innerWidth > 1200) {
      setVideosSwiperCount(2);
    } else if (window.innerWidth < 1200 && window.innerWidth > 991.9) {
      setVideosSwiperCount(2);
    } else if (window.innerWidth < 992 && window.innerWidth > 767.9) {
      setVideosSwiperCount(1);
    } else if (window.innerWidth < 768 && window.innerWidth > 0) {
      setVideosSwiperCount(1);
    }
  };

  const getGenre = (reqMethod, pathName, setLoading = setIsCurrentGenreLoading, headers, params) => {
    showData(reqMethod, pathName, setLoading, headers, params)
      .then((response) => {
        // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
        // if (response.data.data.genre_releases !== null) {
        //   response.data.data.genre_releases.forEach((release) => {
        //     if (release.release_image.includes("via.placeholder.com")) {
        //       release.release_image = release.release_image.replace("http://127.0.0.1:8000/storage/", "");
        //     }
        //   });
        // }
        // ------------------ REMOVE BEFORE PRODUCTION --------------------------------

        setCurrentGenre(response.data);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    getGenre(
      "GET",
      `${baseUrl}fm-genre/${genreId}`,
      setIsCurrentGenreLoading,
      {
        Accept: "application/json",
        "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
        Authorization: `Bearer ${userToken}`,
      },
      {
        only_trashed: onlyTrashed,
      }
    );
    setMounted(true);

    setReleasesSlidesCount();
    setVideosSlidesCount();
    window.addEventListener("resize", function () {
      setReleasesSlidesCount();
      setVideosSlidesCount();
    });
  }, []);

  useEffect(() => {
    getGenre(
      "GET",
      `${baseUrl}fm-genre/${genreId}`,
      setIsCurrentGenreLoading,
      {
        Accept: "application/json",
        "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
        Authorization: `Bearer ${userToken}`,
      },
      {
        only_trashed: onlyTrashed,
      }
    );
    setMounted(true);
  }, [genreId]);

  useEffect(() => {
    if (mounted && currentGenre.data) {
      let globalCrumbsUpdated = globalCrumbs.map((crumb) => {
        if (crumb.name === genreId.toUpperCase()) {
          crumb.name = currentGenre.data.genre_title.toUpperCase();
        }
        return crumb;
      });
      setGlobalCrumbs(globalCrumbsUpdated);
    }
  }, [currentGenre]);

  let [releasesSearchTimeOut, setReleasesSearchTimeOut] = useState("");
  let [releasesSearchText, setReleasesSearchText] = useState("");
  let [isReleasesSearchLoading, setIsReleasesSearchLoading] = useState(false);

  const getSearchReleases = () => {
    setIsReleasesSearchLoading(true);
    if (releasesSearchTimeOut) {
      clearTimeout(releasesSearchTimeOut);
    }
    let getReleasesSearchTimeOut = setTimeout(() => {
      getData(
        "GET",
        `${baseUrl}fm-releases`,
        {
          search_query: releasesSearchText,
          genre_keys: [currentGenre.data.genre_key],
        },
        setIsReleasesSearchLoading
      )
        .then((response) => {
          // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
          // if (response.data.data.genre_releases !== null) {
          //   response.data.data.forEach((release) => {
          //     if (release.release_image.includes("via.placeholder.com")) {
          //       release.release_image = release.release_image.replace("http://127.0.0.1:8000/storage/", "");
          //     }
          //   });
          // }
          // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
          setIsReleasesSearchLoading(false);
          currentGenre.data.genre_releases = response.data.data;
        })
        .catch((error) => {
          navigate("/not-found");
        });
    }, 1500);
    setReleasesSearchTimeOut(getReleasesSearchTimeOut);
  };

  useEffect(() => {
    if (mounted) {
      getSearchReleases();
    }
  }, [releasesSearchText]);

  let [videosSearchTimeOut, setVideosSearchTimeOut] = useState("");
  let [videosSearchText, setVideosSearchText] = useState("");
  let [isVideosSearchLoading, setIsVideosSearchLoading] = useState(false);

  const getSearchVideos = () => {
    setIsVideosSearchLoading(true);
    if (videosSearchTimeOut) {
      clearTimeout(videosSearchTimeOut);
    }
    let getVideosSearchTimeOut = setTimeout(() => {
      getData(
        "GET",
        `${baseUrl}fm-videos`,
        {
          search_query: videosSearchText,
          genre_keys: [currentGenre.data.genre_key],
        },
        setIsVideosSearchLoading
      )
        .then((response) => {
          setIsVideosSearchLoading(false);
          currentGenre.data.genre_videos = response.data.data;
        })
        .catch((error) => {
          navigate("/not-found");
        });
    }, 1500);
    setVideosSearchTimeOut(getVideosSearchTimeOut);
  };

  useEffect(() => {
    if (mounted) {
      getSearchVideos();
    }
  }, [videosSearchText]);

  return (
    <>
      {!isCurrentGenreLoading ? (
        <>
          <div className="container">
            <h1 className="fu-text-3-rem fu-fw-800 fu-text-white mb-3 mt-3">{currentGenre.data.genre_title ? currentGenre.data.genre_title : ""}</h1>
            <div className="py-2 mt-4">
              <div className="d-flex align-items-center mb-4">
                {currentGenre.data.genre_deleted_at === null ? (
                  userCan("fm_genre_update") ? (
                    <div className="mx-2 my-1 my-sm-0">
                      <Link to={`/fm-genres/update/${currentGenre.data.genre_key}`} className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`} disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                        <span className="btn-bg"></span>
                        Edit
                      </Link>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {currentGenre.data.genre_deleted_at === null ? (
                  userCan("fm_genre_soft_delete") ? (
                    <div className="mx-2 my-1 my-sm-0">
                      <button
                        className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`}
                        onClick={() =>
                          softDeleteGenre(
                            "DELETE",
                            `${baseUrl}fm-genres/delete/${currentGenre.data.genre_key}`,
                            {
                              Accept: "application/json",
                              "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                              Authorization: `Bearer ${user.accessToken}`,
                            },
                            setIsSoftDeleteLoading
                          )
                        }
                        disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                        <span className="btn-bg"></span>
                        Delete
                      </button>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {currentGenre.data.genre_deleted_at !== null ? (
                  userCan("fm_genre_restore_trashed") ? (
                    <div className="mx-2 my-1 my-sm-0">
                      <button
                        className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`}
                        onClick={() =>
                          restoreGenre(
                            "PATCH",
                            `${baseUrl}fm-genres/restore/${currentGenre.data.genre_key}`,
                            {
                              Accept: "application/json",
                              "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                              Authorization: `Bearer ${user.accessToken}`,
                            },
                            setIsRestoreLoading
                          )
                        }
                        disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                        <span className="btn-bg"></span>
                        Restore
                      </button>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {userCan("fm_genre_force_delete") ? (
                  <div className="ms-2 my-1 my-sm-0">
                    <button
                      className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`}
                      onClick={() =>
                        forceDeleteGenre(
                          "DELETE",
                          `${baseUrl}fm-genres/force-delete/${currentGenre.data.genre_key}`,
                          {
                            Accept: "application/json",
                            "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                            Authorization: `Bearer ${user.accessToken}`,
                          },
                          setIsForceDeleteLoading
                        )
                      }
                      disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                      <span className="btn-bg"></span>
                      Force Delete
                    </button>
                  </div>
                ) : (
                  ""
                )}
                {isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? (
                  <div className="position-relative ms-4">
                    <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="genre-tags">
                <div className="mb-4 info-tags-container justify-content-start">
                  {currentGenre.data._id ? (
                    <>
                      <div className="mx-1 my-1 info-tag">
                        <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">#ID: </span>
                        <span className="d-inline-flex justify-content-center align-items-center">{currentGenre.data._id}</span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {currentGenre.data.genre_key ? (
                    <>
                      <div className="mx-1 my-1 info-tag">
                        <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">#KEY: </span>
                        <span className="d-inline-flex justify-content-center align-items-center">{currentGenre.data.genre_key}</span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {currentGenre.data.genre_videos_count !== null ? (
                    <>
                      <div className="mx-1 my-1 info-tag">
                        <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">RELATED VIDEOS COUNT: </span>
                        <span className="d-inline-flex justify-content-center align-items-center">{currentGenre.data.genre_videos_count}</span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {currentGenre.data.genre_releases_count !== null ? (
                    <>
                      <div className="mx-1 my-1 info-tag">
                        <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">RELATED RELEASES COUNT: </span>
                        <span className="d-inline-flex justify-content-center align-items-center">{currentGenre.data.genre_releases_count}</span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {currentGenre.data.genre_created_at ? (
                    userCan("created_at_show") ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED AT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{new Date(currentGenre.data.genre_created_at).toLocaleString("en-US", { timeZone: "Africa/Cairo", hour12: true })}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {currentGenre.data.genre_updated_at ? (
                    userCan("updated_at_show") ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED AT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{new Date(currentGenre.data.genre_updated_at).toLocaleString("en-US", { timeZone: "Africa/Cairo", hour12: true })}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {currentGenre.data.genre_deleted_at ? (
                    userCan("deleted_at_show") ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">DELETED AT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{new Date(currentGenre.data.genre_deleted_at).toLocaleString("en-US", { timeZone: "Africa/Cairo", hour12: true })}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {currentGenre.data.genre_created_by ? (
                    userCan("created_by_show") ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED BY, NAME: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentGenre.data.genre_created_by.name}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {currentGenre.data.genre_created_by ? (
                    userCan("created_by_show") ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED BY, #ID: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentGenre.data.genre_created_by._id}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {currentGenre.data.genre_created_by ? (
                    userCan("created_by_show") ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED BY, KEY: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentGenre.data.genre_created_by.key}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {currentGenre.data.genre_updated_by ? (
                    userCan("updated_by_show") ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED BY, NAME: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentGenre.data.genre_updated_by.name}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {currentGenre.data.genre_updated_by ? (
                    userCan("updated_by_show") ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED BY, #ID: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentGenre.data.genre_updated_by._id}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {currentGenre.data.genre_updated_by ? (
                    userCan("updated_by_show") ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED BY, KEY: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentGenre.data.genre_updated_by.key}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* genre releases */}
              {currentGenre.data.genre_releases !== null ? (
                <div className="mb-4">
                  <div className="d-flex flex-column flex-lg-row align-items-center mb-4">
                    <div className="flex-grow-1 iu-row-heading mb-2 mb-lg-0">
                      <h2 className="mb-0 row-title">RELEASES</h2>
                    </div>
                    <div className="d-flex justify-content-between align-items-center row-search-container ms-0 ms-lg-2">
                      <div className="item-search-box-container flex-grow-1">
                        <div className="loading-fixed-dimensions item-search-box-loading">
                          {isReleasesSearchLoading ? (
                            <div className="position-relative">
                              <LoadingCircle circleCont={20} icon={15} loader={20} dot={20} />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="item-search-box">
                          <input
                            type="text"
                            className="form-control shadow-none"
                            id="releases_search_query"
                            placeholder={`${currentGenre.data.genre_title} Releases Search...`}
                            onInput={(e) => {
                              setReleasesSearchText(e.target.value);
                              clearTimeout(releasesSearchTimeOut);
                            }}
                            ref={releasesSearchInput}
                          />
                        </div>
                      </div>
                      <div className="ms-2">
                        <button
                          className="fu-btn fu-btn-gray"
                          onClick={() => {
                            setReleasesSearchText("");
                            clearTimeout(releasesSearchTimeOut);
                            releasesSearchInput.current.value = "";
                          }}>
                          <span className="btn-bg"></span>
                          Clear Search
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="item-releases-search-row-height">
                    {releasesSearchText.length > 0 && !isReleasesSearchLoading && currentGenre.data.genre_releases.length === 0 ? (
                      <div className="d-flex flex-column justify-content-center align-items-center item-releases-search-msg-height">
                        <div>
                          <h2 className="fu-text-3-rem fu-text-yellow">
                            <i className="bi bi-search"></i>
                          </h2>
                        </div>
                        <div>
                          <h3 className="h5 fu-text-light-gray line-clamp-2">
                            There Are No Releases Related To<span className="d-inline-flex fu-text-yellow mx-2">{currentGenre.data.genre_title}</span>With This Text<span className="d-inline-flex fu-text-yellow mx-2">"{releasesSearchText}"</span>
                          </h3>
                        </div>
                      </div>
                    ) : (
                      <div className="row gx-3">
                        <Swiper className="myswiper px-2 h-100" slidesPerView={releasesSwiperCount} spaceBetween={10} keyboard={true} navigation={true} modules={[Navigation, Keyboard]}>
                          {currentGenre.data.genre_releases.map((release, index) => (
                            <SwiperSlide key={index}>
                              <ReleaseCard release={release} />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* genre videos */}
              {currentGenre.data.genre_videos !== null ? (
                <div className="mb-4">
                  <div className="d-flex flex-column flex-lg-row align-items-center mb-4">
                    <div className="flex-grow-1 iu-row-heading mb-2 mb-lg-0">
                      <h2 className="mb-0 row-title">VIDEOS</h2>
                    </div>
                    <div className="d-flex justify-content-between align-items-center row-search-container ms-0 ms-lg-2">
                      <div className="item-search-box-container flex-grow-1">
                        <div className="loading-fixed-dimensions item-search-box-loading">
                          {isVideosSearchLoading ? (
                            <div className="position-relative">
                              <LoadingCircle circleCont={20} icon={15} loader={20} dot={20} />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="item-search-box">
                          <input
                            type="text"
                            className="form-control shadow-none"
                            id="video_search_query"
                            placeholder={`${currentGenre.data.genre_title} Videos Search...`}
                            onInput={(e) => {
                              setVideosSearchText(e.target.value);
                              clearTimeout(videosSearchTimeOut);
                            }}
                            ref={videosSearchInput}
                          />
                        </div>
                      </div>
                      <div className="ms-2">
                        <button
                          className="fu-btn fu-btn-gray"
                          onClick={() => {
                            setVideosSearchText("");
                            clearTimeout(videosSearchTimeOut);
                            videosSearchInput.current.value = "";
                          }}>
                          <span className="btn-bg"></span>
                          Clear Search
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="item-videos-search-row-height">
                    {videosSearchText.length > 0 && !isVideosSearchLoading && currentGenre.data.genre_videos.length === 0 ? (
                      <div className="d-flex flex-column justify-content-center align-items-center item-videos-search-msg-height">
                        <div>
                          <h2 className="fu-text-3-rem fu-text-yellow">
                            <i className="bi bi-search"></i>
                          </h2>
                        </div>
                        <div>
                          <h3 className="h5 fu-text-light-gray line-clamp-2">
                            There Are No Videos Related To<span className="d-inline-flex fu-text-yellow mx-2">{currentGenre.data.genre_title}</span>With This Text<span className="d-inline-flex fu-text-yellow mx-2">"{videosSearchText}"</span>
                          </h3>
                        </div>
                      </div>
                    ) : (
                      <div className="row gx-3">
                        <Swiper className="myswiper py-3 px-2 h-100" slidesPerView={videosSwiperCount} spaceBetween={10} keyboard={true} navigation={true} modules={[Navigation, Keyboard]}>
                          {currentGenre.data.genre_videos.map((video, index) => (
                            <SwiperSlide key={index}>
                              <VideoCard video={video} />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
};

export default GenreDetails;
