import React, { createContext, useState } from "react";

export let fmGenresContext = createContext(0);

const GenresContext = ({ children }) => {
  let [genreMsg, setGenreMsg] = useState("");

  let [filtrationDetails, setFiltrationDetails] = useState({
    search_query: "",
    only_trashed: 0,
  });

  let [isFiltrationLoading, setIsFiltrationLoading] = useState(false);

  let fmGenresLifting = {
    genreMsg,
    setGenreMsg,
    filtrationDetails,
    setFiltrationDetails,
    isFiltrationLoading,
    setIsFiltrationLoading,
  };

  return (
    <>
      <fmGenresContext.Provider value={{ ...fmGenresLifting }}>{children}</fmGenresContext.Provider>
    </>
  );
};

export default GenresContext;
