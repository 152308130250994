import React, { createContext, useState } from "react";

export let fmTfArtistsContext = createContext(0);

const TfArtistsContext = ({ children }) => {
  let [tfArtistMsg, setTfArtistMsg] = useState("");

  let fmTfArtistsLifting = {
    tfArtistMsg,
    setTfArtistMsg,
  };

  return (
    <>
      <fmTfArtistsContext.Provider value={{ ...fmTfArtistsLifting }}>{children}</fmTfArtistsContext.Provider>
    </>
  );
};

export default TfArtistsContext;
