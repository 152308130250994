import React, { useContext, useEffect, useRef, useState } from "react";
import { fmDataContext } from "../../Context/FmData";
import Pagination from "../Pagination/Pagination";
import { Link } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import VideoRow from "./VideoRow";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { fmVideosContext } from "../../Context/VideosContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";

import VideosFiltration from "./VideosFiltration";

const Videos = () => {
  const isCalledRef = useRef(false);

  let { baseUrl, navigate, getData, getAllDataWithoutPaginate } = useContext(fmDataContext);

  let { videoMsg, setVideoMsg, filtrationDetails, isFiltrationLoading, setFiltrationDetails } = useContext(fmVideosContext);

  let [videos, setVideos] = useState({});
  let [labels, setLabels] = useState([]);
  let [artists, setArtists] = useState([]);
  let [genres, setGenres] = useState([]);

  let [isGetMediaBasiesLoading, setIsGetMediaBasiesLoading] = useState(true);

  let [isGetVideosLoading, setIsGetVideosLoading] = useState(true);

  let [isPaginateLoading, setIsPaginateLoading] = useState(false);

  let [pageNum, setPageNum] = useState(1);

  let perPage = 20;
  let orderBy = "id";
  let orderType = "asc";

  const getVideos = (reqMethod, pathName, params, setLoading = setIsGetVideosLoading, message = null) => {
    getData(reqMethod, pathName, params, setLoading)
      .then((response) => {
        setVideos(response.data);
        if (videoMsg.length > 0) {
          toast(videoMsg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
            onClose: () => setVideoMsg(""),
          });
        }
        if (message) {
          toast(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const getMediaBasies = (reqMethod, pathName, params, setLoading = setIsGetMediaBasiesLoading) => {
    getAllDataWithoutPaginate(reqMethod, pathName, params, setLoading)
      .then((response) => {
        setLabels(response.data.labels);
        setArtists(response.data.artists);
        setGenres(response.data.genres);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    if (!isCalledRef.current) {
      getVideos(
        "GET",
        `${baseUrl}fm-videos`,
        {
          page_num: pageNum,
          per_page: perPage,
          order_type: orderType,
          order_by: orderBy,
        },
        setIsGetVideosLoading
      );
      getMediaBasies("GET", `${baseUrl}fm-get-tracks-data`, null, setIsGetMediaBasiesLoading);
    }
    return () => {
      isCalledRef.current = true;
      setFiltrationDetails({
        search_query: "",
        label_keys: [],
        genre_keys: [],
        artist_keys: [],
        only_trashed: 0,
      });
    };
  }, []);

  return (
    <>
      <div className="container">
        <div className={`${!isGetMediaBasiesLoading && !isGetVideosLoading ? "min-vh-100" : ""}`}>
          <div className="mb-2 mb-sm-5">
            <div className="d-flex align-items-center justify-content-between mb-3 mt-3">
              <h1 className="page-heading-title mb-0">VIDEOS</h1>
              {userCan("fm_video_create") ? (
                <div className="me-3">
                  <Link to="/fm-videos/create" className="fu-btn fu-btn-gray">
                    <span className="btn-bg"></span>
                    Create New
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="mt-4">
              <VideosFiltration labels={labels} artists={artists} genres={genres} getVideos={getVideos} perPage={perPage} orderBy={orderBy} orderType={orderType} />
            </div>
          </div>
          {!isGetMediaBasiesLoading && !isGetVideosLoading ? (
            <>
              <div className="row py-3 d-none d-xxl-flex table-heading-brdr">
                <div className="col-md-1">
                  <h3 className="h6 mb-0 fu-text-white">#ID</h3>
                </div>
                <div className="col-md-4">
                  <h3 className="h6 mb-0 fu-text-white">KEY</h3>
                </div>
                <div className="col-md-3">
                  <h3 className="h6 mb-0 fu-text-white">VIDEO TITLE</h3>
                </div>
                <div className="col-md-4">
                  <h3 className="h6 mb-0 fu-text-white">OPERATIONS</h3>
                </div>
              </div>
              <div className="table-heading-brdr mt-4 mt-sm-0 d-xxl-none"></div>
              {isPaginateLoading ? (
                <div className="d-flex justify-content-center mt-5">
                  <div className="position-relative mt-5">
                    <LoadingCircle circleCont={85} icon={80} loader={85} dot={85} />
                  </div>
                </div>
              ) : (
                <>
                  {videos.data && videos.data.length > 0 ? (
                    videos.data.map((video, index) => <VideoRow key={index} meta={videos.meta} video={video} index={index} getVideos={getVideos} perPage={perPage} pageNum={pageNum} orderBy={orderBy} orderType={orderType} />)
                  ) : videos.data && videos.data.length === 0 && filtrationDetails.search_query === "" && filtrationDetails.label_keys.length === 0 && filtrationDetails.genre_keys.length === 0 && filtrationDetails.artist_keys.length === 0 && filtrationDetails.only_trashed === 0 && !isFiltrationLoading ? (
                    <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                      <div>
                        <h2 className="fu-text-3-rem fu-text-yellow">
                          <i className="bi bi-database-add"></i>
                        </h2>
                      </div>
                      <div>
                        <h3 className="h5 fu-text-light-gray">
                          There Are No Videos
                          {userCan("fm_video_create") ? (
                            <>
                              ,
                              <Link to="/fm-videos/create" className="link-dashed ms-2">
                                Create New
                              </Link>
                            </>
                          ) : (
                            ""
                          )}
                        </h3>
                      </div>
                    </div>
                  ) : !isFiltrationLoading ? (
                    <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                      <div>
                        <h2 className="fu-text-3-rem fu-text-yellow">
                          <i className="bi bi-search"></i>
                        </h2>
                      </div>
                      <div>
                        <h3 className="h5 fu-text-light-gray">There are no videos matching your search</h3>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="mt-5">{videos ? videos.meta && videos.meta.last_page !== 1 ? <Pagination pages={videos.meta.links} pagination={videos.meta} getItems={getVideos} pathName={"fm-videos"} filtrationDetails={filtrationDetails} setIsPaginateLoading={setIsPaginateLoading} setPageNum={setPageNum} per_page={perPage} order_by={orderBy} order_type={orderType} /> : "" : ""}</div>
                </>
              )}
            </>
          ) : (
            <LoadingScreen />
          )}
        </div>
      </div>
    </>
  );
};

export default Videos;
