import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { fmDataContext } from "../../Context/FmData";
import Joi from "joi";
import { useParams } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { authenticationContext } from "../../Context/AuthContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";

import Select from "react-select";
import makeAnimated from "react-select/animated";

const UpdateNewBlog = () => {
  let [mounted, setMounted] = useState(false);

  let { newBlogId } = useParams();
  let { baseUrl, navigate, showData, globalCrumbs, setGlobalCrumbs, getAllDataWithoutPaginate } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  let [currentNewBlog, setCurrentNewBlog] = useState({});
  let [isNewBlogLoading, setIsNewBlogLoading] = useState(true);

  let [selectedImage, setSelectedImage] = useState({});

  let [joiErrorsList, setJoiErrorsList] = useState([]);
  let [imageInputError, setImageInputError] = useState(null);

  let [newBlogData, setNewBlogData] = useState({
    title: "",
    description: null,
    image: selectedImage,
    modify_user_id: null,
    btn_text_outside: null,
    btn_path_outside: null,
    btn_text_inside: null,
    btn_path_inside: null,
    news_ids: [],
  });

  let [isUpdateLoading, setIsUpdateLoading] = useState(false);

  let [updateResponse, setUpdateResponse] = useState({});

  const animatedComponents = makeAnimated();
  let newsKeysRef = useRef();

  let [selectedNews, setSelectedNews] = useState([]);

  let [isGetNewsLoading, setIsGetNewsLoading] = useState(true);
  let [news, setNews] = useState([]);

  let [navigateTimeOut, setNavigateTimeOut] = useState("");

  const getNewBlog = (reqMethod, pathName, setLoading = setIsNewBlogLoading) => {
    showData(reqMethod, pathName, setLoading)
      .then((response) => {
        // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
        // if (response.data.data.new_image.includes("via.placeholder.com")) {
        //   response.data.data.new_image = response.data.data.new_image.replace("http://127.0.0.1:8000/storage/", "");
        // }
        // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
        let newBlogResponse = response.data.data;

        setCurrentNewBlog(newBlogResponse);

        setNewBlogData({
          title: newBlogResponse.new_title ? newBlogResponse.new_title : "",
          description: newBlogResponse.new_description ? newBlogResponse.new_description.replaceAll("</br>", "\n") : null,
          image: selectedImage,
          modify_user_id: null,
          btn_text_outside: newBlogResponse.new_btn_text_outside ? newBlogResponse.new_btn_text_outside : null,
          btn_path_outside: newBlogResponse.new_btn_path_outside ? newBlogResponse.new_btn_path_outside : null,
          btn_text_inside: newBlogResponse.new_btn_text_inside ? newBlogResponse.new_btn_text_inside : null,
          btn_path_inside: newBlogResponse.new_btn_path_inside ? newBlogResponse.new_btn_path_inside : null,
          news_ids: [],
        });
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const getNewBlogData = (e) => {
    const newBlog = { ...newBlogData };
    newBlog[e.target.name] = e.target.value;
    setNewBlogData(newBlog);
  };

  const handleNewsSelect = () => {
    const newBlog = { ...newBlogData };
    newBlog.news_ids = selectedNews.map((newBlog) => newBlog.value);
    setNewBlogData(newBlog);
  };

  const getAllNews = (reqMethod, pathName, params, setLoading = setIsGetNewsLoading) => {
    getAllDataWithoutPaginate(reqMethod, pathName, params, setLoading)
      .then((response) => {
        setNews(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const handleImageSelect = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const updateItem = async (e) => {
    e.preventDefault();
    if (userCan("fm_new_blog_update")) {
      newBlogData.modify_user_id = user.currentUser._id;

      const validationResult = validateUpdateNewBlogForm();
      let imageError = null;
      setUpdateResponse({});

      if (selectedImage && selectedImage.name) {
        newBlogData.image = selectedImage;
        if (!["image/png", "image/jpg", "image/jpeg", "image/gif"].includes(newBlogData.image.type)) {
          imageError = "File must be an Image and from These extensions (png, jpg, jpeg, gif)";
        } else if (newBlogData.image.size > 5 * 1024 * 1024) {
          imageError = "Image Size Must Be Less Than 5 MB";
        }
      } else {
        newBlogData.image = null;
      }

      if (validationResult.error || imageError) {
        validationResult.error ? setJoiErrorsList(validationResult.error.details) : setJoiErrorsList([]);
        setImageInputError(imageError);
      } else {
        setIsUpdateLoading(true);
        setImageInputError(null);
        setJoiErrorsList([]);
        newBlogData.description = newBlogData.description ? newBlogData.description.replaceAll("\n", "</br>") : null;
        await axios({
          method: "POST",
          url: `${baseUrl}fm-news/update/${currentNewBlog.new_key}`,
          data: newBlogData,
          params: {
            _method: "PUT",
          },
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
            Authorization: `Bearer ${user.accessToken}`,
          },
        })
          .then((response) => {
            newBlogData.description = newBlogData.description ? newBlogData.description.replaceAll("</br>", "\n") : null;
            setUpdateResponse(response.data);
            setIsUpdateLoading(false);

            if (response.data.msg && response.data.msg.includes("successfully")) {
              let timeOut = setTimeout(() => {
                setUpdateResponse({});
                navigate(`/fm-news/${currentNewBlog.new_key}?archived=0`);
              }, 4000);
              setNavigateTimeOut(timeOut);
              setNewBlogData({
                title: "",
                description: null,
                image: {},
                modify_user_id: null,
                btn_text_outside: null,
                btn_path_outside: null,
                btn_text_inside: null,
                btn_path_inside: null,
                news_ids: [],
              });
              setSelectedImage(null);
              e.target.reset();
              newsKeysRef.current.clearValue();
              toast(`${response.data.msg}, navigating...`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                containerId: "update_done_toast",
              });
            } else if (response.data.msg && response.data.msg.includes("failed")) {
              toast(response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          })
          .catch((errors) => {
            navigate("/not-found");
          });
      }
    } else {
      navigate("/not-found");
    }
  };

  const validateUpdateNewBlogForm = () => {
    const scheme = Joi.object({
      title: Joi.string().min(1).max(500).required(),
      description: Joi.string().min(1).max(2000).required(),
      image: Joi.any(),
      modify_user_id: Joi.number(),
      btn_text_outside: Joi.string().min(1).max(255).allow(null, "").optional(),
      btn_path_outside: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Beatport URL is not valid").allow(null, "").optional(),
      btn_text_inside: Joi.string().min(1).max(255).allow(null, "").optional(),
      btn_path_inside: Joi.string().min(1).max(255).allow(null, "").optional(),
      news_ids: Joi.array().items(Joi.number()).allow(null).optional(),
    });
    return scheme.validate(newBlogData, { abortEarly: false });
  };

  useEffect(() => {
    getNewBlog("GET", `${baseUrl}fm-new/${newBlogId}`, setIsNewBlogLoading);
    getAllNews("GET", `${baseUrl}fm-all-news`, null, setIsGetNewsLoading);
    setMounted(true);
  }, []);

  useEffect(() => {
    return () => {
      if (mounted) {
        clearTimeout(navigateTimeOut);
      }
    };
  }, [navigateTimeOut]);

  useEffect(() => {
    if (mounted && currentNewBlog) {
      let globalCrumbsUpdated = globalCrumbs.map((crumb) => {
        if (crumb.name === newBlogId.toUpperCase()) {
          crumb.name = `${currentNewBlog.new_title
            .split(" ")
            .slice(0, currentNewBlog.new_title.split(" ").length > 5 ? 5 : currentNewBlog.new_title.split(" ").length - 1)
            .join(" ")
            .toUpperCase()}...`;
        }
        return crumb;
      });
      setGlobalCrumbs(globalCrumbsUpdated);

      if (newsKeysRef.current && currentNewBlog.related_news) {
        newsKeysRef.current.setValue(
          currentNewBlog.related_news.map((newBlog) => ({
            value: newBlog._id,
            label: `${newBlog.new_title
              .split(" ")
              .slice(0, newBlog.new_title.split(" ").length > 5 ? 5 : newBlog.new_title.split(" ").length - 1)
              .join(" ")}...`,
          }))
        );
      }
    }
  }, [currentNewBlog]);

  useEffect(() => {
    handleNewsSelect();
  }, [selectedNews]);

  return (
    <>
      {isNewBlogLoading || isGetNewsLoading ? (
        <LoadingScreen />
      ) : (
        <div className="container position-relative pt-3 pt-md-4">
          <div className="mb-3 mb-md-4">
            <h1 className="page-heading-title fu-up-new-head mb-0">Update New Blog</h1>
          </div>
          <div className="py-2 mb-4 iu-row-heading create-submit-row">
            <h2 className="mb-0 row-title">
              Edit (
              {` ${currentNewBlog.new_title
                .split(" ")
                .slice(0, currentNewBlog.new_title.split(" ").length > 3 ? 3 : currentNewBlog.new_title.split(" ").length - 1)
                .join(" ")}... `}
              ) data
            </h2>
          </div>
          <div className="row">
            <div>
              <div>
                <Form onSubmit={updateItem}>
                  <Row>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="newBlogTitle">
                        <Form.Label className="fu-text-light-gray" data-title="required">
                          New Blog Title
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter New Blog Title" className="fu-input" name="title" value={newBlogData.title ? newBlogData.title : ""} onInput={getNewBlogData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "title" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("t", "New blog t")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <Form.Group className="mb-3" controlId="newBlogImage">
                            <Form.Label className="fu-text-light-gray" data-title="required">
                              New Blog Image
                              <span className="required-input mx-1" data-title="required">
                                *
                              </span>
                            </Form.Label>
                            <Form.Control type="file" placeholder="Choose New Blog Image" className="fu-input" name="image" onInput={handleImageSelect} />

                            {imageInputError !== null ? <div className="alert alert-danger py-1 px-2 mt-2 mb-0">{imageInputError}</div> : ""}
                          </Form.Group>
                        </div>
                        <div className="ms-2 align-self-center">
                          <div className="rectangle-table-item-img-container d-flex align-items-center">
                            <img src={currentNewBlog.new_image} alt="new blog" className="w-100" />
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="newBlogDescription">
                        <Form.Label className="fu-text-light-gray" data-title="required">
                          New Blog Description
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </Form.Label>
                        <div className="textarea-container">
                          <Form.Control as="textarea" rows={4} className="fu-input" placeholder="Enter new blog description" name="description" value={newBlogData.description ? newBlogData.description.replaceAll("</br>", "\n") : ""} onInput={getNewBlogData} />
                        </div>
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "description" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("d", "New blog d")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>

                    {/* btn txt outside */}
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="btnTextOutside">
                        <Form.Label className="fu-text-light-gray" data-title="optional if button path outside is empty">
                          Button Text Outside Futurising music
                          <span className="optional-input mx-1" data-title="optional if button path outside is empty">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Button Text" className="fu-input" name="btn_text_outside" value={newBlogData.btn_text_outside ? newBlogData.btn_text_outside : ""} onInput={getNewBlogData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "btn_text_outside" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("btn", "button").replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    {/* btn path outside */}
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="btnPathOutside">
                        <Form.Label className="fu-text-light-gray" data-title="optional if button text outside is empty">
                          Button Path Outside Futurising music
                          <span className="optional-input mx-1" data-title="optional if button text outside is empty">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Button Path URL" className="fu-input" name="btn_path_outside" value={newBlogData.btn_path_outside ? newBlogData.btn_path_outside : ""} onInput={getNewBlogData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "btn_path_outside" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("btn", "button").replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>

                    {/* btn text inside */}
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="btnTextInside">
                        <Form.Label className="fu-text-light-gray" data-title="optional if button path inside is empty">
                          Button Text Inside Futurising music
                          <span className="optional-input mx-1" data-title="optional if button path inside is empty">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Button Text" className="fu-input" name="btn_text_inside" value={newBlogData.btn_text_inside ? newBlogData.btn_text_inside : ""} onInput={getNewBlogData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "btn_text_inside" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("btn", "button").replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    {/* btn path inside */}

                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="btnPathInside">
                        <div className="d-flex flex-wrap align-items-center">
                          <Form.Label className="fu-text-light-gray me-2" data-title="optional if button text inside is empty">
                            Button Path Inside Futurising music
                            <span className="optional-input mx-1" data-title="optional if button text inside is empty">
                              *
                            </span>
                          </Form.Label>
                          <span className="fu-text-light-gray d-inline-flex mb-2 form-explain">
                            <i className="me-2">
                              <strong>EX:</strong>
                            </i>
                            <span className="text-lined-through">https://www.futurisingmusic.com/</span>
                            <span className="fu-text-yellow">music/f2bd0b57-0365-4676-bbb2-c1bf6fe33510</span>
                          </span>
                        </div>
                        <Form.Control type="text" placeholder="Enter Button Path" className="fu-input" name="btn_path_inside" value={newBlogData.btn_path_inside ? newBlogData.btn_path_inside : ""} onInput={getNewBlogData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "btn_path_inside" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("btn", "button").replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>

                    {/* related news */}
                    <Col lg={6}>
                      <div className="mb-3">
                        <div className="fu-text-light-gray w-fit-content mb-2" data-title="optional">
                          Related News
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </div>
                        <div className="select-options">
                          <Select
                            ref={newsKeysRef}
                            classNames={{
                              control: () => "select-filtration",
                              valueContainer: ({ innerProps }) => {
                                innerProps.style = { display: "flex", flexWrap: "nowrap" };
                              },
                            }}
                            styles={{
                              multiValue: () => ({ overflow: "visible", width: "max-content", display: "inline-flex", alignItems: "center", backgroundColor: "#2f2f2f", margin: "0 2px", borderRadius: "3px", fontSize: "14px", padding: "0 0 0 2px" }),
                              multiValueLabel: () => ({ color: "#fdb915" }),
                              multiValueRemove: () => ({ color: "#a5a5a5", marginLeft: "3px", padding: "2px", display: "inline-flex", alignItems: "center", transition: "all 0.3s", ":hover": { color: "#fdb915" } }),
                            }}
                            placeholder="Select Related News...."
                            name="relatedNews"
                            onChange={setSelectedNews}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={news.map((newBlog) => ({
                              value: newBlog.id,
                              label: `${newBlog.title
                                .split(" ")
                                .slice(0, newBlog.title.split(" ").length > 5 ? 5 : newBlog.title.split(" ").length - 1)
                                .join(" ")}...`,
                            }))}
                          />
                        </div>
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "news_ids" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("_ids", "")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </div>
                    </Col>
                  </Row>
                  {updateResponse.errors
                    ? Object.keys(updateResponse.errors).map((key) =>
                        updateResponse.errors[key].map((error, index) => (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error.replaceAll("btn", "button")}
                          </div>
                        ))
                      )
                    : ""}

                  <div className="d-flex align-items-center mt-4">
                    <div>
                      <Button className={`fu-btn fu-btn-gray ${isUpdateLoading ? "disabled" : ""}`} type="submit" disabled={isUpdateLoading ? true : false}>
                        <span className="btn-bg"></span>
                        Submit<i className="bi bi-send ms-2"></i>
                      </Button>
                    </div>
                    {isUpdateLoading ? (
                      <div className="position-relative ms-3">
                        <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateNewBlog;
