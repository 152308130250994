import React, { useContext, useEffect, useRef, useState } from "react";
import { fmDataContext } from "../../Context/FmData";
import { Link } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import styles from "./Home.module.scss";
import jwtDecode from "jwt-decode";
import LabelCard from "../LabelCard/LabelCard";
import ArtistCard from "../ArtistCard/ArtistCard";
import ReleaseCard from "../ReleaseCard/ReleaseCard";
import Reveal from "../Reveal/Reveal";
import VideoCard from "../VideoCard/VideoCard";

const Home = () => {
  let [mounted, setMounted] = useState(false);

  let { baseUrl, getData, navigate } = useContext(fmDataContext);

  let [isHomeDataLoading, setIsHomeDataLoading] = useState(true);
  let [homeData, setHomeData] = useState({});

  let genresSearchInput = useRef();
  let labelsSearchInput = useRef();
  let artistsSearchInput = useRef();
  let nowReleasesSearchInput = useRef();
  let soonReleasesSearchInput = useRef();
  let videosSearchInput = useRef();

  let [genresSwiperCount, setGenresSwiperCount] = useState(4);
  let [labelsSwiperCount, setLabelsSwiperCount] = useState(6);
  let [artistsSwiperCount, setArtistsSwiperCount] = useState(6);
  let [nowReleasesSwiperCount, setNowReleasesSwiperCount] = useState(4);
  let [soonReleasesSwiperCount, setSoonReleasesSwiperCount] = useState(4);
  let [videosSwiperCount, setVideosSwiperCount] = useState(2);

  const setGenresSlidesCount = () => {
    if (window.innerWidth > 1400) {
      setGenresSwiperCount(4);
    } else if (window.innerWidth < 1400 && window.innerWidth >= 1200) {
      setGenresSwiperCount(3);
    } else if (window.innerWidth < 1200 && window.innerWidth > 991.9) {
      setGenresSwiperCount(3);
    } else if (window.innerWidth < 992 && window.innerWidth > 767.9) {
      setGenresSwiperCount(2);
    } else if (window.innerWidth < 768 && window.innerWidth > 0) {
      setGenresSwiperCount(1);
    }
  };

  const setLabelsSlidesCount = () => {
    if (window.innerWidth > 1400) {
      setLabelsSwiperCount(6);
    } else if (window.innerWidth < 1400 && window.innerWidth >= 1200) {
      setLabelsSwiperCount(5);
    } else if (window.innerWidth < 1200 && window.innerWidth > 991.9) {
      setLabelsSwiperCount(4);
    } else if (window.innerWidth < 992 && window.innerWidth > 767.9) {
      setLabelsSwiperCount(3);
    } else if (window.innerWidth < 768 && window.innerWidth > 575.9) {
      setLabelsSwiperCount(2);
    } else if (window.innerWidth < 576 && window.innerWidth > 0) {
      setLabelsSwiperCount(1);
    }
  };

  const setArtistsSlidesCount = () => {
    if (window.innerWidth > 1400) {
      setArtistsSwiperCount(6);
    } else if (window.innerWidth < 1400 && window.innerWidth >= 1200) {
      setArtistsSwiperCount(5);
    } else if (window.innerWidth < 1200 && window.innerWidth > 991.9) {
      setArtistsSwiperCount(4);
    } else if (window.innerWidth < 992 && window.innerWidth > 767.9) {
      setArtistsSwiperCount(3);
    } else if (window.innerWidth < 768 && window.innerWidth > 575.9) {
      setArtistsSwiperCount(2);
    } else if (window.innerWidth < 576 && window.innerWidth > 0) {
      setArtistsSwiperCount(1);
    }
  };

  const setNowReleasesSlidesCount = () => {
    if (window.innerWidth >= 1400) {
      setNowReleasesSwiperCount(4);
    } else if (window.innerWidth < 1400 && window.innerWidth > 1199.9) {
      setNowReleasesSwiperCount(3);
    } else if (window.innerWidth < 1200 && window.innerWidth > 991.9) {
      setNowReleasesSwiperCount(3);
    } else if (window.innerWidth < 992 && window.innerWidth > 767.9) {
      setNowReleasesSwiperCount(2);
    } else if (window.innerWidth < 768 && window.innerWidth > 0) {
      setNowReleasesSwiperCount(1);
    }
  };

  const setSoonReleasesSlidesCount = () => {
    if (window.innerWidth >= 1400) {
      setSoonReleasesSwiperCount(4);
    } else if (window.innerWidth < 1400 && window.innerWidth > 1199.9) {
      setSoonReleasesSwiperCount(3);
    } else if (window.innerWidth < 1200 && window.innerWidth > 991.9) {
      setSoonReleasesSwiperCount(3);
    } else if (window.innerWidth < 992 && window.innerWidth > 767.9) {
      setSoonReleasesSwiperCount(2);
    } else if (window.innerWidth < 768 && window.innerWidth > 0) {
      setSoonReleasesSwiperCount(1);
    }
  };

  const setVideosSlidesCount = () => {
    if (window.innerWidth > 1200) {
      setVideosSwiperCount(2);
    } else if (window.innerWidth < 1200 && window.innerWidth > 991.9) {
      setVideosSwiperCount(2);
    } else if (window.innerWidth < 992 && window.innerWidth > 767.9) {
      setVideosSwiperCount(1);
    } else if (window.innerWidth < 768 && window.innerWidth > 0) {
      setVideosSwiperCount(1);
    }
  };

  let userToken = jwtDecode(localStorage.getItem("userToken")).accessToken;

  const getHomeData = (reqMethod, pathName, params = null, setLoading = setIsHomeDataLoading) => {
    getData(reqMethod, pathName, params, setLoading, {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
      Authorization: `Bearer ${userToken}`,
    })
      .then((response) => {
        // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
        // if (response.data.labels) {
        //   response.data.labels.forEach((label) => {
        //     if (label.label_image.includes("via.placeholder.com")) {
        //       label.label_image = label.label_image.replace("http://127.0.0.1:8000/storage/", "");
        //     }
        //   });
        // }
        // if (response.data.artists) {
        //   response.data.artists.forEach((artist) => {
        //     if (artist.artist_image.includes("via.placeholder.com")) {
        //       artist.artist_image = artist.artist_image.replace("http://127.0.0.1:8000/storage/", "");
        //     }
        //   });
        // }
        // if (response.data.out_now_releases) {
        //   response.data.out_now_releases.forEach((release) => {
        //     if (release.release_image.includes("via.placeholder.com")) {
        //       release.release_image = release.release_image.replace("http://127.0.0.1:8000/storage/", "");
        //     }
        //   });
        // }
        // if (response.data.out_soon_releases) {
        //   response.data.out_soon_releases.forEach((release) => {
        //     if (release.release_image.includes("via.placeholder.com")) {
        //       release.release_image = release.release_image.replace("http://127.0.0.1:8000/storage/", "");
        //     }
        //   });
        // }

        // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
        setHomeData(response.data);
        setLoading(false);
      })
      .catch(() => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    getHomeData("GET", `${baseUrl}fm-get-db-home-page`, null, setIsHomeDataLoading);

    setMounted(true);

    setGenresSlidesCount();
    setLabelsSlidesCount();
    setArtistsSlidesCount();
    setNowReleasesSlidesCount();
    setSoonReleasesSlidesCount();
    setVideosSlidesCount();
    window.addEventListener("resize", function () {
      setGenresSlidesCount();
      setLabelsSlidesCount();
      setArtistsSlidesCount();
      setNowReleasesSlidesCount();
      setSoonReleasesSlidesCount();
      setVideosSlidesCount();
    });
  }, []);

  let [genresSearchTimeOut, setGenresSearchTimeOut] = useState("");
  let [genresSearchText, setGenresSearchText] = useState("");
  let [isGenresSearchLoading, setIsGenresSearchLoading] = useState(false);
  const getSearchGenres = () => {
    setIsGenresSearchLoading(true);
    if (genresSearchTimeOut) {
      clearTimeout(genresSearchTimeOut);
    }
    let getGenresSearchTimeOut = setTimeout(() => {
      getData(
        "GET",
        `${baseUrl}fm-all-full-genres`,
        {
          search_query: genresSearchText,
        },
        setIsGenresSearchLoading
      )
        .then((response) => {
          homeData.genres = response.data.data;
          setIsGenresSearchLoading(false);
        })
        .catch(() => {
          navigate("/not-found");
        });
    }, 1500);
    setGenresSearchTimeOut(getGenresSearchTimeOut);
  };
  useEffect(() => {
    if (mounted) {
      getSearchGenres();
    }
  }, [genresSearchText]);

  let [labelsSearchTimeOut, setLabelsSearchTimeOut] = useState("");
  let [labelsSearchText, setLabelsSearchText] = useState("");
  let [isLabelsSearchLoading, setIsLabelsSearchLoading] = useState(false);
  const getSearchLabels = () => {
    setIsLabelsSearchLoading(true);
    if (labelsSearchTimeOut) {
      clearTimeout(labelsSearchTimeOut);
    }
    let getLabelsSearchTimeOut = setTimeout(() => {
      getData(
        "GET",
        `${baseUrl}fm-all-full-labels`,
        {
          search_query: labelsSearchText,
        },
        setIsLabelsSearchLoading
      )
        .then((response) => {
          // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
          // if (response.data.data !== null && response.data.data.length > 0) {
          //   response.data.data.forEach((label) => {
          //     if (label.label_image.includes("via.placeholder.com")) {
          //       label.label_image = label.label_image.replace("http://127.0.0.1:8000/storage/", "");
          //     }
          //   });
          // }
          // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
          homeData.labels = response.data.data;
          setIsLabelsSearchLoading(false);
        })
        .catch(() => {
          navigate("/not-found");
        });
    }, 1500);
    setLabelsSearchTimeOut(getLabelsSearchTimeOut);
  };
  useEffect(() => {
    if (mounted) {
      getSearchLabels();
    }
  }, [labelsSearchText]);

  let [artistsSearchTimeOut, setArtistsSearchTimeOut] = useState("");
  let [artistsSearchText, setArtistsSearchText] = useState("");
  let [isArtistsSearchLoading, setIsArtistsSearchLoading] = useState(false);
  const getSearchArtists = () => {
    setIsArtistsSearchLoading(true);
    if (artistsSearchTimeOut) {
      clearTimeout(artistsSearchTimeOut);
    }
    let getArtistsSearchTimeOut = setTimeout(() => {
      getData(
        "GET",
        `${baseUrl}fm-all-full-artists`,
        {
          search_query: artistsSearchText,
        },
        setIsArtistsSearchLoading
      )
        .then((response) => {
          // remove before production ------------------------
          // if (response.data.data !== null && response.data.data.length > 0) {
          //   response.data.data.forEach((artist) => {
          //     if (artist.artist_image.includes("via.placeholder.com")) {
          //       artist.artist_image = artist.artist_image.replace("http://127.0.0.1:8000/storage/", "");
          //     }
          //   });
          // }
          // remove before production ------------------------
          homeData.artists = response.data.data;
          setIsArtistsSearchLoading(false);
        })
        .catch(() => {
          navigate("/not-found");
        });
    }, 1500);
    setArtistsSearchTimeOut(getArtistsSearchTimeOut);
  };
  useEffect(() => {
    if (mounted) {
      getSearchArtists();
    }
  }, [artistsSearchText]);

  let [nowReleasesSearchTimeOut, setNowReleasesSearchTimeOut] = useState("");
  let [nowReleasesSearchText, setNowReleasesSearchText] = useState("");
  let [isNowReleasesSearchLoading, setIsNowReleasesSearchLoading] = useState(false);
  const getSearchNowReleases = () => {
    setIsNowReleasesSearchLoading(true);
    if (nowReleasesSearchTimeOut) {
      clearTimeout(nowReleasesSearchTimeOut);
    }
    let getNowReleasesSearchTimeOut = setTimeout(() => {
      getData(
        "GET",
        `${baseUrl}fm-all-full-releases`,
        {
          search_query: nowReleasesSearchText,
          pre_order: 0,
        },
        setIsNowReleasesSearchLoading
      )
        .then((response) => {
          // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
          // if (response.data.data !== null && response.data.data.length > 0) {
          //   response.data.data.forEach((release) => {
          //     if (release.release_image.includes("via.placeholder.com")) {
          //       release.release_image = release.release_image.replace("http://127.0.0.1:8000/storage/", "");
          //     }
          //   });
          // }
          // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
          homeData.out_now_releases = response.data.data;
          setIsNowReleasesSearchLoading(false);
        })
        .catch(() => {
          navigate("/not-found");
        });
    }, 1500);
    setNowReleasesSearchTimeOut(getNowReleasesSearchTimeOut);
  };
  useEffect(() => {
    if (mounted) {
      getSearchNowReleases();
    }
  }, [nowReleasesSearchText]);

  let [soonReleasesSearchTimeOut, setSoonReleasesSearchTimeOut] = useState("");
  let [soonReleasesSearchText, setSoonReleasesSearchText] = useState("");
  let [isSoonReleasesSearchLoading, setIsSoonReleasesSearchLoading] = useState(false);
  const getSearchSoonReleases = () => {
    setIsSoonReleasesSearchLoading(true);
    if (soonReleasesSearchTimeOut) {
      clearTimeout(soonReleasesSearchTimeOut);
    }
    let getSoonReleasesSearchTimeOut = setTimeout(() => {
      getData(
        "GET",
        `${baseUrl}fm-all-full-releases`,
        {
          search_query: soonReleasesSearchText,
          pre_order: 1,
        },
        setIsSoonReleasesSearchLoading
      )
        .then((response) => {
          // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
          // if (response.data.data !== null && response.data.data.length > 0) {
          //   response.data.data.forEach((release) => {
          //     if (release.release_image.includes("via.placeholder.com")) {
          //       release.release_image = release.release_image.replace("http://127.0.0.1:8000/storage/", "");
          //     }
          //   });
          // }
          // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
          homeData.out_soon_releases = response.data.data;
          setIsSoonReleasesSearchLoading(false);
        })
        .catch(() => {
          navigate("/not-found");
        });
    }, 1500);
    setSoonReleasesSearchTimeOut(getSoonReleasesSearchTimeOut);
  };
  useEffect(() => {
    if (mounted) {
      getSearchSoonReleases();
    }
  }, [soonReleasesSearchText]);

  let [videosSearchTimeOut, setVideosSearchTimeOut] = useState("");
  let [videosSearchText, setVideosSearchText] = useState("");
  let [isVideosSearchLoading, setIsVideosSearchLoading] = useState(false);
  const getSearchVideos = () => {
    setIsVideosSearchLoading(true);
    if (videosSearchTimeOut) {
      clearTimeout(videosSearchTimeOut);
    }
    let getVideosSearchTimeOut = setTimeout(() => {
      getData(
        "GET",
        `${baseUrl}fm-all-full-videos`,
        {
          search_query: videosSearchText,
        },
        setIsVideosSearchLoading
      )
        .then((response) => {
          homeData.videos = response.data.data;
          setIsVideosSearchLoading(false);
        })
        .catch(() => {
          navigate("/not-found");
        });
    }, 1500);
    setVideosSearchTimeOut(getVideosSearchTimeOut);
  };
  useEffect(() => {
    if (mounted) {
      getSearchVideos();
    }
  }, [videosSearchText]);

  return (
    <div className="container-fluid container-sm">
      {isHomeDataLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-between my-3 my-sm-4">
            <h1 className="fu-text-white fu-fw-800 fu-text-4-rem fu-text-3-rem-md fu-text-2-rem-sm fu-text-22-px-xs fu-text-16-px-xxs mb-0 d-flex align-items-center">
              <span className={styles.fm_bill}>
                F<span className={styles.dash}></span>M
              </span>
              <span className={styles.db_word}>DASHBOARD</span>
            </h1>
          </div>
          <div className="mb-4 info-tags-container justify-content-start">
            {homeData.subscribers !== null ? (
              <>
                <div className="mx-1 my-1 info-tag">
                  <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-light-gray">SUBSCRIBERS COUNT: </span>
                  <span className="d-inline-flex justify-content-center align-items-center fu-text-white me-3">{homeData.subscribers}</span>
                  <span className="d-inline-flex justify-content-center align-items-center">
                    <Link to="/fm-subscribed-emails" className={styles.see_all_link}>
                      SEE ALL
                    </Link>
                  </span>
                </div>
              </>
            ) : (
              ""
            )}
            {homeData.track_demos !== null ? (
              <>
                <div className="mx-1 my-1 info-tag">
                  <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-light-gray">TRACK DEMOS COUNT: </span>
                  <span className="d-inline-flex justify-content-center align-items-center fu-text-white me-3">{homeData.track_demos}</span>
                  <span className="d-inline-flex justify-content-center align-items-center">
                    <Link to="/fm-track-demos" className={styles.see_all_link}>
                      SEE ALL
                    </Link>
                  </span>
                </div>
              </>
            ) : (
              ""
            )}
            {homeData.contact_messages !== null ? (
              <>
                <div className="mx-1 my-1 info-tag">
                  <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-light-gray">CONTACT MESSAGES COUNT: </span>
                  <span className="d-inline-flex justify-content-center align-items-center fu-text-white me-3">{homeData.contact_messages}</span>
                  <span className="d-inline-flex justify-content-center align-items-center">
                    <Link to="/fm-contact-messages" className={styles.see_all_link}>
                      SEE ALL
                    </Link>
                  </span>
                </div>
              </>
            ) : (
              ""
            )}
            {homeData.genres_count !== null ? (
              <>
                <div className="mx-1 my-1 info-tag">
                  <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-light-gray">GENRES COUNT: </span>
                  <span className="d-inline-flex justify-content-center align-items-center fu-text-white me-3">{homeData.genres_count}</span>
                  <span className="d-inline-flex justify-content-center align-items-center">
                    <Link to="/fm-genres" className={styles.see_all_link}>
                      SEE ALL
                    </Link>
                  </span>
                </div>
              </>
            ) : (
              ""
            )}
            {homeData.labels_count !== null ? (
              <>
                <div className="mx-1 my-1 info-tag">
                  <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-light-gray">LABELS COUNT: </span>
                  <span className="d-inline-flex justify-content-center align-items-center fu-text-white me-3">{homeData.labels_count}</span>
                  <span className="d-inline-flex justify-content-center align-items-center">
                    <Link to="/fm-labels" className={styles.see_all_link}>
                      SEE ALL
                    </Link>
                  </span>
                </div>
              </>
            ) : (
              ""
            )}
            {homeData.artists_count !== null ? (
              <>
                <div className="mx-1 my-1 info-tag">
                  <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-light-gray">ARTIST COUNT: </span>
                  <span className="d-inline-flex justify-content-center align-items-center fu-text-white me-3">{homeData.artists_count}</span>
                  <span className="d-inline-flex justify-content-center align-items-center">
                    <Link to="/fm-artists" className={styles.see_all_link}>
                      SEE ALL
                    </Link>
                  </span>
                </div>
              </>
            ) : (
              ""
            )}
            {homeData.out_now_releases_count !== null ? (
              <>
                <div className="mx-1 my-1 info-tag">
                  <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-light-gray">OUT NOW TRACKS COUNT: </span>
                  <span className="d-inline-flex justify-content-center align-items-center fu-text-white me-3">{homeData.out_now_releases_count}</span>
                  <span className="d-inline-flex justify-content-center align-items-center">
                    <Link to="/fm-releases" className={styles.see_all_link}>
                      SEE ALL
                    </Link>
                  </span>
                </div>
              </>
            ) : (
              ""
            )}
            {homeData.out_soon_releases_count !== null ? (
              <>
                <div className="mx-1 my-1 info-tag">
                  <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-light-gray">OUT SOON TRACKS COUNT: </span>
                  <span className="d-inline-flex justify-content-center align-items-center fu-text-white me-3">{homeData.out_soon_releases_count}</span>
                  <span className="d-inline-flex justify-content-center align-items-center">
                    <Link to="/fm-releases" className={styles.see_all_link}>
                      SEE ALL
                    </Link>
                  </span>
                </div>
              </>
            ) : (
              ""
            )}
            {homeData.videos_count !== null ? (
              <>
                <div className="mx-1 my-1 info-tag">
                  <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-light-gray">VIDEOS COUNT: </span>
                  <span className="d-inline-flex justify-content-center align-items-center fu-text-white me-3">{homeData.videos_count}</span>
                  <span className="d-inline-flex justify-content-center align-items-center">
                    <Link to="/fm-videos" className={styles.see_all_link}>
                      SEE ALL
                    </Link>
                  </span>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          {/* FM GENRES */}
          {homeData.genres !== null ? (
            <div className="mb-4">
              <div className="d-flex flex-column flex-lg-row align-items-center mb-4">
                <div className="flex-grow-1 iu-row-heading mb-2 mb-lg-0">
                  <h2 className="mb-0 row-title">FM GENRES</h2>
                </div>
                <div className="d-flex justify-content-between align-items-center row-search-container ms-0 ms-lg-2">
                  <div className="item-search-box-container flex-grow-1">
                    <div className="loading-fixed-dimensions item-search-box-loading">
                      {isGenresSearchLoading ? (
                        <div className="position-relative">
                          <LoadingCircle circleCont={20} icon={15} loader={20} dot={20} />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="item-search-box">
                      <input
                        type="text"
                        className="form-control shadow-none"
                        id="genre_search_query"
                        placeholder="FM Genres Search..."
                        onInput={(e) => {
                          setGenresSearchText(e.target.value);
                          clearTimeout(genresSearchTimeOut);
                        }}
                        ref={genresSearchInput}
                      />
                    </div>
                  </div>

                  <div className="ms-2">
                    <button
                      className="fu-btn fu-btn-gray"
                      onClick={() => {
                        setGenresSearchText("");
                        clearTimeout(genresSearchTimeOut);
                        genresSearchInput.current.value = "";
                      }}>
                      <span className="btn-bg"></span>
                      Clear Search
                    </button>
                  </div>
                </div>
              </div>
              <div className="item-genres-search-row-height">
                {genresSearchText.length > 0 && !isGenresSearchLoading && homeData.genres.length === 0 ? (
                  <div className="d-flex flex-column justify-content-center align-items-center item-genres-search-msg-height">
                    <div>
                      <h2 className="fu-text-3-rem fu-text-yellow">
                        <i className="bi bi-search"></i>
                      </h2>
                    </div>
                    <div>
                      <h3 className="h5 fu-text-light-gray line-clamp-2">
                        There Are No Genres With This Text<span className="d-inline-flex fu-text-yellow mx-2">"{genresSearchText}"</span>
                      </h3>
                    </div>
                  </div>
                ) : (
                  <div className="row gx-3">
                    <Swiper className="myswiper px-2 h-100" slidesPerView={genresSwiperCount} spaceBetween={10} keyboard={true} navigation={true} modules={[Navigation, Keyboard]}>
                      {homeData.genres.map((genre, index) => (
                        <SwiperSlide key={index}>
                          <Reveal>
                            <div className="genre-card-container">
                              <h2 className="genre-title">{genre.genre_title}</h2>
                              <div className="genre-info-box">
                                <h6 className="fu-text-white fu-text-1-rem fu-fw-700">
                                  <span className="d-inline-flex justify-content-center align-items-center fu-text-light-gray fu-fw-400 me-2">Releases Count:</span>
                                  {genre.genre_releases_count}
                                </h6>
                                <h6 className="fu-text-white fu-text-1-rem fu-fw-700 mb-3">
                                  <span className="d-inline-flex justify-content-center align-items-center fu-text-light-gray fu-fw-400 me-2">Videos Count:</span>
                                  {genre.genre_videos_count}
                                </h6>
                                <div className="d-flex justify-content-end">
                                  <div>
                                    <Link to={`/fm-genres/${genre.genre_key}`} className="btn fu-btn-sm fu-btn-gray z-index-high">
                                      <span className="btn-bg"></span>
                                      Genre Details
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Reveal>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          {/* FM LABELS */}
          {homeData.labels !== null ? (
            <div className="mb-4">
              <div className="d-flex flex-column flex-lg-row align-items-center mb-4">
                <div className="flex-grow-1 iu-row-heading mb-2 mb-lg-0">
                  <h2 className="mb-0 row-title">FM LABELS</h2>
                </div>
                <div className="d-flex justify-content-between align-items-center row-search-container ms-0 ms-lg-2">
                  <div className="item-search-box-container flex-grow-1">
                    <div className="loading-fixed-dimensions item-search-box-loading">
                      {isLabelsSearchLoading ? (
                        <div className="position-relative">
                          <LoadingCircle circleCont={20} icon={15} loader={20} dot={20} />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="item-search-box">
                      <input
                        type="text"
                        className="form-control shadow-none"
                        id="label_search_query"
                        placeholder="FM Labels Search..."
                        onInput={(e) => {
                          setLabelsSearchText(e.target.value);
                          clearTimeout(labelsSearchTimeOut);
                        }}
                        ref={labelsSearchInput}
                      />
                    </div>
                  </div>
                  <div className="ms-2">
                    <button
                      className="fu-btn fu-btn-gray"
                      onClick={() => {
                        setLabelsSearchText("");
                        clearTimeout(labelsSearchTimeOut);
                        labelsSearchInput.current.value = "";
                      }}>
                      <span className="btn-bg"></span>
                      Clear Search
                    </button>
                  </div>
                </div>
              </div>
              <div className="item-labels-search-row-height">
                {labelsSearchText.length > 0 && !isLabelsSearchLoading && homeData.labels.length === 0 ? (
                  <div className="d-flex flex-column justify-content-center align-items-center item-labels-search-msg-height">
                    <div>
                      <h2 className="fu-text-3-rem fu-text-yellow">
                        <i className="bi bi-search"></i>
                      </h2>
                    </div>
                    <div>
                      <h3 className="h5 fu-text-light-gray line-clamp-2">
                        There Are No Labels With This Text<span className="d-inline-flex fu-text-yellow mx-2">"{labelsSearchText}"</span>
                      </h3>
                    </div>
                  </div>
                ) : (
                  <div className="row gx-3">
                    <Swiper className="myswiper py-3 px-2 h-100" slidesPerView={labelsSwiperCount} spaceBetween={10} keyboard={true} navigation={true} modules={[Navigation, Keyboard]}>
                      {homeData.labels.map((label, index) => (
                        <SwiperSlide key={index} className="my-1">
                          <LabelCard label={label} />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          {/* FM ARTISTS */}
          {homeData.artists !== null ? (
            <div className="mb-4">
              <div className="d-flex flex-column flex-lg-row align-items-center mb-4">
                <div className="flex-grow-1 iu-row-heading mb-2 mb-lg-0">
                  <h2 className="mb-0 row-title">FM ARTISTS</h2>
                </div>
                <div className="d-flex justify-content-between align-items-center row-search-container ms-0 ms-lg-2">
                  <div className="item-search-box-container flex-grow-1">
                    <div className="loading-fixed-dimensions item-search-box-loading">
                      {isArtistsSearchLoading ? (
                        <div className="position-relative">
                          <LoadingCircle circleCont={20} icon={15} loader={20} dot={20} />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="item-search-box">
                      <input
                        type="text"
                        className="form-control shadow-none"
                        id="artist_search_query"
                        placeholder="FM Artists Search..."
                        onInput={(e) => {
                          setArtistsSearchText(e.target.value);
                          clearTimeout(artistsSearchTimeOut);
                        }}
                        ref={artistsSearchInput}
                      />
                    </div>
                  </div>
                  <div className="ms-2">
                    <button
                      className="fu-btn fu-btn-gray"
                      onClick={() => {
                        setArtistsSearchText("");
                        clearTimeout(artistsSearchTimeOut);
                        artistsSearchInput.current.value = "";
                      }}>
                      <span className="btn-bg"></span>
                      Clear Search
                    </button>
                  </div>
                </div>
              </div>
              <div className="item-artists-search-row-height">
                {artistsSearchText.length > 0 && !isArtistsSearchLoading && homeData.artists.length === 0 ? (
                  <div className="d-flex flex-column justify-content-center align-items-center item-artists-search-msg-height">
                    <div>
                      <h2 className="fu-text-3-rem fu-text-yellow">
                        <i className="bi bi-search"></i>
                      </h2>
                    </div>
                    <div>
                      <h3 className="h5 fu-text-light-gray line-clamp-2">
                        There Are No Artists With This Text<span className="d-inline-flex fu-text-yellow mx-2">"{artistsSearchText}"</span>
                      </h3>
                    </div>
                  </div>
                ) : (
                  <div className="row gx-3">
                    <Swiper className="myswiper py-3 px-2 h-100" slidesPerView={artistsSwiperCount} spaceBetween={10} keyboard={true} navigation={true} modules={[Navigation, Keyboard]}>
                      {homeData.artists.map((artist, index) => (
                        <SwiperSlide key={index} className="my-1">
                          <ArtistCard artist={artist} />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          {/* FM OUT NOW RELEASES */}
          {homeData.out_now_releases !== null ? (
            <div className="mb-4">
              <div className="d-flex flex-column flex-lg-row align-items-center mb-4">
                <div className="flex-grow-1 iu-row-heading mb-2 mb-lg-0">
                  <h2 className="mb-0 row-title">FM OUT NOW RELEASES</h2>
                </div>

                <div className="d-flex justify-content-between align-items-center row-search-container ms-0 ms-lg-2">
                  <div className="item-search-box-container flex-grow-1">
                    <div className="loading-fixed-dimensions item-search-box-loading">
                      {isNowReleasesSearchLoading ? (
                        <div className="position-relative">
                          <LoadingCircle circleCont={20} icon={15} loader={20} dot={20} />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="item-search-box">
                      <input
                        type="text"
                        className="form-control shadow-none"
                        id="now_release_search_query"
                        placeholder="FM Out Now Releases Search..."
                        onInput={(e) => {
                          setNowReleasesSearchText(e.target.value);
                          clearTimeout(nowReleasesSearchTimeOut);
                        }}
                        ref={nowReleasesSearchInput}
                      />
                    </div>
                  </div>

                  <div className="ms-2">
                    <button
                      className="fu-btn fu-btn-gray"
                      onClick={() => {
                        setNowReleasesSearchText("");
                        clearTimeout(nowReleasesSearchTimeOut);
                        nowReleasesSearchInput.current.value = "";
                      }}>
                      <span className="btn-bg"></span>
                      Clear Search
                    </button>
                  </div>
                </div>
              </div>
              <div className="item-releases-search-row-height">
                {nowReleasesSearchText.length > 0 && !isNowReleasesSearchLoading && homeData.out_now_releases.length === 0 ? (
                  <div className="d-flex flex-column justify-content-center align-items-center item-releases-search-msg-height">
                    <div>
                      <h2 className="fu-text-3-rem fu-text-yellow">
                        <i className="bi bi-search"></i>
                      </h2>
                    </div>
                    <div>
                      <h3 className="h5 fu-text-light-gray line-clamp-2">
                        There Are No Releases With This Text<span className="d-inline-flex fu-text-yellow mx-2">"{nowReleasesSearchText}"</span>
                      </h3>
                    </div>
                  </div>
                ) : (
                  <div className="row gx-3">
                    <Swiper className="myswiper px-2 h-100" slidesPerView={nowReleasesSwiperCount} spaceBetween={10} keyboard={true} navigation={true} modules={[Navigation, Keyboard]}>
                      {homeData.out_now_releases.map((release, index) => (
                        <SwiperSlide key={index} className="my-1">
                          <ReleaseCard release={release} />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          {/* FM OUT SOON RELEASES */}
          {homeData.out_soon_releases !== null ? (
            <div className="mb-4">
              <div className="d-flex flex-column flex-lg-row align-items-center mb-4">
                <div className="flex-grow-1 iu-row-heading mb-2 mb-lg-0">
                  <h2 className="mb-0 row-title">FM OUT SOON RELEASES</h2>
                </div>

                <div className="d-flex justify-content-between align-items-center row-search-container ms-0 ms-lg-2">
                  <div className="item-search-box-container flex-grow-1">
                    <div className="loading-fixed-dimensions item-search-box-loading">
                      {isSoonReleasesSearchLoading ? (
                        <div className="position-relative">
                          <LoadingCircle circleCont={20} icon={15} loader={20} dot={20} />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="item-search-box">
                      <input
                        type="text"
                        className="form-control shadow-none"
                        id="soon_release_search_query"
                        placeholder="FM Out Soon Releases Search..."
                        onInput={(e) => {
                          setSoonReleasesSearchText(e.target.value);
                          clearTimeout(soonReleasesSearchTimeOut);
                        }}
                        ref={soonReleasesSearchInput}
                      />
                    </div>
                  </div>

                  <div className="ms-2">
                    <button
                      className="fu-btn fu-btn-gray"
                      onClick={() => {
                        setSoonReleasesSearchText("");
                        clearTimeout(soonReleasesSearchTimeOut);
                        soonReleasesSearchInput.current.value = "";
                      }}>
                      <span className="btn-bg"></span>
                      Clear Search
                    </button>
                  </div>
                </div>
              </div>

              <div className="item-releases-search-row-height">
                {soonReleasesSearchText.length > 0 && !isSoonReleasesSearchLoading && homeData.out_soon_releases.length === 0 ? (
                  <div className="d-flex flex-column justify-content-center align-items-center item-releases-search-msg-height">
                    <div>
                      <h2 className="fu-text-3-rem fu-text-yellow">
                        <i className="bi bi-search"></i>
                      </h2>
                    </div>
                    <div>
                      <h3 className="h5 fu-text-light-gray line-clamp-2">
                        There Are No Releases With This Text<span className="d-inline-flex fu-text-yellow mx-2">"{soonReleasesSearchText}"</span>
                      </h3>
                    </div>
                  </div>
                ) : (
                  <div className="row gx-3">
                    <Swiper className="myswiper px-2 h-100" slidesPerView={soonReleasesSwiperCount} spaceBetween={10} keyboard={true} navigation={true} modules={[Navigation, Keyboard]}>
                      {homeData.out_soon_releases.map((release, index) => (
                        <SwiperSlide key={index} className="my-1">
                          <ReleaseCard release={release} />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          {/* FM VIDEOS */}
          {homeData.videos !== null ? (
            <div className="mb-4">
              <div className="d-flex flex-column flex-lg-row align-items-center mb-4">
                <div className="flex-grow-1 iu-row-heading mb-2 mb-lg-0">
                  <h2 className="mb-0 row-title">FM VIDEOS</h2>
                </div>
                <div className="d-flex justify-content-between align-items-center row-search-container ms-0 ms-lg-2">
                  <div className="item-search-box-container flex-grow-1">
                    <div className="loading-fixed-dimensions item-search-box-loading">
                      {isVideosSearchLoading ? (
                        <div className="position-relative">
                          <LoadingCircle circleCont={20} icon={15} loader={20} dot={20} />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="item-search-box">
                      <input
                        type="text"
                        className="form-control shadow-none"
                        id="video_search_query"
                        placeholder="FM Videos Search..."
                        onInput={(e) => {
                          setVideosSearchText(e.target.value);
                          clearTimeout(videosSearchTimeOut);
                        }}
                        ref={videosSearchInput}
                      />
                    </div>
                  </div>
                  <div className="ms-2">
                    <button
                      className="fu-btn fu-btn-gray"
                      onClick={() => {
                        setVideosSearchText("");
                        clearTimeout(videosSearchTimeOut);
                        videosSearchInput.current.value = "";
                      }}>
                      <span className="btn-bg"></span>
                      Clear Search
                    </button>
                  </div>
                </div>
              </div>
              <div className="item-videos-search-row-height">
                {videosSearchText.length > 0 && !isVideosSearchLoading && homeData.videos.length === 0 ? (
                  <div className="d-flex flex-column justify-content-center align-items-center item-videos-search-msg-height">
                    <div>
                      <h2 className="fu-text-3-rem fu-text-yellow">
                        <i className="bi bi-search"></i>
                      </h2>
                    </div>
                    <div>
                      <h3 className="h5 fu-text-light-gray line-clamp-2">
                        There Are No Videos With This Text<span className="d-inline-flex fu-text-yellow mx-2">"{videosSearchText}"</span>
                      </h3>
                    </div>
                  </div>
                ) : (
                  <div className="row gx-3">
                    <Swiper className="swiper px-2 h-100" slidesPerView={videosSwiperCount} spaceBetween={20} keyboard={true} navigation={true} modules={[Navigation, Keyboard]}>
                      {homeData.videos.map((video, index) => (
                        <SwiperSlide key={index} className="my-1">
                          <VideoCard video={video} />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default Home;
