import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { fmDataContext } from "../../Context/FmData";
import Joi from "joi";
import { useParams } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { authenticationContext } from "../../Context/AuthContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";

import jwtDecode from "jwt-decode";

const UpdatePartnership = () => {
  let [mounted, setMounted] = useState(false);

  let { partnershipId } = useParams();
  let { baseUrl, navigate, showData, globalCrumbs, setGlobalCrumbs } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  let [currentPartnership, setCurrentPartnership] = useState({});
  let [isPartnershipLoading, setIsPartnershipLoading] = useState(true);

  let [selectedImage, setSelectedImage] = useState({});

  let [joiErrorsList, setJoiErrorsList] = useState([]);
  let [imageInputError, setImageInputError] = useState(null);

  let [partnershipData, setPartnershipData] = useState({
    name: "",
    about: "",
    image: selectedImage,
    modify_user_id: null,
    facebook_url: null,
    instagram_url: null,
    threads_url: null,
    x_url: null,
    linkedin_url: null,
    website_url: null,
  });

  let [isUpdateLoading, setIsUpdateLoading] = useState(false);

  let [updateResponse, setUpdateResponse] = useState({});

  let [navigateTimeOut, setNavigateTimeOut] = useState("");

  let userToken = jwtDecode(localStorage.getItem("userToken")).accessToken;

  const getPartnership = (reqMethod, pathName, setLoading = setIsPartnershipLoading, headers) => {
    showData(reqMethod, pathName, setLoading, headers)
      .then((response) => {
        // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
        // if (response.data.data.partnership_image.includes("via.placeholder.com")) {
        //   response.data.data.partnership_image = response.data.data.partnership_image.replace("http://127.0.0.1:8000/storage/", "");
        // }
        // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
        let partnershipResponse = response.data.data;
        setCurrentPartnership(partnershipResponse);
        setPartnershipData({
          name: partnershipResponse.partnership_name ? partnershipResponse.partnership_name : "",
          about: partnershipResponse.partnership_bio ? partnershipResponse.partnership_bio.replaceAll("</br>", "\n") : "",
          image: selectedImage,
          modify_user_id: null,
          facebook_url: partnershipResponse && partnershipResponse.partnership_social_media_links.facebook_url ? partnershipResponse.partnership_social_media_links.facebook_url : null,
          instagram_url: partnershipResponse && partnershipResponse.partnership_social_media_links.instagram_url ? partnershipResponse.partnership_social_media_links.instagram_url : null,
          threads_url: partnershipResponse && partnershipResponse.partnership_social_media_links.threads_url ? partnershipResponse.partnership_social_media_links.threads_url : null,
          x_url: partnershipResponse && partnershipResponse.partnership_social_media_links.x_url ? partnershipResponse.partnership_social_media_links.x_url : null,
          linkedin_url: partnershipResponse && partnershipResponse.partnership_social_media_links.linkedin_url ? partnershipResponse.partnership_social_media_links.linkedin_url : null,
          website_url: partnershipResponse && partnershipResponse.partnership_social_media_links.website_url ? partnershipResponse.partnership_social_media_links.website_url : null,
        });
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const getPartnershipData = (e) => {
    const partnership = { ...partnershipData };
    partnership[e.target.name] = e.target.value;
    setPartnershipData(partnership);
  };

  const handleImageSelect = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const updateItem = async (e) => {
    e.preventDefault();
    if (userCan("fm_partnership_update")) {
      partnershipData.modify_user_id = user.currentUser._id;
      const validationResult = validateUpdatePartnershipForm();
      let imageError = null;
      setUpdateResponse({});
      if (selectedImage && selectedImage.name) {
        partnershipData.image = selectedImage;
        if (!["image/png", "image/jpg", "image/jpeg", "image/gif"].includes(partnershipData.image.type)) {
          imageError = "File must be an Image and from These extensions (png, jpg, jpeg, gif)";
        } else if (partnershipData.image.size > 5 * 1024 * 1024) {
          imageError = "Image Size Must Be Less Than 5 MB";
        }
      } else {
        partnershipData.image = null;
      }
      if (validationResult.error || imageError) {
        validationResult.error ? setJoiErrorsList(validationResult.error.details) : setJoiErrorsList([]);
        setImageInputError(imageError);
      } else {
        setIsUpdateLoading(true);
        setImageInputError(null);
        setJoiErrorsList([]);
        partnershipData.about = partnershipData.about ? partnershipData.about.replaceAll("\n", "</br>") : "";
        await axios({
          method: "POST",
          url: `${baseUrl}fm-partnerships/update/${currentPartnership.key}`,
          data: partnershipData,
          params: {
            _method: "PUT",
          },
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
            Authorization: `Bearer ${user.accessToken}`,
          },
        })
          .then((response) => {
            partnershipData.about = partnershipData.about ? partnershipData.about.replaceAll("</br>", "\n") : "";
            setUpdateResponse(response.data);
            setIsUpdateLoading(false);
            if (response.data.msg && response.data.msg.includes("successfully")) {
              let timeOut = setTimeout(() => {
                setUpdateResponse({});
                navigate(`/fm-partnerships/${currentPartnership.key}?archived=0`);
              }, 4000);
              setNavigateTimeOut(timeOut);
              setPartnershipData({
                name: "",
                about: "",
                image: {},
                modify_user_id: null,
                facebook_url: null,
                instagram_url: null,
                threads_url: null,
                x_url: null,
                linkedin_url: null,
                website_url: null,
              });
              setSelectedImage(null);
              e.target.reset();
              toast(`${response.data.msg}, navigating...`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                containerId: "update_done_toast",
              });
            } else if (response.data.msg && response.data.msg.includes("failed")) {
              toast(response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          })
          .catch((errors) => {
            navigate("/not-found");
          });
      }
    } else {
      navigate("/not-found");
    }
  };

  const validateUpdatePartnershipForm = () => {
    const scheme = Joi.object({
      name: Joi.string().min(1).max(255).required(),
      about: Joi.string().min(1).max(1500).required(),
      image: Joi.any(),
      modify_user_id: Joi.number(),
      facebook_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Facebook URL is not valid").allow(null, "").optional(),
      instagram_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Instagram URL is not valid").allow(null, "").optional(),
      threads_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("threads URL is not valid").allow(null, "").optional(),
      x_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("X URL is not valid").allow(null, "").optional(),
      linkedin_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Linkedin URL is not valid").allow(null, "").optional(),
      website_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Website URL is not valid").allow(null, "").optional(),
    });
    return scheme.validate(partnershipData, { abortEarly: false });
  };

  useEffect(() => {
    getPartnership("GET", `${baseUrl}fm-partnership/${partnershipId}`, setIsPartnershipLoading, {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
      Authorization: `Bearer ${userToken}`,
    });
    setMounted(true);
  }, []);

  useEffect(() => {
    return () => {
      if (mounted) {
        clearTimeout(navigateTimeOut);
      }
    };
  }, [navigateTimeOut]);

  useEffect(() => {
    if (mounted && currentPartnership) {
      let globalCrumbsUpdated = globalCrumbs.map((crumb) => {
        if (crumb.name === partnershipId.toUpperCase()) {
          crumb.name = currentPartnership.partnership_name.toUpperCase();
        }
        return crumb;
      });
      setGlobalCrumbs(globalCrumbsUpdated);
    }
  }, [currentPartnership]);

  return (
    <>
      {isPartnershipLoading ? (
        <LoadingScreen />
      ) : (
        <div className="container position-relative pt-3 pt-md-4">
          <div className="mb-3 mb-md-4">
            <h1 className="fu-text-3-rem fu-text-3-rem-md fu-text-md-2-rem fu-text-sm-2-rem fu-text-1-8-rem-xs fu-text-18-px-xxs fu-text-16-px-xxs fu-fw-800 fu-text-white mb-0">Update Partnership</h1>
          </div>
          <div className="py-2 mb-4 iu-row-heading create-submit-row">
            <h2 className="mb-0 row-title">Edit ( {currentPartnership.partnership_name} ) data</h2>
          </div>
          <div className="row">
            <div>
              <div>
                <Form onSubmit={updateItem}>
                  <Row>
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="partnershipName">
                        <Form.Label className="fu-text-light-gray" data-title="required">
                          Partnership Name
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Partnership Name" className="fu-input" name="name" value={partnershipData.name ? partnershipData.name : ""} onInput={getPartnershipData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "name" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("name", "Partnership name")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col xl={6}>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <Form.Group className="mb-3" controlId="partnershipImage">
                            <Form.Label className="fu-text-light-gray" data-title="required">
                              Partnership Image
                              <span className="required-input mx-1" data-title="required">
                                *
                              </span>
                            </Form.Label>
                            <Form.Control type="file" placeholder="choose Partnership Image" className="fu-input" name="image" onInput={handleImageSelect} />

                            {imageInputError !== null ? <div className="alert alert-danger py-1 px-2 mt-2 mb-0">{imageInputError}</div> : ""}
                          </Form.Group>
                        </div>
                        <div className="ms-2 align-self-center align-self-md-baseline">
                          <div className="square-table-item-img-container">
                            <img src={currentPartnership.partnership_image} alt="artist" className="w-100" />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Form.Group className="mb-3" controlId="aboutPartnership">
                    <Form.Label className="fu-text-light-gray" data-title="required">
                      About Partnership
                      <span className="required-input mx-1" data-title="required">
                        *
                      </span>
                    </Form.Label>
                    <div className="textarea-container">
                      <Form.Control as="textarea" rows={4} className="fu-input" placeholder="Enter your partnership bio" name="about" value={partnershipData.about ? partnershipData.about.replaceAll("</br>", "\n") : ""} onInput={getPartnershipData} />
                    </div>
                    {joiErrorsList.map((error, index) =>
                      error.path[0] === "about" ? (
                        <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                          {error.message.replace("about", "Partnership about")}
                        </div>
                      ) : (
                        ""
                      )
                    )}
                  </Form.Group>
                  {/* ------------------------------------ */}
                  <Row>
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="facebookUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          FaceBook URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Partnership FaceBook URL" className="fu-input" name="facebook_url" value={partnershipData.facebook_url ? partnershipData.facebook_url : ""} onInput={getPartnershipData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "facebook_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="instagramUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Instagram URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Partnership Instagram URL" className="fu-input" name="instagram_url" value={partnershipData.instagram_url ? partnershipData.instagram_url : ""} onInput={getPartnershipData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "instagram_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="threadsUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Threads URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Partnership Threads URL" className="fu-input" name="threads_url" value={partnershipData.threads_url ? partnershipData.threads_url : ""} onInput={getPartnershipData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "threads_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="xUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          X URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Partnership X URL" className="fu-input" name="x_url" value={partnershipData.x_url ? partnershipData.x_url : ""} onInput={getPartnershipData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "x_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="linkedInUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          LinkedIn URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Partnership LinkedIn URL" className="fu-input" name="linkedin_url" value={partnershipData.linkedin_url ? partnershipData.linkedin_url : ""} onInput={getPartnershipData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "linkedin_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="websiteUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Website URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Partnership Website URL" className="fu-input" name="website_url" value={partnershipData.website_url ? partnershipData.website_url : ""} onInput={getPartnershipData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "website_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  {updateResponse.errors
                    ? Object.keys(updateResponse.errors).map((key) =>
                        updateResponse.errors[key].map((error, index) => (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error}
                          </div>
                        ))
                      )
                    : ""}

                  <div className="d-flex align-items-center mt-4">
                    <div>
                      <Button className={`fu-btn fu-btn-gray ${isUpdateLoading ? "disabled" : ""}`} type="submit" disabled={isUpdateLoading ? true : false}>
                        <span className="btn-bg"></span>
                        Submit<i className="bi bi-send ms-2"></i>
                      </Button>
                    </div>
                    {isUpdateLoading ? (
                      <div className="position-relative ms-3">
                        <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdatePartnership;
