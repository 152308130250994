import React, { useContext, useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { fmDataContext } from "../../Context/FmData";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { fmHomeSlidesContext } from "../../Context/HomeSlidesContext";
import userCan from "../../helpers/userCan";
import Swal from "sweetalert2/dist/sweetalert2.all.min";
import "@sweetalert2/theme-dark/dark.min.css";
import jwtDecode from "jwt-decode";
import styles from "./HomeSlideDetails.module.scss";
import { authenticationContext } from "../../Context/AuthContext";

const HomeSlideDetails = () => {
  let [mounted, setMounted] = useState(false);

  let { homeSlideId } = useParams();
  let [params, setParams] = useSearchParams();

  let { baseUrl, navigate, showData, editItemExistence, globalCrumbs, setGlobalCrumbs } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  let [currentHomeSlide, setCurrentHomeSlide] = useState({});
  let onlyTrashed = params.get("archived");
  let [isCurrentHomeSlideLoading, setIsCurrentHomeSlideLoading] = useState(true);

  let [isSoftDeleteLoading, setIsSoftDeleteLoading] = useState(false);
  let [isForceDeleteLoading, setIsForceDeleteLoading] = useState(false);
  let [isRestoreLoading, setIsRestoreLoading] = useState(false);

  let { setHomeSlideMsg } = useContext(fmHomeSlidesContext);

  let userToken = jwtDecode(localStorage.getItem("userToken")).accessToken;

  const softDeleteHomeSlide = (reqMethod, pathName, headers, setLoading) => {
    if (userCan("fm_home_carousel_slide_soft_delete")) {
      Swal.fire({
        title: "Are you sure?",
        html: `<p class="mb-0 line-clamp-2">You will delete <span class="fu-text-light-gray">${currentHomeSlide.data.slide_title} slide</span></p>`,
        icon: "warning",
        showCancelButton: true,
        customClass: {
          cancelButton: "popup-btn",
          confirmButton: "popup-btn",
        },
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              setHomeSlideMsg(`${currentHomeSlide.data.slide_title.slice(0, currentHomeSlide.data.slide_title.length > 20 ? 20 : currentHomeSlide.data.slide_title.length)}${currentHomeSlide.data.slide_title.length > 20 ? "..." : ""} slide Deleted Successfully`);
              navigate("/fm-home-slides");
            })
            .catch((error) => {
              navigate("/not-found");
            });
        }
      });
    } else {
      navigate("/not-found");
    }
  };

  const restoreHomeSlide = (reqMethod, pathName, headers, setLoading) => {
    if (userCan("fm_home_carousel_slide_restore_trashed")) {
      Swal.fire({
        title: "Are you sure?",
        html: `<p class="mb-0 line-clamp-2">You will restore <span class="fu-text-light-gray">${currentHomeSlide.data.slide_title} slide</span></p>`,
        icon: "question",
        showCancelButton: true,
        customClass: {
          cancelButton: "popup-btn",
          confirmButton: "popup-btn",
        },
        confirmButtonText: "Yes, restore it!",
      }).then((result) => {
        if (result.isConfirmed) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              setHomeSlideMsg(`${currentHomeSlide.data.slide_title.slice(0, currentHomeSlide.data.slide_title.length > 20 ? 20 : currentHomeSlide.data.slide_title.length)}${currentHomeSlide.data.slide_title.length > 20 ? "..." : ""} Restored Successfully`);
              navigate("/fm-home-slides");
            })
            .catch((error) => {
              navigate("/not-found");
            });
        }
      });
    } else {
      navigate("/not-found");
    }
  };

  const forceDeleteHomeSlide = (reqMethod, pathName, headers, setLoading) => {
    Swal.fire({
      title: "Are you sure?",
      html: `<p class="mb-0 line-clamp-2">You will delete <span class="fu-text-light-gray">${currentHomeSlide.data.slide_title} slide permanently</span></p><p class="mb-0">You won't be able to revert this!</p>`,
      icon: "warning",
      showCancelButton: true,
      customClass: {
        cancelButton: "popup-btn",
        confirmButton: "popup-btn",
      },
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        if (userCan("fm_home_carousel_slide_force_delete")) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              setHomeSlideMsg(`${currentHomeSlide.data.slide_title.slice(0, currentHomeSlide.data.slide_title.length > 20 ? 20 : currentHomeSlide.data.slide_title.length)}${currentHomeSlide.data.slide_title.length > 20 ? "..." : ""} Deleted Permanently Successfully`);
              navigate("/fm-home-slides");
            })
            .catch((error) => {
              navigate("/not-found");
            });
        } else {
          navigate("/not-found");
        }
      }
    });
  };

  const getHomeSlide = (reqMethod, pathName, setLoading = setIsCurrentHomeSlideLoading, headers, params) => {
    showData(reqMethod, pathName, setLoading, headers, params)
      .then((response) => {
        if (response.data.data.slide_image.includes("via.placeholder.com")) {
          response.data.data.slide_image = response.data.data.slide_image.replace("http://127.0.0.1:8000/storage/", "");
        }

        setCurrentHomeSlide(response.data);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    getHomeSlide(
      "GET",
      `${baseUrl}fm-home-carousel-slide/${homeSlideId}`,
      setIsCurrentHomeSlideLoading,
      {
        Accept: "application/json",
        "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
        Authorization: `Bearer ${userToken}`,
      },
      {
        only_trashed: onlyTrashed,
      }
    );
    setMounted(true);
  }, []);

  useEffect(() => {
    if (mounted) {
      getHomeSlide(
        "GET",
        `${baseUrl}fm-home-carousel-slide/${homeSlideId}`,
        setIsCurrentHomeSlideLoading,
        {
          Accept: "application/json",
          "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
          Authorization: `Bearer ${userToken}`,
        },
        {
          only_trashed: onlyTrashed,
        }
      );
    }
  }, [homeSlideId]);

  useEffect(() => {
    if (mounted && currentHomeSlide.data) {
      let globalCrumbsUpdated = globalCrumbs.map((crumb) => {
        if (crumb.name === homeSlideId.toUpperCase()) {
          crumb.name = `${currentHomeSlide.data.slide_title
            .split(" ")
            .slice(0, currentHomeSlide.data.slide_title.split(" ").length > 5 ? 5 : currentHomeSlide.data.slide_title.split(" ").length)
            .join(" ")}${currentHomeSlide.data.slide_title.split(" ").length > 5 ? "..." : ""} slide`.toUpperCase();
        }
        return crumb;
      });
      setGlobalCrumbs(globalCrumbsUpdated);
    }
  }, [currentHomeSlide]);

  return (
    <>
      <div className={`${!isCurrentHomeSlideLoading ? "min-vh-100" : ""}`}>
        {isCurrentHomeSlideLoading ? (
          <LoadingScreen />
        ) : (
          <>
            <div className={styles.slide_container}>
              <div className={`pt-4 ${styles.slide_parent}`}>
                <div className={`${styles.slide} z-index-high`}>
                  <div className="container">
                    <div className="row gy-3 gy-sm-4 gy-lg-0">
                      <div className="col-12 col-lg-6 col-xl-5 offset-xl-1 order-last order-lg-first">
                        <div className={`${styles.slide_caption} pt-lg-4 mt-lg-5 pt-xxl-5`}>
                          <div className="mb-4 mb-md-5">
                            <h2 className={`${styles.slide_caption_heading} mb-2 mb-sm-3 mb-md-4`}>{currentHomeSlide.data.slide_title.toUpperCase()}</h2>
                            {currentHomeSlide.data.slide_description ? <p className={`${styles.slide_caption_descriprion} mb-0`} dangerouslySetInnerHTML={{ __html: currentHomeSlide.data.slide_description }} /> : ""}
                          </div>
                          <div>
                            {(currentHomeSlide.data.btn_text_outside || currentHomeSlide.data.btn_text_inside) && (
                              <div className="my-4">
                                {currentHomeSlide.data.btn_text_outside && (
                                  <a href={currentHomeSlide.data.btn_path_outside} className="fu-btn-sm fu-btn-gray" target="_blank" rel="noreferrer">
                                    <span className="btn-bg"></span>
                                    {currentHomeSlide.data.btn_text_outside}
                                  </a>
                                )}
                                {currentHomeSlide.data.btn_text_inside && (
                                  <a href={`http://localhost:3001/${currentHomeSlide.data.btn_path_inside}`} className="fu-btn-sm fu-btn-gray ms-2" target="_blank" rel="noreferrer">
                                    <span className="btn-bg"></span>
                                    {currentHomeSlide.data.btn_text_inside}
                                  </a>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-lg-6 col-xl-5 order-first order-first order-lg-last">
                        <div>
                          <img className={`d-block mx-auto mx-lg-0 ms-lg-auto rounded-3 ${styles.slide_img_size}`} src={currentHomeSlide.data.slide_image} alt="slide" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className={`${styles.slide_bg_blur}`} style={{ backgroundImage: `url(${currentHomeSlide.data.slide_image})` }}></div>
                </div>
              </div>
              <div className={styles.gradient}></div>
            </div>
            <div className="position-relative mt-2 mt-sm-4 mt-lg-5 z-index-high">
              <div className="container">
                <div className="d-flex flex-wrap align-items-center justify-content-center mb-4">
                  {currentHomeSlide.data.slide_deleted_at === null ? (
                    userCan("fm_home_carousel_slide_update") ? (
                      <div className="mx-2 my-1 my-sm-0">
                        <Link to={`/fm-home-slides/update/${currentHomeSlide.data.key}`} className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`} disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                          <span className="btn-bg"></span>
                          Edit
                        </Link>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {currentHomeSlide.data.slide_deleted_at === null ? (
                    userCan("fm_home_carousel_slide_soft_delete") ? (
                      <div className="mx-2 my-1 my-sm-0">
                        <button
                          className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`}
                          onClick={() =>
                            softDeleteHomeSlide(
                              "DELETE",
                              `${baseUrl}fm-home-carousel-slides/delete/${currentHomeSlide.data.key}`,
                              {
                                Accept: "application/json",
                                "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                                Authorization: `Bearer ${user.accessToken}`,
                              },
                              setIsSoftDeleteLoading
                            )
                          }
                          disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                          <span className="btn-bg"></span>
                          Delete
                        </button>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {currentHomeSlide.data.slide_deleted_at !== null ? (
                    userCan("fm_home_carousel_slide_restore_trashed") ? (
                      <div className="mx-2 my-1 my-sm-0">
                        <button
                          className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`}
                          onClick={() =>
                            restoreHomeSlide(
                              "PATCH",
                              `${baseUrl}fm-home-carousel-slides/restore/${currentHomeSlide.data.key}`,
                              {
                                Accept: "application/json",
                                "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                                Authorization: `Bearer ${user.accessToken}`,
                              },
                              setIsRestoreLoading
                            )
                          }
                          disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                          <span className="btn-bg"></span>
                          Restore
                        </button>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {userCan("fm_home_carousel_slide_force_delete") ? (
                    <div className="ms-2">
                      <button
                        className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`}
                        onClick={() =>
                          forceDeleteHomeSlide(
                            "DELETE",
                            `${baseUrl}fm-home-carousel-slides/force-delete/${currentHomeSlide.data.key}`,
                            {
                              Accept: "application/json",
                              "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                              Authorization: `Bearer ${user.accessToken}`,
                            },
                            setIsForceDeleteLoading
                          )
                        }
                        disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                        <span className="btn-bg"></span>
                        Force Delete
                      </button>
                    </div>
                  ) : (
                    ""
                  )}

                  {isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? (
                    <div className="position-relative ms-4">
                      <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="home-slides-tags">
                  <div className="mb-4 info-tags-container">
                    {currentHomeSlide.data._id ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">#ID: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentHomeSlide.data._id}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentHomeSlide.data.key ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">#KEY: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentHomeSlide.data.key}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {currentHomeSlide.data.slide_created_at ? (
                      userCan("created_at_show") ? (
                        <>
                          <div className="mx-1 my-1 info-tag">
                            <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED AT: </span>
                            <span className="d-inline-flex justify-content-center align-items-center">{new Date(currentHomeSlide.data.slide_created_at).toLocaleString("en-US", { timeZone: "Africa/Cairo", hour12: true })}</span>
                          </div>
                        </>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {currentHomeSlide.data.slide_updated_at ? (
                      userCan("updated_at_show") ? (
                        <>
                          <div className="mx-1 my-1 info-tag">
                            <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED AT: </span>
                            <span className="d-inline-flex justify-content-center align-items-center">{new Date(currentHomeSlide.data.slide_updated_at).toLocaleString("en-US", { timeZone: "Africa/Cairo", hour12: true })}</span>
                          </div>
                        </>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {currentHomeSlide.data.slide_deleted_at ? (
                      userCan("deleted_at_show") ? (
                        <>
                          <div className="mx-1 my-1 info-tag">
                            <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">DELETED AT: </span>
                            <span className="d-inline-flex justify-content-center align-items-center">{new Date(currentHomeSlide.data.slide_deleted_at).toLocaleString("en-US", { timeZone: "Africa/Cairo", hour12: true })}</span>
                          </div>
                        </>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {currentHomeSlide.data.slide_created_by ? (
                      userCan("created_by_show") ? (
                        <>
                          <div className="mx-1 my-1 info-tag">
                            <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED BY, NAME: </span>
                            <span className="d-inline-flex justify-content-center align-items-center">{currentHomeSlide.data.slide_created_by.name}</span>
                          </div>
                        </>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {currentHomeSlide.data.slide_created_by ? (
                      userCan("created_by_show") ? (
                        <>
                          <div className="mx-1 my-1 info-tag">
                            <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED BY, #ID: </span>
                            <span className="d-inline-flex justify-content-center align-items-center">{currentHomeSlide.data.slide_created_by._id}</span>
                          </div>
                        </>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {currentHomeSlide.data.slide_created_by ? (
                      userCan("created_by_show") ? (
                        <>
                          <div className="mx-1 my-1 info-tag">
                            <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED BY, KEY: </span>
                            <span className="d-inline-flex justify-content-center align-items-center">{currentHomeSlide.data.slide_created_by.key}</span>
                          </div>
                        </>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {currentHomeSlide.data.slide_updated_by ? (
                      userCan("updated_by_show") ? (
                        <>
                          <div className="mx-1 my-1 info-tag">
                            <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED BY, NAME: </span>
                            <span className="d-inline-flex justify-content-center align-items-center">{currentHomeSlide.data.slide_updated_by.name}</span>
                          </div>
                        </>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {currentHomeSlide.data.slide_updated_by ? (
                      userCan("updated_by_show") ? (
                        <>
                          <div className="mx-1 my-1 info-tag">
                            <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED BY, #ID: </span>
                            <span className="d-inline-flex justify-content-center align-items-center">{currentHomeSlide.data.slide_updated_by._id}</span>
                          </div>
                        </>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {currentHomeSlide.data.slide_updated_by ? (
                      userCan("updated_by_show") ? (
                        <>
                          <div className="mx-1 my-1 info-tag">
                            <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED BY, KEY: </span>
                            <span className="d-inline-flex justify-content-center align-items-center">{currentHomeSlide.data.slide_updated_by.key}</span>
                          </div>
                        </>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default HomeSlideDetails;
