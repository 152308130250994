import React, { createContext, useState } from "react";

export let fmHomeSlidesContext = createContext(0);

const HomeSlidesContext = ({ children }) => {
  let [homeSlideMsg, setHomeSlideMsg] = useState("");

  let [filtrationDetails, setFiltrationDetails] = useState({
    search_query: "",
    only_trashed: 0,
  });

  let [isFiltrationLoading, setIsFiltrationLoading] = useState(false);

  let fmHomeSlidesLifting = {
    homeSlideMsg,
    setHomeSlideMsg,
    filtrationDetails,
    setFiltrationDetails,
    isFiltrationLoading,
    setIsFiltrationLoading,
  };

  return (
    <>
      <fmHomeSlidesContext.Provider value={{ ...fmHomeSlidesLifting }}>{children}</fmHomeSlidesContext.Provider>
    </>
  );
};

export default HomeSlidesContext;
