import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { fmDataContext } from "../../Context/FmData";
import Joi from "joi";
import { useParams } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { authenticationContext } from "../../Context/AuthContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";

const UpdatePrivacyPolicy = () => {
  let [mounted, setMounted] = useState(false);

  let { privacyPolicyId } = useParams();
  let { baseUrl, navigate, showData, globalCrumbs, setGlobalCrumbs } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  let [currentPrivacyPolicy, setCurrentPrivacyPolicy] = useState({});
  let [isPrivacyPolicyLoading, setIsPrivacyPolicyLoading] = useState(true);

  let [joiErrorsList, setJoiErrorsList] = useState([]);

  let [privacyPolicyData, setPrivacyPolicyData] = useState({
    description: "",
    modify_user_id: null,
  });

  let [isUpdateLoading, setIsUpdateLoading] = useState(false);

  let [updateResponse, setUpdateResponse] = useState({});

  let [navigateTimeOut, setNavigateTimeOut] = useState("");

  let userToken = jwtDecode(localStorage.getItem("userToken")).accessToken;

  const getPrivacyPolicyRow = (reqMethod, pathName, setLoading = setIsPrivacyPolicyLoading, headers) => {
    showData(reqMethod, pathName, setLoading, headers)
      .then((response) => {
        let privacyPolicyResponse = response.data.data;
        setCurrentPrivacyPolicy(privacyPolicyResponse);

        setPrivacyPolicyData({
          description: privacyPolicyResponse.description ? privacyPolicyResponse.description.replaceAll("</br>", "\n") : "",
          modify_user_id: null,
        });

        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const getPrivacyPolicyData = (e) => {
    const privacyPolicy = { ...privacyPolicyData };
    privacyPolicy[e.target.name] = e.target.value;
    setPrivacyPolicyData(privacyPolicy);
  };

  const updateItem = async (e) => {
    e.preventDefault();
    if (userCan("fm_privacy_policy_update")) {
      privacyPolicyData.modify_user_id = user.currentUser._id;

      const validationResult = validateUpdatePrivacyPolicyForm();
      setUpdateResponse({});

      if (validationResult.error) {
        validationResult.error ? setJoiErrorsList(validationResult.error.details) : setJoiErrorsList([]);
      } else {
        setIsUpdateLoading(true);
        setJoiErrorsList([]);
        privacyPolicyData.description = privacyPolicyData.description ? privacyPolicyData.description.replaceAll("\n", "</br>") : "";
        await axios({
          method: "POST",
          url: `${baseUrl}fm-privacy-policies/update/${currentPrivacyPolicy.key}`,
          data: privacyPolicyData,
          params: {
            _method: "PUT",
          },
          headers: {
            Accept: "application/json",
            "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
            Authorization: `Bearer ${user.accessToken}`,
          },
        })
          .then((response) => {
            privacyPolicyData.description = privacyPolicyData.description ? privacyPolicyData.description.replaceAll("</br>", "\n") : "";
            setUpdateResponse(response.data);
            setIsUpdateLoading(false);

            if (response.data.msg && response.data.msg.includes("successfully")) {
              let timeOut = setTimeout(() => {
                setUpdateResponse({});
                navigate(`/fm-privacy-policies/${currentPrivacyPolicy.key}`);
              }, 4000);
              setNavigateTimeOut(timeOut);
              setPrivacyPolicyData({
                description: "",
                modify_user_id: null,
              });
              e.target.reset();
              toast(`${response.data.msg}, navigating...`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                containerId: "update_done_toast",
              });
            } else if (response.data.msg && response.data.msg.includes("failed")) {
              toast(response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          })
          .catch((errors) => {
            navigate("/not-found");
          });
      }
    } else {
      navigate("/not-found");
    }
  };

  const validateUpdatePrivacyPolicyForm = () => {
    const scheme = Joi.object({
      description: Joi.string().min(1).required(),
      modify_user_id: Joi.number(),
    });
    return scheme.validate(privacyPolicyData, { abortEarly: false });
  };

  useEffect(() => {
    getPrivacyPolicyRow("GET", `${baseUrl}fm-privacy-policies/show/${privacyPolicyId}`, setIsPrivacyPolicyLoading, {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
      Authorization: `Bearer ${userToken}`,
    });
    setMounted(true);
  }, []);

  useEffect(() => {
    return () => {
      if (mounted) {
        clearTimeout(navigateTimeOut);
      }
    };
  }, [navigateTimeOut]);

  useEffect(() => {
    if (mounted && currentPrivacyPolicy) {
      let globalCrumbsUpdated = globalCrumbs.map((crumb) => {
        if (crumb.name === privacyPolicyId.toUpperCase()) {
          crumb.name = `privacy policy description`.toUpperCase();
        }
        return crumb;
      });
      setGlobalCrumbs(globalCrumbsUpdated);
    }
  }, [currentPrivacyPolicy]);

  return (
    <>
      {isPrivacyPolicyLoading ? (
        <LoadingScreen />
      ) : (
        <div className="container position-relative pt-3 pt-md-4">
          <div className="mb-3 mb-md-4">
            <h1 className="fu-fw-800 fu-text-white fu-text-2-rem fu-text-sm-1-5-rem fu-text-xs-1-2-rem fu-text-18-px-xxs fu-text-18-px-xxxs mb-0">Update Privacy Policy Description</h1>
          </div>
          <div className="py-2 mb-4 iu-row-heading create-submit-row">
            <h2 className="mb-0 row-title">Edit ( Privacy Policy ) data</h2>
          </div>
          <div className="row">
            <div>
              <div>
                <Form onSubmit={updateItem}>
                  <Row>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="aboutCompany">
                        <Form.Label className="fu-text-light-gray" data-title="required">
                          Privacy Policy Description
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </Form.Label>
                        <div className="textarea-container">
                          <Form.Control as="textarea" rows={4} className="fu-input" placeholder="Enter Privacy Policy Description" name="description" value={privacyPolicyData.description ? privacyPolicyData.description.replaceAll("</br>", "\n") : ""} onInput={getPrivacyPolicyData} />
                        </div>
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "description" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("description", "Privacy Policy Description")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* ------------------------------------ */}

                  {updateResponse.errors
                    ? Object.keys(updateResponse.errors).map((key) =>
                        updateResponse.errors[key].map((error, index) => (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error}
                          </div>
                        ))
                      )
                    : ""}

                  <div className="d-flex align-items-center mt-4">
                    <div>
                      <Button className={`fu-btn fu-btn-gray ${isUpdateLoading ? "disabled" : ""}`} type="submit" disabled={isUpdateLoading ? true : false}>
                        <span className="btn-bg"></span>
                        Submit<i className="bi bi-send ms-2"></i>
                      </Button>
                    </div>
                    {isUpdateLoading ? (
                      <div className="position-relative ms-3">
                        <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdatePrivacyPolicy;
