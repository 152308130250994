import React, { useContext } from "react";
import userCan from "../../helpers/userCan";
import { fmDataContext } from "../../Context/FmData";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { fmGenresContext } from "../../Context/GenresContext";
import { toast } from "react-toastify";

const GenresFiltration = (props) => {
  let { getGenres, perPage, orderBy, orderType } = props;

  let { filtrationDetails, setFiltrationDetails, setIsFiltrationLoading, isFiltrationLoading } = useContext(fmGenresContext);

  let { baseUrl } = useContext(fmDataContext);

  const submitFiltration = (e) => {
    e.preventDefault();
    setIsFiltrationLoading(true);
    let currentFiltrationDetails = { ...filtrationDetails };
    let currentOnlyTrashedValue = e.target.only_trashed && e.target.only_trashed.checked ? 1 : 0;
    if (e.nativeEvent.submitter.innerText === "DONE") {
      if (e.target.search_query.value === "" && currentFiltrationDetails.search_query === "" && currentOnlyTrashedValue === 0 && currentFiltrationDetails.only_trashed === 0) {
        toast("There are no search info for search!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          progressStyle: { backgroundColor: "#fdb915" },
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setIsFiltrationLoading(false);
      } else if (currentFiltrationDetails.search_query === e.target.search_query.value && currentFiltrationDetails.only_trashed === currentOnlyTrashedValue) {
        toast("Search result to this search is already in show!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          progressStyle: { backgroundColor: "#fdb915" },
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setIsFiltrationLoading(false);
      } else {
        currentFiltrationDetails.search_query = e.target.search_query.value;
        currentFiltrationDetails.only_trashed = e.target.only_trashed && e.target.only_trashed.checked ? 1 : 0;
        setFiltrationDetails(currentFiltrationDetails);
        getGenres(
          "GET",
          `${baseUrl}fm-genres`,
          {
            page_num: 1,
            per_page: perPage,
            order_type: orderType,
            order_by: orderBy,
            ...currentFiltrationDetails,
          },
          setIsFiltrationLoading
        );
      }
    } else if (e.nativeEvent.submitter.innerText === "CLEAR SEARCH") {
      if (e.target.search_query.value === "" && currentFiltrationDetails.search_query === "" && currentOnlyTrashedValue === 0 && currentFiltrationDetails.only_trashed === 0) {
        toast("There are no search info to clear it!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          progressStyle: { backgroundColor: "#fdb915" },
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setIsFiltrationLoading(false);
      } else {
        e.target.reset();
        setFiltrationDetails({
          search_query: "",
          only_trashed: 0,
        });
        getGenres(
          "GET",
          `${baseUrl}fm-genres`,
          {
            page_num: 1,
            per_page: perPage,
            order_type: orderType,
            order_by: orderBy,
            search_query: "",
            only_trashed: 0,
          },
          setIsFiltrationLoading
        );
      }
    }
  };

  return (
    <>
      <form onSubmit={submitFiltration}>
        <div className="row g-3 align-items-center">
          {/* search_query */}
          <div className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-4">
            <div>
              <input type="text" className="form-control shadow-none" name="search_query" id="search_query" placeholder="Search in genres..." />
            </div>
          </div>
          {/* only_trashed */}
          {userCan("fm_genre_restore_trashed") ? (
            <div className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-4">
              <div className="form-check">
                <input type="checkbox" name="only_trashed" id="only_trashed" className="form-check-input shadow-none border-0 mouse-pointer" />
                <label htmlFor="only_trashed" className="form-check-label fu-text-white mouse-pointer">
                  Only Trashed
                </label>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="col-12">
            <div className="d-flex mt-0 mt-sm-2 align-items-center justify-content-center justify-content-xl-start">
              <div>
                <button type="submit" className="btn fu-btn-sm fu-btn-gray" disabled={isFiltrationLoading ? true : false}>
                  <span className="btn-bg"></span>
                  DONE
                </button>
              </div>
              <div className="ms-3">
                <button type="submit" className="btn fu-btn-sm fu-btn-gray" disabled={isFiltrationLoading ? true : false}>
                  <span className="btn-bg"></span>
                  CLEAR SEARCH
                </button>
              </div>
              {isFiltrationLoading ? (
                <div className="position-relative ms-4">
                  <LoadingCircle circleCont={28} icon={23} loader={28} dot={28} />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default GenresFiltration;
