import React, { useContext, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { fmDataContext } from "../../Context/FmData";
import Joi from "joi";

import { authenticationContext } from "../../Context/AuthContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";
import LoadingCircle from "../LoadingCircle/LoadingCircle";

const SendPost = () => {
  let { baseUrl, navigate } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  let [selectedImage, setSelectedImage] = useState({});

  let [joiErrorsList, setJoiErrorsList] = useState([]);
  let [imageInputError, setImageInputError] = useState(null);

  let [newBlogData, setNewBlogData] = useState({
    title: "",
    description: "",
    image: selectedImage,
    btn_text: null,
    btn_path: null,
  });

  let [isCreateLoading, setIsCreateLoading] = useState(false);

  let [createResponse, setCreateResponse] = useState({});

  const getNewBlogData = (e) => {
    const newBlog = { ...newBlogData };
    newBlog[e.target.name] = e.target.value;
    setNewBlogData(newBlog);
  };

  const handleImageSelect = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const createItem = async (e) => {
    e.preventDefault();

    if (userCan("send_blog_mail")) {
      newBlogData.description = newBlogData.description ? newBlogData.description.replaceAll("\n", "</br>") : "";
      const validationResult = validateCreateNewBlogForm();
      let imageError = null;
      setCreateResponse({});

      if (selectedImage) {
        newBlogData.image = selectedImage;
        if (!newBlogData.image.name) {
          imageError = "Image Input Is Required";
        } else if (!["image/png", "image/jpg", "image/jpeg", "image/gif"].includes(newBlogData.image.type)) {
          imageError = "File must be an Image and from These extensions (png, jpg, jpeg, gif)";
        } else if (newBlogData.image.size > 5 * 1024 * 1024) {
          imageError = "Image Size Must Be Less Than 5 MB";
        }
      } else {
        imageError = "Image Input Is Required";
        newBlogData.image = {};
      }

      if (validationResult.error || imageError) {
        validationResult.error ? setJoiErrorsList(validationResult.error.details) : setJoiErrorsList([]);
        setImageInputError(imageError);
      } else {
        setIsCreateLoading(true);
        setImageInputError(null);
        setJoiErrorsList([]);
        await axios({
          method: "POST",
          url: `${baseUrl}fm-send-blog-mail`,
          data: newBlogData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
            Authorization: `Bearer ${user.accessToken}`,
          },
        })
          .then((response) => {
            setCreateResponse(response.data);
            setIsCreateLoading(false);
            if (response.data.message && response.data.message.includes("successfully")) {
              setNewBlogData({
                title: "",
                description: null,
                image: {},
                btn_text: null,
                btn_path: null,
              });
              setSelectedImage({});
              e.target.reset();
              setCreateResponse({});
              toast(response.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            } else {
              setNewBlogData({
                title: "",
                description: null,
                image: {},
                btn_text: null,
                btn_path: null,
              });
              setSelectedImage({});
              e.target.reset();
              toast("Faild to send, try again later", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          })
          .catch((errors) => {
            navigate("/not-found");
          });
      }
    } else {
      navigate("/not-found");
    }
  };

  const validateCreateNewBlogForm = () => {
    const scheme = Joi.object({
      title: Joi.string().min(1).max(500).required(),
      description: Joi.string().min(1).max(3000).required(),
      image: Joi.any(),
      btn_text: Joi.string().min(1).max(255).allow(null, "").optional(),
      btn_path: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("This URL is not valid").allow(null, "").optional(),
    });
    return scheme.validate(newBlogData, { abortEarly: false });
  };

  return (
    <>
      <div className="container position-relative pt-3 pt-md-4">
        <div className="mb-3 mb-md-4">
          <h1 className="page-heading-title fu-cr-new-head mb-0">Send News</h1>
        </div>
        <div className="py-2 mb-4 iu-row-heading create-submit-row">
          <h2 className="mb-0 row-title">Send News To Subscribers List</h2>
        </div>
        <div className="row">
          <div>
            <div>
              <Form onSubmit={createItem}>
                <Row>
                  <Col xl={6}>
                    <Form.Group className="mb-3" controlId="newBlogTitle">
                      <Form.Label className="fu-text-light-gray" data-title="required">
                        Title
                        <span className="required-input mx-1" data-title="required">
                          *
                        </span>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter New Blog Title" className="fu-input" name="title" onInput={getNewBlogData} />
                      {joiErrorsList.map((error, index) =>
                        error.path[0] === "title" ? (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error.message}
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    </Form.Group>
                  </Col>
                  <Col xl={6}>
                    <Form.Group className="mb-3" controlId="newBlogImage">
                      <Form.Label className="fu-text-light-gray" data-title="required">
                        Image
                        <span className="required-input mx-1" data-title="required">
                          *
                        </span>
                      </Form.Label>
                      <Form.Control type="file" placeholder="choose New Blog Image" className="fu-input" name="image" onInput={handleImageSelect} />
                      {imageInputError !== null ? <div className="alert alert-danger py-1 px-2 mt-2 mb-0">{imageInputError}</div> : ""}
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3" controlId="newBlogDescription">
                      <Form.Label className="fu-text-light-gray" data-title="required">
                        Description
                        <span className="required-input mx-1" data-title="required">
                          *
                        </span>
                      </Form.Label>
                      <div className="textarea-container">
                        <Form.Control as="textarea" rows={4} className="fu-input" placeholder="Enter new blog description" name="description" onInput={getNewBlogData} />
                      </div>
                      {joiErrorsList.map((error, index) =>
                        error.path[0] === "description" ? (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error.message}
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    </Form.Group>
                  </Col>
                  {/* btn txt */}
                  <Col xl={6}>
                    <Form.Group className="mb-3" controlId="btnTextOutside">
                      <Form.Label className="fu-text-light-gray" data-title="optional if button path outside is empty">
                        Button Text
                        <span className="optional-input mx-1" data-title="optional if button path outside is empty">
                          *
                        </span>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Button Text" className="fu-input" name="btn_text" onInput={getNewBlogData} />
                      {joiErrorsList.map((error, index) =>
                        error.path[0] === "btn_text" ? (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error.message.replace("btn", "button").replaceAll("_", " ")}
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    </Form.Group>
                  </Col>
                  {/* btn path */}
                  <Col xl={6}>
                    <Form.Group className="mb-3" controlId="btnPathOutside">
                      <Form.Label className="fu-text-light-gray" data-title="optional if button text outside is empty">
                        Button Path
                        <span className="optional-input mx-1" data-title="optional if button text outside is empty">
                          *
                        </span>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Button Path URL" className="fu-input" name="btn_path" onInput={getNewBlogData} />
                      {joiErrorsList.map((error, index) =>
                        error.path[0] === "btn_path" ? (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error.message.replace("btn", "button").replaceAll("_", " ")}
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                {/* ------------------------------------ */}

                {createResponse.errors
                  ? Object.keys(createResponse.errors).map((key) =>
                      createResponse.errors[key].map((error, index) => (
                        <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                          {error.replaceAll("btn", "button")}
                        </div>
                      ))
                    )
                  : ""}
                <div className="d-flex align-items-center mt-4">
                  <div>
                    <Button className={`fu-btn fu-btn-gray ${isCreateLoading ? "disabled" : ""}`} type="submit" disabled={isCreateLoading ? true : false}>
                      <span className="btn-bg"></span>
                      Submit<i className="bi bi-send ms-2"></i>
                    </Button>
                  </div>
                  {isCreateLoading ? (
                    <div className="position-relative ms-3">
                      <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendPost;
