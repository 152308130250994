import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";

const RouteGuard = ({ children }) => {
  const location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem("userToken")) {
      navigate("/login");
    }
  }, [location.pathname]);

  if (!localStorage.getItem("userToken")) {
    localStorage.removeItem("userToken");
    return <Navigate to="/login" />;
  } else {
    try {
      let currentUser = jwtDecode(localStorage.getItem("userToken"));
      if (!currentUser.accessToken || !currentUser.currentUser || !currentUser.currentUser._id || !currentUser.currentUser.key || !currentUser.currentUser.name || !currentUser.currentUser.email || !currentUser.currentUser.role || !currentUser.currentUser.role_name || !currentUser.currentUser.permissions || !currentUser.currentUser.status) {
        localStorage.removeItem("userToken");
        return <Navigate to="/login" />;
      }
      return children;
    } catch (error) {
      localStorage.removeItem("userToken");
      return <Navigate to="/login" />;
    }
  }
};

export default RouteGuard;
