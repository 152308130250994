import React, { createContext, useState } from "react";

export let fmUsersContext = createContext(0);

const UsersContext = ({ children }) => {
  let [userMsg, setUserMsg] = useState("");

  let [filtrationDetails, setFiltrationDetails] = useState({
    search_query: "",
    only_trashed: 0,
    role_key: null,
    user_status: null,
  });

  let [isFiltrationLoading, setIsFiltrationLoading] = useState(false);

  let fmUsersLifting = {
    userMsg,
    setUserMsg,
    filtrationDetails,
    setFiltrationDetails,
    isFiltrationLoading,
    setIsFiltrationLoading,
  };

  return (
    <>
      <fmUsersContext.Provider value={{ ...fmUsersLifting }}>{children}</fmUsersContext.Provider>
    </>
  );
};

export default UsersContext;
