import jwtDecode from "jwt-decode";

const userCan = (permission) => {
  let user = jwtDecode(localStorage.getItem("userToken"));
  let permissions = user.currentUser.permissions.map((permission) => permission.name);

  if (permissions.includes(permission)) {
    return true;
  } else {
    return false;
  }
};

export default userCan;
