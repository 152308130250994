import React, { useEffect, useRef } from "react";
import styles from "./Navbar.module.scss";
import Images from "../../images";
import { Link, useLocation } from "react-router-dom";
import userCan from "../../helpers/userCan";

const Navbar = () => {
  let isCalledRef = useRef(false);
  let mainNavRef = useRef();
  let location = useLocation();

  const handleMouseEnter = () => {
    document.body.classList.add("nav-open");
  };

  const handleMouseLeave = () => {
    document.body.classList.remove("nav-open");
  };

  let setNavLinkActive = () => {
    let navLinks = Array.from(mainNavRef.current.children[1].children[0].children);
    let navLinksText = navLinks.map((navLink) => {
      return `fm ${navLink.children[0].children[1].children[0].innerHTML}`;
    });
    navLinks.forEach((navLink) => {
      navLink.classList.remove(`${styles.active}`);
      navLinksText.forEach((navLinkText, index) => {
        if (location.pathname.split("/")[1].toLocaleLowerCase() === "") {
          navLinks[0].classList.add(`${styles.active}`);
        } else if (location.pathname.split("/")[1].toLocaleLowerCase() === navLinkText.toLocaleLowerCase().replace(/\s+/g, "-")) {
          navLinks[index].classList.add(`${styles.active}`);
        }
      });
    });
  };

  useEffect(() => {
    if (!isCalledRef.current) {
      if (mainNavRef.current) {
        const mainNavbar = mainNavRef.current;
        mainNavbar.addEventListener("mouseenter", handleMouseEnter);
        mainNavbar.addEventListener("mouseleave", handleMouseLeave);
        setNavLinkActive();
      }
    }
    return () => {
      if (isCalledRef.current) {
        document.body.classList.remove("nav-open");
      }
      isCalledRef.current = true;
    };
  }, []);

  useEffect(() => {
    setNavLinkActive();
  }, [location.pathname]);

  return (
    <>
      <nav className="navbar p-0">
        <div className="container-fluid">
          <div className={`offcanvas offcanvas-start show bg-black-color border-0 main-navbar ${styles.side_nav}`} data-bs-scroll="true" data-bs-backdrop="false" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" ref={mainNavRef}>
            <Link to="/" className="d-block pt-3">
              <span className="d-flex align-items-center">
                <span className={`d-block ${styles.icon_container}`}>
                  <span className={`icon-width ${styles.icon_width}`}>
                    <img src={Images.futurisingIcon} alt="company icon" className={styles.icon} />
                  </span>
                </span>
                <span className={`d-block ${styles.logo_container}`}>
                  <span className={`d-block ${styles.logo_width}`}>
                    <img src={Images.futurisingIogo} alt="company logo" className={styles.logo} />
                  </span>
                </span>
              </span>
            </Link>
            <div className="d-flex flex-column flex-grow-1 justify-content-center">
              <ul className="navbar-nav navbar-links-list">
                <li className="nav-item mb-2">
                  <Link to="/" className={`d-flex align-items-center ${styles.nav_link_container}`}>
                    <span className="d-block">
                      <span className={`d-block nav-icon-container ${styles.link_icon_container}`}>
                        <span className={`nav-link p-0 ${styles.link_icon}`}>
                          <i className="bi bi-house"></i>
                        </span>
                      </span>
                    </span>
                    <span className={`d-block ${styles.link_text_container} ${styles.link_text_container_1}`}>
                      <span className={`mb-0 d-block ${styles.link_text}`}>Home</span>
                    </span>
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to="/fm-labels" className={`d-flex align-items-center ${styles.nav_link_container}`}>
                    <span className="d-block">
                      <span className={`d-block nav-icon-container ${styles.link_icon_container}`}>
                        <span className={`nav-link p-0 ${styles.link_icon}`}>
                          <i className="bi bi-bookmark-star"></i>
                        </span>
                      </span>
                    </span>
                    <span className={`d-block ${styles.link_text_container} ${styles.link_text_container_2}`}>
                      <span className={`mb-0 d-block ${styles.link_text}`}>Labels</span>
                    </span>
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to="/fm-artists" className={`d-flex align-items-center ${styles.nav_link_container}`}>
                    <span className="d-block">
                      <span className={`d-block nav-icon-container ${styles.link_icon_container}`}>
                        <span className={`nav-link p-0 ${styles.link_icon}`}>
                          <i className="bi bi-boombox"></i>
                        </span>
                      </span>
                    </span>
                    <span className={`d-block ${styles.link_text_container} ${styles.link_text_container_3}`}>
                      <span className={`mb-0 d-block ${styles.link_text}`}>Artists</span>
                    </span>
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to="/fm-genres" className={`d-flex align-items-center ${styles.nav_link_container}`}>
                    <span className="d-block">
                      <span className={`d-block nav-icon-container ${styles.link_icon_container}`}>
                        <span className={`nav-link p-0 ${styles.link_icon}`}>
                          <i className="bi bi-tags"></i>
                        </span>
                      </span>
                    </span>
                    <span className={`d-block ${styles.link_text_container} ${styles.link_text_container_4}`}>
                      <span className={`mb-0 d-block ${styles.link_text}`}>Genres</span>
                    </span>
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to="/fm-releases" className={`d-flex align-items-center ${styles.nav_link_container}`}>
                    <span className="d-block">
                      <span className={`d-block nav-icon-container ${styles.link_icon_container}`}>
                        <span className={`nav-link p-0 ${styles.link_icon}`}>
                          <i className="bi bi-headphones"></i>
                        </span>
                      </span>
                    </span>
                    <span className={`d-block ${styles.link_text_container} ${styles.link_text_container_5}`}>
                      <span className={`mb-0 d-block ${styles.link_text}`}>Releases</span>
                    </span>
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to="/fm-videos" className={`d-flex align-items-center ${styles.nav_link_container}`}>
                    <span className="d-block">
                      <span className={`d-block nav-icon-container ${styles.link_icon_container}`}>
                        <span className={`nav-link p-0 ${styles.link_icon}`}>
                          <i className="bi bi-play-circle"></i>
                        </span>
                      </span>
                    </span>
                    <span className={`d-block ${styles.link_text_container} ${styles.link_text_container_6}`}>
                      <span className={`mb-0 d-block ${styles.link_text}`}>Videos</span>
                    </span>
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to="/fm-news" className={`d-flex align-items-center ${styles.nav_link_container}`}>
                    <span className="d-block">
                      <span className={`d-block nav-icon-container ${styles.link_icon_container}`}>
                        <span className={`nav-link p-0 ${styles.link_icon}`}>
                          <i className="bi bi-envelope-paper"></i>
                        </span>
                      </span>
                    </span>
                    <span className={`d-block ${styles.link_text_container} ${styles.link_text_container_7}`}>
                      <span className={`mb-0 d-block ${styles.link_text}`}>News</span>
                    </span>
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to="/fm-top-five-artists" className={`d-flex align-items-center ${styles.nav_link_container}`}>
                    <span className="d-block">
                      <span className={`d-block nav-icon-container ${styles.link_icon_container}`}>
                        <span className={`nav-link p-0 ${styles.link_icon}`}>
                          <i className="bi bi-trophy"></i>
                        </span>
                      </span>
                    </span>
                    <span className={`d-block ${styles.link_text_container} ${styles.link_text_container_8}`}>
                      <span className={`mb-0 d-block ${styles.link_text}`}>Top five Artists</span>
                    </span>
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to="/fm-top-five-releases" className={`d-flex align-items-center ${styles.nav_link_container}`}>
                    <span className="d-block">
                      <span className={`d-block nav-icon-container ${styles.link_icon_container}`}>
                        <span className={`nav-link p-0 ${styles.link_icon}`}>
                          <i className="bi bi-star"></i>
                        </span>
                      </span>
                    </span>
                    <span className={`d-block ${styles.link_text_container} ${styles.link_text_container_9}`}>
                      <span className={`mb-0 d-block ${styles.link_text}`}>Top five Releases</span>
                    </span>
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to="/fm-partnerships" className={`d-flex align-items-center ${styles.nav_link_container}`}>
                    <span className="d-block">
                      <span className={`d-block nav-icon-container ${styles.link_icon_container}`}>
                        <span className={`nav-link p-0 ${styles.link_icon}`}>
                          <i className="bi bi-diagram-3"></i>
                        </span>
                      </span>
                    </span>
                    <span className={`d-block ${styles.link_text_container} ${styles.link_text_container_10}`}>
                      <span className={`mb-0 d-block ${styles.link_text}`}>Partnerships</span>
                    </span>
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to="/fm-team-members" className={`d-flex align-items-center ${styles.nav_link_container}`}>
                    <span className="d-block">
                      <span className={`d-block nav-icon-container ${styles.link_icon_container}`}>
                        <span className={`nav-link p-0 ${styles.link_icon}`}>
                          <i className="bi bi-person-badge"></i>
                        </span>
                      </span>
                    </span>
                    <span className={`d-block ${styles.link_text_container} ${styles.link_text_container_11}`}>
                      <span className={`mb-0 d-block ${styles.link_text}`}>Team Members</span>
                    </span>
                  </Link>
                </li>
                {userCan("fm_subscribed_email_show") ? (
                  <li className="nav-item mb-2">
                    <Link to="/fm-subscribed-emails" className={`d-flex align-items-center ${styles.nav_link_container}`}>
                      <span className="d-block">
                        <span className={`d-block nav-icon-container ${styles.link_icon_container}`}>
                          <span className={`nav-link p-0 ${styles.link_icon}`}>
                            <i className="bi bi-people"></i>
                          </span>
                        </span>
                      </span>
                      <span className={`d-block ${styles.link_text_container} ${styles.link_text_container_12}`}>
                        <span className={`mb-0 d-block ${styles.link_text}`}>Subscribed Emails</span>
                      </span>
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                {userCan("fm_contact_message_show_all") ? (
                  <li className="nav-item mb-2">
                    <Link to="/fm-contact-messages" className={`d-flex align-items-center ${styles.nav_link_container}`}>
                      <span className="d-block">
                        <span className={`d-block nav-icon-container ${styles.link_icon_container}`}>
                          <span className={`nav-link p-0 ${styles.link_icon}`}>
                            <i className="bi bi-chat-square-text"></i>
                          </span>
                        </span>
                      </span>
                      <span className={`d-block ${styles.link_text_container} ${styles.link_text_container_13}`}>
                        <span className={`mb-0 d-block ${styles.link_text}`}>Contact Messages</span>
                      </span>
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                {userCan("fm_track_demo_show_all") ? (
                  <li className="nav-item mb-2">
                    <Link to="/fm-track-demos" className={`d-flex align-items-center ${styles.nav_link_container}`}>
                      <span className="d-block">
                        <span className={`d-block nav-icon-container ${styles.link_icon_container}`}>
                          <span className={`nav-link p-0 ${styles.link_icon}`}>
                            <i className="bi bi-file-music"></i>
                          </span>
                        </span>
                      </span>
                      <span className={`d-block ${styles.link_text_container} ${styles.link_text_container_14}`}>
                        <span className={`mb-0 d-block ${styles.link_text}`}>Track Demos</span>
                      </span>
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                <li className="nav-item mb-2">
                  <Link to="/fm-page-headers" className={`d-flex align-items-center ${styles.nav_link_container}`}>
                    <span className="d-block">
                      <span className={`d-block nav-icon-container ${styles.link_icon_container}`}>
                        <span className={`nav-link p-0 ${styles.link_icon}`}>
                          <i className="bi bi-images"></i>
                        </span>
                      </span>
                    </span>
                    <span className={`d-block ${styles.link_text_container} ${styles.link_text_container_15}`}>
                      <span className={`mb-0 d-block ${styles.link_text}`}>Page Headers</span>
                    </span>
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to="/fm-home-slides" className={`d-flex align-items-center ${styles.nav_link_container}`}>
                    <span className="d-block">
                      <span className={`d-block nav-icon-container ${styles.link_icon_container}`}>
                        <span className={`nav-link p-0 ${styles.link_icon}`}>
                          <i className="bi bi-layers"></i>
                        </span>
                      </span>
                    </span>
                    <span className={`d-block ${styles.link_text_container} ${styles.link_text_container_16}`}>
                      <span className={`mb-0 d-block ${styles.link_text}`}>Home Slides</span>
                    </span>
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to="/fm-futurising-data" className={`d-flex align-items-center ${styles.nav_link_container}`}>
                    <span className="d-block">
                      <span className={`d-block nav-icon-container ${styles.link_icon_container}`}>
                        <span className={`nav-link p-0 ${styles.link_icon}`}>
                          <i className="bi bi-info-circle"></i>
                        </span>
                      </span>
                    </span>
                    <span className={`d-block ${styles.link_text_container} ${styles.link_text_container_17}`}>
                      <span className={`mb-0 d-block ${styles.link_text}`}>Futurising Data</span>
                    </span>
                  </Link>
                </li>
                <li className="nav-item mb-2">
                  <Link to="/fm-privacy-policies" className={`d-flex align-items-center ${styles.nav_link_container}`}>
                    <span className="d-block">
                      <span className={`d-block nav-icon-container ${styles.link_icon_container}`}>
                        <span className={`nav-link p-0 ${styles.link_icon}`}>
                          <i className="bi bi-shield-exclamation"></i>
                        </span>
                      </span>
                    </span>
                    <span className={`d-block ${styles.link_text_container} ${styles.link_text_container_18}`}>
                      <span className={`mb-0 d-block ${styles.link_text}`}>Privacy Policies</span>
                    </span>
                  </Link>
                </li>
                {userCan("fm_settings_show") ? (
                  <li className="nav-item mb-2">
                    <Link to="/fm-users" className={`d-flex align-items-center ${styles.nav_link_container}`}>
                      <span className="d-block">
                        <span className={`d-block nav-icon-container ${styles.link_icon_container}`}>
                          <span className={`nav-link p-0 ${styles.link_icon}`}>
                            <i className="bi bi-person-vcard"></i>
                          </span>
                        </span>
                      </span>
                      <span className={`d-block ${styles.link_text_container} ${styles.link_text_container_19}`}>
                        <span className={`mb-0 d-block ${styles.link_text}`}>Users</span>
                      </span>
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                {userCan("fm_settings_show") ? (
                  <li className="nav-item mb-2">
                    <Link to="/fm-roles" className={`d-flex align-items-center ${styles.nav_link_container}`}>
                      <span className="d-block">
                        <span className={`d-block nav-icon-container ${styles.link_icon_container}`}>
                          <span className={`nav-link p-0 ${styles.link_icon}`}>
                            <i className="bi bi-ui-checks-grid"></i>
                          </span>
                        </span>
                      </span>
                      <span className={`d-block ${styles.link_text_container} ${styles.link_text_container_20}`}>
                        <span className={`mb-0 d-block ${styles.link_text}`}>Roles</span>
                      </span>
                    </Link>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
