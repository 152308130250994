import React from "react";
import jwtDecode from "jwt-decode";
import { Navigate } from "react-router-dom";

const PermissionGuard = ({ children, permission }) => {
  let user = jwtDecode(localStorage.getItem("userToken"));
  let permissions = user.currentUser.permissions.map((permission) => permission.name);

  if (permissions.includes(permission)) {
    return children;
  } else {
    return <Navigate to={"/forbidden"} />;
  }
};

export default PermissionGuard;
