import React, { useContext, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { fmDataContext } from "../../Context/FmData";
import Joi from "joi";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { authenticationContext } from "../../Context/AuthContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";

const CreateLabel = () => {
  let { baseUrl, navigate } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);
  let [selectedImage, setSelectedImage] = useState({});

  let [joiErrorsList, setJoiErrorsList] = useState([]);
  let [imageInputError, setImageInputError] = useState(null);
  let [labelData, setLabelData] = useState({
    name: "",
    about: null,
    image: selectedImage,
    user_id: null,
    beatport_url: null,
    spotify_url: null,
    sound_cloud_url: null,
    youtube_url: null,
    facebook_url: null,
    instagram_url: null,
    threads_url: null,
    x_url: null,
    tiktok_url: null,
    linkedin_url: null,
    discord_url: null,
    website_url: null,
  });

  let [isCreateLoading, setIsCreateLoading] = useState(false);

  let [createResponse, setCreateResponse] = useState({});

  const getLabelData = (e) => {
    const label = { ...labelData };
    label[e.target.name] = e.target.value;
    setLabelData(label);
  };

  const handleImageSelect = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const createItem = async (e) => {
    e.preventDefault();

    if (userCan("fm_label_create")) {
      labelData.user_id = user.currentUser._id;
      labelData.about = labelData.about ? labelData.about.replaceAll("\n", "</br>") : null;
      const validationResult = validateCreateLabelForm();
      let imageError = null;
      setCreateResponse({});
      if (selectedImage) {
        labelData.image = selectedImage;
        if (!labelData.image.name) {
          imageError = "Image Input Is Required";
        } else if (!["image/png", "image/jpg", "image/jpeg", "image/gif"].includes(labelData.image.type)) {
          imageError = "File must be an Image and from These extensions (png, jpg, jpeg, gif)";
        } else if (labelData.image.size > 5 * 1024 * 1024) {
          imageError = "Image Size Must Be Less Than 5 MB";
        }
      } else {
        imageError = "Image Input Is Required";
        labelData.image = {};
      }
      if (validationResult.error || imageError) {
        validationResult.error ? setJoiErrorsList(validationResult.error.details) : setJoiErrorsList([]);
        setImageInputError(imageError);
      } else {
        setIsCreateLoading(true);
        setImageInputError(null);
        setJoiErrorsList([]);
        await axios({
          method: "POST",
          url: `${baseUrl}fm-labels/create`,
          data: labelData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
            Authorization: `Bearer ${user.accessToken}`,
          },
        })
          .then((response) => {
            setCreateResponse(response.data);
            setIsCreateLoading(false);
            if (response.data.msg && response.data.msg.includes("successfully")) {
              setLabelData({
                name: "",
                about: null,
                image: selectedImage,
                user_id: null,
                beatport_url: null,
                spotify_url: null,
                sound_cloud_url: null,
                youtube_url: null,
                facebook_url: null,
                instagram_url: null,
                threads_url: null,
                x_url: null,
                tiktok_url: null,
                linkedin_url: null,
                discord_url: null,
                website_url: null,
              });
              setSelectedImage({});
              e.target.reset();
              setCreateResponse({});
              toast(response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            } else if (response.data.msg && response.data.msg.includes("failed")) {
              toast(response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          })
          .catch((errors) => {
            navigate("/not-found");
          });
      }
    } else {
      navigate("/not-found");
    }
  };

  const validateCreateLabelForm = () => {
    const scheme = Joi.object({
      name: Joi.string().min(1).max(255).required(),
      about: Joi.string().min(1).max(1500).allow(null, "").optional(),
      image: Joi.any(),
      user_id: Joi.number(),
      beatport_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Beatport URL is not valid").allow(null, "").optional(),
      spotify_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Spotify URL is not valid").allow(null, "").optional(),
      sound_cloud_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Sound cloud URL is not valid").allow(null, "").optional(),
      youtube_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Youtube URL is not valid").allow(null, "").optional(),
      facebook_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Facebook URL is not valid").allow(null, "").optional(),
      instagram_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Instagram URL is not valid").allow(null, "").optional(),
      threads_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("threads URL is not valid").allow(null, "").optional(),
      x_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("X URL is not valid").allow(null, "").optional(),
      tiktok_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Tiktok URL is not valid").allow(null, "").optional(),
      linkedin_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Linkedin URL is not valid").allow(null, "").optional(),
      discord_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Discord URL is not valid").allow(null, "").optional(),
      website_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Website URL is not valid").allow(null, "").optional(),
    });
    return scheme.validate(labelData, { abortEarly: false });
  };

  return (
    <>
      <div className="container position-relative pt-3 pt-md-4">
        <div className="mb-3 mb-md-4">
          <h1 className="page-heading-title mb-0">Create Label</h1>
        </div>
        <div className="py-2 mb-4 iu-row-heading create-submit-row">
          <h2 className="mb-0 row-title">Submit New Label To Sub Labels List</h2>
        </div>
        <div className="row">
          <div>
            <div>
              <Form onSubmit={createItem}>
                <Row>
                  <Col lg={6}>
                    <Form.Group className="mb-3" controlId="labelName">
                      <Form.Label className="fu-text-light-gray" data-title="required">
                        Label Name
                        <span className="required-input mx-1" data-title="required">
                          *
                        </span>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Label Name" className="fu-input" name="name" onInput={getLabelData} autoComplete="on" />
                      {joiErrorsList.map((error, index) =>
                        error.path[0] === "name" ? (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error.message.replace("n", "Label n")}
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3" controlId="labelImage">
                      <Form.Label className="fu-text-light-gray" data-title="required">
                        Label Image
                        <span className="required-input mx-1" data-title="required">
                          *
                        </span>
                      </Form.Label>
                      <Form.Control type="file" placeholder="choose Label Image" className="fu-input" name="image" onInput={handleImageSelect} />
                      {imageInputError !== null ? <div className="alert alert-danger py-1 px-2 mt-2 mb-0">{imageInputError}</div> : ""}
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-3" controlId="aboutLabel">
                  <Form.Label className="fu-text-light-gray" data-title="optional">
                    About Label
                    <span className="optional-input mx-1" data-title="optional">
                      *
                    </span>
                  </Form.Label>
                  <div className="textarea-container">
                    <Form.Control as="textarea" rows={4} className="fu-input" placeholder="Enter your label bio" name="about" onInput={getLabelData} autoComplete="on" />
                  </div>
                  {joiErrorsList.map((error, index) =>
                    error.path[0] === "about" ? (
                      <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                        {error.message}
                      </div>
                    ) : (
                      ""
                    )
                  )}
                </Form.Group>
                {/* ------------------------------------ */}
                <Row>
                  <Col lg={6}>
                    <Form.Group className="mb-3" controlId="beatportUrl">
                      <Form.Label className="fu-text-light-gray" data-title="optional">
                        Beatport URL
                        <span className="optional-input mx-1" data-title="optional">
                          *
                        </span>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Label Beatport URL" className="fu-input" name="beatport_url" onInput={getLabelData} autoComplete="on" />
                      {joiErrorsList.map((error, index) =>
                        error.path[0] === "beatport_url" ? (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error.message.replace("_", " ")}
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3" controlId="spotifyUrl">
                      <Form.Label className="fu-text-light-gray" data-title="optional">
                        Spotify URL
                        <span className="optional-input mx-1" data-title="optional">
                          *
                        </span>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Label Spotify URL" className="fu-input" name="spotify_url" onInput={getLabelData} autoComplete="on" />
                      {joiErrorsList.map((error, index) =>
                        error.path[0] === "spotify_url" ? (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error.message.replace("_", " ")}
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3" controlId="soundCloudUrl">
                      <Form.Label className="fu-text-light-gray" data-title="optional">
                        Sound Cloud URL
                        <span className="optional-input mx-1" data-title="optional">
                          *
                        </span>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Label Sound Cloud URL" className="fu-input" name="sound_cloud_url" onInput={getLabelData} autoComplete="on" />
                      {joiErrorsList.map((error, index) =>
                        error.path[0] === "sound_cloud_url" ? (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error.message.replaceAll("_", " ")}
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3" controlId="youtubeUrl">
                      <Form.Label className="fu-text-light-gray" data-title="optional">
                        YouTube URL
                        <span className="optional-input mx-1" data-title="optional">
                          *
                        </span>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Label YouTube URL" className="fu-input" name="youtube_url" onInput={getLabelData} autoComplete="on" />
                      {joiErrorsList.map((error, index) =>
                        error.path[0] === "youtube_url" ? (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error.message.replaceAll("_", " ")}
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    </Form.Group>
                  </Col>
                  {/* ------------------------------------ */}
                  <Col lg={6}>
                    <Form.Group className="mb-3" controlId="facebookUrl">
                      <Form.Label className="fu-text-light-gray" data-title="optional">
                        FaceBook URL
                        <span className="optional-input mx-1" data-title="optional">
                          *
                        </span>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Label FaceBook URL" className="fu-input" name="facebook_url" onInput={getLabelData} autoComplete="on" />
                      {joiErrorsList.map((error, index) =>
                        error.path[0] === "facebook_url" ? (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error.message.replaceAll("_", " ")}
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3" controlId="instagramUrl">
                      <Form.Label className="fu-text-light-gray" data-title="optional">
                        Instagram URL
                        <span className="optional-input mx-1" data-title="optional">
                          *
                        </span>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Label Instagram URL" className="fu-input" name="instagram_url" onInput={getLabelData} autoComplete="on" />
                      {joiErrorsList.map((error, index) =>
                        error.path[0] === "instagram_url" ? (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error.message.replaceAll("_", " ")}
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3" controlId="threadsUrl">
                      <Form.Label className="fu-text-light-gray" data-title="optional">
                        Threads URL
                        <span className="optional-input mx-1" data-title="optional">
                          *
                        </span>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Label Threads URL" className="fu-input" name="threads_url" onInput={getLabelData} autoComplete="on" />
                      {joiErrorsList.map((error, index) =>
                        error.path[0] === "threads_url" ? (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error.message.replaceAll("_", " ")}
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3" controlId="xUrl">
                      <Form.Label className="fu-text-light-gray" data-title="optional">
                        X URL
                        <span className="optional-input mx-1" data-title="optional">
                          *
                        </span>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Label X URL" className="fu-input" name="x_url" onInput={getLabelData} autoComplete="on" />
                      {joiErrorsList.map((error, index) =>
                        error.path[0] === "x_url" ? (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error.message.replaceAll("_", " ")}
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3" controlId="tiktokUrl">
                      <Form.Label className="fu-text-light-gray" data-title="optional">
                        TikTok URL
                        <span className="optional-input mx-1" data-title="optional">
                          *
                        </span>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Label TikTok URL" className="fu-input" name="tiktok_url" onInput={getLabelData} autoComplete="on" />
                      {joiErrorsList.map((error, index) =>
                        error.path[0] === "tiktok_url" ? (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error.message.replaceAll("_", " ")}
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3" controlId="linkedInUrl">
                      <Form.Label className="fu-text-light-gray" data-title="optional">
                        LinkedIn URL
                        <span className="optional-input mx-1" data-title="optional">
                          *
                        </span>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Label LinkedIn URL" className="fu-input" name="linkedin_url" onInput={getLabelData} autoComplete="on" />
                      {joiErrorsList.map((error, index) =>
                        error.path[0] === "linkedin_url" ? (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error.message.replaceAll("_", " ")}
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3" controlId="discordUrl">
                      <Form.Label className="fu-text-light-gray" data-title="optional">
                        Discord URL
                        <span className="optional-input mx-1" data-title="optional">
                          *
                        </span>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Label Discord URL" className="fu-input" name="discord_url" onInput={getLabelData} autoComplete="on" />
                      {joiErrorsList.map((error, index) =>
                        error.path[0] === "discord_url" ? (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error.message.replaceAll("_", " ")}
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="mb-3" controlId="websiteUrl">
                      <Form.Label className="fu-text-light-gray" data-title="optional">
                        Website URL
                        <span className="optional-input mx-1" data-title="optional">
                          *
                        </span>
                      </Form.Label>
                      <Form.Control type="text" placeholder="Enter Label Website URL" className="fu-input" name="website_url" onInput={getLabelData} autoComplete="on" />
                      {joiErrorsList.map((error, index) =>
                        error.path[0] === "website_url" ? (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error.message.replaceAll("_", " ")}
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                {createResponse.errors
                  ? Object.keys(createResponse.errors).map((key) =>
                      createResponse.errors[key].map((error, index) => (
                        <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                          {error}
                        </div>
                      ))
                    )
                  : ""}
                <div className="d-flex align-items-center mt-4">
                  <div>
                    <Button className={`fu-btn fu-btn-gray ${isCreateLoading ? "disabled" : ""}`} type="submit" disabled={isCreateLoading ? true : false}>
                      <span className="btn-bg"></span>
                      Submit<i className="bi bi-send ms-2"></i>
                    </Button>
                  </div>
                  {isCreateLoading ? (
                    <div className="position-relative ms-3">
                      <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateLabel;
