import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fmDataContext } from "../../Context/FmData";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { fmRolesContext } from "../../Context/RolesContext";
import userCan from "../../helpers/userCan";

import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

import Swal from "sweetalert2/dist/sweetalert2.all.min";
import "@sweetalert2/theme-dark/dark.min.css";
import jwtDecode from "jwt-decode";
import { authenticationContext } from "../../Context/AuthContext";
import styles from "./RoleDetails.module.scss";
import Reveal from "../Reveal/Reveal";

const RoleDetails = () => {
  let [mounted, setMounted] = useState(false);

  let { roleId } = useParams();

  let { baseUrl, navigate, showData, editItemExistence, getData, globalCrumbs, setGlobalCrumbs, goToBlogDetails } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  let [currentRole, setCurrentRole] = useState({});

  let [isCurrentRoleLoading, setIsCurrentRoleLoading] = useState(true);

  let [isForceDeleteLoading, setIsForceDeleteLoading] = useState(false);

  let { setRoleMsg } = useContext(fmRolesContext);

  let userToken = jwtDecode(localStorage.getItem("userToken")).accessToken;

  let usersSearchInput = useRef();
  let permissionsSearchInput = useRef();

  let [usersSwiperCount, setUsersSwiperCount] = useState(4);

  const forceDeleteRole = (reqMethod, pathName, headers, setLoading) => {
    Swal.fire({
      title: "Are you sure?",
      html: `<p class="mb-0">You will delete <span class="fu-text-light-gray">${currentRole.data.name}</span> permanently</p><p class="mb-0">You won't be able to revert this!</p>`,
      icon: "warning",
      showCancelButton: true,
      customClass: {
        cancelButton: "popup-btn",
        confirmButton: "popup-btn",
      },
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        if (userCan("fm_settings_force_delete")) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              setRoleMsg(`${currentRole.data.name} Deleted Permanently Successfully`);
              navigate("/fm-roles");
            })
            .catch((error) => {
              navigate("/not-found");
            });
        } else {
          navigate("/not-found");
        }
      }
    });
  };

  const setUsersSlidesCount = () => {
    if (window.innerWidth > 1400) {
      setUsersSwiperCount(4);
    } else if (window.innerWidth < 1400 && window.innerWidth >= 1200) {
      setUsersSwiperCount(3);
    } else if (window.innerWidth < 1200 && window.innerWidth > 991.9) {
      setUsersSwiperCount(3);
    } else if (window.innerWidth < 992 && window.innerWidth > 767.9) {
      setUsersSwiperCount(2);
    } else if (window.innerWidth < 768 && window.innerWidth > 575.9) {
      setUsersSwiperCount(1);
    } else if (window.innerWidth < 576 && window.innerWidth > 0) {
      setUsersSwiperCount(1);
    }
  };

  const getRole = (reqMethod, pathName, setLoading = setIsCurrentRoleLoading, headers, params = null) => {
    showData(reqMethod, pathName, setLoading, headers, params)
      .then((response) => {
        setCurrentRole(response.data);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    getRole("GET", `${baseUrl}fm-role/${roleId}`, setIsCurrentRoleLoading, {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
      Authorization: `Bearer ${userToken}`,
    });
    setMounted(true);

    setUsersSlidesCount();
    window.addEventListener("resize", function () {
      setUsersSlidesCount();
    });
  }, []);

  useEffect(() => {
    getRole("GET", `${baseUrl}fm-role/${roleId}`, setIsCurrentRoleLoading, {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
      Authorization: `Bearer ${userToken}`,
    });
    setMounted(true);
  }, [roleId]);

  useEffect(() => {
    if (mounted && currentRole.data) {
      let globalCrumbsUpdated = globalCrumbs.map((crumb) => {
        if (crumb.name === roleId.toUpperCase()) {
          crumb.name = currentRole.data.name.toUpperCase();
        }
        return crumb;
      });
      setGlobalCrumbs(globalCrumbsUpdated);
    }
  }, [currentRole]);

  let [permissionsSearchTimeOut, setPermissionsSearchTimeOut] = useState("");
  let [permissionsSearchText, setPermissionsSearchText] = useState("");
  let [isPermissionsSearchLoading, setIsPermissionsSearchLoading] = useState(false);

  const getSearchPermissions = () => {
    setIsPermissionsSearchLoading(true);
    if (permissionsSearchTimeOut) {
      clearTimeout(permissionsSearchTimeOut);
    }
    let getPermissionsSearchTimeOut = setTimeout(() => {
      getData(
        "GET",
        `${baseUrl}fm-specific-permissions`,
        {
          search_query: permissionsSearchText,
          role_key: currentRole.data.key,
        },
        setIsPermissionsSearchLoading,
        {
          Accept: "application/json",
          "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
          Authorization: `Bearer ${userToken}`,
        }
      )
        .then((response) => {
          setIsPermissionsSearchLoading(false);
          currentRole.data.role_permissions = response.data.data;
        })
        .catch((error) => {
          navigate("/not-found");
        });
    }, 1500);
    setPermissionsSearchTimeOut(getPermissionsSearchTimeOut);
  };

  useEffect(() => {
    if (mounted) {
      getSearchPermissions();
    }
  }, [permissionsSearchText]);

  let [usersSearchTimeOut, setUsersSearchTimeOut] = useState("");
  let [usersSearchText, setUsersSearchText] = useState("");
  let [isUsersSearchLoading, setIsUsersSearchLoading] = useState(false);

  const getSearchUsers = () => {
    setIsUsersSearchLoading(true);

    if (usersSearchTimeOut) {
      clearTimeout(usersSearchTimeOut);
    }
    let getUsersSearchTimeOut = setTimeout(() => {
      getData(
        "GET",
        `${baseUrl}fm-role-users`,
        {
          search_query: usersSearchText,
          role_key: currentRole.data.key,
        },
        setIsUsersSearchLoading,
        {
          Accept: "application/json",
          "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
          Authorization: `Bearer ${userToken}`,
        }
      )
        .then((response) => {
          setIsUsersSearchLoading(false);
          currentRole.data.role_users = response.data.data;
        })
        .catch((error) => {
          navigate("/not-found");
        });
    }, 1500);
    setUsersSearchTimeOut(getUsersSearchTimeOut);
  };

  useEffect(() => {
    if (mounted) {
      getSearchUsers();
    }
  }, [usersSearchText]);

  return (
    <>
      <div className={`${!isCurrentRoleLoading ? "min-vh-100" : ""}`}>
        {!isCurrentRoleLoading ? (
          <>
            <div className="container">
              <h1 className="fu-text-3-rem fu-text-3-rem-md fu-text-md-2-rem fu-text-sm-2-rem fu-text-1-8-rem-xs fu-text-18-px-xxs fu-text-16-px-xxs fu-fw-800 fu-text-white mb-0 mb-sm-2 mb-md-3 mt-3">{currentRole.data.name.toUpperCase()}</h1>
              <div className="py-2 mt-2 mt-md-3">
                {currentRole.data.name !== "super_admin" ? (
                  <div className="d-flex flex-wrap align-items-center justify-content-start mb-4">
                    {userCan("fm_settings_update") ? (
                      <div className="me-2">
                        <Link to={`/fm-roles/update/${currentRole.data.key}`} className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading ? "disabled" : ""}`} disabled={isForceDeleteLoading ? true : false}>
                          <span className="btn-bg"></span>
                          Edit
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}
                    {userCan("fm_settings_force_delete") ? (
                      <div className="me-2">
                        <button
                          className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading ? "disabled" : ""}`}
                          onClick={() =>
                            forceDeleteRole(
                              "DELETE",
                              `${baseUrl}fm-roles/force-delete/${currentRole.data.key}`,
                              {
                                Accept: "application/json",
                                "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                                Authorization: `Bearer ${user.accessToken}`,
                              },
                              setIsForceDeleteLoading
                            )
                          }
                          disabled={isForceDeleteLoading ? true : false}>
                          <span className="btn-bg"></span>
                          Force Delete
                        </button>
                      </div>
                    ) : (
                      ""
                    )}

                    {isForceDeleteLoading ? (
                      <div className="position-relative ms-4">
                        <LoadingCircle circleCont={30} icon={25} loader={30} dot={30} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
                <div className="role-tags">
                  <div className="mb-4 info-tags-container justify-content-start">
                    {currentRole.data._id ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">#ID: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentRole.data._id}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentRole.data.key ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">#KEY: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentRole.data.key}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentRole.data.role_users_count !== null ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">ROLE USERS COUNT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentRole.data.role_users_count}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentRole.data.role_permissions_count !== null ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">ROLE PERMISSIONS COUNT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentRole.data.role_permissions_count}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentRole.data.role_created_at ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED AT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{new Date(currentRole.data.role_created_at).toLocaleString("en-US", { timeZone: "Africa/Cairo", hour12: true })}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentRole.data.role_updated_at ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED AT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{new Date(currentRole.data.role_updated_at).toLocaleString("en-US", { timeZone: "Africa/Cairo", hour12: true })}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                {/* role users */}
                {currentRole.data.role_users !== null ? (
                  <div className="mb-4">
                    <div className="d-flex flex-column flex-lg-row align-items-center mb-4">
                      <div className="flex-grow-1 iu-row-heading mb-2 mb-lg-0">
                        <h2 className="mb-0 row-title">USERS</h2>
                      </div>
                      <div className="d-flex justify-content-between align-items-center row-search-container ms-0 ms-lg-2">
                        <div className="item-search-box-container flex-grow-1">
                          <div className="loading-fixed-dimensions item-search-box-loading">
                            {isUsersSearchLoading ? (
                              <div className="position-relative">
                                <LoadingCircle circleCont={20} icon={15} loader={20} dot={20} />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="item-search-box">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              id="roles_search_query"
                              placeholder={`${currentRole.data.name} Users Search...`}
                              onInput={(e) => {
                                setUsersSearchText(e.target.value);
                                clearTimeout(usersSearchTimeOut);
                              }}
                              ref={usersSearchInput}
                            />
                          </div>
                        </div>
                        <div className="ms-2">
                          <button
                            className="fu-btn fu-btn-gray"
                            onClick={() => {
                              setUsersSearchText("");
                              clearTimeout(usersSearchTimeOut);
                              usersSearchInput.current.value = "";
                            }}>
                            <span className="btn-bg"></span>
                            Clear Search
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="item-role-users-search-row-height">
                      {usersSearchText.length > 0 && !isUsersSearchLoading && currentRole.data.role_users.length === 0 ? (
                        <div className="d-flex flex-column justify-content-center align-items-center item-role-users-search-msg-height">
                          <div>
                            <h2 className="fu-text-3-rem fu-text-yellow">
                              <i className="bi bi-search"></i>
                            </h2>
                          </div>
                          <div>
                            <h3 className="h5 fu-text-light-gray line-clamp-2">
                              There Are No Users Related To<span className="d-inline-flex fu-text-yellow mx-2">{currentRole.data.name}</span>With This Text<span className="d-inline-flex fu-text-yellow mx-2">"{usersSearchText}"</span>
                            </h3>
                          </div>
                        </div>
                      ) : (
                        <div className="row gx-3">
                          <Swiper className="myswiper px-2" slidesPerView={usersSwiperCount} spaceBetween={10} keyboard={true} navigation={true} modules={[Navigation, Keyboard]}>
                            {currentRole.data.role_users.map((user, index) => (
                              <SwiperSlide key={index}>
                                <Reveal>
                                  <div className={styles.user_card_container} onClick={() => goToBlogDetails(`/fm-users/${user.key}`, "0")}>
                                    <div className="d-flex mb-1">
                                      <span className="fu-text-yellow">#ID:</span>
                                      <span className="fu-text-light-gray ms-2 line-clamp-1">{user._id}</span>
                                    </div>
                                    <div className="d-flex mb-1">
                                      <span className="fu-text-yellow">#KEY:</span>
                                      <span className="fu-text-light-gray ms-2 line-clamp-1">{user.key}</span>
                                    </div>
                                    <div className="d-flex mb-1">
                                      <span className="fu-text-yellow">NAME:</span>
                                      <span className="fu-text-light-gray ms-2 line-clamp-1">{user.name}</span>
                                    </div>
                                    <div className="d-flex mb-1">
                                      <span className="fu-text-yellow">EMAIL:</span>
                                      <span className="fu-text-light-gray ms-2 line-clamp-1">{user.email}</span>
                                    </div>
                                    <div className="d-flex mb-1">
                                      <span className="fu-text-yellow">ROLE:</span>
                                      <span className="fu-text-light-gray ms-2 line-clamp-1">{user.role}</span>
                                    </div>
                                    <div className="d-flex mb-1">
                                      <span className="fu-text-yellow">STATUS:</span>
                                      <span className="fu-text-light-gray ms-2 line-clamp-1">{user.status}</span>
                                    </div>
                                  </div>
                                </Reveal>
                              </SwiperSlide>
                            ))}
                          </Swiper>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {/* role permission */}
                {currentRole.data.role_permissions !== null ? (
                  <div className="mb-4">
                    <div className="d-flex flex-column flex-lg-row align-items-center mb-4">
                      <div className="flex-grow-1 iu-row-heading mb-2 mb-lg-0">
                        <h2 className="mb-0 row-title">PERMISSIONS</h2>
                      </div>
                      <div className="d-flex justify-content-between align-items-center row-search-container ms-0 ms-lg-2">
                        <div className="item-search-box-container flex-grow-1">
                          <div className="loading-fixed-dimensions item-search-box-loading">
                            {isPermissionsSearchLoading ? (
                              <div className="position-relative">
                                <LoadingCircle circleCont={20} icon={15} loader={20} dot={20} />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="item-search-box">
                            <input
                              type="text"
                              className="form-control shadow-none"
                              id="permissions_search_query"
                              placeholder={`${currentRole.data.name} Permissions Search...`}
                              onInput={(e) => {
                                setPermissionsSearchText(e.target.value);
                                clearTimeout(permissionsSearchTimeOut);
                              }}
                              ref={permissionsSearchInput}
                            />
                          </div>
                        </div>
                        <div className="ms-2">
                          <button
                            className="fu-btn fu-btn-gray"
                            onClick={() => {
                              setPermissionsSearchText("");
                              clearTimeout(permissionsSearchTimeOut);
                              permissionsSearchInput.current.value = "";
                            }}>
                            <span className="btn-bg"></span>
                            Clear Search
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="item-role-permissions-search-row-height">
                      {permissionsSearchText.length > 0 && !isPermissionsSearchLoading && currentRole.data.role_permissions.length === 0 ? (
                        <div className="d-flex flex-column justify-content-center align-items-center item-role-permissions-search-msg-height">
                          <div>
                            <h2 className="fu-text-3-rem fu-text-yellow">
                              <i className="bi bi-search"></i>
                            </h2>
                          </div>
                          <div>
                            <h3 className="h5 fu-text-light-gray line-clamp-2">
                              There Are No Permissions Related To<span className="d-inline-flex fu-text-yellow mx-2">{currentRole.data.name}</span>With This Text<span className="d-inline-flex fu-text-yellow mx-2">"{permissionsSearchText}"</span>
                            </h3>
                          </div>
                        </div>
                      ) : (
                        <div className="mb-4 info-tags-container justify-content-start">
                          {currentRole.data.role_permissions.map((permission, index) => (
                            <div className="mx-1 my-1 info-tag" key={index}>
                              {/* <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">#ID: </span> */}
                              <span className="d-inline-flex justify-content-center align-items-center">{permission.name}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </>
        ) : (
          <LoadingScreen />
        )}
      </div>
    </>
  );
};

export default RoleDetails;
