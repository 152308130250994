import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { fmDataContext } from "../../Context/FmData";
import Joi from "joi";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { authenticationContext } from "../../Context/AuthContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Reveal from "../Reveal/Reveal";
import LoadingScreen from "../LoadingScreen/LoadingScreen";

const CreateFuturisingData = () => {
  let { baseUrl, navigate, getData } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  let [joiErrorsList, setJoiErrorsList] = useState([]);

  const animatedComponents = makeAnimated();

  let [futurisingData, setFuturisingData] = useState({
    about_company: "",
    phone_number: "",
    email_address: "",
    office_address: "",
    working_hours: "",
    shop_status: "",
    shop_url: "",
    user_id: null,
    beatport_url: null,
    spotify_url: null,
    sound_cloud_url: null,
    youtube_url: null,
    facebook_url: null,
    instagram_url: null,
    threads_url: null,
    x_url: null,
    tiktok_url: null,
    linkedin_url: null,
    discord_url: null,
  });

  let [isCreateLoading, setIsCreateLoading] = useState(false);

  let shopStatusRef = useRef();

  let [selectedShopStatus, setSelectedShopStatus] = useState(null);

  let [createResponse, setCreateResponse] = useState({});

  let [futurisingDataRows, setFuturisingDataRows] = useState({});

  let [isGetFmDataLoading, setIsGetFmDataLoading] = useState(true);

  const getFuturisingData = (e) => {
    const futurisingInfo = { ...futurisingData };
    futurisingInfo[e.target.name] = e.target.value;
    setFuturisingData(futurisingInfo);
  };

  const handleShopStatusSelect = () => {
    const futurisingInfo = { ...futurisingData };
    futurisingInfo.shop_status = selectedShopStatus !== null && selectedShopStatus.value !== null ? selectedShopStatus.value : "";
    setFuturisingData(futurisingInfo);
  };

  const getFmDataRows = (reqMethod, pathName, setLoading = setIsGetFmDataLoading, params = null) => {
    getData(reqMethod, pathName, params, setLoading)
      .then((response) => {
        setFuturisingDataRows(response.data);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const createItem = async (e) => {
    e.preventDefault();

    if (userCan("fm_futurising_data_create")) {
      futurisingData.user_id = user.currentUser._id;
      futurisingData.about_company = futurisingData.about_company ? futurisingData.about_company.replaceAll("\n", "</br>") : "";
      const validationResult = validateCreateFuturisingDataForm();

      setCreateResponse({});

      if (validationResult.error) {
        validationResult.error ? setJoiErrorsList(validationResult.error.details) : setJoiErrorsList([]);
      } else {
        setJoiErrorsList([]);
        setIsCreateLoading(true);
        await axios({
          method: "POST",
          url: `${baseUrl}fm-futurising-data/create`,
          data: futurisingData,
          headers: {
            Accept: "application/json",
            "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
            Authorization: `Bearer ${user.accessToken}`,
          },
        })
          .then((response) => {
            setCreateResponse(response.data);
            if (response.data.msg && response.data.msg.includes("successfully")) {
              getData("GET", `${baseUrl}fm-futurising-data`, null, setIsCreateLoading)
                .then((fmRowsResponse) => {
                  setFuturisingDataRows(fmRowsResponse.data);
                  setFuturisingData({
                    about_company: "",
                    phone_number: "",
                    email_address: "",
                    office_address: "",
                    working_hours: "",
                    shop_status: "",
                    shop_url: "",
                    user_id: null,
                    beatport_url: null,
                    spotify_url: null,
                    sound_cloud_url: null,
                    youtube_url: null,
                    facebook_url: null,
                    instagram_url: null,
                    threads_url: null,
                    x_url: null,
                    tiktok_url: null,
                    linkedin_url: null,
                    discord_url: null,
                  });
                  shopStatusRef.current.clearValue();
                  e.target.reset();
                  setCreateResponse({});
                  setIsCreateLoading(false);
                  toast(response.data.msg, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    progressStyle: { backgroundColor: "#fdb915" },
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                  });
                })
                .catch((error) => {
                  navigate("/not-found");
                });
            } else if (response.data.msg && response.data.msg.includes("failed")) {
              setIsCreateLoading(false);
              toast(response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          })
          .catch((errors) => {
            navigate("/not-found");
          });
      }
    } else {
      navigate("/not-found");
    }
  };

  const validateCreateFuturisingDataForm = () => {
    const scheme = Joi.object({
      about_company: Joi.string().min(1).max(5000).required(),
      phone_number: Joi.string().min(1).max(1000).required(),
      email_address: Joi.string().min(1).max(1000).required(),
      office_address: Joi.string().min(1).max(1500).required(),
      working_hours: Joi.string().min(1).max(1000).required(),
      shop_status: Joi.string().min(1).max(255).required(),
      shop_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Beatport URL is not valid").allow(null, "").optional(),
      user_id: Joi.number(),
      beatport_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Beatport URL is not valid").allow(null, "").optional(),
      spotify_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Spotify URL is not valid").allow(null, "").optional(),
      sound_cloud_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Sound cloud URL is not valid").allow(null, "").optional(),
      youtube_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Youtube URL is not valid").allow(null, "").optional(),
      facebook_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Facebook URL is not valid").allow(null, "").optional(),
      instagram_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Instagram URL is not valid").allow(null, "").optional(),
      threads_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("threads URL is not valid").allow(null, "").optional(),
      x_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("X URL is not valid").allow(null, "").optional(),
      tiktok_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Tiktok URL is not valid").allow(null, "").optional(),
      linkedin_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Linkedin URL is not valid").allow(null, "").optional(),
      discord_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Discord URL is not valid").allow(null, "").optional(),
    });
    return scheme.validate(futurisingData, { abortEarly: false });
  };

  useEffect(() => {
    getFmDataRows("GET", `${baseUrl}fm-futurising-data`, setIsGetFmDataLoading);
  }, []);

  useEffect(() => {
    handleShopStatusSelect();
  }, [selectedShopStatus]);

  return (
    <>
      {isGetFmDataLoading ? (
        <LoadingScreen />
      ) : (
        <>
          {futurisingDataRows.data && futurisingDataRows.data.length >= 1 ? (
            <Reveal>
              <div className="custom-height d-flex flex-column justify-content-center align-items-center limitation-msg">
                <div className="mb-1 mb-sm-2 mb-lg-3 fu-text-4-rem fu-text-yellow">
                  <i className="bi bi-info-circle"></i>
                </div>
                <h1 className="fu-text-2-rem fu-text-white fu-fw-800 mb-0">Futurising Data is already exists</h1>
                <h2 className="fu-text-1-rem fu-text-yellow fu-fw-800 mb-0">You can't add another</h2>
              </div>
            </Reveal>
          ) : (
            <div className="container position-relative pt-3 pt-md-4">
              <div className="mb-3 mb-md-4">
                <h1 className="fu-fw-800 fu-text-white fu-text-3-rem fu-text-3-rem-md fu-text-md-2-rem fu-text-sm-2-rem fu-text-1-8-rem-xs fu-text-18-px-xxs fu-text-16-px-xxs mb-0">Create Futurising Data</h1>
              </div>
              <div className="py-2 mb-4 iu-row-heading create-submit-row">
                <h2 className="mb-0 row-title">Submit Futurising Info</h2>
              </div>
              <div className="row">
                <div>
                  <div>
                    <Form onSubmit={createItem}>
                      <Row>
                        <Col md={12}>
                          <Form.Group className="mb-3" controlId="aboutCompany">
                            <Form.Label className="fu-text-light-gray" data-title="required">
                              About Futurising Music
                              <span className="required-input mx-1" data-title="required">
                                *
                              </span>
                            </Form.Label>
                            <div className="textarea-container">
                              <Form.Control as="textarea" rows={4} className="fu-input" placeholder="Enter About Futurising Music" name="about_company" onInput={getFuturisingData} />
                            </div>
                            {joiErrorsList.map((error, index) =>
                              error.path[0] === "about_company" ? (
                                <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                                  {error.message.replace("about_company", "About Company")}
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="mb-3" controlId="phoneNumber">
                            <Form.Label className="fu-text-light-gray" data-title="required">
                              Phone Number
                              <span className="required-input mx-1" data-title="required">
                                *
                              </span>
                            </Form.Label>
                            <Form.Control type="text" placeholder="Enter Phone Number" className="fu-input" name="phone_number" onInput={getFuturisingData} />
                            {joiErrorsList.map((error, index) =>
                              error.path[0] === "phone_number" ? (
                                <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                                  {error.message.replace("phone_number", "Phone Number")}
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="mb-3" controlId="emailAddress">
                            <Form.Label className="fu-text-light-gray" data-title="required">
                              Email Address
                              <span className="required-input mx-1" data-title="required">
                                *
                              </span>
                            </Form.Label>
                            <Form.Control type="text" placeholder="Enter Email Address" className="fu-input" name="email_address" onInput={getFuturisingData} />
                            {joiErrorsList.map((error, index) =>
                              error.path[0] === "email_address" ? (
                                <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                                  {error.message.replace("email_address", "Email Address")}
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="mb-3" controlId="officeAddress">
                            <Form.Label className="fu-text-light-gray" data-title="required">
                              Office Address
                              <span className="required-input mx-1" data-title="required">
                                *
                              </span>
                            </Form.Label>
                            <Form.Control type="text" placeholder="Enter Office Address" className="fu-input" name="office_address" onInput={getFuturisingData} />
                            {joiErrorsList.map((error, index) =>
                              error.path[0] === "office_address" ? (
                                <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                                  {error.message.replace("office_address", "Office Address")}
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="mb-3" controlId="workingHours">
                            <Form.Label className="fu-text-light-gray" data-title="required">
                              Working Hours
                              <span className="required-input mx-1" data-title="required">
                                *
                              </span>
                            </Form.Label>
                            <Form.Control type="text" placeholder="Enter Working Hours" className="fu-input" name="working_hours" onInput={getFuturisingData} />
                            {joiErrorsList.map((error, index) =>
                              error.path[0] === "working_hours" ? (
                                <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                                  {error.message.replace("working_hours", "Working Hours")}
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <div className="fu-text-light-gray w-fit-content mb-2" data-title="required">
                              Shop Status
                              <span className="required-input mx-1" data-title="required">
                                *
                              </span>
                            </div>
                            <div className="select-options">
                              <Select
                                ref={shopStatusRef}
                                classNames={{
                                  control: () => "select-filtration",
                                  option: ({ isSelected, innerProps }) => (isSelected ? (innerProps.style = { backgroundColor: "#fdb915", color: "#191919" }) : (innerProps.style = { backgroundColor: "#ffffff" })),
                                }}
                                placeholder="Select Shop Status...."
                                name="shopStatus"
                                onChange={setSelectedShopStatus}
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                isClearable
                                options={[
                                  { value: "active", label: "Active" },
                                  { value: "inactive", label: "Inactive" },
                                ]}
                              />
                            </div>
                            {joiErrorsList.map((error, index) =>
                              error.path[0] === "shop_status" ? (
                                <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                                  {error.message.replace("shop_status", "Shop Status")}
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="mb-3" controlId="shopUrl">
                            <Form.Label className="fu-text-light-gray" data-title="optional if shop status is inactive">
                              Shop URL
                              <span className="optional-input mx-1" data-title="optional if shop status is inactive">
                                *
                              </span>
                            </Form.Label>
                            <Form.Control type="text" placeholder="Enter Working Hours" className="fu-input" name="shop_url" onInput={getFuturisingData} />
                            {joiErrorsList.map((error, index) =>
                              error.path[0] === "shop_url" ? (
                                <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                                  {error.message.replace("shop_url", "Shop URL")}
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </Form.Group>
                        </Col>
                      </Row>

                      {/* ------------------------------------ */}
                      <Row>
                        <Col lg={6}>
                          <Form.Group className="mb-3" controlId="beatportUrl">
                            <Form.Label className="fu-text-light-gray" data-title="optional">
                              Beatport URL
                              <span className="optional-input mx-1" data-title="optional">
                                *
                              </span>
                            </Form.Label>
                            <Form.Control type="text" placeholder="Enter Futurising Music Beatport URL" className="fu-input" name="beatport_url" onInput={getFuturisingData} />
                            {joiErrorsList.map((error, index) =>
                              error.path[0] === "beatport_url" ? (
                                <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                                  {error.message.replace("_", " ")}
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="mb-3" controlId="spotifyUrl">
                            <Form.Label className="fu-text-light-gray" data-title="optional">
                              Spotify URL
                              <span className="optional-input mx-1" data-title="optional">
                                *
                              </span>
                            </Form.Label>
                            <Form.Control type="text" placeholder="Enter Futurising Music Spotify URL" className="fu-input" name="spotify_url" onInput={getFuturisingData} />
                            {joiErrorsList.map((error, index) =>
                              error.path[0] === "spotify_url" ? (
                                <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                                  {error.message.replace("_", " ")}
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="mb-3" controlId="soundCloudUrl">
                            <Form.Label className="fu-text-light-gray" data-title="optional">
                              Sound Cloud URL
                              <span className="optional-input mx-1" data-title="optional">
                                *
                              </span>
                            </Form.Label>
                            <Form.Control type="text" placeholder="Enter Futurising Music Sound Cloud URL" className="fu-input" name="sound_cloud_url" onInput={getFuturisingData} />
                            {joiErrorsList.map((error, index) =>
                              error.path[0] === "sound_cloud_url" ? (
                                <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                                  {error.message.replaceAll("_", " ")}
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="mb-3" controlId="youtubeUrl">
                            <Form.Label className="fu-text-light-gray" data-title="optional">
                              YouTube URL
                              <span className="optional-input mx-1" data-title="optional">
                                *
                              </span>
                            </Form.Label>
                            <Form.Control type="text" placeholder="Enter Futurising Music YouTube URL" className="fu-input" name="youtube_url" onInput={getFuturisingData} />
                            {joiErrorsList.map((error, index) =>
                              error.path[0] === "youtube_url" ? (
                                <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                                  {error.message.replaceAll("_", " ")}
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </Form.Group>
                        </Col>
                        {/* ------------------------------------ */}
                        <Col lg={6}>
                          <Form.Group className="mb-3" controlId="facebookUrl">
                            <Form.Label className="fu-text-light-gray" data-title="optional">
                              FaceBook URL
                              <span className="optional-input mx-1" data-title="optional">
                                *
                              </span>
                            </Form.Label>
                            <Form.Control type="text" placeholder="Enter Futurising Music FaceBook URL" className="fu-input" name="facebook_url" onInput={getFuturisingData} />
                            {joiErrorsList.map((error, index) =>
                              error.path[0] === "facebook_url" ? (
                                <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                                  {error.message.replaceAll("_", " ")}
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="mb-3" controlId="instagramUrl">
                            <Form.Label className="fu-text-light-gray" data-title="optional">
                              Instagram URL
                              <span className="optional-input mx-1" data-title="optional">
                                *
                              </span>
                            </Form.Label>
                            <Form.Control type="text" placeholder="Enter Futurising Music Instagram URL" className="fu-input" name="instagram_url" onInput={getFuturisingData} />
                            {joiErrorsList.map((error, index) =>
                              error.path[0] === "instagram_url" ? (
                                <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                                  {error.message.replaceAll("_", " ")}
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="mb-3" controlId="threadsUrl">
                            <Form.Label className="fu-text-light-gray" data-title="optional">
                              Threads URL
                              <span className="optional-input mx-1" data-title="optional">
                                *
                              </span>
                            </Form.Label>
                            <Form.Control type="text" placeholder="Enter Futurising Music Threads URL" className="fu-input" name="threads_url" onInput={getFuturisingData} />
                            {joiErrorsList.map((error, index) =>
                              error.path[0] === "threads_url" ? (
                                <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                                  {error.message.replaceAll("_", " ")}
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="mb-3" controlId="xUrl">
                            <Form.Label className="fu-text-light-gray" data-title="optional">
                              X URL
                              <span className="optional-input mx-1" data-title="optional">
                                *
                              </span>
                            </Form.Label>
                            <Form.Control type="text" placeholder="Enter Futurising Music X URL" className="fu-input" name="x_url" onInput={getFuturisingData} />
                            {joiErrorsList.map((error, index) =>
                              error.path[0] === "x_url" ? (
                                <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                                  {error.message.replaceAll("_", " ")}
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="mb-3" controlId="tiktokUrl">
                            <Form.Label className="fu-text-light-gray" data-title="optional">
                              TikTok URL
                              <span className="optional-input mx-1" data-title="optional">
                                *
                              </span>
                            </Form.Label>
                            <Form.Control type="text" placeholder="Enter Futurising Music TikTok URL" className="fu-input" name="tiktok_url" onInput={getFuturisingData} />
                            {joiErrorsList.map((error, index) =>
                              error.path[0] === "tiktok_url" ? (
                                <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                                  {error.message.replaceAll("_", " ")}
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="mb-3" controlId="linkedInUrl">
                            <Form.Label className="fu-text-light-gray" data-title="optional">
                              LinkedIn URL
                              <span className="optional-input mx-1" data-title="optional">
                                *
                              </span>
                            </Form.Label>
                            <Form.Control type="text" placeholder="Enter Futurising Music LinkedIn URL" className="fu-input" name="linkedin_url" onInput={getFuturisingData} />
                            {joiErrorsList.map((error, index) =>
                              error.path[0] === "linkedin_url" ? (
                                <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                                  {error.message.replaceAll("_", " ")}
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group className="mb-3" controlId="discordUrl">
                            <Form.Label className="fu-text-light-gray" data-title="optional">
                              Discord URL
                              <span className="optional-input mx-1" data-title="optional">
                                *
                              </span>
                            </Form.Label>
                            <Form.Control type="text" placeholder="Enter Futurising Music Discord URL" className="fu-input" name="discord_url" onInput={getFuturisingData} />
                            {joiErrorsList.map((error, index) =>
                              error.path[0] === "discord_url" ? (
                                <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                                  {error.message.replaceAll("_", " ")}
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                      {createResponse.errors
                        ? Object.keys(createResponse.errors).map((key) =>
                            createResponse.errors[key].map((error, index) => (
                              <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                                {error}
                              </div>
                            ))
                          )
                        : ""}
                      <div className="d-flex align-items-center mt-4">
                        <div>
                          <Button className={`fu-btn fu-btn-gray ${isCreateLoading ? "disabled" : ""}`} type="submit" disabled={isCreateLoading ? true : false}>
                            <span className="btn-bg"></span>
                            Submit<i className="bi bi-send ms-2"></i>
                          </Button>
                        </div>
                        {isCreateLoading ? (
                          <div className="position-relative ms-3">
                            <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CreateFuturisingData;
