import React, { createContext, useState } from "react";

export let fmRolesContext = createContext(0);

const RolesContext = ({ children }) => {
  let [roleMsg, setRoleMsg] = useState("");

  let [filtrationDetails, setFiltrationDetails] = useState({
    search_query: "",
  });

  let [isFiltrationLoading, setIsFiltrationLoading] = useState(false);

  let fmRolesLifting = {
    roleMsg,
    setRoleMsg,
    filtrationDetails,
    setFiltrationDetails,
    isFiltrationLoading,
    setIsFiltrationLoading,
  };

  return (
    <>
      <fmRolesContext.Provider value={{ ...fmRolesLifting }}>{children}</fmRolesContext.Provider>
    </>
  );
};

export default RolesContext;
