import React, { useContext, useEffect, useRef, useState } from "react";
import { fmDataContext } from "../../Context/FmData";
import Pagination from "../Pagination/Pagination";
import { Link } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import PartnershipRow from "./PartnershipRow";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { fmPartnershipsContext } from "../../Context/PartnershipsContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";
import PartnershipsFiltration from "./PartnershipsFiltration";

const Partnerships = () => {
  const isCalledRef = useRef(false);

  let { baseUrl, navigate, getData } = useContext(fmDataContext);

  let [partnerships, setPartnerships] = useState({});

  let [isGetPartnershipsLoading, setIsGetPartnershipsLoading] = useState(true);

  let [isPaginateLoading, setIsPaginateLoading] = useState(false);

  let [pageNum, setPageNum] = useState(1);

  let perPage = 20;
  let orderBy = "id";
  let orderType = "asc";

  let { partnershipMsg, setPartnershipMsg, filtrationDetails, isFiltrationLoading, setFiltrationDetails } = useContext(fmPartnershipsContext);

  const getPartnerships = (reqMethod, pathName, params, setLoading = setIsGetPartnershipsLoading, message = null) => {
    getData(reqMethod, pathName, params, setLoading)
      .then((response) => {
        // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
        // if (response.data.data) {
        //   response.data.data.forEach((partnership) => {
        //     if (partnership.partnership_image.includes("via.placeholder.com")) {
        //       partnership.partnership_image = partnership.partnership_image.replace("http://127.0.0.1:8000/storage/", "");
        //     }
        //   });
        // }
        // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
        setPartnerships(response.data);
        setLoading(false);
        if (partnershipMsg.length > 0) {
          toast(partnershipMsg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
            onClose: () => setPartnershipMsg(""),
          });
        }
        if (message) {
          toast(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    if (!isCalledRef.current) {
      getPartnerships(
        "GET",
        `${baseUrl}fm-partnerships`,
        {
          page_num: pageNum,
          per_page: perPage,
          order_type: orderType,
          order_by: orderBy,
        },
        setIsGetPartnershipsLoading
      );
    }

    return () => {
      isCalledRef.current = true;
      setFiltrationDetails({
        search_query: "",
        only_trashed: 0,
      });
    };
  }, []);

  return (
    <>
      <div className="container">
        <div className={`${!isGetPartnershipsLoading ? "min-vh-100" : ""}`}>
          <div className="mb-2 mb-sm-5">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-3 mt-3">
              <h1 className="fu-text-3-rem fu-text-3-rem-md fu-text-md-2-rem fu-text-sm-2-rem fu-text-1-8-rem-xs fu-text-18-px-xxs fu-text-16-px-xxs fu-fw-800 fu-text-white mb-0">PARTNERSHIPS</h1>
              {userCan("fm_partnership_create") ? (
                <div className="me-3">
                  <Link to="/fm-partnerships/create" className="fu-btn fu-btn-gray">
                    <span className="btn-bg"></span>
                    Create New
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="mt-4">
              <PartnershipsFiltration getPartnerships={getPartnerships} perPage={perPage} orderBy={orderBy} orderType={orderType} />
            </div>
          </div>

          {!isGetPartnershipsLoading ? (
            <>
              <div className="row py-3 d-none d-xxl-flex table-heading-brdr">
                <div className="col-md-1">
                  <h3 className="h6 mb-0 fu-text-white">#ID</h3>
                </div>
                <div className="col-md-4">
                  <h3 className="h6 mb-0 fu-text-white">KEY</h3>
                </div>
                <div className="col-md-3">
                  <h3 className="h6 mb-0 fu-text-white">PARTNERSHIP NAME</h3>
                </div>
                <div className="col-md-4">
                  <h3 className="h6 mb-0 fu-text-white">OPERATIONS</h3>
                </div>
              </div>
              <div className="table-heading-brdr mt-4 mt-sm-0 d-xxl-none"></div>
              {isPaginateLoading ? (
                <div className="d-flex justify-content-center mt-5">
                  <div className="position-relative mt-5">
                    <LoadingCircle circleCont={85} icon={80} loader={85} dot={85} />
                  </div>
                </div>
              ) : (
                <>
                  {partnerships.data && partnerships.data.length > 0 ? (
                    partnerships.data.map((partnership, index) => <PartnershipRow key={index} meta={partnerships.meta} partnership={partnership} index={index} getPartnerships={getPartnerships} perPage={perPage} pageNum={pageNum} orderBy={orderBy} orderType={orderType} />)
                  ) : partnerships.data && partnerships.data.length === 0 && filtrationDetails.search_query === "" && filtrationDetails.only_trashed === 0 && !isFiltrationLoading ? (
                    <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                      <div>
                        <h2 className="fu-text-3-rem fu-text-yellow">
                          <i className="bi bi-database-add"></i>
                        </h2>
                      </div>
                      <div>
                        <h3 className="h5 fu-text-light-gray">
                          There Are No Partnerships
                          {userCan("fm_partnership_create") ? (
                            <>
                              ,
                              <Link to="/fm-partnerships/create" className="link-dashed ms-2">
                                Create New
                              </Link>
                            </>
                          ) : (
                            ""
                          )}
                        </h3>
                      </div>
                    </div>
                  ) : !isFiltrationLoading ? (
                    <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                      <div>
                        <h2 className="fu-text-3-rem fu-text-yellow">
                          <i className="bi bi-search"></i>
                        </h2>
                      </div>
                      <div>
                        <h3 className="h5 fu-text-light-gray">There are no partnerships matching your search</h3>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="mt-5">{partnerships ? partnerships.meta && partnerships.meta.last_page !== 1 ? <Pagination pages={partnerships.meta.links} pagination={partnerships.meta} getItems={getPartnerships} pathName={"fm-partnerships"} filtrationDetails={filtrationDetails} setIsPaginateLoading={setIsPaginateLoading} setPageNum={setPageNum} per_page={perPage} order_by={orderBy} order_type={orderType} /> : "" : ""}</div>
                </>
              )}
            </>
          ) : (
            <LoadingScreen />
          )}
        </div>
      </div>
    </>
  );
};

export default Partnerships;
