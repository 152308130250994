import axios from "axios";
import React, { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

export let fmDataContext = createContext(0);

const FmData = ({ children }) => {
  let baseUrl = process.env.REACT_APP_FM_API_BASE_URL;

  let navigate = useNavigate();

  let [globalCrumbs, setGlobalCrumbs] = useState([]);

  const getAllDataWithoutPaginate = async (method, url, params, setLoading) => {
    setLoading(true);
    return await axios({
      method,
      url,
      params,
      headers: {
        Accept: "application/json",
        "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
      },
    });
  };

  const getData = async (
    method,
    url,
    params,
    setLoading,
    headers = {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
    }
  ) => {
    setLoading(true);
    return await axios({
      method,
      url,
      params,
      headers,
    });
  };

  const showData = async (
    method,
    url,
    setLoading,
    headers = {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
    },
    params = null
  ) => {
    setLoading(true);
    return await axios({
      method,
      url,
      params,
      headers,
    });
  };

  const editItemExistence = async (method, url, headers, setLoading, params = null) => {
    setLoading(true);
    return await axios({
      method,
      url,
      params,
      headers,
    });
  };

  const goToBlogDetails = (pathname, searchText) => {
    navigate({
      pathname,
      search: `?archived=${searchText}`,
    });
  };

  let fmDataLifting = {
    baseUrl,
    navigate,
    getData,
    showData,
    editItemExistence,
    globalCrumbs,
    setGlobalCrumbs,
    goToBlogDetails,
    getAllDataWithoutPaginate,
  };

  return (
    <>
      <fmDataContext.Provider value={{ ...fmDataLifting }}>{children}</fmDataContext.Provider>
    </>
  );
};

export default FmData;
