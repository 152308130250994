import React, { useContext, useEffect, useRef, useState } from "react";
import { fmDataContext } from "../../Context/FmData";
import Pagination from "../Pagination/Pagination";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import SubscribedEmailRow from "./SubscribedEmailRow";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { fmSubscribedEmailsContext } from "../../Context/SubscribedEmailsContext";
import { toast } from "react-toastify";
import Swal from "sweetalert2/dist/sweetalert2.all.min";
import "@sweetalert2/theme-dark/dark.min.css";
import SubscribedEmailsFilration from "./SubscribedEmailsFilration";
import jwtDecode from "jwt-decode";
import { Link } from "react-router-dom";
import axios from "axios";
import userCan from "../../helpers/userCan";

const SubscribedEmails = () => {
  const isCalledRef = useRef(false);

  let { baseUrl, navigate, getData } = useContext(fmDataContext);

  let [subscribedEmails, setSubscribedEmails] = useState({});

  let [isGetSubscribedEmailsLoading, setIsGetSubscribedEmailsLoading] = useState(true);

  let [isPaginateLoading, setIsPaginateLoading] = useState(false);

  let [pageNum, setPageNum] = useState(1);

  let perPage = 20;
  let orderBy = "id";
  let orderType = "asc";

  let [isSendNewsletterLoading, setIsSendNewsletterLoading] = useState(false);

  let { subscribedEmailMsg, setSubscribedEmailMsg, filtrationDetails, isFiltrationLoading, setFiltrationDetails } = useContext(fmSubscribedEmailsContext);

  let userToken = jwtDecode(localStorage.getItem("userToken")).accessToken;

  const getSubscribedEmails = (
    reqMethod,
    pathName,
    params,
    setLoading = setIsGetSubscribedEmailsLoading,
    message = null,
    headers = {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
      Authorization: `Bearer ${userToken}`,
    }
  ) => {
    getData(reqMethod, pathName, params, setLoading, headers)
      .then((response) => {
        setSubscribedEmails(response.data);
        setLoading(false);

        if (subscribedEmailMsg.length > 0) {
          toast(subscribedEmailMsg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
            onClose: () => setSubscribedEmailMsg(""),
          });
        }

        if (message) {
          toast(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    if (!isCalledRef.current) {
      getSubscribedEmails(
        "GET",
        `${baseUrl}fm-subscribed-emails`,
        {
          page_num: pageNum,
          per_page: perPage,
          order_type: orderType,
          order_by: orderBy,
        },
        setIsGetSubscribedEmailsLoading
      );
    }
    return () => {
      isCalledRef.current = true;
      setFiltrationDetails({
        search_query: "",
      });
    };
  }, []);

  const sendNewsletterToEmails = () => {
    if (userCan("send_newsletter_mail")) {
      Swal.fire({
        title: "Are you sure?",
        html: `<p class="mb-0">You will send newsletter mail to subscribed emails!</p>`,
        icon: "warning",
        showCancelButton: true,
        customClass: {
          cancelButton: "popup-btn",
          confirmButton: "popup-btn",
        },
        confirmButtonText: "Yes, send it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setIsSendNewsletterLoading(true);
          await axios({
            method: "POST",
            url: `${baseUrl}fm-send-newsletter-mail`,
            headers: {
              Accept: "application/json",
              "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
              Authorization: `Bearer ${userToken}`,
            },
          })
            .then((response) => {
              setIsSendNewsletterLoading(false);
              toast(response.data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            })
            .catch((error) => {
              setIsSendNewsletterLoading(false);
              navigate("/not-found");
            });
        }
      });
    } else {
      navigate("/not-found");
    }
  };

  return (
    <>
      <div className="container">
        <div className={`${!isGetSubscribedEmailsLoading ? "min-vh-100" : ""}`}>
          <div className="mb-2 mb-sm-4 mb-lg-5 mt-3">
            <div className="d-flex align-items-lg-center justify-content-between mb-3 flex-column flex-lg-row">
              <h1 className="fu-text-3-rem fu-text-3-rem-md fu-text-md-2-rem fu-text-sm-2-rem fu-text-1-8-rem-xs fu-text-18-px-xxs fu-text-16-px-xxs fu-fw-800 fu-text-white mb-3 mb-lg-0">SUBSCRIBED EMAILS</h1>
              <div className="d-flex flex-wrap align-items-center">
                {isSendNewsletterLoading ? (
                  <div className="position-relative ms-4 ms-lg-0 me-0 me-lg-4 order-last order-lg-first">
                    <LoadingCircle circleCont={30} icon={25} loader={30} dot={30} />
                  </div>
                ) : (
                  ""
                )}
                {userCan("send_newsletter_mail") ? (
                  <div className="me-3 my-1 my-sm-0">
                    <button className={`fu-btn-sm fu-btn-gray ${isSendNewsletterLoading ? "disabled" : ""}`} disabled={isSendNewsletterLoading ? true : false} onClick={() => sendNewsletterToEmails()}>
                      <span className="btn-bg"></span>
                      Send Newsletter
                    </button>
                  </div>
                ) : (
                  ""
                )}
                {userCan("send_blog_mail") ? (
                  <div className="my-1 my-sm-0">
                    <Link to="/fm-subscribed-emails/send-post" className="fu-btn-sm fu-btn-gray">
                      <span className="btn-bg"></span>
                      Send Single Post
                    </Link>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="mt-4">
              <SubscribedEmailsFilration getSubscribedEmails={getSubscribedEmails} perPage={perPage} orderBy={orderBy} orderType={orderType} />
            </div>
          </div>
          {!isGetSubscribedEmailsLoading ? (
            <>
              <div className="row py-3 d-none d-xxl-flex table-heading-brdr">
                <div className="col-md-1">
                  <h3 className="h6 mb-0 fu-text-white">#ID</h3>
                </div>
                <div className="col-md-4">
                  <h3 className="h6 mb-0 fu-text-white">KEY</h3>
                </div>
                <div className="col-md-3">
                  <h3 className="h6 mb-0 fu-text-white">SUBSCRIBED EMAIL</h3>
                </div>
                <div className="col-md-2">
                  <h3 className="h6 mb-0 fu-text-white">SUBSCRIBED AT</h3>
                </div>
                <div className="col-md-2">
                  <h3 className="h6 mb-0 fu-text-white">OPERATIONS</h3>
                </div>
              </div>
              <div className="table-heading-brdr mt-4 mt-sm-0 d-xxl-none"></div>
              {isPaginateLoading ? (
                <div className="d-flex justify-content-center mt-5">
                  <div className="position-relative mt-5">
                    <LoadingCircle circleCont={85} icon={80} loader={85} dot={85} />
                  </div>
                </div>
              ) : (
                <>
                  {subscribedEmails.data && subscribedEmails.data.length > 0 ? (
                    subscribedEmails.data.map((subscribedEmail, index) => <SubscribedEmailRow key={index} meta={subscribedEmails.meta} subscribedEmail={subscribedEmail} index={index} getSubscribedEmails={getSubscribedEmails} perPage={perPage} pageNum={pageNum} orderBy={orderBy} orderType={orderType} />)
                  ) : subscribedEmails.data && subscribedEmails.data.length === 0 && filtrationDetails.search_query === "" && !isFiltrationLoading ? (
                    <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                      <div>
                        <h2 className="fu-text-3-rem fu-text-yellow">
                          <i className="bi bi-database-add"></i>
                        </h2>
                      </div>
                      <div>
                        <h3 className="h5 fu-text-light-gray">There Are No Subscribed Emails</h3>
                      </div>
                    </div>
                  ) : !isFiltrationLoading ? (
                    <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                      <div>
                        <h2 className="fu-text-3-rem fu-text-yellow">
                          <i className="bi bi-search"></i>
                        </h2>
                      </div>
                      <div>
                        <h3 className="h5 fu-text-light-gray line-clamp-2">There are no subscribed emails matching your search</h3>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="mt-5">{subscribedEmails ? subscribedEmails.meta && subscribedEmails.meta.last_page !== 1 ? <Pagination pages={subscribedEmails.meta.links} pagination={subscribedEmails.meta} getItems={getSubscribedEmails} pathName={"fm-subscribed-emails"} filtrationDetails={filtrationDetails} setIsPaginateLoading={setIsPaginateLoading} setPageNum={setPageNum} per_page={perPage} order_by={orderBy} order_type={orderType} /> : "" : ""}</div>
                </>
              )}
            </>
          ) : (
            <LoadingScreen />
          )}
        </div>
      </div>
    </>
  );
};

export default SubscribedEmails;
