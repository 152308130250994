import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Reveal from "../Reveal/Reveal";
import { fmDataContext } from "../../Context/FmData";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import userCan from "../../helpers/userCan";
import Swal from "sweetalert2/dist/sweetalert2.all.min";
import "@sweetalert2/theme-dark/dark.min.css";
import { authenticationContext } from "../../Context/AuthContext";
import { fmLabelsContext } from "../../Context/LabelsContext";

const LabelRow = (props) => {
  let { meta, label, getLabels, perPage, pageNum, orderBy, orderType } = props;
  let { baseUrl, navigate, editItemExistence, goToBlogDetails } = useContext(fmDataContext);
  let { filtrationDetails } = useContext(fmLabelsContext);
  let { user } = useContext(authenticationContext);

  let [isSoftDeleteLoading, setIsSoftDeleteLoading] = useState(false);
  let [isForceDeleteLoading, setIsForceDeleteLoading] = useState(false);
  let [isRestoreLoading, setIsRestoreLoading] = useState(false);

  const softDeleteLabel = (reqMethod, pathName, headers, setLoading) => {
    if (userCan("fm_label_soft_delete")) {
      Swal.fire({
        title: "Are you sure?",
        html: `<p class="mb-0">You will delete <span class="fu-text-light-gray">${label.label_name}</span></p>`,
        icon: "warning",
        showCancelButton: true,
        customClass: {
          cancelButton: "popup-btn",
          confirmButton: "popup-btn",
        },
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              getLabels(
                "GET",
                `${baseUrl}fm-labels`,
                {
                  page_num: meta.from === meta.to && meta.last_page > 1 ? meta.current_page - 1 : meta.last_page < pageNum ? 1 : pageNum,
                  per_page: perPage,
                  order_type: orderType,
                  order_by: orderBy,
                  ...filtrationDetails,
                },
                setLoading,
                `${label.label_name} Deleted Successfully`
              );
            })
            .catch((error) => {
              navigate("/not-found");
            });
        }
      });
    } else {
      navigate("/not-found");
    }
  };

  const restoreLabel = (reqMethod, pathName, headers, setLoading) => {
    if (userCan("fm_label_restore_trashed")) {
      Swal.fire({
        title: "Are you sure?",
        html: `<p class="mb-0">You will restore <span class="fu-text-light-gray">${label.label_name}</span></p>`,
        icon: "question",
        showCancelButton: true,
        customClass: {
          cancelButton: "popup-btn",
          confirmButton: "popup-btn",
        },
        confirmButtonText: "Yes, restore it!",
      }).then((result) => {
        if (result.isConfirmed) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              getLabels(
                "GET",
                `${baseUrl}fm-labels`,
                {
                  page_num: meta.from === meta.to && meta.last_page > 1 ? meta.current_page - 1 : meta.last_page < pageNum ? 1 : pageNum,
                  per_page: perPage,
                  order_type: orderType,
                  order_by: orderBy,
                  ...filtrationDetails,
                },
                setLoading,
                `${label.label_name} Restored Successfully`
              );
            })
            .catch((error) => {
              navigate("/not-found");
            });
        }
      });
    } else {
      navigate("/not-found");
    }
  };

  const forceDeleteLabel = (reqMethod, pathName, headers, setLoading) => {
    if (userCan("fm_label_force_delete")) {
      Swal.fire({
        title: "Are you sure?",
        html: `<p class="mb-0">You will delete <span class="fu-text-light-gray">${label.label_name}</span> permanently</p><p class="mb-0">You won't be able to revert this!</p>`,
        icon: "warning",
        showCancelButton: true,
        customClass: {
          cancelButton: "popup-btn",
          confirmButton: "popup-btn",
        },
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              getLabels(
                "GET",
                `${baseUrl}fm-labels`,
                {
                  page_num: meta.from === meta.to && meta.last_page > 1 ? meta.current_page - 1 : meta.last_page < pageNum ? 1 : pageNum,
                  per_page: perPage,
                  order_type: orderType,
                  order_by: orderBy,
                  ...filtrationDetails,
                },
                setLoading,
                `${label.label_name} Deleted Permanently Successfully`
              );
            })
            .catch((error) => {
              navigate("/not-found");
            });
        }
      });
    } else {
      navigate("/not-found");
    }
  };

  return (
    <Reveal>
      <div className="row py-3 align-items-center row-brdr">
        {/* id */}
        <div className="col-12 col-xxl-1 order-2 order-xxl-first">
          <h4 className="h6 mb-0 my-lg-0 fu-text-white row-info">
            <span className="fw-bold d-d-inline-block d-xxl-none me-2">#ID:</span>
            {label._id}
          </h4>
        </div>
        {/* key */}
        <div className="col-12 col-xxl-4 order-3 order-xxl-1">
          <h4 className="h6 mb-0 my-xxl-0 fu-text-white row-info">
            <span className="fw-bold d-d-inline-block d-xxl-none me-2">#KEY:</span>
            {label.label_key}
          </h4>
        </div>
        {/* image & name */}
        <div className="col-12 col-xxl-3 order-first order-xxl-2">
          <div className="d-flex align-items-center my-xxl-0 mouse-pointer mb-3 mt-2" onClick={() => goToBlogDetails(`/fm-labels/${label.label_key}`, label.label_deleted_at ? "1" : "0")}>
            <div className="table-item-img-container">
              <img src={label.label_image} alt="label" className="w-100 rounded-circle" />
            </div>
            <h4 className="h6 mb-0 ms-2 px-2 fu-text-white line-clamp-1">{label.label_name}</h4>
          </div>
        </div>
        {/* operation */}
        <div className="col-12 col-xxl-4 order-4 order-xxl-3">
          <div className="d-flex align-items-center flex-wrap justify-content-center justify-content-xxl-start mt-3 mt-xxl-0">
            <div className="me-2 mb-2 mb-xxl-0">
              <Link to={`/fm-labels/${label.label_key}?archived=${label.label_deleted_at ? "1" : "0"}`} className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`} disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                <span className="btn-bg"></span>
                Show
              </Link>
            </div>
            {label.label_deleted_at === null ? (
              userCan("fm_label_update") ? (
                <div className="me-2 mb-2 mb-xxl-0">
                  <Link to={`/fm-labels/update/${label.label_key}`} className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`} disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                    <span className="btn-bg"></span>
                    Edit
                  </Link>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {label.label_deleted_at === null ? (
              userCan("fm_label_soft_delete") ? (
                <div className="me-2 mb-2 mb-xxl-0">
                  <button
                    className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`}
                    onClick={() =>
                      softDeleteLabel(
                        "DELETE",
                        `${baseUrl}fm-labels/delete/${label.label_key}`,
                        {
                          Accept: "application/json",
                          "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                          Authorization: `Bearer ${user.accessToken}`,
                        },
                        setIsSoftDeleteLoading
                      )
                    }
                    disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                    <span className="btn-bg"></span>
                    Delete
                  </button>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {label.label_deleted_at !== null ? (
              userCan("fm_label_restore_trashed") ? (
                <div className="me-2 mb-2 mb-xxl-0">
                  <button
                    className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`}
                    onClick={() =>
                      restoreLabel(
                        "PATCH",
                        `${baseUrl}fm-labels/restore/${label.label_key}`,
                        {
                          Accept: "application/json",
                          "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                          Authorization: `Bearer ${user.accessToken}`,
                        },
                        setIsRestoreLoading
                      )
                    }
                    disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                    <span className="btn-bg"></span>
                    Restore
                  </button>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {userCan("fm_label_force_delete") ? (
              <div className="me-2 mb-2 mb-xxl-0">
                <button
                  className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`}
                  onClick={() =>
                    forceDeleteLabel(
                      "DELETE",
                      `${baseUrl}fm-labels/force-delete/${label.label_key}`,
                      {
                        Accept: "application/json",
                        "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                        Authorization: `Bearer ${user.accessToken}`,
                      },
                      setIsForceDeleteLoading
                    )
                  }
                  disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                  <span className="btn-bg"></span>
                  Force Delete
                </button>
              </div>
            ) : (
              ""
            )}

            {isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? (
              <div className="position-relative ms-2 mb-2 mb-xxl-0">
                <LoadingCircle circleCont={30} icon={25} loader={30} dot={30} />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </Reveal>
  );
};

export default LabelRow;
