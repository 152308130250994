import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Reveal from "../Reveal/Reveal";
import { fmDataContext } from "../../Context/FmData";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import userCan from "../../helpers/userCan";
import Swal from "sweetalert2/dist/sweetalert2.all.min";
import "@sweetalert2/theme-dark/dark.min.css";
import { authenticationContext } from "../../Context/AuthContext";
import { fmReleasesContext } from "../../Context/ReleasesContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ReleaseRow = (props) => {
  let { meta, release, getReleases, perPage, pageNum, orderBy, orderType } = props;
  let { baseUrl, navigate, editItemExistence, goToBlogDetails } = useContext(fmDataContext);

  let { filtrationDetails } = useContext(fmReleasesContext);

  let { user } = useContext(authenticationContext);

  let [isSoftDeleteLoading, setIsSoftDeleteLoading] = useState(false);
  let [isForceDeleteLoading, setIsForceDeleteLoading] = useState(false);
  let [isRestoreLoading, setIsRestoreLoading] = useState(false);

  const softDeleteRelease = (reqMethod, pathName, headers, setLoading) => {
    if (userCan("fm_release_soft_delete")) {
      Swal.fire({
        title: "Are you sure?",
        html: `<p class="mb-0">You will delete <span class="fu-text-light-gray">${release.release_title}</span></p>`,
        icon: "warning",
        showCancelButton: true,
        customClass: {
          cancelButton: "popup-btn",
          confirmButton: "popup-btn",
        },
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          if (release.is_from_top_five) {
            toast("Unable to delete release in top releases list", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              progressStyle: { backgroundColor: "#fdb915" },
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          } else {
            editItemExistence(reqMethod, pathName, headers, setLoading)
              .then((response) => {
                getReleases(
                  "GET",
                  `${baseUrl}fm-releases`,
                  {
                    page_num: meta.from === meta.to && meta.last_page > 1 ? meta.current_page - 1 : meta.last_page < pageNum ? 1 : pageNum,
                    per_page: perPage,
                    order_type: orderType,
                    order_by: orderBy,
                    ...filtrationDetails,
                  },
                  setLoading,
                  `${release.release_title} Deleted Successfully`
                );
              })
              .catch((error) => {
                navigate("/not-found");
              });
          }
        }
      });
    } else {
      navigate("/not-found");
    }
  };

  const restoreRelease = (reqMethod, pathName, headers, setLoading) => {
    if (userCan("fm_release_restore_trashed")) {
      Swal.fire({
        title: "Are you sure?",
        html: `<p class="mb-0">You will restore <span class="fu-text-light-gray">${release.release_title}</span></p>`,
        icon: "question",
        showCancelButton: true,
        customClass: {
          cancelButton: "popup-btn",
          confirmButton: "popup-btn",
        },
        confirmButtonText: "Yes, restore it!",
      }).then((result) => {
        if (result.isConfirmed) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              getReleases(
                "GET",
                `${baseUrl}fm-releases`,
                {
                  page_num: meta.from === meta.to && meta.last_page > 1 ? meta.current_page - 1 : meta.last_page < pageNum ? 1 : pageNum,
                  per_page: perPage,
                  order_type: orderType,
                  order_by: orderBy,
                  ...filtrationDetails,
                },
                setLoading,
                `${release.release_title} Restored Successfully`
              );
            })
            .catch((error) => {
              navigate("/not-found");
            });
        }
      });
    } else {
      navigate("/not-found");
    }
  };

  const forceDeleteRelease = (reqMethod, pathName, headers, setLoading) => {
    if (userCan("fm_release_force_delete")) {
      Swal.fire({
        title: "Are you sure?",
        html: `<p class="mb-0">You will delete <span class="fu-text-light-gray">${release.release_title}</span> permanently</p><p class="mb-0">You won't be able to revert this!</p>`,
        icon: "warning",
        showCancelButton: true,
        customClass: {
          cancelButton: "popup-btn",
          confirmButton: "popup-btn",
        },
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          if (release.is_from_top_five) {
            toast("Unable to delete release in top releases list", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              progressStyle: { backgroundColor: "#fdb915" },
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          } else {
            editItemExistence(reqMethod, pathName, headers, setLoading)
              .then((response) => {
                getReleases(
                  "GET",
                  `${baseUrl}fm-releases`,
                  {
                    page_num: meta.from === meta.to && meta.last_page > 1 ? meta.current_page - 1 : meta.last_page < pageNum ? 1 : pageNum,
                    per_page: perPage,
                    order_type: orderType,
                    order_by: orderBy,
                    ...filtrationDetails,
                  },
                  setLoading,
                  `${release.release_title} Deleted Permanently Successfully`
                );
              })
              .catch((error) => {
                navigate("/not-found");
              });
          }
        }
      });
    } else {
      navigate("/not-found");
    }
  };

  return (
    <Reveal>
      <div className="row py-3 align-items-center row-brdr">
        {/* id */}
        <div className="col-12 col-xxl-1 order-2 order-xxl-first">
          <h4 className="h6 mb-0 my-xxl-0 fu-text-white row-info">
            <span className="fw-bold d-d-inline-block d-xxl-none me-2">#ID:</span>
            {release._id}
          </h4>
        </div>
        {/* key */}
        <div className="col-12 col-xxl-4 order-3 order-xxl-1">
          <h4 className="h6 mb-0 my-xxl-0 fu-text-white row-info">
            <span className="fw-bold d-d-inline-block d-xxl-none me-2">KEY:</span>
            {release.release_key}
          </h4>
        </div>
        {/* image & name */}
        <div className="col-12 col-xxl-3 order-first order-xxl-2">
          <div className="d-flex align-items-center mouse-pointer mb-3 mt-2 my-xxl-0" onClick={() => goToBlogDetails(`/fm-releases/${release.release_key}`, release.release_deleted_at ? "1" : "0")}>
            {release.release_image ? (
              <div className="square-table-item-img-container me-3">
                <img src={release.release_image} alt="release" className="w-100" />
              </div>
            ) : (
              ""
            )}
            {(release.is_pre_order || release.is_from_top_five) && (
              <div className="d-inline-flex align-items-center">
                {release.is_pre_order && (
                  <div className="pre-order-circle me-2" data-title="out soon">
                    <i className="bi bi-hourglass-split d-inline-flex"></i>
                  </div>
                )}
                {release.is_from_top_five && (
                  <div className="top-five-circle me-2" data-title="from top five releases">
                    <i className="bi bi-star-fill d-inline-flex"></i>
                  </div>
                )}
              </div>
            )}
            <h4 className="h6 mb-0 fu-text-white line-clamp-1">{release.release_title}</h4>
          </div>
        </div>
        {/* operation */}
        <div className="col-12 col-xxl-4 order-4 order-xxl-3">
          <div className="d-flex align-items-center flex-wrap flex-xxl-nowrap justify-content-center justify-content-xxl-start mt-3 mt-xxl-0">
            <div className="me-2 mb-2 mb-xxl-0">
              <Link to={`/fm-releases/${release.release_key}?archived=${release.release_deleted_at ? "1" : "0"}`} className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`} disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                <span className="btn-bg"></span>
                Show
              </Link>
            </div>
            {release.release_deleted_at === null ? (
              userCan("fm_release_update") ? (
                <div className="me-2 mb-2 mb-xxl-0">
                  <Link to={`/fm-releases/update/${release.release_key}`} className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`} disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                    <span className="btn-bg"></span>
                    Edit
                  </Link>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {release.release_deleted_at === null ? (
              userCan("fm_release_soft_delete") ? (
                <div className="me-2 mb-2 mb-xxl-0">
                  <button
                    className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`}
                    onClick={() =>
                      softDeleteRelease(
                        "DELETE",
                        `${baseUrl}fm-releases/delete/${release.release_key}`,
                        {
                          Accept: "application/json",
                          "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                          Authorization: `Bearer ${user.accessToken}`,
                        },
                        setIsSoftDeleteLoading
                      )
                    }
                    disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                    <span className="btn-bg"></span>
                    Delete
                  </button>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {release.release_deleted_at !== null ? (
              userCan("fm_release_restore_trashed") ? (
                <div className="me-2 mb-2 mb-xxl-0">
                  <button
                    className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`}
                    onClick={() =>
                      restoreRelease(
                        "PATCH",
                        `${baseUrl}fm-releases/restore/${release.release_key}`,
                        {
                          Accept: "application/json",
                          "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                          Authorization: `Bearer ${user.accessToken}`,
                        },
                        setIsRestoreLoading
                      )
                    }
                    disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                    <span className="btn-bg"></span>
                    Restore
                  </button>
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {userCan("fm_release_force_delete") ? (
              <div className="me-2 mb-2 mb-xxl-0">
                <button
                  className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`}
                  onClick={() =>
                    forceDeleteRelease(
                      "DELETE",
                      `${baseUrl}fm-releases/force-delete/${release.release_key}`,
                      {
                        Accept: "application/json",
                        "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                        Authorization: `Bearer ${user.accessToken}`,
                      },
                      setIsForceDeleteLoading
                    )
                  }
                  disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                  <span className="btn-bg"></span>
                  Force Delete
                </button>
              </div>
            ) : (
              ""
            )}

            {isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? (
              <div className="position-relative ms-3 mb-2 mb-xxl-0">
                <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </Reveal>
  );
};

export default ReleaseRow;
