import { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { authenticationContext } from "../../Context/AuthContext";
import jwtDecode from "jwt-decode";
import { useLocation } from "react-router-dom";
import sign from "jwt-encode";

const ValidateUser = ({ children }) => {
  let [mounted, setMounted] = useState(false);
  const isCalledRef = useRef(false);

  let encodedUserData = localStorage.getItem("userToken");
  let { authApiBaseUrl, setUser, setUserPermissions, navigate, saveUserData } = useContext(authenticationContext);
  const location = useLocation();

  let removeUserDataAndNavigate = () => {
    localStorage.removeItem("userToken");
    setUser(null);
    setUserPermissions([]);
    navigate("/login");
  };

  let validateUserData = async () => {
    try {
      var decodedUserData = jwtDecode(encodedUserData);
      await axios({
        method: "GET",
        url: `${authApiBaseUrl}logged-in-user-details`,
        headers: {
          Accept: "application/json",
          "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
          Authorization: `Bearer ${decodedUserData.accessToken}`,
        },
      })
        .then((response) => {
          if (response.data.data === "Unauthorized") {
            removeUserDataAndNavigate();
          } else {
            if (response.data.data.permissions === null || response.data.data.role_name === null) {
              removeUserDataAndNavigate();
            } else {
              let currentEncodedUserData = sign({ accessToken: decodedUserData.accessToken, currentUser: response.data.data }, "secret");
              localStorage.setItem("userToken", currentEncodedUserData);
              saveUserData();
            }
          }
        })
        .catch((error) => {
          removeUserDataAndNavigate();
        });
    } catch (error) {
      removeUserDataAndNavigate();
    }
  };

  useEffect(() => {
    if (!isCalledRef.current) {
      if (localStorage.getItem("userToken")) {
        validateUserData();
      } else {
        removeUserDataAndNavigate();
      }
    }
    setMounted(true);
    return () => {
      isCalledRef.current = true;
    };
  }, []);

  useEffect(() => {
    if (mounted) {
      if (localStorage.getItem("userToken")) {
        validateUserData();
      } else {
        removeUserDataAndNavigate();
      }
    }
  }, [location.pathname]);

  return children;
};

export default ValidateUser;
