import React, { createContext, useState } from "react";

export let fmPartnershipsContext = createContext(0);

const PartnershipsContext = ({ children }) => {
  let [partnershipMsg, setPartnershipMsg] = useState("");

  let [filtrationDetails, setFiltrationDetails] = useState({
    search_query: "",
    only_trashed: 0,
  });

  let [isFiltrationLoading, setIsFiltrationLoading] = useState(false);

  let fmPartnershipsLifting = {
    partnershipMsg,
    setPartnershipMsg,
    filtrationDetails,
    setFiltrationDetails,
    isFiltrationLoading,
    setIsFiltrationLoading,
  };

  return (
    <>
      <fmPartnershipsContext.Provider value={{ ...fmPartnershipsLifting }}>{children}</fmPartnershipsContext.Provider>
    </>
  );
};

export default PartnershipsContext;
