import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { fmDataContext } from "../../Context/FmData";
import Joi from "joi";
import { useParams } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { authenticationContext } from "../../Context/AuthContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";

import Select from "react-select";
import makeAnimated from "react-select/animated";

const UpdateArtist = () => {
  let [mounted, setMounted] = useState(false);

  let { artistId } = useParams();
  let { baseUrl, navigate, showData, globalCrumbs, setGlobalCrumbs, getAllDataWithoutPaginate } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  let [currentArtist, setCurrentArtist] = useState({});
  let [isArtistLoading, setIsArtistLoading] = useState(true);

  let [selectedImage, setSelectedImage] = useState({});

  let [joiErrorsList, setJoiErrorsList] = useState([]);
  let [imageInputError, setImageInputError] = useState(null);

  let [artistData, setArtistData] = useState({
    name: "",
    about: null,
    image: selectedImage,
    modify_user_id: null,
    verified: null,
    beatport_url: null,
    spotify_url: null,
    sound_cloud_url: null,
    youtube_url: null,
    youtube_music_url: null,
    apple_music_url: null,
    deezer_music_url: null,
    amazon_music_url: null,
    facebook_url: null,
    instagram_url: null,
    threads_url: null,
    x_url: null,
    tiktok_url: null,
    linkedin_url: null,
    discord_url: null,
    website_url: null,
    labels_ids: [],
  });

  let [isUpdateLoading, setIsUpdateLoading] = useState(false);

  let [updateResponse, setUpdateResponse] = useState({});

  const animatedComponents = makeAnimated();
  let labelKeysRef = useRef();
  let artistStatusRef = useRef();

  let [selectedLabels, setSelectedLabels] = useState([]);
  let [selectedArtistStatus, setSelectedArtistStatus] = useState(null);

  let [isGetLabelsLoading, setIsGetLabelsLoading] = useState(true);
  let [labels, setLabels] = useState([]);

  let [navigateTimeOut, setNavigateTimeOut] = useState("");

  const getArtist = (reqMethod, pathName, setLoading = setIsArtistLoading) => {
    showData(reqMethod, pathName, setLoading)
      .then((response) => {
        // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
        // if (response.data.data.artist_image.includes("via.placeholder.com")) {
        //   response.data.data.artist_image = response.data.data.artist_image.replace("http://127.0.0.1:8000/storage/", "");
        // }
        // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
        let artistResponse = response.data.data;

        setCurrentArtist(artistResponse);
        setArtistData({
          name: artistResponse.artist_name ? artistResponse.artist_name : "",
          about: artistResponse.artist_bio ? artistResponse.artist_bio.replaceAll("</br>", "\n") : null,
          image: selectedImage,
          modify_user_id: null,
          verified: artistResponse.is_verified ? 1 : 0,
          beatport_url: artistResponse.artist_streaming_platforms_links && artistResponse.artist_streaming_platforms_links.beatport_url ? artistResponse.artist_streaming_platforms_links.beatport_url : null,
          spotify_url: artistResponse.artist_streaming_platforms_links && artistResponse.artist_streaming_platforms_links.spotify_url ? artistResponse.artist_streaming_platforms_links.spotify_url : null,
          sound_cloud_url: artistResponse.artist_streaming_platforms_links && artistResponse.artist_streaming_platforms_links.sound_cloud_url ? artistResponse.artist_streaming_platforms_links.sound_cloud_url : null,
          youtube_url: artistResponse.artist_streaming_platforms_links && artistResponse.artist_streaming_platforms_links.youtube_url ? artistResponse.artist_streaming_platforms_links.youtube_url : null,
          youtube_music_url: artistResponse.artist_streaming_platforms_links && artistResponse.artist_streaming_platforms_links.youtube_music_url ? artistResponse.artist_streaming_platforms_links.youtube_music_url : null,
          apple_music_url: artistResponse.artist_streaming_platforms_links && artistResponse.artist_streaming_platforms_links.apple_music_url ? artistResponse.artist_streaming_platforms_links.apple_music_url : null,
          deezer_music_url: artistResponse.artist_streaming_platforms_links && artistResponse.artist_streaming_platforms_links.deezer_music_url ? artistResponse.artist_streaming_platforms_links.deezer_music_url : null,
          amazon_music_url: artistResponse.artist_streaming_platforms_links && artistResponse.artist_streaming_platforms_links.amazon_music_url ? artistResponse.artist_streaming_platforms_links.amazon_music_url : null,
          facebook_url: artistResponse.artist_social_media_links && artistResponse.artist_social_media_links.facebook_url ? artistResponse.artist_social_media_links.facebook_url : null,
          instagram_url: artistResponse.artist_social_media_links && artistResponse.artist_social_media_links.instagram_url ? artistResponse.artist_social_media_links.instagram_url : null,
          threads_url: artistResponse.artist_social_media_links && artistResponse.artist_social_media_links.threads_url ? artistResponse.artist_social_media_links.threads_url : null,
          x_url: artistResponse.artist_social_media_links && artistResponse.artist_social_media_links.x_url ? artistResponse.artist_social_media_links.x_url : null,
          tiktok_url: artistResponse.artist_social_media_links && artistResponse.artist_social_media_links.tiktok_url ? artistResponse.artist_social_media_links.tiktok_url : null,
          linkedin_url: artistResponse.artist_social_media_links && artistResponse.artist_social_media_links.linkedin_url ? artistResponse.artist_social_media_links.linkedin_url : null,
          discord_url: artistResponse.artist_social_media_links && artistResponse.artist_social_media_links.discord_url ? artistResponse.artist_social_media_links.discord_url : null,
          website_url: artistResponse.artist_social_media_links && artistResponse.artist_social_media_links.website_url ? artistResponse.artist_social_media_links.website_url : null,
          labels_ids: artistResponse.artist_labels ? artistResponse.artist_labels.map((label) => label._id) : [],
        });
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const getArtistData = (e) => {
    const artist = { ...artistData };
    artist[e.target.name] = e.target.value;
    setArtistData(artist);
  };

  const handleArtistStatus = () => {
    const artist = { ...artistData };
    artist.verified = selectedArtistStatus !== null && selectedArtistStatus.value !== null ? selectedArtistStatus.value : null;
    setArtistData(artist);
  };

  const handleLabelsSelect = () => {
    const artist = { ...artistData };
    artist.labels_ids = selectedLabels.map((label) => label.value);
    setArtistData(artist);
  };

  const getAllLabels = (reqMethod, pathName, params, setLoading = setIsGetLabelsLoading) => {
    getAllDataWithoutPaginate(reqMethod, pathName, params, setLoading)
      .then((response) => {
        setLabels(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const handleImageSelect = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const updateItem = async (e) => {
    e.preventDefault();
    if (userCan("fm_artist_update")) {
      artistData.modify_user_id = user.currentUser._id;

      const validationResult = validateUpdateArtistForm();
      let imageError = null;
      setUpdateResponse({});

      if (selectedImage && selectedImage.name) {
        artistData.image = selectedImage;
        if (!["image/png", "image/jpg", "image/jpeg", "image/gif"].includes(artistData.image.type)) {
          imageError = "File must be an Image and from These extensions (png, jpg, jpeg, gif)";
        } else if (artistData.image.size > 5 * 1024 * 1024) {
          imageError = "Image Size Must Be Less Than 5 MB";
        }
      } else {
        artistData.image = null;
      }

      if (validationResult.error || imageError) {
        validationResult.error ? setJoiErrorsList(validationResult.error.details) : setJoiErrorsList([]);
        setImageInputError(imageError);
      } else {
        setIsUpdateLoading(true);
        setImageInputError(null);
        setJoiErrorsList([]);
        artistData.about = artistData.about ? artistData.about.replaceAll("\n", "</br>") : null;
        await axios({
          method: "POST",
          url: `${baseUrl}fm-artists/update/${currentArtist.artist_key}`,
          data: artistData,
          params: {
            _method: "PUT",
          },
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
            Authorization: `Bearer ${user.accessToken}`,
          },
        })
          .then((response) => {
            artistData.about = artistData.about ? artistData.about.replaceAll("</br>", "\n") : null;
            setUpdateResponse(response.data);
            setIsUpdateLoading(false);

            if (response.data.msg && response.data.msg.includes("successfully")) {
              let timeOut = setTimeout(() => {
                setUpdateResponse({});
                navigate(`/fm-artists/${currentArtist.artist_key}?archived=0`);
              }, 4000);
              setNavigateTimeOut(timeOut);
              setArtistData({
                name: "",
                about: null,
                image: {},
                modify_user_id: null,
                verified: null,
                beatport_url: null,
                spotify_url: null,
                sound_cloud_url: null,
                youtube_url: null,
                youtube_music_url: null,
                apple_music_url: null,
                deezer_music_url: null,
                amazon_music_url: null,
                facebook_url: null,
                instagram_url: null,
                threads_url: null,
                x_url: null,
                tiktok_url: null,
                linkedin_url: null,
                discord_url: null,
                website_url: null,
                labels_ids: [],
              });
              setSelectedImage(null);
              e.target.reset();
              labelKeysRef.current.clearValue();
              artistStatusRef.current.clearValue();
              toast(`${response.data.msg}, navigating...`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                containerId: "update_done_toast",
              });
            } else if (response.data.msg && response.data.msg.includes("failed")) {
              toast(response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          })
          .catch((errors) => {
            navigate("/not-found");
          });
      }
    } else {
      navigate("/not-found");
    }
  };

  const validateUpdateArtistForm = () => {
    const scheme = Joi.object({
      name: Joi.string().min(1).max(255).required(),
      about: Joi.string().min(1).max(1500).allow(null, "").optional(),
      image: Joi.any(),
      modify_user_id: Joi.number(),
      verified: Joi.number().required().messages({
        "number.base": `"Artist Status" is a required field`,
        "number.empty": `"Artist Status" is a required field`,
        "any.required": `"Artist Status" is a required field`,
      }),
      beatport_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Beatport URL is not valid").allow(null, "").optional(),
      spotify_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Spotify URL is not valid").allow(null, "").optional(),
      sound_cloud_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Sound cloud URL is not valid").allow(null, "").optional(),
      youtube_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Youtube URL is not valid").allow(null, "").optional(),
      youtube_music_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Youtube Music URL is not valid").allow(null, "").optional(),
      apple_music_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Apple Music URL is not valid").allow(null, "").optional(),
      deezer_music_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Deezer URL is not valid").allow(null, "").optional(),
      amazon_music_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Amazon Music URL is not valid").allow(null, "").optional(),
      facebook_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Facebook URL is not valid").allow(null, "").optional(),
      instagram_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Instagram URL is not valid").allow(null, "").optional(),
      threads_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("threads URL is not valid").allow(null, "").optional(),
      x_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("X URL is not valid").allow(null, "").optional(),
      tiktok_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Tiktok URL is not valid").allow(null, "").optional(),
      linkedin_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Linkedin URL is not valid").allow(null, "").optional(),
      discord_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Discord URL is not valid").allow(null, "").optional(),
      website_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Website URL is not valid").allow(null, "").optional(),
      labels_ids: Joi.array().items(Joi.number()).allow(null).optional(),
    });
    return scheme.validate(artistData, { abortEarly: false });
  };

  useEffect(() => {
    getArtist("GET", `${baseUrl}fm-artist/${artistId}`, setIsArtistLoading);
    getAllLabels("GET", `${baseUrl}fm-all-labels`, null, setIsGetLabelsLoading);
    setMounted(true);
  }, []);

  useEffect(() => {
    return () => {
      if (mounted) {
        clearTimeout(navigateTimeOut);
      }
    };
  }, [navigateTimeOut]);

  useEffect(() => {
    if (mounted && currentArtist) {
      let globalCrumbsUpdated = globalCrumbs.map((crumb) => {
        if (crumb.name === artistId.toUpperCase()) {
          crumb.name = currentArtist.artist_name.toUpperCase();
        }
        return crumb;
      });
      setGlobalCrumbs(globalCrumbsUpdated);
      if (labelKeysRef.current && currentArtist.artist_labels) {
        labelKeysRef.current.setValue(currentArtist.artist_labels.map((label) => ({ value: label._id, label: label.label_name })));
      }

      if (artistStatusRef.current) {
        artistStatusRef.current.setValue(currentArtist.is_verified ? { value: 1, label: "Main Artist" } : { value: 0, label: "Guest Artist" });
      }
    }
  }, [currentArtist]);

  useEffect(() => {
    handleLabelsSelect();
  }, [selectedLabels]);

  useEffect(() => {
    handleArtistStatus();
  }, [selectedArtistStatus]);

  return (
    <>
      {isArtistLoading || isGetLabelsLoading ? (
        <LoadingScreen />
      ) : (
        <div className="container position-relative pt-3 pt-md-4">
          <div className="mb-3 mb-md-4">
            <h1 className="page-heading-title mb-0">Update Artist</h1>
          </div>
          <div className="py-2 mb-4 iu-row-heading create-submit-row">
            <h2 className="mb-0 row-title">Edit ( {currentArtist.artist_name} ) data</h2>
          </div>
          <div className="row">
            <div>
              <div>
                <Form onSubmit={updateItem}>
                  <Row>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="artistName">
                        <Form.Label className="fu-text-light-gray" data-title="required">
                          Artist Name
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Artist Name" className="fu-input" name="name" value={artistData.name ? artistData.name : ""} onInput={getArtistData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "name" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("n", "Artist n")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <Form.Group className="mb-3" controlId="artistImage">
                            <Form.Label className="fu-text-light-gray" data-title="required">
                              Artist Image
                              <span className="required-input mx-1" data-title="required">
                                *
                              </span>
                            </Form.Label>
                            <Form.Control type="file" placeholder="Choose Artist Image" className="fu-input" name="image" onInput={handleImageSelect} />

                            {imageInputError !== null ? <div className="alert alert-danger py-1 px-2 mt-2 mb-0">{imageInputError}</div> : ""}
                          </Form.Group>
                        </div>
                        <div className="ms-2 align-self-center align-self-lg-center align-self-xl-baseline">
                          <div className="table-item-img-container">
                            <img src={currentArtist.artist_image} alt="artist" className="w-100 rounded-circle" />
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="aboutArtist">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          About Artist
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <div className="textarea-container">
                          <Form.Control as="textarea" rows={4} className="fu-input" placeholder="Enter your artist bio" name="about" value={artistData.about ? artistData.about.replaceAll("</br>", "\n") : ""} onInput={getArtistData} autoComplete="on" />
                        </div>
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "about" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>

                    <Col lg={6}>
                      <div className="mb-3">
                        <div className="fu-text-light-gray w-fit-content mb-2" data-title="optional">
                          Artist Labels
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </div>
                        <div className="select-options">
                          <Select
                            ref={labelKeysRef}
                            classNames={{
                              control: () => "select-filtration",
                              valueContainer: ({ innerProps }) => {
                                innerProps.style = { display: "flex", flexWrap: "nowrap" };
                              },
                            }}
                            styles={{
                              multiValue: () => ({ overflow: "visible", width: "max-content", display: "inline-flex", alignItems: "center", backgroundColor: "#2f2f2f", margin: "0 2px", borderRadius: "3px", fontSize: "14px", padding: "0 0 0 2px" }),
                              multiValueLabel: () => ({ color: "#fdb915" }),
                              multiValueRemove: () => ({ color: "#a5a5a5", marginLeft: "3px", padding: "2px", display: "inline-flex", alignItems: "center", transition: "all 0.3s", ":hover": { color: "#fdb915" } }),
                            }}
                            placeholder="Select Artist Labels...."
                            name="artistLabels"
                            onChange={setSelectedLabels}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={labels.map((label) => ({ value: label.id, label: label.name }))}
                          />
                        </div>
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "labels_ids" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("_ids", "")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className="mb-3">
                        <div className="fu-text-light-gray w-fit-content mb-2" data-title="required">
                          Artist Status
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </div>
                        <div className="select-options">
                          <Select
                            ref={artistStatusRef}
                            classNames={{
                              control: () => "select-filtration",
                              option: ({ isSelected, innerProps }) => (isSelected ? (innerProps.style = { backgroundColor: "#fdb915", color: "#191919" }) : (innerProps.style = { backgroundColor: "#ffffff" })),
                            }}
                            placeholder="Select Artist Status...."
                            name="verified"
                            onChange={setSelectedArtistStatus}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            isClearable
                            options={[
                              { value: 1, label: "Main Artist" },
                              { value: 0, label: "Guest Artist" },
                            ]}
                          />
                        </div>
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "verified" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("verified", "Artist Status")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </div>
                    </Col>
                  </Row>
                  {/* ------------------------------------ */}

                  <Row>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="beatportUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Beatport URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Artist Beatport URL" className="fu-input" name="beatport_url" value={artistData.beatport_url ? artistData.beatport_url : ""} onInput={getArtistData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "beatport_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="spotifyUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Spotify URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Artist Spotify URL" className="fu-input" name="spotify_url" value={artistData.spotify_url ? artistData.spotify_url : ""} onInput={getArtistData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "spotify_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="soundCloudUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Sound Cloud URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Artist Sound Cloud URL" className="fu-input" name="sound_cloud_url" value={artistData.sound_cloud_url ? artistData.sound_cloud_url : ""} onInput={getArtistData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "sound_cloud_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="youtubeUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          YouTube URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Artist YouTube URL" className="fu-input" name="youtube_url" value={artistData.youtube_url ? artistData.youtube_url : ""} onInput={getArtistData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "youtube_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    {/* ------------------ */}
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="youTubeMusicUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          YouTube Music URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Artist YouTube Music URL" className="fu-input" name="youtube_music_url" value={artistData.youtube_music_url ? artistData.youtube_music_url : ""} onInput={getArtistData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "youtube_music_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="appleMusicUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Apple Music URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Artist Apple Music URL" className="fu-input" name="apple_music_url" value={artistData.apple_music_url ? artistData.apple_music_url : ""} onInput={getArtistData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "apple_music_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="deezerUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Deezer URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Artist Deezer URL" className="fu-input" name="deezer_music_url" value={artistData.deezer_music_url ? artistData.deezer_music_url : ""} onInput={getArtistData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "deezer_music_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="amazonMusicUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Amazon Music URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Artist Amazon Music URL" className="fu-input" name="amazon_music_url" value={artistData.amazon_music_url ? artistData.amazon_music_url : ""} onInput={getArtistData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "amazon_music_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    {/* ------------------------------------ */}
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="facebookUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          FaceBook URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Artist FaceBook URL" className="fu-input" name="facebook_url" value={artistData.facebook_url ? artistData.facebook_url : ""} onInput={getArtistData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "facebook_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="instagramUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Instagram URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Artist Instagram URL" className="fu-input" name="instagram_url" value={artistData.instagram_url ? artistData.instagram_url : ""} onInput={getArtistData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "instagram_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="threadsUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Threads URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Artist Threads URL" className="fu-input" name="threads_url" value={artistData.threads_url ? artistData.threads_url : ""} onInput={getArtistData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "threads_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="xUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          X URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Artist X URL" className="fu-input" name="x_url" value={artistData.x_url ? artistData.x_url : ""} onInput={getArtistData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "x_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="tiktokUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          TikTok URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Artist TikTok URL" className="fu-input" name="tiktok_url" value={artistData.tiktok_url ? artistData.tiktok_url : ""} onInput={getArtistData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "tiktok_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="linkedInUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          LinkedIn URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Artist LinkedIn URL" className="fu-input" name="linkedin_url" value={artistData.linkedin_url ? artistData.linkedin_url : ""} onInput={getArtistData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "linkedin_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="discordUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Discord URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Artist Discord URL" className="fu-input" name="discord_url" value={artistData.discord_url ? artistData.discord_url : ""} onInput={getArtistData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "discord_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3" controlId="websiteUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Website URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Artist Website URL" className="fu-input" name="website_url" value={artistData.website_url ? artistData.website_url : ""} onInput={getArtistData} autoComplete="on" />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "website_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  {updateResponse.errors
                    ? Object.keys(updateResponse.errors).map((key) =>
                        updateResponse.errors[key].map((error, index) => (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error}
                          </div>
                        ))
                      )
                    : ""}

                  <div className="d-flex align-items-center mt-4">
                    <div>
                      <Button className={`fu-btn fu-btn-gray ${isUpdateLoading ? "disabled" : ""}`} type="submit" disabled={isUpdateLoading ? true : false}>
                        <span className="btn-bg"></span>
                        Submit<i className="bi bi-send ms-2"></i>
                      </Button>
                    </div>
                    {isUpdateLoading ? (
                      <div className="position-relative ms-3">
                        <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateArtist;
