import React, { useContext } from "react";
import Reveal from "../Reveal/Reveal";
import { fmDataContext } from "../../Context/FmData";

const ReleaseCard = ({ release }) => {
  let { goToBlogDetails } = useContext(fmDataContext);
  return (
    <div className="h-100">
      <Reveal>
        <div className="p-4 h-100 release-container" onClick={() => goToBlogDetails(`/fm-releases/${release.release_key}`, release.release_deleted_at ? "1" : "0")}>
          <div className="release-bg" style={{ backgroundImage: `url(${release.release_image})` }}></div>
          {release.is_pre_order ? <span className="pre-order-label-container">PRE ORDER</span> : ""}

          <div className="release-img-container mb-2">
            {release.release_image ? <img src={release.release_image} alt="release" className="w-100 h-100" /> : ""}
            <div className="listen-now-layer">
              <span className="listen-text">LISTEN NOW</span>
            </div>
          </div>
          <div className="release-caption">
            <div>
              <div className="d-flex align-items-center mb-1">
                {release.is_from_top_five && (
                  <div className="position-relative z-index-high">
                    <div className="top-five-circle me-2" data-title="from top five releases">
                      <i className="bi bi-star-fill d-inline-flex"></i>
                    </div>
                  </div>
                )}
                <h3 className="mb-0 release-name">{release.release_title}</h3>
              </div>
              {release.release_label !== null ? <p className="mb-0 fu-channel-video-name">{release.release_label.label_name}</p> : ""}
              {release.release_artists !== null ? <p className="mb-0 release-artist"> {release.release_artists.map((artist, index) => `${artist.artist_name}${index < release.release_artists.length - 1 ? "," : ""} `)} </p> : ""}
            </div>
          </div>
        </div>
      </Reveal>
    </div>
  );
};

export default ReleaseCard;
