import React, { createContext, useState } from "react";

export let fmVideosContext = createContext(0);

const VideosContext = ({ children }) => {
  let [videoMsg, setVideoMsg] = useState("");

  let [filtrationDetails, setFiltrationDetails] = useState({
    search_query: "",
    label_keys: [],
    genre_keys: [],
    artist_keys: [],
    only_trashed: 0,
  });

  let [isFiltrationLoading, setIsFiltrationLoading] = useState(false);

  let fmVideosLifting = {
    videoMsg,
    setVideoMsg,
    filtrationDetails,
    setFiltrationDetails,
    isFiltrationLoading,
    setIsFiltrationLoading,
  };

  return (
    <>
      <fmVideosContext.Provider value={{ ...fmVideosLifting }}>{children}</fmVideosContext.Provider>
    </>
  );
};

export default VideosContext;
