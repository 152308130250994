import React, { useContext, useEffect, useRef, useState } from "react";
import { fmDataContext } from "../../Context/FmData";
import Pagination from "../Pagination/Pagination";
import { Link } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import ReleaseRow from "./ReleaseRow";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { fmReleasesContext } from "../../Context/ReleasesContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";
import ReleasesFiltration from "./ReleasesFiltration";

const Releases = () => {
  const isCalledRef = useRef(false);

  let { baseUrl, navigate, getData, getAllDataWithoutPaginate } = useContext(fmDataContext);

  let { releaseMsg, setReleaseMsg, filtrationDetails, isFiltrationLoading, setFiltrationDetails } = useContext(fmReleasesContext);

  let [releases, setReleases] = useState({});
  let [labels, setLabels] = useState([]);
  let [artists, setArtists] = useState([]);
  let [genres, setGenres] = useState([]);

  let [isGetMediaBasiesLoading, setIsGetMediaBasiesLoading] = useState(true);

  let [isGetReleasesLoading, setIsGetReleasesLoading] = useState(true);

  let [isPaginateLoading, setIsPaginateLoading] = useState(false);

  let [pageNum, setPageNum] = useState(1);

  let perPage = 20;
  let orderBy = "id";
  let orderType = "desc";

  const getReleases = (reqMethod, pathName, params, setLoading = setIsGetReleasesLoading, message = null) => {
    getData(reqMethod, pathName, params, setLoading)
      .then((response) => {
        // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
        // if (response.data.data) {
        //   response.data.data.forEach((release) => {
        //     if (release.release_image.includes("via.placeholder.com")) {
        //       release.release_image = release.release_image.replace("http://127.0.0.1:8000/storage/", "");
        //     }
        //   });
        // }
        // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
        setReleases(response.data);

        if (releaseMsg.length > 0) {
          toast(releaseMsg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
            onClose: () => setReleaseMsg(""),
          });
        }

        if (message) {
          toast(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }

        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const getMediaBasies = (reqMethod, pathName, params, setLoading = setIsGetMediaBasiesLoading) => {
    getAllDataWithoutPaginate(reqMethod, pathName, params, setLoading)
      .then((response) => {
        setLabels(response.data.labels);
        setArtists(response.data.artists);
        setGenres(response.data.genres);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    if (!isCalledRef.current) {
      getReleases(
        "GET",
        `${baseUrl}fm-releases`,
        {
          page_num: pageNum,
          per_page: perPage,
          order_type: orderType,
          order_by: orderBy,
        },
        setIsGetReleasesLoading
      );
      getMediaBasies("GET", `${baseUrl}fm-get-tracks-data`, null, setIsGetMediaBasiesLoading);
    }

    return () => {
      isCalledRef.current = true;
      setFiltrationDetails({
        search_query: "",
        pre_order: null,
        label_keys: [],
        genre_keys: [],
        artist_keys: [],
        top_five_releases: 0,
        only_trashed: 0,
      });
    };
  }, []);

  return (
    <>
      <div className="container">
        <div className={`${!isGetReleasesLoading && !isGetMediaBasiesLoading ? "min-vh-100" : ""}`}>
          <div className="mb-2 mb-sm-5">
            <div className="d-flex align-items-center justify-content-between mb-3 mt-3">
              <h1 className="page-heading-title mb-0">RELEASES</h1>
              {userCan("fm_release_create") ? (
                <div className="me-3">
                  <Link to="/fm-releases/create" className="fu-btn fu-btn-gray">
                    <span className="btn-bg"></span>
                    Create New
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="mt-4">
              <ReleasesFiltration labels={labels} artists={artists} genres={genres} getReleases={getReleases} perPage={perPage} orderBy={orderBy} orderType={orderType} />
            </div>
          </div>
          {!isGetMediaBasiesLoading && !isGetReleasesLoading ? (
            <>
              <div className="row py-3 d-none d-xxl-flex table-heading-brdr">
                <div className="col-md-1">
                  <h3 className="h6 mb-0 fu-text-white">#ID</h3>
                </div>
                <div className="col-md-4">
                  <h3 className="h6 mb-0 fu-text-white">KEY</h3>
                </div>
                <div className="col-md-3">
                  <h3 className="h6 mb-0 fu-text-white">RELEASE TITLE</h3>
                </div>
                <div className="col-md-4">
                  <h3 className="h6 mb-0 fu-text-white">OPERATIONS</h3>
                </div>
              </div>
              <div className="table-heading-brdr mt-4 mt-sm-0 d-xxl-none"></div>
              {isPaginateLoading ? (
                <div className="d-flex justify-content-center mt-5">
                  <div className="position-relative mt-5">
                    <LoadingCircle circleCont={85} icon={80} loader={85} dot={85} />
                  </div>
                </div>
              ) : (
                <>
                  {releases.data && releases.data.length > 0 ? (
                    releases.data.map((release, index) => <ReleaseRow key={index} meta={releases.meta} release={release} index={index} getReleases={getReleases} perPage={perPage} pageNum={pageNum} orderBy={orderBy} orderType={orderType} />)
                  ) : releases.data && releases.data.length === 0 && filtrationDetails.search_query === "" && filtrationDetails.label_keys.length === 0 && filtrationDetails.genre_keys.length === 0 && filtrationDetails.artist_keys.length === 0 && filtrationDetails.pre_order === null && filtrationDetails.top_five_releases === 0 && filtrationDetails.only_trashed === 0 && !isFiltrationLoading ? (
                    <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                      <div>
                        <h2 className="fu-text-3-rem fu-text-yellow">
                          <i className="bi bi-database-add"></i>
                        </h2>
                      </div>
                      <div>
                        <h3 className="h5 fu-text-light-gray">
                          There Are No Releases
                          {userCan("fm_release_create") ? (
                            <>
                              ,
                              <Link to="/fm-releases/create" className="link-dashed ms-2">
                                Create New
                              </Link>
                            </>
                          ) : (
                            ""
                          )}
                        </h3>
                      </div>
                    </div>
                  ) : !isFiltrationLoading ? (
                    <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                      <div>
                        <h2 className="fu-text-3-rem fu-text-yellow">
                          <i className="bi bi-search"></i>
                        </h2>
                      </div>
                      <div>
                        <h3 className="h5 fu-text-light-gray">There are no releases matching your search</h3>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="mt-5">{releases ? releases.meta && releases.meta.last_page !== 1 ? <Pagination pages={releases.meta.links} pagination={releases.meta} getItems={getReleases} pathName={"fm-releases"} filtrationDetails={filtrationDetails} setIsPaginateLoading={setIsPaginateLoading} setPageNum={setPageNum} per_page={perPage} order_by={orderBy} order_type={orderType} /> : "" : ""}</div>
                </>
              )}
            </>
          ) : (
            <LoadingScreen />
          )}
        </div>
      </div>
    </>
  );
};

export default Releases;
