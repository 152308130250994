import React, { useContext, useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { fmDataContext } from "../../Context/FmData";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { fmUsersContext } from "../../Context/UsersContext";
import userCan from "../../helpers/userCan";
import Swal from "sweetalert2/dist/sweetalert2.all.min";
import "@sweetalert2/theme-dark/dark.min.css";
import jwtDecode from "jwt-decode";

import { authenticationContext } from "../../Context/AuthContext";
import axios from "axios";
import { toast } from "react-toastify";

const UserDetails = () => {
  let [mounted, setMounted] = useState(false);

  let { userId } = useParams();
  let [params, setParams] = useSearchParams();

  let { baseUrl, navigate, showData, editItemExistence, globalCrumbs, setGlobalCrumbs } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  let [currentUser, setCurrentUser] = useState({});
  let onlyTrashed = params.get("archived");
  let [isCurrentUserLoading, setIsCurrentUserLoading] = useState(true);

  let [isSoftDeleteLoading, setIsSoftDeleteLoading] = useState(false);
  let [isForceDeleteLoading, setIsForceDeleteLoading] = useState(false);
  let [isRestoreLoading, setIsRestoreLoading] = useState(false);
  let [isLogoutLoading, setIsLogoutLoading] = useState(false);

  let { setUserMsg } = useContext(fmUsersContext);

  let userToken = jwtDecode(localStorage.getItem("userToken")).accessToken;

  const softDeleteUser = (reqMethod, pathName, headers, setLoading) => {
    if (userCan("fm_user_soft_delete")) {
      Swal.fire({
        title: "Are you sure?",
        html: `<p class="mb-0">You will delete <span class="fu-text-light-gray">${currentUser.data.name}</span></p>`,
        icon: "warning",
        showCancelButton: true,
        customClass: {
          cancelButton: "popup-btn fu-btn-sm",
          confirmButton: "popup-btn fu-btn-sm",
        },
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              if (response.data.msg && response.data.msg.includes("successfully")) {
                setUserMsg(`${currentUser.data.name} Deleted Successfully`);
              } else if (response.data.msg && response.data.msg.includes("Unable")) {
                setUserMsg(`Unable To Delete ${currentUser.data.name}`);
              }
              navigate("/fm-users");
            })
            .catch((error) => {
              navigate("/not-found");
            });
        }
      });
    } else {
      navigate("/not-found");
    }
  };

  const restoreUser = (reqMethod, pathName, headers, setLoading) => {
    if (userCan("fm_user_restore_trashed")) {
      Swal.fire({
        title: "Are you sure?",
        html: `<p class="mb-0">You will restore <span class="fu-text-light-gray">${currentUser.data.name}</span></p>`,
        icon: "question",
        showCancelButton: true,
        customClass: {
          cancelButton: "popup-btn fu-btn-sm",
          confirmButton: "popup-btn fu-btn-sm",
        },
        confirmButtonText: "Yes, restore it!",
      }).then((result) => {
        if (result.isConfirmed) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              setUserMsg(`${currentUser.data.name} Restored Successfully`);
              navigate("/fm-users");
            })
            .catch((error) => {
              navigate("/not-found");
            });
        }
      });
    } else {
      navigate("/not-found");
    }
  };

  const forceDeleteUser = (reqMethod, pathName, headers, setLoading) => {
    Swal.fire({
      title: "Are you sure?",
      html: `<p class="mb-0">You will delete <span class="fu-text-light-gray">${currentUser.data.name}</span> permanently</p><p class="mb-0">You won't be able to revert this!</p>`,
      icon: "warning",
      showCancelButton: true,
      customClass: {
        cancelButton: "popup-btn fu-btn-sm",
        confirmButton: "popup-btn fu-btn-sm",
      },
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        if (userCan("fm_user_force_delete")) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              if (response.data.msg && response.data.msg.includes("successfully")) {
                setUserMsg(`${currentUser.data.name} Deleted Permanently Successfully`);
              } else if (response.data.msg && response.data.msg.includes("Unable")) {
                setUserMsg(`Unable To Delete ${currentUser.data.name}`);
              }
              navigate("/fm-users");
            })
            .catch((error) => {
              navigate("/not-found");
            });
        } else {
          navigate("/not-found");
        }
      }
    });
  };

  const logoutCurrentUser = async (method, url, headers, setLoading) => {
    Swal.fire({
      title: "Are you sure?",
      html: `<p class="mb-0">You will logout <span class="fu-text-light-gray">${currentUser.data.name}</span></p>`,
      icon: "warning",
      showCancelButton: true,
      customClass: {
        cancelButton: "popup-btn fu-btn-sm",
        confirmButton: "popup-btn fu-btn-sm",
      },
      confirmButtonText: `Yes, logout ${currentUser.data.name}!`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoading(true);
        await axios({
          method,
          url,
          headers,
        })
          .then((response) => {
            if (response.data.msg && response.data.msg.includes("successfully")) {
              setLoading(false);
              currentUser.data.is_logged_in_now = response.data.logged_out_user.is_logged_in_now;
              toast(`${currentUser.data.name} Logged out successfully`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            } else {
              setLoading(false);
              toast(`faild to logout ${currentUser.data.name} `, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          })
          .catch((error) => {
            navigate("/not-found");
          });
      }
    });
  };

  const getUser = (reqMethod, pathName, setLoading = setIsCurrentUserLoading, headers, params) => {
    showData(reqMethod, pathName, setLoading, headers, params)
      .then((response) => {
        setCurrentUser(response.data);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    getUser(
      "GET",
      `${baseUrl}fm-user/${userId}`,
      setIsCurrentUserLoading,
      {
        Accept: "application/json",
        "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
        Authorization: `Bearer ${userToken}`,
      },
      {
        only_trashed: onlyTrashed,
      }
    );
    setMounted(true);
  }, []);

  useEffect(() => {
    getUser(
      "GET",
      `${baseUrl}fm-user/${userId}`,
      setIsCurrentUserLoading,
      {
        Accept: "application/json",
        "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
        Authorization: `Bearer ${userToken}`,
      },
      {
        only_trashed: onlyTrashed,
      }
    );
    setMounted(true);
  }, [userId]);

  useEffect(() => {
    if (mounted && currentUser.data) {
      let globalCrumbsUpdated = globalCrumbs.map((crumb) => {
        if (crumb.name === userId.toUpperCase()) {
          crumb.name = currentUser.data.name.toUpperCase();
        }
        return crumb;
      });
      setGlobalCrumbs(globalCrumbsUpdated);
    }
  }, [currentUser]);

  return (
    <>
      {!isCurrentUserLoading && user.currentUser ? (
        <>
          <div className="min-vh-100">
            <div className="container">
              <div className="d-flex align-items-center mb-1 mb-sm-3 mt-4">
                <div className="d-inline-flex me-2">{currentUser.data.is_logged_in_now ? <span className="d-inline-flex online-circle" data-title="online"></span> : <span className="d-inline-flex offline-circle" data-title="offline"></span>}</div>
                {currentUser.data.user_role === "super_admin" ? (
                  <div className="super-admin-circle ms-2" data-title="super admin">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="super-admin-crown">
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        <path d="M4 19H20M11.2929 5.70711L8.70711 8.2929C8.31658 8.68342 7.68342 8.68342 7.29289 8.2929L5.70711 6.70711C5.07714 6.07714 4 6.52331 4 7.41422V15C4 15.5523 4.44772 16 5 16H19C19.5523 16 20 15.5523 20 15V7.41421C20 6.52331 18.9229 6.07714 18.2929 6.70711L16.7071 8.2929C16.3166 8.68342 15.6834 8.68342 15.2929 8.2929L12.7071 5.70711C12.3166 5.31658 11.6834 5.31658 11.2929 5.70711Z" stroke="#fdb915" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                      </g>
                    </svg>
                  </div>
                ) : (
                  ""
                )}
                {currentUser.data.user_status && currentUser.data.user_status !== "active" ? (
                  <div className="blocked-user-circle ms-2" data-title="blocked user">
                    <svg viewBox="0 0 24 24" id="meteor-icon-kit__regular-blocked-circle" fill="none" xmlns="http://www.w3.org/2000/svg" className="blocked-user">
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                      <g id="SVGRepo_iconCarrier">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM16.2929 6.29289C16.6834 5.90237 17.3166 5.90237 17.7071 6.29289C18.0976 6.68342 18.0976 7.31658 17.7071 7.70711L7.70711 17.7071C7.31658 18.0976 6.68342 18.0976 6.29289 17.7071C5.90237 17.3166 5.90237 16.6834 6.29289 16.2929L16.2929 6.29289Z"
                          fill="#fdb915"></path>
                      </g>
                    </svg>
                  </div>
                ) : (
                  ""
                )}
                <h1 className="fu-text-2-rem fu-text-sm-1-5-rem fu-text-xs-1-2-rem fu-text-18-px-xxs fu-text-18-px-xxxs fu-fw-800 fu-text-white ms-2 mb-0">{currentUser.data.name}</h1>
              </div>
              <div className="py-2">
                <div className="d-flex flex-wrap align-items-center justify-content-start mb-4">
                  {currentUser.data.deleted_at === null ? (
                    userCan("fm_user_update") ? (
                      <div className="mx-2 my-1 my-sm-0">
                        <Link to={`/fm-users/update/${currentUser.data.key}`} className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading || isLogoutLoading ? "disabled" : ""}`} disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading || isLogoutLoading ? true : false}>
                          <span className="btn-bg"></span>
                          Edit
                        </Link>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {currentUser.data.deleted_at === null ? (
                    userCan("fm_user_soft_delete") ? (
                      <div className="mx-2 my-1 my-sm-0">
                        <button
                          className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading || isLogoutLoading ? "disabled" : ""}`}
                          onClick={() =>
                            softDeleteUser(
                              "DELETE",
                              `${baseUrl}fm-users/delete/${currentUser.data.key}`,
                              {
                                Accept: "application/json",
                                "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                                Authorization: `Bearer ${user.accessToken}`,
                              },
                              setIsSoftDeleteLoading
                            )
                          }
                          disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading || isLogoutLoading ? true : false}>
                          <span className="btn-bg"></span>
                          Delete
                        </button>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {currentUser.data.deleted_at !== null ? (
                    userCan("fm_user_restore_trashed") ? (
                      <div className="mx-2 my-1 my-sm-0">
                        <button
                          className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading || isLogoutLoading ? "disabled" : ""}`}
                          onClick={() =>
                            restoreUser(
                              "PATCH",
                              `${baseUrl}fm-users/restore/${currentUser.data.key}`,
                              {
                                Accept: "application/json",
                                "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                                Authorization: `Bearer ${user.accessToken}`,
                              },
                              setIsRestoreLoading
                            )
                          }
                          disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading || isLogoutLoading ? true : false}>
                          <span className="btn-bg"></span>
                          Restore
                        </button>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {userCan("fm_user_force_delete") ? (
                    <div className="mx-2 my-1 my-sm-0">
                      <button
                        className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading || isLogoutLoading ? "disabled" : ""}`}
                        onClick={() =>
                          forceDeleteUser(
                            "DELETE",
                            `${baseUrl}fm-users/force-delete/${currentUser.data.key}`,
                            {
                              Accept: "application/json",
                              "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                              Authorization: `Bearer ${user.accessToken}`,
                            },
                            setIsForceDeleteLoading
                          )
                        }
                        disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading || isLogoutLoading ? true : false}>
                        <span className="btn-bg"></span>
                        Force Delete
                      </button>
                    </div>
                  ) : (
                    ""
                  )}

                  {currentUser.data.deleted_at === null && currentUser.data.key !== user.currentUser.key && currentUser.data.is_logged_in_now ? (
                    userCan("logout_another_user") ? (
                      <div className="mx-2 my-1 my-sm-0">
                        <button
                          className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading || isLogoutLoading ? "disabled" : ""}`}
                          onClick={() =>
                            logoutCurrentUser(
                              "POST",
                              `${baseUrl}fm-users/logout-user/${currentUser.data.key}`,
                              {
                                Accept: "application/json",
                                "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                                Authorization: `Bearer ${user.accessToken}`,
                              },
                              setIsLogoutLoading
                            )
                          }
                          disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading || isLogoutLoading ? true : false}>
                          <span className="btn-bg"></span>
                          Logout {currentUser.data.name}
                        </button>
                      </div>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}

                  {isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading || isLogoutLoading ? (
                    <div className="position-relative ms-4">
                      <LoadingCircle circleCont={28} icon={23} loader={28} dot={28} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {/* USER INFO */}
                <div className="user-tags">
                  <div className="mb-4 info-tags-container justify-content-start">
                    {currentUser.data._id ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">#ID: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentUser.data._id}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.key ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">#KEY: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentUser.data.key}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.email_address ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">EMAIL: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentUser.data.email_address}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.user_role ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">USER ROLE: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentUser.data.user_role}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.user_status ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">USER STATUS: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentUser.data.user_status}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.created_at ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED AT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{new Date(currentUser.data.created_at).toLocaleString("en-US", { timeZone: "Africa/Cairo", hour12: true })}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.updated_at ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED AT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{new Date(currentUser.data.updated_at).toLocaleString("en-US", { timeZone: "Africa/Cairo", hour12: true })}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.deleted_at ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">DELETED AT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{new Date(currentUser.data.deleted_at).toLocaleString("en-US", { timeZone: "Africa/Cairo", hour12: true })}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                {/* ITEMS COUNT */}
                <div className="user-tags">
                  <div className="mb-4 info-tags-container justify-content-start">
                    {currentUser.data.created_releases_count !== null ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED RELEASES COUNT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentUser.data.created_releases_count}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.updated_releases_count !== null ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED RELEASES COUNT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentUser.data.updated_releases_count}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.created_artists_count !== null ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED ARTISTS COUNT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentUser.data.created_artists_count}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.updated_artists_count !== null ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED ARTISTS COUNT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentUser.data.updated_artists_count}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.created_labels_count !== null ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED LABELS COUNT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentUser.data.created_labels_count}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.updated_labels_count !== null ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED LABELS COUNT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentUser.data.updated_labels_count}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.created_videos_count !== null ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED VIDEOS COUNT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentUser.data.created_videos_count}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.updated_videos_count !== null ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED VIDEOS COUNT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentUser.data.updated_videos_count}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.created_genres_count !== null ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED GENRES COUNT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentUser.data.created_genres_count}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.updated_genres_count !== null ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED GENRES COUNT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentUser.data.updated_genres_count}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.created_news_count !== null ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED NEWS COUNT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentUser.data.created_news_count}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.updated_news_count !== null ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED NEWS COUNT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentUser.data.updated_news_count}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.created_top_five_artists_count !== null ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED TOP 5 ARTISTS COUNT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentUser.data.created_top_five_artists_count}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.updated_top_five_artists_count !== null ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED TOP 5 ARTISTS COUNT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentUser.data.updated_top_five_artists_count}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.created_top_five_releases_count !== null ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED TOP 5 RELEASES COUNT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentUser.data.created_top_five_releases_count}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.updated_top_five_releases_count !== null ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED TOP 5 RELEASES COUNT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentUser.data.updated_top_five_releases_count}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.created_home_carousel_slides_count !== null ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED HOME SLIDES COUNT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentUser.data.created_home_carousel_slides_count}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.updated_home_carousel_slides_count !== null ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED HOME SLIDES COUNT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentUser.data.updated_home_carousel_slides_count}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.created_page_headers_count !== null ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED PAGE HEADERS COUNT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentUser.data.created_page_headers_count}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.updated_page_headers_count !== null ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED PAGE HEADERS COUNT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentUser.data.updated_page_headers_count}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.created_partnerships_count !== null ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED PARTNERSHIPS COUNT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentUser.data.created_partnerships_count}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.updated_partnerships_count !== null ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED PARTNERSHIPS COUNT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentUser.data.updated_partnerships_count}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.created_team_members_count !== null ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED TEAM MEMBERS COUNT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentUser.data.created_team_members_count}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {currentUser.data.updated_team_members_count !== null ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED TEAM MEMBERS COUNT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentUser.data.updated_team_members_count}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
};

export default UserDetails;
