import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Reveal from "../Reveal/Reveal";
import { fmDataContext } from "../../Context/FmData";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import userCan from "../../helpers/userCan";
import Swal from "sweetalert2/dist/sweetalert2.all.min";
import "@sweetalert2/theme-dark/dark.min.css";
import { authenticationContext } from "../../Context/AuthContext";

const ContactMsgRow = (props) => {
  let { meta, contactMsg, getContactMsgs, perPage, pageNum, orderBy, orderType } = props;
  let { baseUrl, navigate, editItemExistence } = useContext(fmDataContext);

  let { user } = useContext(authenticationContext);

  let [isForceDeleteLoading, setIsForceDeleteLoading] = useState(false);

  const forceDeleteContactMsg = (reqMethod, pathName, headers, setLoading) => {
    if (userCan("fm_contact_message_force_delete")) {
      Swal.fire({
        title: "Are you sure?",
        html: `<p class="mb-0">You will delete <span class="fu-text-light-gray">${contactMsg.user_first_name} ${contactMsg.user_last_name}</span> message permanently</p><p class="mb-0">You won't be able to revert this!</p>`,
        icon: "warning",
        showCancelButton: true,
        customClass: {
          cancelButton: "popup-btn",
          confirmButton: "popup-btn",
        },
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              getContactMsgs(
                "GET",
                `${baseUrl}fm-contact-messages`,
                {
                  page_num: meta.from === meta.to && meta.last_page > 1 ? meta.current_page - 1 : meta.last_page < pageNum ? 1 : pageNum,
                  per_page: perPage,
                  order_type: orderType,
                  order_by: orderBy,
                },
                setLoading,
                `${contactMsg.user_first_name} ${contactMsg.user_last_name} Message Deleted Permanently Successfully`
              );
            })
            .catch((error) => {
              navigate("/not-found");
            });
        }
      });
    } else {
      navigate("/not-found");
    }
  };

  return (
    <Reveal>
      <div className="row py-3 align-items-center row-brdr">
        {/* id */}
        <div className="col-12 col-xxl-1 order-2 order-xxl-first">
          <h4 className="h6 mb-0 my-xxl-0 fu-text-white row-info">
            <span className="fw-bold d-d-inline-block d-xxl-none me-2">#ID:</span>
            {contactMsg._id}
          </h4>
        </div>
        {/* sender name */}
        <div className="col-12 col-xxl-2 order-3 order-xxl-1">
          <h4 className="h6 mb-0 my-xxl-0 fu-text-white row-info">
            <span className="fw-bold d-d-inline-block d-xxl-none me-2">SENDER NAME:</span>
            {contactMsg.user_first_name} {contactMsg.user_last_name}
          </h4>
        </div>
        {/* sender email */}
        <div className="col-12 col-xxl-3 order-first order-xxl-2">
          <div className="d-flex align-items-center my-2 my-lg-0">
            <h4 className="h6 mb-0 fu-text-white row-info">
              <span className="fw-bold d-d-inline-block d-xxl-none me-2">SENDER EMAIL:</span>
              {contactMsg.user_email_address}
            </h4>
          </div>
        </div>
        {/* sent at */}
        <div className="col-12 col-xxl-3 order-4 order-xxl-3">
          <h4 className="h6 mb-0 my-xxl-0 fu-text-white row-info">
            <span className="fw-bold d-d-inline-block d-lg-none me-2">SENT AT:</span>
            {new Date(contactMsg.sent_at).toLocaleString("en-US", { timeZone: "Africa/Cairo", hour12: true })}
          </h4>
        </div>
        {/* operation */}
        <div className="col-12 col-xxl-3 order-last order-xxl-last">
          <div className="d-flex align-items-center flex-wrap justify-content-center justify-content-xxl-start mt-3 mt-xxl-0">
            {userCan("fm_contact_message_show_one") ? (
              <div className="me-2 mb-2 mb-xxl-0">
                <Link to={`/fm-contact-messages/${contactMsg.key}`} className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading ? "disabled" : ""}`} disabled={isForceDeleteLoading ? true : false}>
                  <span className="btn-bg"></span>
                  Show
                </Link>
              </div>
            ) : (
              ""
            )}
            {userCan("fm_contact_message_force_delete") ? (
              <div className="me-2 mb-2 mb-xxl-0">
                <button
                  className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading ? "disabled" : ""}`}
                  onClick={() =>
                    forceDeleteContactMsg(
                      "DELETE",
                      `${baseUrl}fm-contact-messages/force-delete/${contactMsg.key}`,
                      {
                        Accept: "application/json",
                        "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                        Authorization: `Bearer ${user.accessToken}`,
                      },
                      setIsForceDeleteLoading
                    )
                  }
                  disabled={isForceDeleteLoading ? true : false}>
                  <span className="btn-bg"></span>
                  Delete
                </button>
              </div>
            ) : (
              ""
            )}

            {isForceDeleteLoading ? (
              <div className="position-relative ms-2">
                <LoadingCircle circleCont={28} icon={23} loader={28} dot={28} />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </Reveal>
  );
};

export default ContactMsgRow;
