import React, { useContext, useEffect, useRef, useState } from "react";
import { fmDataContext } from "../../Context/FmData";
import { Link } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import TfArtistRow from "./TfArtistRow";
import { fmTfArtistsContext } from "../../Context/TfArtistsContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";

const TFArtists = () => {
  const isCalledRef = useRef(false);

  let { baseUrl, navigate, getData } = useContext(fmDataContext);

  let { tfArtistMsg, setTfArtistMsg } = useContext(fmTfArtistsContext);

  let [tfArtists, setTfArtists] = useState({});

  let [isGetTfArtistsLoading, setIsGetTfArtistsLoading] = useState(true);

  let orderBy = "order";
  let orderType = "asc";

  const getTfArtists = (reqMethod, pathName, params, setLoading = setIsGetTfArtistsLoading, message = null) => {
    getData(reqMethod, pathName, params, setLoading)
      .then((response) => {
        // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
        // if (response.data.data) {
        //   response.data.data.forEach((tfArtist) => {
        //     if (tfArtist.artist_data.artist_image.includes("via.placeholder.com")) {
        //       tfArtist.artist_data.artist_image = tfArtist.artist_data.artist_image.replace("http://127.0.0.1:8000/storage/", "");
        //     }
        //   });
        // }
        // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
        setTfArtists(response.data);
        setLoading(false);
        if (tfArtistMsg.length > 0) {
          toast(tfArtistMsg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
            onClose: () => setTfArtistMsg(""),
          });
        }
        if (message) {
          toast(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    if (!isCalledRef.current) {
      getTfArtists(
        "GET",
        `${baseUrl}fm-top-five-artists`,
        {
          order_type: orderType,
          order_by: orderBy,
        },
        setIsGetTfArtistsLoading
      );
    }
    return () => {
      isCalledRef.current = true;
    };
  }, []);

  return (
    <>
      <div className="container">
        <div className={`${!isGetTfArtistsLoading ? "min-vh-100" : ""}`}>
          <div className="mb-3 mb-sm-5 mt-4">
            <div className="d-flex align-items-center justify-content-between">
              <h1 className="fu-text-3-rem fu-text-sm-2-rem fu-text-1-8-rem-xs fu-text-18-px-xxs fu-text-16-px-xxs fu-text-white fu-fw-800 mb-0">TOP 5 ARTISTS</h1>
              {userCan("fm_top_five_artists_create") ? (
                <div className="me-3">
                  <Link to="/fm-top-five-artists/create" className="fu-btn fu-btn-gray">
                    <span className="btn-bg"></span>
                    Create New
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {!isGetTfArtistsLoading ? (
            <>
              <div className="row py-3 d-none d-xxl-flex table-heading-brdr">
                <div className="col-md-1">
                  <h3 className="h6 mb-0 fu-text-white">#ID</h3>
                </div>
                <div className="col-md-4">
                  <h3 className="h6 mb-0 fu-text-white">KEY</h3>
                </div>
                <div className="col-md-3">
                  <h3 className="h6 mb-0 fu-text-white">ARTIST NAME</h3>
                </div>
                <div className="col-md-1">
                  <h3 className="h6 mb-0 fu-text-white">ORDER</h3>
                </div>
                <div className="col-md-2">
                  <h3 className="h6 mb-0 fu-text-white">OPERATIONS</h3>
                </div>
              </div>
              <div className="table-heading-brdr mt-4 mt-sm-0 d-xxl-none"></div>
              {tfArtists.data && tfArtists.data.length > 0 ? (
                tfArtists.data.map((tfArtist, index) => (tfArtist ? <TfArtistRow key={index} tfArtist={tfArtist} index={index} getTfArtists={getTfArtists} orderBy={orderBy} orderType={orderType} /> : ""))
              ) : tfArtists.data && tfArtists.data.length === 0 ? (
                <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                  <div>
                    <h2 className="fu-text-3-rem fu-text-yellow">
                      <i className="bi bi-database-add"></i>
                    </h2>
                  </div>
                  <div>
                    <h3 className="h5 fu-text-light-gray">
                      There Are No Top Five Artists
                      {userCan("fm_top_five_artists_create") ? (
                        <>
                          ,
                          <Link to="/fm-top-five-artists/create" className="link-dashed ms-2">
                            Create New
                          </Link>
                        </>
                      ) : (
                        ""
                      )}
                    </h3>
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <LoadingScreen />
          )}
        </div>
      </div>
    </>
  );
};

export default TFArtists;
