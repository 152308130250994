import React, { useContext, useEffect, useRef, useState } from "react";
import { fmDataContext } from "../../Context/FmData";
import Pagination from "../Pagination/Pagination";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import TrackDemoRow from "./TrackDemoRow";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { fmTrackDemosContext } from "../../Context/TrackDemosContext";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";

const TrackDemos = () => {
  const isCalledRef = useRef(false);

  let { baseUrl, navigate, getData } = useContext(fmDataContext);

  let [trackDemos, setTrackDemos] = useState({});

  let [isGetTrackDemosLoading, setIsGetTrackDemosLoading] = useState(true);

  let [isPaginateLoading, setIsPaginateLoading] = useState(false);

  let [pageNum, setPageNum] = useState(1);

  let perPage = 20;
  let orderBy = "id";
  let orderType = "desc";

  let { trackDemoMsg, setTrackDemoMsg } = useContext(fmTrackDemosContext);

  let userToken = jwtDecode(localStorage.getItem("userToken")).accessToken;

  const getTrackDemos = (
    reqMethod,
    pathName,
    params,
    setLoading = setIsGetTrackDemosLoading,
    message = null,
    headers = {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
      Authorization: `Bearer ${userToken}`,
    }
  ) => {
    getData(reqMethod, pathName, params, setLoading, headers)
      .then((response) => {
        setTrackDemos(response.data);
        setLoading(false);

        if (trackDemoMsg.length > 0) {
          toast(trackDemoMsg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
            onClose: () => setTrackDemoMsg(""),
          });
        }

        if (message) {
          toast(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    if (!isCalledRef.current) {
      getTrackDemos(
        "GET",
        `${baseUrl}fm-track-demos`,
        {
          page_num: pageNum,
          per_page: perPage,
          order_type: orderType,
          order_by: orderBy,
        },
        setIsGetTrackDemosLoading
      );
    }
    return () => {
      isCalledRef.current = true;
    };
  }, []);

  return (
    <>
      <div className="container">
        <div className={`${!isGetTrackDemosLoading ? "min-vh-100" : ""}`}>
          <div className="mb-2 mb-sm-4 mb-lg-4 mt-3">
            <div className="d-flex align-items-center justify-content-between mb-0">
              <h1 className="fu-text-3-rem fu-text-3-rem-md fu-text-md-2-rem fu-text-sm-2-rem fu-text-1-8-rem-xs fu-text-18-px-xxs fu-text-16-px-xxs fu-fw-800 fu-text-white mb-0">TRACK DEMOS</h1>
            </div>
          </div>
          {!isGetTrackDemosLoading ? (
            <>
              <div className="row py-3 d-none d-xxl-flex table-heading-brdr">
                <div className="col-md-1">
                  <h3 className="h6 mb-0 fu-text-white">#ID</h3>
                </div>
                <div className="col-md-2">
                  <h3 className="h6 mb-0 fu-text-white">SENDER NAME</h3>
                </div>
                <div className="col-md-3">
                  <h3 className="h6 mb-0 fu-text-white">SENDER EMAIL</h3>
                </div>
                <div className="col-md-3">
                  <h3 className="h6 mb-0 fu-text-white">SENT AT</h3>
                </div>
                <div className="col-md-3">
                  <h3 className="h6 mb-0 fu-text-white">OPERATIONS</h3>
                </div>
              </div>
              <div className="table-heading-brdr mt-4 mt-sm-0 d-xxl-none"></div>
              {isPaginateLoading ? (
                <div className="d-flex justify-content-center mt-5">
                  <div className="position-relative mt-5">
                    <LoadingCircle circleCont={85} icon={80} loader={85} dot={85} />
                  </div>
                </div>
              ) : (
                <>
                  {trackDemos.data && trackDemos.data.length > 0 ? (
                    trackDemos.data.map((trackDemo, index) => <TrackDemoRow key={index} meta={trackDemos.meta} trackDemo={trackDemo} index={index} getTrackDemos={getTrackDemos} perPage={perPage} pageNum={pageNum} orderBy={orderBy} orderType={orderType} />)
                  ) : (
                    <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                      <div>
                        <h2 className="fu-text-3-rem fu-text-yellow">
                          <i className="bi bi-database-add"></i>
                        </h2>
                      </div>
                      <div>
                        <h3 className="h5 fu-text-light-gray">There Are No Track Demos</h3>
                      </div>
                    </div>
                  )}
                  <div className="mt-5">{trackDemos ? trackDemos.meta && trackDemos.meta.last_page !== 1 ? <Pagination pages={trackDemos.meta.links} pagination={trackDemos.meta} getItems={getTrackDemos} pathName={"fm-track-demos"} filtrationDetails={{}} setIsPaginateLoading={setIsPaginateLoading} setPageNum={setPageNum} per_page={perPage} order_by={orderBy} order_type={orderType} /> : "" : ""}</div>
                </>
              )}
            </>
          ) : (
            <LoadingScreen />
          )}
        </div>
      </div>
    </>
  );
};

export default TrackDemos;
