import sign from "jwt-encode";
import React, { useContext, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { authenticationContext } from "../../Context/AuthContext";
import Joi from "joi";
import axios from "axios";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { toast } from "react-toastify";

const LoginPage = () => {
  let { navigate, authApiBaseUrl, saveUserData } = useContext(authenticationContext);

  let [isLoginLoading, setIsLoginLoading] = useState(false);

  let [loginResponse, setLoginResponse] = useState({});

  let [joiErrorsList, setJoiErrorsList] = useState([]);

  let [userData, setUserData] = useState({
    email: "",
    password: "",
  });

  function getUserDataDate(e) {
    let user = { ...userData };
    user[e.target.name] = e.target.value;
    setUserData(user);
  }

  function validateLoginForm() {
    let scheme = Joi.object({
      email: Joi.string().required(),
      password: Joi.string().required(),
    });
    return scheme.validate(userData, { abortEarly: false });
  }

  async function sendUserData(e) {
    e.preventDefault();

    setIsLoginLoading(true);
    let validateResult = validateLoginForm();

    if (validateResult.error) {
      setJoiErrorsList(validateResult.error.details);
      setIsLoginLoading(false);
    } else {
      setJoiErrorsList([]);
      setLoginResponse({});
      await axios({
        method: "POST",
        url: `${authApiBaseUrl}fm-login`,
        data: userData,
        headers: {
          Accept: "application/json",
          "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
        },
      })
        .then((response) => {
          if (response.data.message === "success") {
            let encodedUserData = sign({ accessToken: response.data.access_token, currentUser: response.data.current_user }, "secret");
            localStorage.setItem("userToken", encodedUserData);
            saveUserData();
            navigate("/");
            setIsLoginLoading(false);
            setLoginResponse({});
            setJoiErrorsList([]);
            e.target.reset();
          } else {
            setLoginResponse(response.data);
            setIsLoginLoading(false);
          }
        })
        .catch((error) => {
          setIsLoginLoading(false);
          setLoginResponse({});
          setJoiErrorsList([]);
          toast("Some thing wrong try again later", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        });
    }
  }

  return (
    <>
      <div className="container">
        <div className="row flex-column align-items-center justify-content-center min-vh-100">
          <div className="col-11 col-sm-12 col-md-11 col-lg-8">
            <h1 className="fu-text-3-rem fu-fw-800 fu-text-white mb-3">LOGIN</h1>
          </div>
          <div className="col-11 col-sm-12 col-md-11 col-lg-8">
            <Form onSubmit={sendUserData}>
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-3" controlId="email">
                    <Form.Label className="fu-text-light-gray" data-title="required">
                      Email
                      <span className="required-input mx-1" data-title="required">
                        *
                      </span>
                    </Form.Label>
                    <Form.Control type="text" placeholder="Enter Your Email" className="fu-input" name="email" onInput={getUserDataDate} autoComplete="on" />
                    {joiErrorsList.map((error, index) =>
                      error.path[0] === "email" ? (
                        <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                          {error.message}
                        </div>
                      ) : (
                        ""
                      )
                    )}
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="mb-3" controlId="labelName">
                    <Form.Label className="fu-text-light-gray" data-title="required">
                      Password
                      <span className="required-input mx-1" data-title="required">
                        *
                      </span>
                    </Form.Label>
                    <Form.Control type="password" placeholder="Enter Your Password" className="fu-input" name="password" onInput={getUserDataDate} />
                    {joiErrorsList.map((error, index) =>
                      error.path[0] === "password" ? (
                        <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                          {error.message}
                        </div>
                      ) : (
                        ""
                      )
                    )}
                  </Form.Group>
                </Col>
              </Row>
              {loginResponse.message && loginResponse.message.includes("success") ? <div className="alert alert-success py-1 px-2 mt-2 mb-0">{loginResponse.message}</div> : loginResponse.message ? <div className="alert alert-danger py-1 px-2 mt-2 mb-0">{loginResponse.message}</div> : ""}
              {loginResponse.errors
                ? Object.keys(loginResponse.errors).map((key) =>
                    loginResponse.errors[key].map((error, index) => (
                      <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                        {error}
                      </div>
                    ))
                  )
                : ""}
              <div className="d-flex align-items-center mt-3">
                <div>
                  <Button className={`fu-btn fu-btn-gray ${isLoginLoading ? "disabled" : ""}`} type="submit" disabled={isLoginLoading ? true : false}>
                    <span className="btn-bg"></span>
                    Submit<i className="bi bi-send ms-2"></i>
                  </Button>
                </div>
                {isLoginLoading ? (
                  <div className="position-relative ms-3">
                    <LoadingCircle circleCont={30} icon={25} loader={30} dot={30} />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
