import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Reveal from "../Reveal/Reveal";
import { fmDataContext } from "../../Context/FmData";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import userCan from "../../helpers/userCan";
import Swal from "sweetalert2/dist/sweetalert2.all.min";
import "@sweetalert2/theme-dark/dark.min.css";
import { authenticationContext } from "../../Context/AuthContext";
import Images from "../../images";

const TfArtistRow = (props) => {
  let { tfArtist, getTfArtists, orderBy, orderType } = props;
  let { baseUrl, navigate, editItemExistence } = useContext(fmDataContext);

  let { user } = useContext(authenticationContext);

  let [isForceDeleteLoading, setIsForceDeleteLoading] = useState(false);

  const removeTfArtist = (reqMethod, pathName, headers, setLoading) => {
    if (userCan("fm_top_five_artists_force_delete")) {
      Swal.fire({
        title: "Are you sure?",
        html: `<p class="mb-0">You will remove <span class="fu-text-light-gray">${tfArtist.additional_artist_name ? tfArtist.additional_artist_name : tfArtist.artist_data.artist_name}</span> </p><p class="mb-0">from top five artists list!</p>`,
        icon: "warning",
        showCancelButton: true,
        customClass: {
          cancelButton: "popup-btn",
          confirmButton: "popup-btn",
        },
        confirmButtonText: "Yes, remove!",
      }).then((result) => {
        if (result.isConfirmed) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              getTfArtists(
                "GET",
                `${baseUrl}fm-top-five-artists`,
                {
                  order_type: orderType,
                  order_by: orderBy,
                },
                setLoading,
                `${tfArtist.additional_artist_name ? tfArtist.additional_artist_name : tfArtist.artist_data.artist_name} Removed Successfully`
              );
            })
            .catch((error) => {
              navigate("/not-found");
            });
        }
      });
    } else {
      navigate("/not-found");
    }
  };

  return (
    <Reveal>
      <div className="row py-3 align-items-center row-brdr">
        {/* id */}
        <div className="col-12 col-xxl-1 order-2 order-xxl-first">
          <h4 className="h6 mb-0 my-xxl-0 fu-text-white row-info">
            <span className="fw-bold d-d-inline-block d-xxl-none me-2">#ID:</span>
            {tfArtist._id}
          </h4>
        </div>
        {/* key */}
        <div className="col-12 col-xxl-4 order-3 order-xxl-1">
          <h4 className="h6 mb-0 my-xxl-0 fu-text-white row-info line-clamp-1">
            <span className="fw-bold d-d-inline-block d-xxl-none me-2">KEY:</span>
            {tfArtist.key}
          </h4>
        </div>
        {/* image & name */}
        <div className="col-12 col-xxl-3 order-first order-xxl-2">
          <div className="d-flex align-items-center mouse-pointer mb-3 mt-2 my-xxl-0" onClick={() => navigate(`/fm-top-five-artists/${tfArtist.key}`)}>
            <div className="table-item-img-container me-3">
              <img src={tfArtist.artist_data.artist_image} alt="artist" className="w-100 rounded-circle" />
            </div>
            {(tfArtist.artist_data.is_verified || tfArtist.artist_data.is_from_top_five) && (
              <div className="d-inline-flex align-items-center">
                {tfArtist.artist_data.is_verified && (
                  <div className="verification-circle me-2" data-title="main artist">
                    <img src={Images.futurisingIcon} alt="company icon" className="futurising-icon" />
                  </div>
                )}
              </div>
            )}
            <h4 className="h6 mb-0 fu-text-white line-clamp-1">{tfArtist.additional_artist_name ? tfArtist.additional_artist_name : tfArtist.artist_data.artist_name}</h4>
          </div>
        </div>
        {/* order */}
        <div className="col-12 col-xxl-1 order-4 order-xxl-3">
          <h4 className="h6 mb-0 my-xxl-0 fu-text-white row-info">
            <span className="fw-bold d-d-inline-block d-xxl-none me-2">ORDER:</span>
            {tfArtist.artist_order}
          </h4>
        </div>
        {/* operation */}
        <div className="col-12 col-xxl-3 order-4 order-xxl-last">
          <div className="d-flex align-items-center flex-wrap justify-content-center justify-content-xxl-start mt-3 mt-xxl-0">
            <div className="me-2 mb-2 mb-xxl-0">
              <Link to={`/fm-top-five-artists/${tfArtist.key}`} className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading ? "disabled" : ""}`} disabled={isForceDeleteLoading ? true : false}>
                <span className="btn-bg"></span>
                Show
              </Link>
            </div>
            {userCan("fm_top_five_artists_update") ? (
              <div className="me-2 mb-2 mb-xxl-0">
                <Link to={`/fm-top-five-artists/update/${tfArtist.key}`} className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading ? "disabled" : ""}`} disabled={isForceDeleteLoading ? true : false}>
                  <span className="btn-bg"></span>
                  Edit
                </Link>
              </div>
            ) : (
              ""
            )}

            {userCan("fm_top_five_artists_force_delete") ? (
              <div className="me-2 mb-2 mb-xxl-0">
                <button
                  className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading ? "disabled" : ""}`}
                  onClick={() =>
                    removeTfArtist(
                      "DELETE",
                      `${baseUrl}fm-top-five-artists/delete/${tfArtist.key}`,
                      {
                        Accept: "application/json",
                        "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                        Authorization: `Bearer ${user.accessToken}`,
                      },
                      setIsForceDeleteLoading
                    )
                  }
                  disabled={isForceDeleteLoading ? true : false}>
                  <span className="btn-bg"></span>
                  Remove
                </button>
              </div>
            ) : (
              ""
            )}

            {isForceDeleteLoading ? (
              <div className="position-relative ms-2">
                <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </Reveal>
  );
};

export default TfArtistRow;
