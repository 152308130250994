import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { fmDataContext } from "../../Context/FmData";
import Joi from "joi";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { authenticationContext } from "../../Context/AuthContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const CreateVideo = () => {
  let { baseUrl, navigate, getAllDataWithoutPaginate } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  const animatedComponents = makeAnimated();
  let labelKeyRef = useRef();
  let artistKeysRef = useRef();
  let genreKeyRef = useRef();

  let [joiErrorsList, setJoiErrorsList] = useState([]);

  let [selectedLabelKey, setSelectedLabelKey] = useState(null);
  let [selectedArtistKeys, setSelectedArtistKeys] = useState([]);
  let [selectedGenreKey, setSelectedGenreKey] = useState(null);

  let [videoData, setVideoData] = useState({
    title: "",
    youtube_video_id: null,
    user_id: null,
    label_id: null,
    genre_id: null,
    artists_ids: [],
  });

  let [isCreateLoading, setIsCreateLoading] = useState(false);

  let [createResponse, setCreateResponse] = useState({});

  let [isGetMediaBasiesLoading, setIsGetMediaBasiesLoading] = useState(true);

  let [labels, setLabels] = useState([]);

  let [artists, setArtists] = useState([]);

  let [genres, setGenres] = useState([]);

  const getVideoData = (e) => {
    const video = { ...videoData };
    video[e.target.name] = e.target.value;
    setVideoData(video);
  };

  const handleLabelSelect = () => {
    const video = { ...videoData };
    video.label_id = selectedLabelKey !== null && selectedLabelKey.value !== null ? selectedLabelKey.value : null;
    setVideoData(video);
  };

  const handleArtistsSelect = () => {
    const video = { ...videoData };
    video.artists_ids = selectedArtistKeys.map((artist) => artist.value);
    setVideoData(video);
  };

  const handleGenreSelect = () => {
    const video = { ...videoData };
    video.genre_id = selectedGenreKey !== null && selectedGenreKey.value !== null ? selectedGenreKey.value : null;
    setVideoData(video);
  };

  const getMediaBasies = (reqMethod, pathName, params, setLoading = setIsGetMediaBasiesLoading) => {
    getAllDataWithoutPaginate(reqMethod, pathName, params, setLoading)
      .then((response) => {
        setLabels(response.data.labels);
        setArtists(response.data.artists);
        setGenres(response.data.genres);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const createItem = async (e) => {
    e.preventDefault();

    if (userCan("fm_video_create")) {
      videoData.user_id = user.currentUser._id;
      const validationResult = validateCreateVideoForm();
      setCreateResponse({});
      if (validationResult.error) {
        validationResult.error ? setJoiErrorsList(validationResult.error.details) : setJoiErrorsList([]);
      } else {
        setIsCreateLoading(true);
        setJoiErrorsList([]);
        await axios({
          method: "POST",
          url: `${baseUrl}fm-videos/create`,
          data: videoData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
            Authorization: `Bearer ${user.accessToken}`,
          },
        })
          .then((response) => {
            setCreateResponse(response.data);
            setIsCreateLoading(false);
            if (response.data.msg && response.data.msg.includes("successfully")) {
              setVideoData({
                title: "",
                youtube_video_id: null,
                user_id: null,
                label_id: null,
                genre_id: null,
                artists_ids: [],
              });
              e.target.reset();
              labelKeyRef.current.clearValue();
              artistKeysRef.current.clearValue();
              genreKeyRef.current.clearValue();
              setCreateResponse({});
              toast(response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            } else if (response.data.msg && response.data.msg.includes("failed")) {
              toast(response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          })
          .catch((errors) => {
            navigate("/not-found");
          });
      }
    } else {
      navigate("/not-found");
    }
  };

  const validateCreateVideoForm = () => {
    const scheme = Joi.object({
      title: Joi.string().min(1).max(255).required(),
      youtube_video_id: Joi.string().max(255).required(),
      user_id: Joi.number(),
      label_id: Joi.number().allow(null, "").optional(),
      genre_id: Joi.number().allow(null, "").optional(),
      artists_ids: Joi.array().items(Joi.number()).allow(null).optional(),
    });
    return scheme.validate(videoData, { abortEarly: false });
  };

  useEffect(() => {
    getMediaBasies("GET", `${baseUrl}fm-get-tracks-data`, null, setIsGetMediaBasiesLoading);
  }, []);

  useEffect(() => {
    handleLabelSelect();
  }, [selectedLabelKey]);

  useEffect(() => {
    handleArtistsSelect();
  }, [selectedArtistKeys]);

  useEffect(() => {
    handleGenreSelect();
  }, [selectedGenreKey]);

  return (
    <>
      {isGetMediaBasiesLoading ? (
        <LoadingScreen />
      ) : (
        <div className="container position-relative pt-3 pt-md-4">
          <div className="mb-3 mb-md-4">
            <h1 className="page-heading-title mb-0">Create Video</h1>
          </div>
          <div className="py-2 mb-4 iu-row-heading create-submit-row">
            <h2 className="mb-0 row-title">Submit New Video To Videos List</h2>
          </div>
          <div className="row">
            <div>
              <div>
                <Form onSubmit={createItem}>
                  <Row>
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="videoTitle">
                        <Form.Label className="fu-text-light-gray" data-title="required">
                          Video Title
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Video Title" className="fu-input" name="title" onInput={getVideoData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "title" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("t", "Video t")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>

                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="VideoYtId">
                        <div className="d-flex flex-wrap align-items-center">
                          <Form.Label className="fu-text-light-gray me-2" data-title="required">
                            YouTube Video ID
                            <span className="required-input mx-1" data-title="required">
                              *
                            </span>
                          </Form.Label>
                          <span className="fu-text-light-gray d-inline-flex mb-2 form-explain">
                            <i className="me-2">
                              <strong>EX:</strong>
                            </i>
                            <span className="text-lined-through">https://www.youtube.com/watch?v=</span>
                            <span className="fu-text-yellow">OxRjGxFpCbo</span>
                          </span>
                        </div>

                        <Form.Control type="text" placeholder="Enter YouTube Video Id" className="fu-input" name="youtube_video_id" onInput={getVideoData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "youtube_video_id" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("youtube_video_id", "YouTube Video ID")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>

                    {/* video artists optional*/}
                    <Col xl={6}>
                      <div className="mb-3">
                        <div className="fu-text-light-gray w-fit-content mb-2" data-title="optional">
                          Video Artists
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </div>
                        <div className="select-options">
                          <Select
                            ref={artistKeysRef}
                            classNames={{
                              control: () => "select-filtration",
                              valueContainer: ({ innerProps }) => {
                                innerProps.style = { display: "flex", flexWrap: "nowrap" };
                              },
                            }}
                            styles={{
                              multiValue: () => ({ overflow: "visible", width: "max-content", display: "inline-flex", alignItems: "center", backgroundColor: "#2f2f2f", margin: "0 2px", borderRadius: "3px", fontSize: "14px", padding: "0 0 0 2px" }),
                              multiValueLabel: () => ({ color: "#fdb915" }),
                              multiValueRemove: () => ({ color: "#a5a5a5", marginLeft: "3px", padding: "2px", display: "inline-flex", alignItems: "center", transition: "all 0.3s", ":hover": { color: "#fdb915" } }),
                            }}
                            placeholder="Select Video Artists...."
                            name="videoArtist"
                            onChange={setSelectedArtistKeys}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={artists.map((artist) => ({ value: artist.id, label: artist.name }))}
                          />
                        </div>
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "artists_ids" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("_ids", "")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </div>
                    </Col>

                    {/* video genre optional*/}
                    <Col xl={6}>
                      <div className="mb-3">
                        <div className="fu-text-light-gray w-fit-content mb-2" data-title="optional">
                          Video Genre
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </div>
                        <div className="select-options">
                          <Select
                            ref={genreKeyRef}
                            classNames={{
                              control: () => "select-filtration",
                              option: ({ isSelected, innerProps }) => (isSelected ? (innerProps.style = { backgroundColor: "#fdb915", color: "#191919" }) : (innerProps.style = { backgroundColor: "#ffffff" })),
                            }}
                            placeholder="Select Video Genre...."
                            name="videoGenre"
                            onChange={setSelectedGenreKey}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            isClearable
                            options={genres.map((genre) => ({ value: genre.id, label: genre.title }))}
                          />
                        </div>
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "genre_id" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("_id", "")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </div>
                    </Col>

                    {/* video label optional*/}
                    <Col xl={6}>
                      <div className="mb-3">
                        <div className="fu-text-light-gray w-fit-content mb-2" data-title="optional">
                          Video Label
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </div>
                        <div className="select-options">
                          <Select
                            ref={labelKeyRef}
                            classNames={{
                              control: () => "select-filtration",
                              option: ({ isSelected, innerProps }) => (isSelected ? (innerProps.style = { backgroundColor: "#fdb915", color: "#191919" }) : (innerProps.style = { backgroundColor: "#ffffff" })),
                            }}
                            placeholder="Select Video Label...."
                            name="videoLabel"
                            onChange={setSelectedLabelKey}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            isClearable
                            options={labels.map((label) => ({ value: label.id, label: label.name }))}
                          />
                        </div>
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "label_id" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("_id", "")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </div>
                    </Col>
                  </Row>
                  {/* ------------------------------------ */}
                  {createResponse.errors
                    ? Object.keys(createResponse.errors).map((key) =>
                        createResponse.errors[key].map((error, index) => (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error}
                          </div>
                        ))
                      )
                    : ""}

                  <div className="d-flex align-items-center mt-4">
                    <div>
                      <Button className={`fu-btn fu-btn-gray ${isCreateLoading ? "disabled" : ""}`} type="submit" disabled={isCreateLoading ? true : false}>
                        <span className="btn-bg"></span>
                        Submit<i className="bi bi-send ms-2"></i>
                      </Button>
                    </div>
                    {isCreateLoading ? (
                      <div className="position-relative ms-3">
                        <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateVideo;
