import React, { useContext, useRef, useState } from "react";
import userCan from "../../helpers/userCan";
import { fmDataContext } from "../../Context/FmData";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { fmUsersContext } from "../../Context/UsersContext";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { toast } from "react-toastify";

const UsersFilration = (props) => {
  let { getUsers, roles, perPage, orderBy, orderType } = props;

  let { filtrationDetails, setFiltrationDetails, setIsFiltrationLoading, isFiltrationLoading } = useContext(fmUsersContext);

  let { baseUrl } = useContext(fmDataContext);

  const animatedComponents = makeAnimated();

  let [selectedRole, setSelectedRole] = useState(null);
  let [selectedUserStatus, setSelectedUserStatus] = useState(null);

  let roleKeyRef = useRef();
  let userStatusRef = useRef();

  const submitFiltration = (e) => {
    e.preventDefault();

    setIsFiltrationLoading(true);
    let currentFiltrationDetails = { ...filtrationDetails };
    let currentOnlyTrashedValue = e.target.only_trashed.checked ? 1 : 0;

    let currentUserStatus = selectedUserStatus !== null && selectedUserStatus.value !== null ? selectedUserStatus.value : null;
    let currentRoleKey = selectedRole !== null && selectedRole.value !== null ? selectedRole.value : null;

    if (e.nativeEvent.submitter.innerText === "DONE") {
      if (e.target.search_query.value === "" && currentFiltrationDetails.search_query === "" && currentOnlyTrashedValue === 0 && currentFiltrationDetails.only_trashed === 0 && selectedUserStatus === null && currentFiltrationDetails.user_status === null && selectedRole === null && currentFiltrationDetails.role_key === null) {
        toast("There are no search info for search!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          progressStyle: { backgroundColor: "#fdb915" },
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setIsFiltrationLoading(false);
      } else if (currentFiltrationDetails.search_query === e.target.search_query.value && currentFiltrationDetails.only_trashed === currentOnlyTrashedValue && currentFiltrationDetails.user_status === currentUserStatus && currentFiltrationDetails.role_key === currentRoleKey) {
        toast("Search result to this search is already in show!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          progressStyle: { backgroundColor: "#fdb915" },
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setIsFiltrationLoading(false);
      } else {
        currentFiltrationDetails.search_query = e.target.search_query.value;
        currentFiltrationDetails.only_trashed = e.target.only_trashed.checked ? 1 : 0;
        currentFiltrationDetails.user_status = selectedUserStatus !== null && selectedUserStatus.value !== null ? selectedUserStatus.value : null;
        currentFiltrationDetails.role_key = selectedRole !== null && selectedRole.value !== null ? selectedRole.value : null;
        setFiltrationDetails(currentFiltrationDetails);
        getUsers(
          "GET",
          `${baseUrl}fm-users`,
          {
            page_num: 1,
            per_page: perPage,
            order_type: orderType,
            order_by: orderBy,
            ...currentFiltrationDetails,
          },
          setIsFiltrationLoading
        );
      }
    } else if (e.nativeEvent.submitter.innerText === "CLEAR SEARCH") {
      if (e.target.search_query.value === "" && currentFiltrationDetails.search_query === "" && currentOnlyTrashedValue === 0 && currentFiltrationDetails.only_trashed === 0 && selectedUserStatus === null && currentFiltrationDetails.user_status === null && selectedRole === null && currentFiltrationDetails.role_key === null) {
        toast("There are no search info to clear it!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          progressStyle: { backgroundColor: "#fdb915" },
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setIsFiltrationLoading(false);
      } else {
        e.target.reset();
        roleKeyRef.current.clearValue();
        userStatusRef.current.clearValue();
        setFiltrationDetails({
          search_query: "",
          only_trashed: 0,
          role_key: null,
          user_status: null,
        });
        getUsers(
          "GET",
          `${baseUrl}fm-users`,
          {
            page_num: 1,
            per_page: perPage,
            order_type: orderType,
            order_by: orderBy,
            search_query: "",
            only_trashed: 0,
            user_status: null,
            role_key: null,
          },
          setIsFiltrationLoading
        );
      }
    }
  };

  return (
    <>
      <form onSubmit={submitFiltration}>
        <div className="row g-3 align-items-center">
          {/* search_query */}
          <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
            <div>
              <input type="text" className="form-control shadow-none" name="search_query" id="search_query" placeholder="Search in users..." />
            </div>
          </div>
          {/* role key */}
          <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
            <div className="select-options">
              <Select
                ref={roleKeyRef}
                classNames={{
                  control: () => "select-filtration",
                  option: ({ isSelected, innerProps }) => (isSelected ? (innerProps.style = { backgroundColor: "#fdb915", color: "#191919" }) : (innerProps.style = { backgroundColor: "#ffffff" })),
                }}
                placeholder="Select Role...."
                name="user_role"
                onChange={setSelectedRole}
                closeMenuOnSelect={true}
                components={animatedComponents}
                isClearable
                options={roles.map((role) => ({ value: role.key, label: role.name }))}
              />
            </div>
          </div>
          {/* user status */}
          <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
            <div className="select-options">
              <Select
                ref={userStatusRef}
                classNames={{
                  control: () => "select-filtration",
                  option: ({ isSelected, innerProps }) => (isSelected ? (innerProps.style = { backgroundColor: "#fdb915", color: "#191919" }) : (innerProps.style = { backgroundColor: "#ffffff" })),
                }}
                placeholder="User Status...."
                name="user_status"
                onChange={setSelectedUserStatus}
                closeMenuOnSelect={true}
                components={animatedComponents}
                isClearable
                options={[
                  { value: "active", label: "active" },
                  { value: "inactive", label: "inactive" },
                ]}
              />
            </div>
          </div>
          {/* only_trashed */}
          {userCan("fm_user_restore_trashed") ? (
            <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
              <div className="form-check">
                <input type="checkbox" name="only_trashed" id="only_trashed" className="form-check-input shadow-none border-0 mouse-pointer" />
                <label htmlFor="only_trashed" className="form-check-label fu-text-white mouse-pointer">
                  Only Trashed
                </label>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="col-12">
            <div className="d-flex mt-0 mt-sm-2 align-items-center justify-content-center justify-content-xl-start">
              <div>
                <button type="submit" className="btn fu-btn-sm fu-btn-gray" disabled={isFiltrationLoading ? true : false}>
                  <span className="btn-bg"></span>
                  DONE
                </button>
              </div>
              <div className="ms-3">
                <button type="submit" className="btn fu-btn-sm fu-btn-gray" disabled={isFiltrationLoading ? true : false}>
                  <span className="btn-bg"></span>
                  CLEAR SEARCH
                </button>
              </div>
              {isFiltrationLoading ? (
                <div className="position-relative ms-4">
                  <LoadingCircle circleCont={28} icon={23} loader={28} dot={28} />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default UsersFilration;
