import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { fmDataContext } from "../../Context/FmData";
import Joi from "joi";
import { useParams } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { authenticationContext } from "../../Context/AuthContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";

const UpdateHomeSlide = () => {
  let [mounted, setMounted] = useState(false);

  let { homeSlideId } = useParams();
  let { baseUrl, navigate, showData, globalCrumbs, setGlobalCrumbs } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  let [currentHomeSlide, setCurrentHomeSlide] = useState({});
  let [isHomeSlideLoading, setIsHomeSlideLoading] = useState(true);

  let [selectedImage, setSelectedImage] = useState({});

  let [joiErrorsList, setJoiErrorsList] = useState([]);
  let [imageInputError, setImageInputError] = useState(null);

  let [homeSlideData, setHomeSlideData] = useState({
    title: "",
    description: null,
    image: selectedImage,
    modify_user_id: null,
    btn_text_outside: null,
    btn_path_outside: null,
    btn_text_inside: null,
    btn_path_inside: null,
  });

  let [isUpdateLoading, setIsUpdateLoading] = useState(false);

  let [updateResponse, setUpdateResponse] = useState({});

  let [navigateTimeOut, setNavigateTimeOut] = useState("");

  let userToken = jwtDecode(localStorage.getItem("userToken")).accessToken;

  const getHomeSlide = (reqMethod, pathName, setLoading = setIsHomeSlideLoading, headers) => {
    showData(reqMethod, pathName, setLoading, headers)
      .then((response) => {
        if (response.data.data.slide_image.includes("via.placeholder.com")) {
          response.data.data.slide_image = response.data.data.slide_image.replace("http://127.0.0.1:8000/storage/", "");
        }
        let homeSlideResponse = response.data.data;

        setCurrentHomeSlide(homeSlideResponse);
        setHomeSlideData({
          title: homeSlideResponse.slide_title ? homeSlideResponse.slide_title : "",
          description: homeSlideResponse.slide_description ? homeSlideResponse.slide_description.replaceAll("</br>", "\n") : null,
          image: selectedImage,
          modify_user_id: null,
          btn_text_outside: homeSlideResponse.btn_text_outside ? homeSlideResponse.btn_text_outside : null,
          btn_path_outside: homeSlideResponse.btn_path_outside ? homeSlideResponse.btn_path_outside : null,
          btn_text_inside: homeSlideResponse.btn_text_inside ? homeSlideResponse.btn_text_inside : null,
          btn_path_inside: homeSlideResponse.btn_path_inside ? homeSlideResponse.btn_path_inside : null,
        });

        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const getHomeSlideData = (e) => {
    const homeSlide = { ...homeSlideData };
    homeSlide[e.target.name] = e.target.value;
    setHomeSlideData(homeSlide);
  };

  const handleImageSelect = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const updateItem = async (e) => {
    e.preventDefault();
    if (userCan("fm_home_carousel_slide_update")) {
      homeSlideData.modify_user_id = user.currentUser._id;

      const validationResult = validateUpdateHomeSlideForm();
      let imageError = null;
      setUpdateResponse({});

      if (selectedImage && selectedImage.name) {
        homeSlideData.image = selectedImage;
        if (!["image/png", "image/jpg", "image/jpeg", "image/gif"].includes(homeSlideData.image.type)) {
          imageError = "File must be an Image and from These extensions (png, jpg, jpeg, gif)";
        } else if (homeSlideData.image.size > 5 * 1024 * 1024) {
          imageError = "Image Size Must Be Less Than 5 MB";
        }
      } else {
        homeSlideData.image = null;
      }
      if (validationResult.error || imageError) {
        validationResult.error ? setJoiErrorsList(validationResult.error.details) : setJoiErrorsList([]);
        setImageInputError(imageError);
      } else {
        setIsUpdateLoading(true);
        setImageInputError(null);
        setJoiErrorsList([]);
        homeSlideData.description = homeSlideData.description ? homeSlideData.description.replaceAll("\n", "</br>") : null;
        await axios({
          method: "POST",
          url: `${baseUrl}fm-home-carousel-slides/update/${currentHomeSlide.key}`,
          data: homeSlideData,
          params: {
            _method: "PUT",
          },
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
            Authorization: `Bearer ${user.accessToken}`,
          },
        })
          .then((response) => {
            homeSlideData.description = homeSlideData.description ? homeSlideData.description.replaceAll("</br>", "\n") : null;
            setUpdateResponse(response.data);
            setIsUpdateLoading(false);

            if (response.data.msg && response.data.msg.includes("successfully")) {
              let timeOut = setTimeout(() => {
                setUpdateResponse({});
                navigate(`/fm-home-slides/${currentHomeSlide.key}?archived=0`);
              }, 4000);
              setNavigateTimeOut(timeOut);

              setHomeSlideData({
                title: "",
                description: null,
                image: {},
                modify_user_id: null,
                btn_text_outside: null,
                btn_path_outside: null,
                btn_text_inside: null,
                btn_path_inside: null,
              });
              setSelectedImage(null);
              e.target.reset();
              toast(`${response.data.msg}, navigating...`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                containerId: "update_done_toast",
              });
            } else if (response.data.msg && response.data.msg.includes("failed")) {
              toast(response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          })
          .catch((errors) => {
            navigate("/not-found");
          });
      }
    } else {
      navigate("/not-found");
    }
  };

  const validateUpdateHomeSlideForm = () => {
    const scheme = Joi.object({
      title: Joi.string().min(1).max(500).required(),
      description: Joi.string().min(1).max(2000).allow(null, "").optional(),
      image: Joi.any(),
      modify_user_id: Joi.number(),
      btn_text_outside: Joi.string().min(1).max(255).allow(null, "").optional(),
      btn_path_outside: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Beatport URL is not valid").allow(null, "").optional(),
      btn_text_inside: Joi.string().min(1).max(255).allow(null, "").optional(),
      btn_path_inside: Joi.string().min(1).max(255).allow(null, "").optional(),
    });
    return scheme.validate(homeSlideData, { abortEarly: false });
  };

  useEffect(() => {
    getHomeSlide("GET", `${baseUrl}fm-home-carousel-slide/${homeSlideId}`, setIsHomeSlideLoading, { Accept: "application/json", "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY, Authorization: `Bearer ${userToken}` });
    setMounted(true);
  }, []);

  useEffect(() => {
    return () => {
      if (mounted) {
        clearTimeout(navigateTimeOut);
      }
    };
  }, [navigateTimeOut]);

  useEffect(() => {
    if (mounted && currentHomeSlide) {
      let globalCrumbsUpdated = globalCrumbs.map((crumb) => {
        if (crumb.name === homeSlideId.toUpperCase()) {
          crumb.name = `${currentHomeSlide.slide_title
            .split(" ")
            .slice(0, currentHomeSlide.slide_title.split(" ").length > 5 ? 5 : currentHomeSlide.slide_title.split(" ").length)
            .join(" ")}${currentHomeSlide.slide_title.split(" ").length > 5 ? "..." : ""} slide`.toUpperCase();
        }
        return crumb;
      });
      setGlobalCrumbs(globalCrumbsUpdated);
    }
  }, [currentHomeSlide]);

  return (
    <>
      {isHomeSlideLoading ? (
        <LoadingScreen />
      ) : (
        <div className="container position-relative pt-3 pt-md-4">
          <div className="mb-3 mb-md-4">
            <h1 className="fu-text-3-rem fu-text-3-rem-md fu-text-md-2-rem fu-text-sm-2-rem fu-text-1-8-rem-xs fu-text-18-px-xxs fu-text-16-px-xxs fu-fw-800 fu-text-white mb-0">Update Slide</h1>
          </div>
          <div className="py-2 mb-4 iu-row-heading create-submit-row">
            <h2 className="mb-0 row-title">
              Edit (
              {`${currentHomeSlide.slide_title
                .split(" ")
                .slice(0, currentHomeSlide.slide_title.split(" ").length > 3 ? 3 : currentHomeSlide.slide_title.split(" ").length)
                .join(" ")}${currentHomeSlide.slide_title.split(" ").length > 3 ? "..." : ""} slide`.toUpperCase()}
              ) data
            </h2>
          </div>
          <div className="row">
            <div>
              <div>
                <Form onSubmit={updateItem}>
                  <Row>
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="homeSlideTitle">
                        <Form.Label className="fu-text-light-gray" data-title="required">
                          Slide Title
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Slide Title" className="fu-input" name="title" value={homeSlideData.title ? homeSlideData.title : ""} onInput={getHomeSlideData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "title" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("title", "Slide Title")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col xl={6}>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <Form.Group className="mb-3" controlId="slideImage">
                            <Form.Label className="fu-text-light-gray" data-title="required">
                              Slide Image
                              <span className="required-input mx-1" data-title="required">
                                *
                              </span>
                            </Form.Label>
                            <Form.Control type="file" placeholder="Choose Slide Image" className="fu-input" name="image" onInput={handleImageSelect} />

                            {imageInputError !== null ? <div className="alert alert-danger py-1 px-2 mt-2 mb-0">{imageInputError}</div> : ""}
                          </Form.Group>
                        </div>
                        <div className="ms-2 align-self-center align-self-md-baseline">
                          <div className="square-table-item-img-container">
                            <img src={currentHomeSlide.slide_image} alt="Slide" className="w-100" />
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={12}>
                      <Form.Group className="mb-3" controlId="slidegDescription">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Slide Description
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <div className="textarea-container">
                          <Form.Control as="textarea" rows={4} className="fu-input" placeholder="Enter Slide description" name="description" value={homeSlideData.description ? homeSlideData.description.replaceAll("</br>", "\n") : ""} onInput={getHomeSlideData} />
                        </div>
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "description" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("description", "Slide description")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>

                    {/* btn txt outside */}
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="btnTextOutside">
                        <Form.Label className="fu-text-light-gray" data-title="optional if button path outside is empty">
                          Button Text Outside Futurising music
                          <span className="optional-input mx-1" data-title="optional if button path outside is empty">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Button Text" className="fu-input" name="btn_text_outside" value={homeSlideData.btn_text_outside ? homeSlideData.btn_text_outside : ""} onInput={getHomeSlideData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "btn_text_outside" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("btn", "button").replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    {/* btn path outside */}
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="btnPathOutside">
                        <Form.Label className="fu-text-light-gray" data-title="optional if button text outside is empty">
                          Button Path Outside Futurising music
                          <span className="optional-input mx-1" data-title="optional if button text outside is empty">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Button Path URL" className="fu-input" name="btn_path_outside" value={homeSlideData.btn_path_outside ? homeSlideData.btn_path_outside : ""} onInput={getHomeSlideData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "btn_path_outside" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("btn", "button").replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>

                    {/* btn text inside */}
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="btnTextInside">
                        <Form.Label className="fu-text-light-gray" data-title="optional if button path inside is empty">
                          Button Text Inside Futurising music
                          <span className="optional-input mx-1" data-title="optional if button path inside is empty">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Button Text" className="fu-input" name="btn_text_inside" value={homeSlideData.btn_text_inside ? homeSlideData.btn_text_inside : ""} onInput={getHomeSlideData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "btn_text_inside" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("btn", "button").replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    {/* btn path inside */}

                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="btnPathInside">
                        <div className="d-flex flex-wrap align-items-center">
                          <Form.Label className="fu-text-light-gray me-2" data-title="optional if button text inside is empty">
                            Button Path Inside Futurising music
                            <span className="optional-input mx-1" data-title="optional if button text inside is empty">
                              *
                            </span>
                          </Form.Label>
                          <span className="fu-text-light-gray d-inline-flex mb-2 form-explain">
                            <i className="me-2">
                              <strong>EX:</strong>
                            </i>
                            <span className="text-lined-through">https://www.futurisingmusic.com/</span>
                            <span className="fu-text-yellow">music/f2bd0b57-0365-4676-bbb2-c1bf6fe33510</span>
                          </span>
                        </div>
                        <Form.Control type="text" placeholder="Enter Button Path" className="fu-input" name="btn_path_inside" value={homeSlideData.btn_path_inside ? homeSlideData.btn_path_inside : ""} onInput={getHomeSlideData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "btn_path_inside" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("btn", "button").replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* ------------------------------------------------------------------------------ */}
                  {updateResponse.errors
                    ? Object.keys(updateResponse.errors).map((key) =>
                        updateResponse.errors[key].map((error, index) => (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error.replaceAll("btn", "button")}
                          </div>
                        ))
                      )
                    : ""}

                  <div className="d-flex align-items-center mt-4">
                    <div>
                      <Button className={`fu-btn fu-btn-gray ${isUpdateLoading ? "disabled" : ""}`} type="submit" disabled={isUpdateLoading ? true : false}>
                        <span className="btn-bg"></span>
                        Submit<i className="bi bi-send ms-2"></i>
                      </Button>
                    </div>
                    {isUpdateLoading ? (
                      <div className="position-relative ms-3">
                        <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateHomeSlide;
