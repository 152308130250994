import React, { useContext } from "react";
import Reveal from "../Reveal/Reveal";
import Images from "../../images";
import { fmDataContext } from "../../Context/FmData";

const ArtistCard = ({ artist }) => {
  let { goToBlogDetails } = useContext(fmDataContext);

  return (
    <div className="h-100">
      <Reveal>
        <div className="artist-box" onClick={() => goToBlogDetails(`/fm-artists/${artist.artist_key}`, artist.artist_deleted_at ? "1" : "0")}>
          <div className="artist-img-container mb-3">
            {artist.artist_image ? (
              <div className="artist-img">
                <img src={artist.artist_image} alt="artist" className="w-100 h-100" />
              </div>
            ) : (
              ""
            )}
            {artist.is_verified ? (
              <div className="verification-circle">
                <img src={Images.futurisingIcon} alt="company icon" className="futurising-icon" />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="artist-name-container w-100">
            <h2 className="artist-name mb-0 w-100 py-1 px-2">
              <div className="d-inline-flex align-items-center">
                {artist.is_from_top_five && (
                  <div className="top-five-circle me-2" data-title="from top five artists">
                    <i className="bi bi-star-fill d-inline-flex"></i>
                  </div>
                )}
              </div>
              {artist.artist_name ? artist.artist_name : ""}
            </h2>
          </div>
        </div>
      </Reveal>
    </div>
  );
};

export default ArtistCard;
