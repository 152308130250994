import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { fmDataContext } from "../../Context/FmData";
import Joi from "joi";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { authenticationContext } from "../../Context/AuthContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const CreatePageHeader = () => {
  let { baseUrl, navigate } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  const animatedComponents = makeAnimated();

  let pageTitleRef = useRef();

  let [selectedImage, setSelectedImage] = useState({});

  let [joiErrorsList, setJoiErrorsList] = useState([]);
  let [imageInputError, setImageInputError] = useState(null);

  let [selectedPageTitle, setSelectedPageTitle] = useState(null);

  let [pageHeaderData, setPageHeaderData] = useState({
    page_title: "",
    image: selectedImage,
    user_id: null,
  });

  let [isCreateLoading, setIsCreateLoading] = useState(false);

  let [createResponse, setCreateResponse] = useState({});

  const handlePageTitle = () => {
    const pageHeader = { ...pageHeaderData };
    pageHeader.page_title = selectedPageTitle !== null && selectedPageTitle.value !== null ? selectedPageTitle.value : "";
    setPageHeaderData(pageHeader);
  };

  const handleImageSelect = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const createItem = async (e) => {
    e.preventDefault();

    if (userCan("fm_page_header_create")) {
      pageHeaderData.user_id = user.currentUser._id;

      const validationResult = validateCreatePageHeaderForm();
      let imageError = null;
      setCreateResponse({});

      if (selectedImage) {
        pageHeaderData.image = selectedImage;
        if (!pageHeaderData.image.name) {
          imageError = "Image Input Is Required";
        } else if (!["image/png", "image/jpg", "image/jpeg", "image/gif"].includes(pageHeaderData.image.type)) {
          imageError = "File must be an Image and from These extensions (png, jpg, jpeg, gif)";
        } else if (pageHeaderData.image.size > 5 * 1024 * 1024) {
          imageError = "Image Size Must Be Less Than 5 MB";
        }
      } else {
        imageError = "Image Input Is Required";
        pageHeaderData.image = {};
      }

      if (validationResult.error || imageError) {
        validationResult.error ? setJoiErrorsList(validationResult.error.details) : setJoiErrorsList([]);
        setImageInputError(imageError);
      } else {
        setIsCreateLoading(true);
        setImageInputError(null);
        setJoiErrorsList([]);
        await axios({
          method: "POST",
          url: `${baseUrl}fm-page-headers/create`,
          data: pageHeaderData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
            Authorization: `Bearer ${user.accessToken}`,
          },
        })
          .then((response) => {
            setCreateResponse(response.data);
            setIsCreateLoading(false);
            if (response.data.msg && response.data.msg.includes("successfully")) {
              setPageHeaderData({
                page_title: "",
                image: selectedImage,
                user_id: null,
              });
              setSelectedImage({});
              e.target.reset();
              pageTitleRef.current.clearValue();
              setCreateResponse({});
              toast(response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            } else if (response.data.msg && response.data.msg.includes("failed")) {
              toast(response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          })
          .catch((errors) => {
            navigate("/not-found");
          });
      }
    } else {
      navigate("/not-found");
    }
  };

  const validateCreatePageHeaderForm = () => {
    const scheme = Joi.object({
      page_title: Joi.string().min(1).max(255).required(),
      image: Joi.any(),
      user_id: Joi.number(),
    });
    return scheme.validate(pageHeaderData, { abortEarly: false });
  };

  useEffect(() => {}, []);

  useEffect(() => {
    handlePageTitle();
  }, [selectedPageTitle]);

  return (
    <>
      <div className="container position-relative pt-3 pt-md-4">
        <div className="mb-3 mb-md-4">
          <h1 className="fu-text-3-rem fu-text-3-rem-md fu-text-md-2-rem fu-text-sm-2-rem fu-text-1-8-rem-xs fu-text-18-px-xxs fu-text-16-px-xxs fu-fw-800 fu-text-white mb-0">Create Page Header</h1>
        </div>
        <div className="py-2 mb-4 iu-row-heading create-submit-row">
          <h2 className="mb-0 row-title">Submit New Page Header Image</h2>
        </div>
        <div className="row">
          <div>
            <div>
              <Form onSubmit={createItem}>
                <Row>
                  <Col lg={6}>
                    <div className="mb-3">
                      <div className="fu-text-light-gray w-fit-content mb-2" data-title="required">
                        Page Title
                        <span className="required-input mx-1" data-title="required">
                          *
                        </span>
                      </div>
                      <div className="select-options">
                        <Select
                          ref={pageTitleRef}
                          classNames={{
                            control: () => "select-filtration",
                            option: ({ isSelected, innerProps }) => (isSelected ? (innerProps.style = { backgroundColor: "#fdb915", color: "#191919" }) : (innerProps.style = { backgroundColor: "#ffffff" })),
                          }}
                          placeholder="Select Page Title...."
                          name="verified"
                          onChange={setSelectedPageTitle}
                          closeMenuOnSelect={true}
                          components={animatedComponents}
                          isClearable
                          options={[
                            { value: "labels", label: "Labels" },
                            { value: "artists", label: "Artists" },
                            { value: "music", label: "Music" },
                            { value: "videos", label: "Videos" },
                            { value: "contact", label: "Contact" },
                            { value: "news", label: "News" },
                            { value: "partnerships", label: "Partnerships" },
                            { value: "about", label: "About" },
                            { value: "privacy-policy", label: "Privacy-Policy" },
                            { value: "demo-drop", label: "Demo-Drop" },
                          ]}
                        />
                      </div>
                      {joiErrorsList.map((error, index) =>
                        error.path[0] === "page_title" ? (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error.message.replace("page_title", "Page Title")}
                          </div>
                        ) : (
                          ""
                        )
                      )}
                    </div>
                  </Col>

                  <Col lg={6}>
                    <Form.Group className="mb-3" controlId="pageHeaderImage">
                      <Form.Label className="fu-text-light-gray" data-title="required">
                        Page Header Image
                        <span className="required-input mx-1" data-title="required">
                          *
                        </span>
                      </Form.Label>
                      <Form.Control type="file" placeholder="choose page header image" className="fu-input" name="image" onInput={handleImageSelect} />
                      {imageInputError !== null ? <div className="alert alert-danger py-1 px-2 mt-2 mb-0">{imageInputError}</div> : ""}
                    </Form.Group>
                  </Col>
                </Row>
                {/* ------------------------------------------- */}
                {createResponse.errors
                  ? Object.keys(createResponse.errors).map((key) =>
                      createResponse.errors[key].map((error, index) => (
                        <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                          {error}
                        </div>
                      ))
                    )
                  : ""}
                <div className="d-flex align-items-center mt-4">
                  <div>
                    <Button className={`fu-btn fu-btn-gray ${isCreateLoading ? "disabled" : ""}`} type="submit" disabled={isCreateLoading ? true : false}>
                      <span className="btn-bg"></span>
                      Submit<i className="bi bi-send ms-2"></i>
                    </Button>
                  </div>
                  {isCreateLoading ? (
                    <div className="position-relative ms-3">
                      <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePageHeader;
