import React, { useContext, useEffect, useRef, useState } from "react";
import { fmDataContext } from "../../Context/FmData";
import { Link } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import PrivacyPolicyRow from "./PrivacyPolicyRow";
import { fmPrivacyPoliciesContext } from "../../Context/PrivacyPoliciesContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";

const PrivacyPolicies = () => {
  const isCalledRef = useRef(false);

  let { baseUrl, navigate, getData } = useContext(fmDataContext);

  let [privacyPolicies, setPrivacyPolicies] = useState({});

  let [isGetPrivacyPoliciesLoading, setIsGetPrivacyPoliciesLoading] = useState(true);

  let { privacyPoliciesMsg, setPrivacyPoliciesMsg } = useContext(fmPrivacyPoliciesContext);

  const getPrivacyPolicies = (reqMethod, pathName, setLoading = setIsGetPrivacyPoliciesLoading, message = null, params = null) => {
    getData(reqMethod, pathName, params, setLoading)
      .then((response) => {
        setPrivacyPolicies(response.data);
        setLoading(false);

        if (privacyPoliciesMsg.length > 0) {
          toast(privacyPoliciesMsg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
            onClose: () => setPrivacyPoliciesMsg(""),
          });
        }

        if (message) {
          toast(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    if (!isCalledRef.current) {
      getPrivacyPolicies("GET", `${baseUrl}fm-privacy-policies`, setIsGetPrivacyPoliciesLoading);
    }
    return () => {
      isCalledRef.current = true;
    };
  }, []);

  return (
    <>
      <div className="container">
        <div className={`${!isGetPrivacyPoliciesLoading ? "min-vh-100" : ""}`}>
          <div className="mb-4">
            <div className="d-flex flex-column align-items-center justify-content-between mb-3 mt-3">
              <h1 className="fu-text-3-rem fu-text-3-rem-md fu-text-md-2-rem fu-text-sm-2-rem fu-text-1-8-rem-xs fu-text-18-px-xxs fu-text-16-px-xxs fu-text-white fu-fw-800 mb-2">Privacy Policies</h1>
              {userCan("fm_privacy_policy_create") ? (
                <div className="me-3">
                  <Link to="/fm-privacy-policies/create" className="fu-btn fu-btn-gray">
                    <span className="btn-bg"></span>
                    Create New
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {!isGetPrivacyPoliciesLoading ? (
            <>
              <div className="row py-3 d-none d-xxl-flex table-heading-brdr">
                <div className="col-md-1">
                  <h3 className="h6 mb-0 fu-text-white">#ID</h3>
                </div>
                <div className="col-md-4">
                  <h3 className="h6 mb-0 fu-text-white">KEY</h3>
                </div>
                <div className="col-md-3">
                  <h3 className="h6 mb-0 fu-text-white">INFO</h3>
                </div>
                <div className="col-md-4">
                  <h3 className="h6 mb-0 fu-text-white">OPERATIONS</h3>
                </div>
              </div>
              <div className="table-heading-brdr mt-4 mt-sm-0 d-xxl-none"></div>
              {privacyPolicies.data && privacyPolicies.data.length > 0 ? (
                privacyPolicies.data.map((privacyPolicy, index) => <PrivacyPolicyRow key={index} privacyPolicy={privacyPolicy} index={index} getPrivacyPolicies={getPrivacyPolicies} />)
              ) : privacyPolicies.data && privacyPolicies.data.length === 0 ? (
                <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                  <div>
                    <h2 className="fu-text-3-rem fu-text-yellow">
                      <i className="bi bi-database-add"></i>
                    </h2>
                  </div>
                  <div>
                    <h3 className="h5 fu-text-light-gray">
                      Privacy Policies is Empty
                      {userCan("fm_privacy_policy_create") ? (
                        <>
                          ,
                          <Link to="/fm-privacy-policies/create" className="link-dashed ms-2">
                            Create New
                          </Link>
                        </>
                      ) : (
                        ""
                      )}
                    </h3>
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <LoadingScreen />
          )}
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicies;
