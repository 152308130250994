import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { fmDataContext } from "../../Context/FmData";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { fmPrivacyPoliciesContext } from "../../Context/PrivacyPoliciesContext";
import userCan from "../../helpers/userCan";
import Swal from "sweetalert2/dist/sweetalert2.all.min";
import "@sweetalert2/theme-dark/dark.min.css";
import jwtDecode from "jwt-decode";
import { authenticationContext } from "../../Context/AuthContext";

const PrivacyPolicyDetails = () => {
  let [mounted, setMounted] = useState(false);

  let { privacyPolicyId } = useParams();

  let { baseUrl, navigate, showData, editItemExistence, globalCrumbs, setGlobalCrumbs } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  let [currentPrivacyPolicy, setCurrentPrivacyPolicy] = useState({});

  let [isCurrentPrivacyPolicyLoading, setIsCurrentPrivacyPolicyLoading] = useState(true);

  let [isForceDeleteLoading, setIsForceDeleteLoading] = useState(false);

  let { setPrivacyPoliciesMsg } = useContext(fmPrivacyPoliciesContext);

  let userToken = jwtDecode(localStorage.getItem("userToken")).accessToken;

  const forceDeletePrivacyPolicy = (reqMethod, pathName, headers, setLoading) => {
    Swal.fire({
      title: "Are you sure?",
      html: `<p class="mb-0">You will delete <span class="fu-text-light-gray">Privacy Policy</span> permanently</p><p class="mb-0">You won't be able to revert this!</p>`,
      icon: "warning",
      showCancelButton: true,
      customClass: {
        cancelButton: "popup-btn",
        confirmButton: "popup-btn",
      },
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        if (userCan("fm_privacy_policy_force_delete")) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              setPrivacyPoliciesMsg(`Privacy Policy Deleted Permanently Successfully`);
              navigate("/fm-privacy-policies");
            })
            .catch((error) => {
              navigate("/not-found");
            });
        } else {
          navigate("/not-found");
        }
      }
    });
  };

  const getPrivacyPolicy = (reqMethod, pathName, setLoading = setIsCurrentPrivacyPolicyLoading, headers, params = null) => {
    showData(reqMethod, pathName, setLoading, headers, params)
      .then((response) => {
        setCurrentPrivacyPolicy(response.data);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    getPrivacyPolicy("GET", `${baseUrl}fm-privacy-policies/show/${privacyPolicyId}`, setIsCurrentPrivacyPolicyLoading, {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
      Authorization: `Bearer ${userToken}`,
    });
    setMounted(true);
  }, []);

  useEffect(() => {
    getPrivacyPolicy("GET", `${baseUrl}fm-privacy-policies/show/${privacyPolicyId}`, setIsCurrentPrivacyPolicyLoading, {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
      Authorization: `Bearer ${userToken}`,
    });
    setMounted(true);
  }, [privacyPolicyId]);

  useEffect(() => {
    if (mounted && currentPrivacyPolicy.data) {
      let globalCrumbsUpdated = globalCrumbs.map((crumb) => {
        if (crumb.name === privacyPolicyId.toUpperCase()) {
          crumb.name = `Privacy Policy details`.toUpperCase();
        }
        return crumb;
      });
      setGlobalCrumbs(globalCrumbsUpdated);
    }
  }, [currentPrivacyPolicy]);

  return (
    <>
      {!isCurrentPrivacyPolicyLoading ? (
        <>
          <div className="container">
            <div className="d-flex flex-column flex-xl-row align-items-xl-center justify-content-between  mb-2 mb-sm-3 mt-3">
              <h1 className="fu-text-2-rem fu-text-sm-1-5-rem fu-text-xs-1-2-rem fu-text-18-px-xxs fu-text-18-px-xxxs fu-fw-800 fu-text-white">Privacy Policy Details</h1>
            </div>

            <div className="py-2 mt-2 mt-sm-3">
              <div className="d-flex flex-wrap align-items-center mb-4">
                {userCan("fm_privacy_policy_update") ? (
                  <div className="me-2 my-1 my-sm-0">
                    <Link to={`/fm-privacy-policies/update/${currentPrivacyPolicy.data.key}`} className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading ? "disabled" : ""}`} disabled={isForceDeleteLoading ? true : false}>
                      <span className="btn-bg"></span>
                      Edit
                    </Link>
                  </div>
                ) : (
                  ""
                )}

                {userCan("fm_privacy_policy_force_delete") ? (
                  <div className="me-2 my-1 my-sm-0">
                    <button
                      className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading ? "disabled" : ""}`}
                      onClick={() =>
                        forceDeletePrivacyPolicy(
                          "DELETE",
                          `${baseUrl}fm-privacy-policies/force-delete/${currentPrivacyPolicy.data.key}`,
                          {
                            Accept: "application/json",
                            "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                            Authorization: `Bearer ${user.accessToken}`,
                          },
                          setIsForceDeleteLoading
                        )
                      }
                      disabled={isForceDeleteLoading ? true : false}>
                      <span className="btn-bg"></span>
                      Force Delete
                    </button>
                  </div>
                ) : (
                  ""
                )}

                {isForceDeleteLoading ? (
                  <div className="position-relative ms-4">
                    <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="privacy-polict-tags">
                <div className="mb-4 info-tags-container justify-content-start">
                  {currentPrivacyPolicy.data._id ? (
                    <>
                      <div className="mx-1 my-1 info-tag">
                        <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">#ID: </span>
                        <span className="d-inline-flex justify-content-center align-items-center">{currentPrivacyPolicy.data._id}</span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {currentPrivacyPolicy.data.key ? (
                    <>
                      <div className="mx-1 my-1 info-tag">
                        <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">#KEY: </span>
                        <span className="d-inline-flex justify-content-center align-items-center">{currentPrivacyPolicy.data.key}</span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {currentPrivacyPolicy.data.created_at ? (
                    userCan("created_at_show") ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED AT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{new Date(currentPrivacyPolicy.data.created_at).toLocaleString("en-US", { timeZone: "Africa/Cairo", hour12: true })}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {currentPrivacyPolicy.data.updated_at ? (
                    userCan("updated_at_show") ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED AT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{new Date(currentPrivacyPolicy.data.updated_at).toLocaleString("en-US", { timeZone: "Africa/Cairo", hour12: true })}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {currentPrivacyPolicy.data.created_by ? (
                    userCan("created_by_show") ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED BY, NAME: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentPrivacyPolicy.data.created_by.name}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {currentPrivacyPolicy.data.created_by ? (
                    userCan("created_by_show") ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED BY, #ID: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentPrivacyPolicy.data.created_by._id}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {currentPrivacyPolicy.data.created_by ? (
                    userCan("created_by_show") ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED BY, KEY: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentPrivacyPolicy.data.created_by.key}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {currentPrivacyPolicy.data.updated_by ? (
                    userCan("updated_by_show") ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED BY, NAME: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentPrivacyPolicy.data.updated_by.name}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {currentPrivacyPolicy.data.updated_by ? (
                    userCan("updated_by_show") ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED BY, #ID: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentPrivacyPolicy.data.updated_by._id}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {currentPrivacyPolicy.data.updated_by ? (
                    userCan("updated_by_show") ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED BY, KEY: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentPrivacyPolicy.data.updated_by.key}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {currentPrivacyPolicy.data.description ? (
                <div className="mb-4">
                  <div className="mb-3 iu-row-heading">
                    <h2 className="mb-0 row-title">DESCRIPTION</h2>
                  </div>
                  <div>
                    <div className="privacy-policies-description" dangerouslySetInnerHTML={{ __html: currentPrivacyPolicy.data.description }} />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      ) : (
        <LoadingScreen />
      )}
    </>
  );
};

export default PrivacyPolicyDetails;
