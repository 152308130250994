import React, { createContext, useState } from "react";

export let fmTeamMembersContext = createContext(0);

const TeamMembersContext = ({ children }) => {
  let [teamMemberMsg, setTeamMemberMsg] = useState("");

  let [filtrationDetails, setFiltrationDetails] = useState({
    search_query: "",
    only_trashed: 0,
  });

  let [isFiltrationLoading, setIsFiltrationLoading] = useState(false);

  let fmTeamMembersLifting = {
    teamMemberMsg,
    setTeamMemberMsg,
    filtrationDetails,
    setFiltrationDetails,
    isFiltrationLoading,
    setIsFiltrationLoading,
  };

  return (
    <>
      <fmTeamMembersContext.Provider value={{ ...fmTeamMembersLifting }}>{children}</fmTeamMembersContext.Provider>
    </>
  );
};

export default TeamMembersContext;
