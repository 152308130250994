import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { fmDataContext } from "../../Context/FmData";
import Joi from "joi";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { authenticationContext } from "../../Context/AuthContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Reveal from "../Reveal/Reveal";

const CreateTfArtist = () => {
  let { baseUrl, navigate, getAllDataWithoutPaginate, getData } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  const animatedComponents = makeAnimated();

  let artistRef = useRef();
  let artistOrderRef = useRef();

  let [joiErrorsList, setJoiErrorsList] = useState([]);

  let [selectedArtist, setSelectedArtist] = useState(null);
  let [selectedArtistOrder, setSelectedArtistOrder] = useState(null);

  let [tfArtistData, setTfArtistData] = useState({
    name: "",
    order: null,
    artist_id: null,
    user_id: null,
  });

  let [isCreateLoading, setIsCreateLoading] = useState(false);

  let [createResponse, setCreateResponse] = useState({});

  let [isGetArtistsLoading, setIsGetArtistsLoading] = useState(true);
  let [artists, setArtists] = useState([]);

  const getTfArtistData = (e) => {
    const tfArtist = { ...tfArtistData };
    tfArtist[e.target.name] = e.target.value;
    setTfArtistData(tfArtist);
  };

  const handleArtistOrder = () => {
    const tfArtist = { ...tfArtistData };
    tfArtist.order = selectedArtistOrder !== null && selectedArtistOrder.value !== null ? selectedArtistOrder.value : null;
    setTfArtistData(tfArtist);
  };

  const handleArtistSelect = () => {
    const tfArtist = { ...tfArtistData };
    tfArtist.artist_id = selectedArtist !== null && selectedArtist.value !== null ? selectedArtist.value : null;
    setTfArtistData(tfArtist);
  };

  const getAllArtists = (reqMethod, pathName, params, setLoading = setIsGetArtistsLoading) => {
    getAllDataWithoutPaginate(reqMethod, pathName, params, setLoading)
      .then((response) => {
        setArtists(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  let [tfArtists, setTfArtists] = useState({});

  let [isGetTfArtistsLoading, setIsGetTfArtistsLoading] = useState(true);

  const getTfArtists = (reqMethod, pathName, params, setLoading = setIsGetTfArtistsLoading) => {
    getData(reqMethod, pathName, params, setLoading)
      .then((response) => {
        setTfArtists(response.data);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const createItem = async (e) => {
    e.preventDefault();

    if (userCan("fm_top_five_artists_create")) {
      tfArtistData.user_id = user.currentUser._id;
      const validationResult = validateCreateTfArtistForm();
      setCreateResponse({});
      if (validationResult.error) {
        validationResult.error ? setJoiErrorsList(validationResult.error.details) : setJoiErrorsList([]);
      } else {
        setIsCreateLoading(true);
        setJoiErrorsList([]);
        await axios({
          method: "POST",
          url: `${baseUrl}fm-top-five-artists/create`,
          data: tfArtistData,
          headers: {
            Accept: "application/json",
            "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
            Authorization: `Bearer ${user.accessToken}`,
          },
        })
          .then((response) => {
            if (response.data.msg && response.data.msg.includes("successfully")) {
              getData(
                "GET",
                `${baseUrl}fm-top-five-artists`,
                {
                  order_type: "asc",
                  order_by: "order",
                },
                setIsCreateLoading
              )
                .then((tfResponse) => {
                  setTfArtists(tfResponse.data);
                  setTfArtistData({
                    name: "",
                    order: null,
                    artist_id: null,
                    user_id: null,
                  });
                  e.target.reset();
                  artistRef.current.clearValue();
                  artistOrderRef.current.clearValue();
                  setCreateResponse({});
                  setIsCreateLoading(false);
                  toast(response.data.msg, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    progressStyle: { backgroundColor: "#fdb915" },
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                  });
                })
                .catch((error) => {
                  navigate("/not-found");
                });
            } else if (response.data.msg && response.data.msg.includes("failed")) {
              setCreateResponse(response.data);
              toast(response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
              setIsCreateLoading(false);
            } else {
              setCreateResponse(response.data);
              setIsCreateLoading(false);
            }
          })
          .catch((errors) => {
            navigate("/not-found");
          });
      }
    } else {
      navigate("/not-found");
    }
  };

  const validateCreateTfArtistForm = () => {
    const scheme = Joi.object({
      name: Joi.string().min(1).max(255).allow(null, "").optional(),
      order: Joi.number().min(1).max(5).required().messages({
        "number.base": `"Artist Order" is a required field`,
        "number.empty": `"Artist Order" is a required field`,
        "any.required": `"Artist Order" is a required field`,
      }),
      artist_id: Joi.number().required().messages({
        "number.base": `"Artist" is a required field`,
        "number.empty": `"Artist" is a required field`,
        "any.required": `"Artist" is a required field`,
      }),
      user_id: Joi.number(),
    });
    return scheme.validate(tfArtistData, { abortEarly: false });
  };

  useEffect(() => {
    getAllArtists("GET", `${baseUrl}fm-all-artists`, null, setIsGetArtistsLoading);
    getTfArtists(
      "GET",
      `${baseUrl}fm-top-five-artists`,
      {
        order_type: "asc",
        order_by: "order",
      },
      setIsGetTfArtistsLoading
    );
  }, []);

  useEffect(() => {
    handleArtistSelect();
  }, [selectedArtist]);

  useEffect(() => {
    handleArtistOrder();
  }, [selectedArtistOrder]);

  return (
    <>
      {isGetArtistsLoading || isGetTfArtistsLoading ? (
        <LoadingScreen />
      ) : (
        <>
          {tfArtists.data && tfArtists.data.length >= 5 ? (
            <Reveal>
              <div className="custom-height d-flex flex-column justify-content-center align-items-center tf-limitation-msg">
                <div className="mb-1 mb-sm-2 mb-lg-3 fu-text-4-rem fu-text-yellow">
                  <i className="bi bi-info-circle"></i>
                </div>
                <h1 className="fu-text-2-rem fu-text-white fu-fw-800 mb-0">There are five artists is already exists in top five artists list</h1>
                <h2 className="fu-text-1-rem fu-text-yellow fu-fw-800 mb-0">You can't add more</h2>
              </div>
            </Reveal>
          ) : (
            <div className="container position-relative pt-3 pt-md-4">
              <div className="mb-3 mb-md-4">
                <h1 className="fu-text-3-rem page-heading-title-sm-2-rem fu-text-white fu-fw-800 mb-0">Add Artist</h1>
              </div>
              <div className="py-2 mb-4 iu-row-heading create-submit-row">
                <h2 className="mb-0 row-title">Add Artist To Top Five Artists List</h2>
              </div>
              <div className="row">
                <div>
                  <div>
                    <Form onSubmit={createItem}>
                      <Row>
                        <Col xl={6}>
                          <Form.Group className="mb-3" controlId="additionalArtistName">
                            <Form.Label className="fu-text-light-gray" data-title="optional">
                              Additional Artist Name
                              <span className="optional-input mx-1" data-title="optional">
                                *
                              </span>
                            </Form.Label>
                            <Form.Control type="text" placeholder="Enter Additional Artist Name" className="fu-input" name="name" onInput={getTfArtistData} autoComplete="on" />
                            {joiErrorsList.map((error, index) =>
                              error.path[0] === "name" ? (
                                <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                                  {error.message.replace("name", "Additional Artist Name")}
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </Form.Group>
                        </Col>
                        <Col xl={6}>
                          <div className="mb-3">
                            <div className="fu-text-light-gray w-fit-content mb-2" data-title="required">
                              Select Artist
                              <span className="required-input mx-1" data-title="required">
                                *
                              </span>
                            </div>
                            <div className="select-options">
                              <Select
                                ref={artistRef}
                                classNames={{
                                  control: () => "select-filtration",
                                  option: ({ isSelected, innerProps }) => (isSelected ? (innerProps.style = { backgroundColor: "#fdb915", color: "#191919" }) : (innerProps.style = { backgroundColor: "#ffffff" })),
                                }}
                                placeholder="Select Artist...."
                                name="artist"
                                onChange={setSelectedArtist}
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                isClearable
                                options={artists.map((artist) => ({ value: artist.id, label: artist.name }))}
                              />
                            </div>
                            {joiErrorsList.map((error, index) =>
                              error.path[0] === "artist_id" ? (
                                <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                                  {error.message.replace("artist_id", "Artist")}
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </div>
                        </Col>
                        <Col xl={6}>
                          <div className="mb-3">
                            <div className="fu-text-light-gray w-fit-content mb-2" data-title="required">
                              Artist Order
                              <span className="required-input mx-1" data-title="required">
                                *
                              </span>
                            </div>
                            <div className="select-options">
                              <Select
                                ref={artistOrderRef}
                                classNames={{
                                  control: () => "select-filtration",
                                  option: ({ isSelected, innerProps }) => (isSelected ? (innerProps.style = { backgroundColor: "#fdb915", color: "#191919" }) : (innerProps.style = { backgroundColor: "#ffffff" })),
                                }}
                                placeholder="Select Artist Order...."
                                name="artistOrder"
                                onChange={setSelectedArtistOrder}
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                isClearable
                                options={[
                                  { value: 1, label: 1 },
                                  { value: 2, label: 2 },
                                  { value: 3, label: 3 },
                                  { value: 4, label: 4 },
                                  { value: 5, label: 5 },
                                ]}
                              />
                            </div>
                            {joiErrorsList.map((error, index) =>
                              error.path[0] === "order" ? (
                                <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                                  {error.message.replace("order", "Artist Order")}
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </div>
                        </Col>
                      </Row>
                      {createResponse.errors
                        ? Object.keys(createResponse.errors).map((key) =>
                            createResponse.errors[key].map((error, index) => (
                              <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                                {error.includes("The artist id has already been taken.") ? error.replace("The artist id has already been taken.", "This Artist Is Already Exists In Top Five Artists List") : error}
                              </div>
                            ))
                          )
                        : ""}
                      <div className="d-flex align-items-center mt-4">
                        <div>
                          <Button className={`fu-btn fu-btn-gray ${isCreateLoading ? "disabled" : ""}`} type="submit" disabled={isCreateLoading ? true : false}>
                            <span className="btn-bg"></span>
                            Submit<i className="bi bi-send ms-2"></i>
                          </Button>
                        </div>
                        {isCreateLoading ? (
                          <div className="position-relative ms-3">
                            <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CreateTfArtist;
