import React, { createContext, useState } from "react";

export let fmReleasesContext = createContext(0);

const ReleasesContext = ({ children }) => {
  let [releaseMsg, setReleaseMsg] = useState("");

  let [filtrationDetails, setFiltrationDetails] = useState({
    search_query: "",
    pre_order: null,
    label_keys: [],
    genre_keys: [],
    artist_keys: [],
    top_five_releases: 0,
    only_trashed: 0,
  });

  let [isFiltrationLoading, setIsFiltrationLoading] = useState(false);

  let fmReleasesLifting = {
    releaseMsg,
    setReleaseMsg,
    filtrationDetails,
    setFiltrationDetails,
    isFiltrationLoading,
    setIsFiltrationLoading,
  };

  return (
    <>
      <fmReleasesContext.Provider value={{ ...fmReleasesLifting }}>{children}</fmReleasesContext.Provider>
    </>
  );
};

export default ReleasesContext;
