import React, { useContext } from "react";
import Reveal from "../Reveal/Reveal";
import { fmDataContext } from "../../Context/FmData";

const LabelCard = ({ label }) => {
  let { goToBlogDetails } = useContext(fmDataContext);

  return (
    <div className="h-100">
      <Reveal>
        <div className="label-box" onClick={() => goToBlogDetails(`/fm-labels/${label.label_key}`, label.label_deleted_at ? "1" : "0")}>
          <div className="label-img-container mb-3">
            {label.label_image ? (
              <div className="label-img">
                <img src={label.label_image} alt="label" className="w-100 h-100" />
              </div>
            ) : (
              ""
            )}
          </div>
          {label.label_name ? (
            <div className="label-name-container">
              <h2 className="label-name">{label.label_name}</h2>
            </div>
          ) : (
            ""
          )}
        </div>
      </Reveal>
    </div>
  );
};

export default LabelCard;
