import React, { useContext, useRef, useState } from "react";
import userCan from "../../helpers/userCan";
import { fmReleasesContext } from "../../Context/ReleasesContext";
import { fmDataContext } from "../../Context/FmData";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { toast } from "react-toastify";

const ReleasesFiltration = (props) => {
  let { labels, artists, genres, getReleases, perPage, orderBy, orderType } = props;

  let { filtrationDetails, setFiltrationDetails, setIsFiltrationLoading, isFiltrationLoading } = useContext(fmReleasesContext);

  let { baseUrl } = useContext(fmDataContext);

  const animatedComponents = makeAnimated();

  let [selectedLabelKeys, setSelectedLabelKeys] = useState([]);
  let [selectedArtistKeys, setSelectedArtistKeys] = useState([]);
  let [selectedGenreKeys, setSelectedGenreKeys] = useState([]);
  let [selectedReleasesStatus, setSelectedReleaseStatus] = useState(null);

  let labelKeysRef = useRef();
  let artistKeysRef = useRef();
  let genreKeysRef = useRef();
  let releaseStatusRef = useRef();

  const submitFiltration = (e) => {
    e.preventDefault();

    setIsFiltrationLoading(true);
    let currentFiltrationDetails = { ...filtrationDetails };
    let currentOnlyTrashedValue = e.target.only_trashed && e.target.only_trashed.checked ? 1 : 0;
    let currentTopFiveReleasesValue = e.target.top_five_releases.checked ? 1 : 0;
    let currentReleasesStatusValue = selectedReleasesStatus !== null && selectedReleasesStatus.value !== null ? selectedReleasesStatus.value : null;

    if (e.nativeEvent.submitter.innerText === "DONE") {
      if (
        e.target.search_query.value === "" &&
        currentFiltrationDetails.search_query === "" &&
        currentOnlyTrashedValue === 0 &&
        currentFiltrationDetails.only_trashed === 0 &&
        currentTopFiveReleasesValue === 0 &&
        currentFiltrationDetails.top_five_releases === 0 &&
        selectedReleasesStatus === null &&
        currentFiltrationDetails.pre_order === null &&
        selectedLabelKeys.length === 0 &&
        currentFiltrationDetails.label_keys.length === 0 &&
        selectedArtistKeys.length === 0 &&
        currentFiltrationDetails.artist_keys.length === 0 &&
        selectedGenreKeys.length === 0 &&
        currentFiltrationDetails.genre_keys.length === 0
      ) {
        toast("There are no search info for search!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          progressStyle: { backgroundColor: "#fdb915" },
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setIsFiltrationLoading(false);
      } else if (
        currentFiltrationDetails.search_query === e.target.search_query.value &&
        currentFiltrationDetails.only_trashed === currentOnlyTrashedValue &&
        currentFiltrationDetails.top_five_releases === currentTopFiveReleasesValue &&
        currentFiltrationDetails.pre_order === currentReleasesStatusValue &&
        JSON.stringify(currentFiltrationDetails.label_keys.sort()) === JSON.stringify(selectedLabelKeys.map((labelKey) => labelKey.value).sort()) &&
        JSON.stringify(currentFiltrationDetails.artist_keys.sort()) === JSON.stringify(selectedArtistKeys.map((artistKey) => artistKey.value).sort()) &&
        JSON.stringify(currentFiltrationDetails.genre_keys.sort()) === JSON.stringify(selectedGenreKeys.map((genreKey) => genreKey.value).sort())
      ) {
        toast("Search result to this search is already in show!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          progressStyle: { backgroundColor: "#fdb915" },
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setIsFiltrationLoading(false);
      } else {
        currentFiltrationDetails.search_query = e.target.search_query.value;
        currentFiltrationDetails.label_keys = selectedLabelKeys.map((labelKey) => labelKey.value);
        currentFiltrationDetails.genre_keys = selectedGenreKeys.map((genreKey) => genreKey.value);
        currentFiltrationDetails.artist_keys = selectedArtistKeys.map((artistKey) => artistKey.value);
        currentFiltrationDetails.pre_order = selectedReleasesStatus !== null && selectedReleasesStatus.value !== null ? selectedReleasesStatus.value : null;
        currentFiltrationDetails.top_five_releases = e.target.top_five_releases.checked ? 1 : 0;
        currentFiltrationDetails.only_trashed = e.target.only_trashed && e.target.only_trashed.checked ? 1 : 0;
        setFiltrationDetails(currentFiltrationDetails);
        getReleases(
          "GET",
          `${baseUrl}fm-releases`,
          {
            page_num: 1,
            per_page: perPage,
            order_type: orderType,
            order_by: orderBy,
            ...currentFiltrationDetails,
          },
          setIsFiltrationLoading
        );
      }
    } else if (e.nativeEvent.submitter.innerText === "CLEAR SEARCH") {
      if (
        e.target.search_query.value === "" &&
        currentFiltrationDetails.search_query === "" &&
        currentOnlyTrashedValue === 0 &&
        currentFiltrationDetails.only_trashed === 0 &&
        currentTopFiveReleasesValue === 0 &&
        currentFiltrationDetails.top_five_releases === 0 &&
        selectedReleasesStatus === null &&
        currentFiltrationDetails.pre_order === null &&
        selectedLabelKeys.length === 0 &&
        currentFiltrationDetails.label_keys.length === 0 &&
        selectedArtistKeys.length === 0 &&
        currentFiltrationDetails.artist_keys.length === 0 &&
        selectedGenreKeys.length === 0 &&
        currentFiltrationDetails.genre_keys.length === 0
      ) {
        toast("There are no search info to clear it!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          progressStyle: { backgroundColor: "#fdb915" },
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setIsFiltrationLoading(false);
      } else {
        e.target.reset();
        labelKeysRef.current.clearValue();
        artistKeysRef.current.clearValue();
        genreKeysRef.current.clearValue();
        releaseStatusRef.current.clearValue();

        setFiltrationDetails({
          search_query: "",
          pre_order: null,
          label_keys: [],
          genre_keys: [],
          artist_keys: [],
          top_five_releases: 0,
          only_trashed: 0,
        });
        getReleases(
          "GET",
          `${baseUrl}fm-releases`,
          {
            page_num: 1,
            per_page: perPage,
            order_type: orderType,
            order_by: orderBy,
            search_query: "",
            pre_order: null,
            label_keys: [],
            genre_keys: [],
            artist_keys: [],
            top_five_releases: 0,
            only_trashed: 0,
          },
          setIsFiltrationLoading
        );
      }
    }
  };

  return (
    <>
      <form onSubmit={submitFiltration}>
        <div className="row g-3 align-items-center">
          {/* label_keys */}
          <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
            <div className="select-options">
              <Select
                ref={labelKeysRef}
                classNames={{
                  control: () => "select-filtration",
                  valueContainer: ({ innerProps }) => {
                    innerProps.style = { display: "flex", flexWrap: "nowrap" };
                  },
                }}
                styles={{
                  multiValue: () => ({ overflow: "visible", width: "max-content", display: "inline-flex", alignItems: "center", backgroundColor: "#2f2f2f", margin: "0 2px", borderRadius: "3px", fontSize: "14px", padding: "0 0 0 2px" }),
                  multiValueLabel: () => ({ color: "#fdb915" }),
                  multiValueRemove: () => ({ color: "#a5a5a5", marginLeft: "3px", padding: "2px", display: "inline-flex", alignItems: "center", transition: "all 0.3s", ":hover": { color: "#fdb915" } }),
                }}
                placeholder="Select Labels...."
                name="labels"
                onChange={setSelectedLabelKeys}
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={labels.map((label) => ({ value: label.key, label: label.name }))}
              />
            </div>
          </div>
          {/* artist_keys */}
          <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
            <div className="select-options">
              <Select
                ref={artistKeysRef}
                classNames={{
                  control: () => "select-filtration",
                  valueContainer: ({ innerProps }) => {
                    innerProps.style = { display: "flex", flexWrap: "nowrap" };
                  },
                }}
                styles={{
                  multiValue: () => ({ overflow: "visible", width: "max-content", display: "inline-flex", alignItems: "center", backgroundColor: "#2f2f2f", margin: "0 2px", borderRadius: "3px", fontSize: "14px", padding: "0 0 0 2px" }),
                  multiValueLabel: () => ({ color: "#fdb915" }),
                  multiValueRemove: () => ({ color: "#a5a5a5", marginLeft: "3px", padding: "2px", display: "inline-flex", alignItems: "center", transition: "all 0.3s", ":hover": { color: "#fdb915" } }),
                }}
                placeholder="Select Artists...."
                name="artists"
                onChange={setSelectedArtistKeys}
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={artists.map((artist) => ({ value: artist.key, label: artist.name }))}
              />
            </div>
          </div>
          {/* genre_keys */}
          <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
            <div className="select-options">
              <Select
                ref={genreKeysRef}
                classNames={{
                  control: () => "select-filtration",
                  valueContainer: ({ innerProps }) => {
                    innerProps.style = { display: "flex", flexWrap: "nowrap" };
                  },
                }}
                styles={{
                  multiValue: () => ({ overflow: "visible", width: "max-content", display: "inline-flex", alignItems: "center", backgroundColor: "#2f2f2f", margin: "0 2px", borderRadius: "3px", fontSize: "14px", padding: "0 0 0 2px" }),
                  multiValueLabel: () => ({ color: "#fdb915" }),
                  multiValueRemove: () => ({ color: "#a5a5a5", marginLeft: "3px", padding: "2px", display: "inline-flex", alignItems: "center", transition: "all 0.3s", ":hover": { color: "#fdb915" } }),
                }}
                placeholder="Select Genres...."
                name="genres"
                onChange={setSelectedGenreKeys}
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={genres.map((genre) => ({ value: genre.key, label: genre.title }))}
              />
            </div>
          </div>
          {/* pre order */}
          <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
            <div className="select-options">
              <Select
                ref={releaseStatusRef}
                classNames={{
                  control: () => "select-filtration",
                  option: ({ isSelected, innerProps }) => (isSelected ? (innerProps.style = { backgroundColor: "#fdb915", color: "#191919" }) : (innerProps.style = { backgroundColor: "#ffffff" })),
                }}
                placeholder="Release Status...."
                name="release_status"
                onChange={setSelectedReleaseStatus}
                closeMenuOnSelect={true}
                components={animatedComponents}
                isClearable
                options={[
                  { value: 0, label: "Out Now" },
                  { value: 1, label: "Out Soon" },
                ]}
              />
            </div>
          </div>
          {/* search_query */}
          <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-3">
            <div>
              <input type="text" className="form-control shadow-none" name="search_query" id="search_query" placeholder="Search in releases..." />
            </div>
          </div>
          {/* only_trashed */}
          {userCan("fm_release_restore_trashed") ? (
            <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-2">
              <div className="form-check">
                <input type="checkbox" name="only_trashed" id="only_trashed" className="form-check-input shadow-none border-0 mouse-pointer" />
                <label htmlFor="only_trashed" className="form-check-label fu-text-white mouse-pointer">
                  Only Trashed
                </label>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* top_five_releases */}
          <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-2">
            <div className="form-check">
              <input type="checkbox" name="top_five_releases" id="topFiveReleases" className="form-check-input shadow-none border-0 mouse-pointer" />
              <label htmlFor="topFiveReleases" className="form-check-label fu-text-white mouse-pointer">
                Top 5 Releases
              </label>
            </div>
          </div>
          <div className="col-12">
            <div className="d-flex mt-0 mt-sm-2 align-items-center justify-content-center justify-content-xl-start">
              <div>
                <button type="submit" className="btn fu-btn-sm fu-btn-gray" disabled={isFiltrationLoading ? true : false}>
                  <span className="btn-bg"></span>
                  DONE
                </button>
              </div>
              <div className="ms-3">
                <button type="submit" className="btn fu-btn-sm fu-btn-gray" disabled={isFiltrationLoading ? true : false}>
                  <span className="btn-bg"></span>
                  CLEAR SEARCH
                </button>
              </div>
              {isFiltrationLoading ? (
                <div className="position-relative ms-4">
                  <LoadingCircle circleCont={28} icon={23} loader={28} dot={28} />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ReleasesFiltration;
