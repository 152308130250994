import React, { useContext, useEffect, useRef, useState } from "react";
import { fmDataContext } from "../../Context/FmData";
import Pagination from "../Pagination/Pagination";
import { Link } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import NewBlogRow from "./NewBlogRow";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { fmNewsContext } from "../../Context/NewsContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";
import NewsFiltration from "./NewsFiltration";

const News = () => {
  const isCalledRef = useRef(false);

  let { baseUrl, navigate, getData } = useContext(fmDataContext);

  let [news, setNews] = useState({});

  let [isGetNewsLoading, setIsGetNewsLoading] = useState(true);

  let [isPaginateLoading, setIsPaginateLoading] = useState(false);

  let [pageNum, setPageNum] = useState(1);

  let perPage = 20;
  let orderBy = "id";
  let orderType = "asc";

  let { newBlogMsg, setNewBlogMsg, filtrationDetails, isFiltrationLoading, setFiltrationDetails } = useContext(fmNewsContext);

  const getNews = (reqMethod, pathName, params, setLoading = setIsGetNewsLoading, message = null) => {
    getData(reqMethod, pathName, params, setLoading)
      .then((response) => {
        // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
        // if (response.data.data) {
        //   response.data.data.forEach((newBlog) => {
        //     if (newBlog.new_image.includes("via.placeholder.com")) {
        //       newBlog.new_image = newBlog.new_image.replace("http://127.0.0.1:8000/storage/", "");
        //     }
        //   });
        // }
        // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
        setNews(response.data);
        setLoading(false);
        if (newBlogMsg.length > 0) {
          toast(newBlogMsg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
            onClose: () => setNewBlogMsg(""),
          });
        }
        if (message) {
          toast(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    if (!isCalledRef.current) {
      getNews(
        "GET",
        `${baseUrl}fm-news`,
        {
          page_num: pageNum,
          per_page: perPage,
          order_type: orderType,
          order_by: orderBy,
        },
        setIsGetNewsLoading
      );
    }
    return () => {
      isCalledRef.current = true;
      setFiltrationDetails({
        search_query: "",
        only_trashed: 0,
      });
    };
  }, []);

  return (
    <>
      <div className="container">
        <div className={`${!isGetNewsLoading ? "min-vh-100" : ""}`}>
          <div className="mb-2 mb-sm-5">
            <div className="d-flex align-items-center justify-content-between mb-3 mt-3">
              <h1 className="page-heading-title mb-0">NEWS</h1>
              {userCan("fm_new_blog_create") ? (
                <div className="me-3">
                  <Link to="/fm-news/create" className="fu-btn fu-btn-gray">
                    <span className="btn-bg"></span>
                    Create New
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="mt-4">
              <NewsFiltration getNews={getNews} perPage={perPage} orderBy={orderBy} orderType={orderType} />
            </div>
          </div>
          {!isGetNewsLoading ? (
            <>
              <div className="row py-3 d-none d-xxl-flex table-heading-brdr">
                <div className="col-md-1">
                  <h3 className="h6 mb-0 fu-text-white">#ID</h3>
                </div>
                <div className="col-md-4">
                  <h3 className="h6 mb-0 fu-text-white">KEY</h3>
                </div>
                <div className="col-md-3">
                  <h3 className="h6 mb-0 fu-text-white">NEW BLOG NAME</h3>
                </div>
                <div className="col-md-4">
                  <h3 className="h6 mb-0 fu-text-white">OPERATIONS</h3>
                </div>
              </div>
              <div className="table-heading-brdr mt-4 mt-sm-0 d-xxl-none"></div>
              {isPaginateLoading ? (
                <div className="d-flex justify-content-center mt-5">
                  <div className="position-relative mt-5">
                    <LoadingCircle circleCont={85} icon={80} loader={85} dot={85} />
                  </div>
                </div>
              ) : (
                <>
                  {news.data && news.data.length > 0 ? (
                    news.data.map((newBlog, index) => <NewBlogRow key={index} meta={news.meta} newBlog={newBlog} index={index} getNews={getNews} perPage={perPage} pageNum={pageNum} orderBy={orderBy} orderType={orderType} />)
                  ) : news.data && news.data.length === 0 && filtrationDetails.search_query === "" && filtrationDetails.only_trashed === 0 && !isFiltrationLoading ? (
                    <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                      <div>
                        <h2 className="fu-text-3-rem fu-text-yellow">
                          <i className="bi bi-database-add"></i>
                        </h2>
                      </div>
                      <div>
                        <h3 className="h5 fu-text-light-gray">
                          There Are No news
                          {userCan("fm_new_blog_create") ? (
                            <>
                              ,
                              <Link to="/fm-news/create" className="link-dashed ms-2">
                                Create New
                              </Link>
                            </>
                          ) : (
                            ""
                          )}
                        </h3>
                      </div>
                    </div>
                  ) : !isFiltrationLoading ? (
                    <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                      <div>
                        <h2 className="fu-text-3-rem fu-text-yellow">
                          <i className="bi bi-search"></i>
                        </h2>
                      </div>
                      <div>
                        <h3 className="h5 fu-text-light-gray">There are no news matching your search</h3>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="mt-5">{news ? news.meta && news.meta.last_page !== 1 ? <Pagination pages={news.meta.links} pagination={news.meta} getItems={getNews} pathName={"fm-news"} filtrationDetails={filtrationDetails} setIsPaginateLoading={setIsPaginateLoading} setPageNum={setPageNum} per_page={perPage} order_by={orderBy} order_type={orderType} /> : "" : ""}</div>
                </>
              )}
            </>
          ) : (
            <LoadingScreen />
          )}
        </div>
      </div>
    </>
  );
};

export default News;
