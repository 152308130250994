import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Reveal from "../Reveal/Reveal";
import { fmDataContext } from "../../Context/FmData";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import userCan from "../../helpers/userCan";
import Swal from "sweetalert2/dist/sweetalert2.all.min";
import "@sweetalert2/theme-dark/dark.min.css";
import { authenticationContext } from "../../Context/AuthContext";
import { fmRolesContext } from "../../Context/RolesContext";

const RoleRow = (props) => {
  let { meta, role, getRoles, perPage, pageNum, orderBy, orderType } = props;
  let { baseUrl, navigate, editItemExistence } = useContext(fmDataContext);
  let { filtrationDetails } = useContext(fmRolesContext);
  let { user } = useContext(authenticationContext);

  let [isForceDeleteLoading, setIsForceDeleteLoading] = useState(false);

  const forceDeleteRole = (reqMethod, pathName, headers, setLoading) => {
    if (userCan("fm_settings_force_delete")) {
      Swal.fire({
        title: "Are you sure?",
        html: `<p class="mb-0">You will delete <span class="fu-text-light-gray">${role.name}</span> permanently</p><p class="mb-0">You won't be able to revert this!</p>`,
        icon: "warning",
        showCancelButton: true,
        customClass: {
          cancelButton: "popup-btn",
          confirmButton: "popup-btn",
        },
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              getRoles(
                "GET",
                `${baseUrl}fm-roles`,
                {
                  page_num: meta.from === meta.to && meta.last_page > 1 ? meta.current_page - 1 : meta.last_page < pageNum ? 1 : pageNum,
                  per_page: perPage,
                  order_type: orderType,
                  order_by: orderBy,
                  ...filtrationDetails,
                },
                setLoading,
                `${role.name} Deleted Permanently Successfully`
              );
            })
            .catch((error) => {
              navigate("/not-found");
            });
        }
      });
    } else {
      navigate("/not-found");
    }
  };

  return (
    <Reveal>
      <div className="row py-3 align-items-center row-brdr">
        {/* id */}
        <div className="col-12 col-xxl-1 order-2 order-xxl-first">
          <h4 className="h6 mb-0 my-xxl-0 fu-text-white row-info">
            <span className="fw-bold d-d-inline-block d-xxl-none me-2">#ID:</span>
            {role._id}
          </h4>
        </div>
        {/* key */}
        <div className="col-12 col-xxl-4 order-3 order-xxl-1">
          <h4 className="h6 mb-0 my-xxl-0 fu-text-white row-info">
            <span className="fw-bold d-d-inline-block d-xxl-none me-2">KEY:</span>
            {role.key}
          </h4>
        </div>
        {/* role name */}
        <div className="col-12 col-xxl-3 order-first order-xxl-2">
          <div className="d-flex align-items-center mouse-pointer mb-3 mt-2 my-xxl-0" onClick={() => navigate(`/fm-roles/${role.key}`)}>
            <h4 className="h6 mb-0 fu-text-white line-clamp-1">{role.name}</h4>
          </div>
        </div>
        {/* operations */}
        <div className="col-12 col-xxl-4 order-4 order-xxl-3">
          <div className="d-flex align-items-center flex-wrap justify-content-center justify-content-xxl-start mt-3 mt-xxl-0">
            {userCan("fm_settings_show") ? (
              <div className="me-2 mb-2 mb-xxl-0">
                <Link to={`/fm-roles/${role.key}`} className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading ? "disabled" : ""}`} disabled={isForceDeleteLoading ? true : false}>
                  <span className="btn-bg"></span>
                  Show
                </Link>
              </div>
            ) : (
              ""
            )}
            {role.name !== "super_admin" ? (
              <>
                {userCan("fm_settings_update") ? (
                  <div className="me-2 mb-2 mb-xxl-0">
                    <Link to={`/fm-roles/update/${role.key}`} className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading ? "disabled" : ""}`} disabled={isForceDeleteLoading ? true : false}>
                      <span className="btn-bg"></span>
                      Edit
                    </Link>
                  </div>
                ) : (
                  ""
                )}
                {userCan("fm_settings_force_delete") ? (
                  <div className="me-2 mb-2 mb-xxl-0">
                    <button
                      className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading ? "disabled" : ""}`}
                      onClick={() =>
                        forceDeleteRole(
                          "DELETE",
                          `${baseUrl}fm-roles/force-delete/${role.key}`,
                          {
                            Accept: "application/json",
                            "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                            Authorization: `Bearer ${user.accessToken}`,
                          },
                          setIsForceDeleteLoading
                        )
                      }
                      disabled={isForceDeleteLoading ? true : false}>
                      <span className="btn-bg"></span>
                      Delete
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {isForceDeleteLoading ? (
              <div className="position-relative ms-2">
                <LoadingCircle circleCont={28} icon={23} loader={28} dot={28} />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </Reveal>
  );
};

export default RoleRow;
