import React, { createContext, useState } from "react";

export let fmSubscribedEmailsContext = createContext(0);

const SubscribedEmailsContext = ({ children }) => {
  let [subscribedEmailMsg, setSubscribedEmailMsg] = useState("");

  let [filtrationDetails, setFiltrationDetails] = useState({
    search_query: "",
  });

  let [isFiltrationLoading, setIsFiltrationLoading] = useState(false);

  let fmSubscribedEmailsLifting = {
    subscribedEmailMsg,
    setSubscribedEmailMsg,
    filtrationDetails,
    setFiltrationDetails,
    isFiltrationLoading,
    setIsFiltrationLoading,
  };

  return (
    <>
      <fmSubscribedEmailsContext.Provider value={{ ...fmSubscribedEmailsLifting }}>{children}</fmSubscribedEmailsContext.Provider>
    </>
  );
};

export default SubscribedEmailsContext;
