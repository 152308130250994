import React, { createContext, useState } from "react";

export let fmContactMsgsContext = createContext(0);

const ContactMsgsContext = ({ children }) => {
  let [contactMsg, setContactMsg] = useState("");

  let fmContactMsgsLifting = {
    contactMsg,
    setContactMsg,
  };

  return (
    <>
      <fmContactMsgsContext.Provider value={{ ...fmContactMsgsLifting }}>{children}</fmContactMsgsContext.Provider>
    </>
  );
};

export default ContactMsgsContext;
