import React, { useContext, useEffect, useRef, useState } from "react";
import { fmDataContext } from "../../Context/FmData";
import { Link } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import PageHeaderRow from "./PageHeaderRow";
import { fmPageHeadersContext } from "../../Context/PageHeadersContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";
import PageHeadersFilration from "./PageHeadersFilration";

const PageHeaders = () => {
  const isCalledRef = useRef(false);

  let { baseUrl, navigate, getData } = useContext(fmDataContext);

  let [pageHeaders, setPageHeaders] = useState({});

  let [isGetPageHeadersLoading, setIsGetPageHeadersLoading] = useState(true);

  let orderBy = "id";
  let orderType = "asc";

  let { pageHeaderMsg, setPageHeaderMsg, filtrationDetails, isFiltrationLoading, setFiltrationDetails } = useContext(fmPageHeadersContext);

  const getPageHeaders = (reqMethod, pathName, params, setLoading = setIsGetPageHeadersLoading, message = null) => {
    getData(reqMethod, pathName, params, setLoading)
      .then((response) => {
        // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
        // if (response.data.data) {
        //   response.data.data.forEach((pageHeader) => {
        //     if (pageHeader.image.includes("via.placeholder.com")) {
        //       pageHeader.image = pageHeader.image.replace("http://127.0.0.1:8000/storage/", "");
        //     }
        //   });
        // }
        // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
        setPageHeaders(response.data);
        setLoading(false);

        if (pageHeaderMsg.length > 0) {
          toast(pageHeaderMsg, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
            onClose: () => setPageHeaderMsg(""),
          });
        }

        if (message) {
          toast(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            progressStyle: { backgroundColor: "#fdb915" },
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    if (!isCalledRef.current) {
      getPageHeaders(
        "GET",
        `${baseUrl}fm-page-headers`,
        {
          order_type: orderType,
          order_by: orderBy,
        },
        setIsGetPageHeadersLoading
      );
    }

    return () => {
      isCalledRef.current = true;
      setFiltrationDetails({
        search_query: "",
        only_trashed: 0,
      });
    };
  }, []);

  return (
    <>
      <div className="container">
        <div className={`${!isGetPageHeadersLoading ? "min-vh-100" : ""}`}>
          <div className="mb-2 mb-sm-5">
            <div className="d-flex align-items-center justify-content-between mb-3 mt-3">
              <h1 className="fu-text-3-rem fu-text-3-rem-md fu-text-md-2-rem fu-text-sm-2-rem fu-text-1-8-rem-xs fu-text-18-px-xxs fu-text-16-px-xxs fu-fw-800 fu-text-white mb-0">PAGE HEADERS</h1>
              {userCan("fm_page_header_create") ? (
                <div className="me-3">
                  <Link to="/fm-page-headers/create" className="fu-btn fu-btn-gray">
                    <span className="btn-bg"></span>
                    Create New
                  </Link>
                </div>
              ) : (
                ""
              )}
            </div>
            {/* ----------------------------------------------------- */}
            <div className="mt-4">
              <PageHeadersFilration getPageHeaders={getPageHeaders} orderBy={orderBy} orderType={orderType} />
            </div>
            {/* ----------------------------------------------------- */}
          </div>
          {!isGetPageHeadersLoading ? (
            <>
              <div className="row py-3 d-none d-xxl-flex table-heading-brdr">
                <div className="col-md-1">
                  <h3 className="h6 mb-0 fu-text-white">#ID</h3>
                </div>
                <div className="col-md-4">
                  <h3 className="h6 mb-0 fu-text-white">KEY</h3>
                </div>
                <div className="col-md-3">
                  <h3 className="h6 mb-0 fu-text-white">PAGE TITLE</h3>
                </div>
                <div className="col-md-4">
                  <h3 className="h6 mb-0 fu-text-white">OPERATIONS</h3>
                </div>
              </div>
              <div className="table-heading-brdr mt-4 mt-sm-0 d-xxl-none"></div>
              {pageHeaders.data && pageHeaders.data.length > 0 ? (
                pageHeaders.data.map((pageHeader, index) => <PageHeaderRow key={index} pageHeader={pageHeader} index={index} getPageHeaders={getPageHeaders} orderBy={orderBy} orderType={orderType} />)
              ) : pageHeaders.data && pageHeaders.data.length === 0 && filtrationDetails.search_query === "" && filtrationDetails.only_trashed === 0 && !isFiltrationLoading ? (
                <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                  <div>
                    <h2 className="fu-text-3-rem fu-text-yellow">
                      <i className="bi bi-database-add"></i>
                    </h2>
                  </div>
                  <div>
                    <h3 className="h5 fu-text-light-gray">
                      There Are No Page Headers
                      {userCan("fm_page_header_create") ? (
                        <>
                          ,
                          <Link to="/fm-page-headers/create" className="link-dashed ms-2">
                            Create New
                          </Link>
                        </>
                      ) : (
                        ""
                      )}
                    </h3>
                  </div>
                </div>
              ) : !isFiltrationLoading ? (
                <div className="d-flex flex-column justify-content-center align-items-center mt-5 pt-5 item-msg">
                  <div>
                    <h2 className="fu-text-3-rem fu-text-yellow">
                      <i className="bi bi-search"></i>
                    </h2>
                  </div>
                  <div>
                    <h3 className="h5 fu-text-light-gray">There are no page headers matching your search</h3>
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          ) : (
            <LoadingScreen />
          )}
        </div>
      </div>
    </>
  );
};

export default PageHeaders;
