import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { fmDataContext } from "../../Context/FmData";
import Joi from "joi";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { authenticationContext } from "../../Context/AuthContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Reveal from "../Reveal/Reveal";

const CreateTfRelease = () => {
  let { baseUrl, navigate, getAllDataWithoutPaginate, getData } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  const animatedComponents = makeAnimated();

  let releaseRef = useRef();
  let releaseOrderRef = useRef();

  let [joiErrorsList, setJoiErrorsList] = useState([]);

  let [selectedRelease, setSelectedRelease] = useState(null);
  let [selectedReleaseOrder, setSelectedReleaseOrder] = useState(null);

  let [tfReleaseData, setTfReleaseData] = useState({
    title: "",
    order: null,
    release_id: null,
    user_id: null,
  });

  let [isCreateLoading, setIsCreateLoading] = useState(false);

  let [createResponse, setCreateResponse] = useState({});

  let [isGetReleasesLoading, setIsGetReleasesLoading] = useState(true);
  let [releases, setReleases] = useState([]);

  const getTfReleaseData = (e) => {
    const tfRelease = { ...tfReleaseData };
    tfRelease[e.target.name] = e.target.value;
    setTfReleaseData(tfRelease);
  };

  const handleReleaseOrder = () => {
    const tfRelease = { ...tfReleaseData };
    tfRelease.order = selectedReleaseOrder !== null && selectedReleaseOrder.value !== null ? selectedReleaseOrder.value : null;
    setTfReleaseData(tfRelease);
  };

  const handleReleaseSelect = () => {
    const tfRelease = { ...tfReleaseData };
    tfRelease.release_id = selectedRelease !== null && selectedRelease.value !== null ? selectedRelease.value : null;
    setTfReleaseData(tfRelease);
  };

  const getAllReleases = (reqMethod, pathName, params, setLoading = setIsGetReleasesLoading) => {
    getAllDataWithoutPaginate(reqMethod, pathName, params, setLoading)
      .then((response) => {
        setReleases(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  let [tfReleases, setTfReleases] = useState({});

  let [isGetTfReleasesLoading, setIsGetTfReleasesLoading] = useState(true);

  const getTfReleases = (reqMethod, pathName, params, setLoading = setIsGetTfReleasesLoading) => {
    getData(reqMethod, pathName, params, setLoading)
      .then((response) => {
        setTfReleases(response.data);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const createItem = async (e) => {
    e.preventDefault();
    if (userCan("fm_top_five_releases_create")) {
      tfReleaseData.user_id = user.currentUser._id;
      const validationResult = validateCreateTfReleaseForm();
      setCreateResponse({});
      if (validationResult.error) {
        validationResult.error ? setJoiErrorsList(validationResult.error.details) : setJoiErrorsList([]);
      } else {
        setIsCreateLoading(true);
        setJoiErrorsList([]);
        await axios({
          method: "POST",
          url: `${baseUrl}fm-top-five-releases/create`,
          data: tfReleaseData,
          headers: {
            Accept: "application/json",
            "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
            Authorization: `Bearer ${user.accessToken}`,
          },
        })
          .then((response) => {
            if (response.data.msg && response.data.msg.includes("successfully")) {
              getData(
                "GET",
                `${baseUrl}fm-top-five-releases`,
                {
                  order_type: "asc",
                  order_by: "order",
                },
                setIsCreateLoading
              )
                .then((tfResponse) => {
                  setTfReleases(tfResponse.data);
                  setTfReleaseData({
                    title: "",
                    order: null,
                    release_id: null,
                    user_id: null,
                  });
                  e.target.reset();
                  releaseRef.current.clearValue();
                  releaseOrderRef.current.clearValue();
                  setCreateResponse({});
                  setIsCreateLoading(false);
                  toast(response.data.msg, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    progressStyle: { backgroundColor: "#fdb915" },
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                  });
                })
                .catch((error) => {
                  navigate("/not-found");
                });
            } else if (response.data.msg && response.data.msg.includes("failed")) {
              setCreateResponse(response.data);
              toast(response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
              setIsCreateLoading(false);
            } else {
              setCreateResponse(response.data);
              setIsCreateLoading(false);
            }
          })
          .catch((errors) => {
            navigate("/not-found");
          });
      }
    } else {
      navigate("/not-found");
    }
  };

  const validateCreateTfReleaseForm = () => {
    const scheme = Joi.object({
      title: Joi.string().min(1).max(255).allow(null, "").optional(),
      order: Joi.number().min(1).max(5).required().messages({
        "number.base": `"Release Order" is a required field`,
        "number.empty": `"Release Order" is a required field`,
        "any.required": `"Release Order" is a required field`,
      }),
      release_id: Joi.number().required().messages({
        "number.base": `"Release" is a required field`,
        "number.empty": `"Release" is a required field`,
        "any.required": `"Release" is a required field`,
      }),
      user_id: Joi.number(),
    });
    return scheme.validate(tfReleaseData, { abortEarly: false });
  };

  useEffect(() => {
    getAllReleases("GET", `${baseUrl}fm-all-releases`, null, setIsGetReleasesLoading);
    getTfReleases(
      "GET",
      `${baseUrl}fm-top-five-releases`,
      {
        order_type: "asc",
        order_by: "order",
      },
      setIsGetTfReleasesLoading
    );
  }, []);

  useEffect(() => {
    handleReleaseSelect();
  }, [selectedRelease]);

  useEffect(() => {
    handleReleaseOrder();
  }, [selectedReleaseOrder]);

  return (
    <>
      {isGetReleasesLoading || isGetTfReleasesLoading ? (
        <LoadingScreen />
      ) : (
        <>
          {tfReleases.data && tfReleases.data.length >= 5 ? (
            <Reveal>
              <div className="custom-height d-flex flex-column justify-content-center align-items-center tf-limitation-msg">
                <div className="mb-1 mb-sm-2 mb-lg-3 fu-text-4-rem fu-text-yellow">
                  <i className="bi bi-info-circle"></i>
                </div>
                <h1 className="fu-text-2-rem fu-text-white fu-fw-800 mb-0">There are five releases is already exists in top five releases list</h1>
                <h2 className="fu-text-1-rem fu-text-yellow fu-fw-800 mb-0">You can't add more</h2>
              </div>
            </Reveal>
          ) : (
            <div className="container position-relative pt-3 pt-md-4">
              <div className="mb-3 mb-md-4">
                <h1 className="fu-text-3-rem page-heading-title-sm-2-rem fu-text-white fu-fw-800 mb-0">Add Release</h1>
              </div>
              <div className="py-2 mb-4 iu-row-heading create-submit-row">
                <h2 className="mb-0 row-title">Add Release To Top Five Releases List</h2>
              </div>
              <div className="row">
                <div>
                  <div>
                    <Form onSubmit={createItem}>
                      <Row>
                        <Col xl={6}>
                          <Form.Group className="mb-3" controlId="additionalReleaseTitle">
                            <Form.Label className="fu-text-light-gray" data-title="optional">
                              Additional Release Title
                              <span className="optional-input mx-1" data-title="optional">
                                *
                              </span>
                            </Form.Label>
                            <Form.Control type="text" placeholder="Enter Additional Release Title" className="fu-input" name="title" onInput={getTfReleaseData} />
                            {joiErrorsList.map((error, index) =>
                              error.path[0] === "title" ? (
                                <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                                  {error.message.replace("title", "Additional Release Title")}
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </Form.Group>
                        </Col>

                        <Col xl={6}>
                          <div className="mb-3">
                            <div className="fu-text-light-gray w-fit-content mb-2" data-title="required">
                              Select Release
                              <span className="required-input mx-1" data-title="required">
                                *
                              </span>
                            </div>
                            <div className="select-options">
                              <Select
                                ref={releaseRef}
                                classNames={{
                                  control: () => "select-filtration",
                                  option: ({ isSelected, innerProps }) => (isSelected ? (innerProps.style = { backgroundColor: "#fdb915", color: "#191919" }) : (innerProps.style = { backgroundColor: "#ffffff" })),
                                }}
                                placeholder="Select Release...."
                                name="release"
                                onChange={setSelectedRelease}
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                isClearable
                                options={releases.map((release) => ({ value: release.id, label: release.title }))}
                              />
                            </div>
                            {joiErrorsList.map((error, index) =>
                              error.path[0] === "release_id" ? (
                                <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                                  {error.message.replace("release_id", "Release")}
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </div>
                        </Col>
                        <Col xl={6}>
                          <div className="mb-3">
                            <div className="fu-text-light-gray w-fit-content mb-2" data-title="required">
                              Release Order
                              <span className="required-input mx-1" data-title="required">
                                *
                              </span>
                            </div>
                            <div className="select-options">
                              <Select
                                ref={releaseOrderRef}
                                classNames={{
                                  control: () => "select-filtration",
                                  option: ({ isSelected, innerProps }) => (isSelected ? (innerProps.style = { backgroundColor: "#fdb915", color: "#191919" }) : (innerProps.style = { backgroundColor: "#ffffff" })),
                                }}
                                placeholder="Select Release Order...."
                                name="releaseOrder"
                                onChange={setSelectedReleaseOrder}
                                closeMenuOnSelect={true}
                                components={animatedComponents}
                                isClearable
                                options={[
                                  { value: 1, label: 1 },
                                  { value: 2, label: 2 },
                                  { value: 3, label: 3 },
                                  { value: 4, label: 4 },
                                  { value: 5, label: 5 },
                                ]}
                              />
                            </div>
                            {joiErrorsList.map((error, index) =>
                              error.path[0] === "order" ? (
                                <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                                  {error.message.replace("order", "Release Order")}
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </div>
                        </Col>
                      </Row>
                      {/* ------------------------------------ */}

                      {createResponse.errors
                        ? Object.keys(createResponse.errors).map((key) =>
                            createResponse.errors[key].map((error, index) => (
                              <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                                {error.includes("The release id has already been taken.") ? error.replace("The release id has already been taken.", "This Release Is Already Exists In Top Five Releases List") : error}
                              </div>
                            ))
                          )
                        : ""}
                      <div className="d-flex align-items-center mt-4">
                        <div>
                          <Button className={`fu-btn fu-btn-gray ${isCreateLoading ? "disabled" : ""}`} type="submit" disabled={isCreateLoading ? true : false}>
                            <span className="btn-bg"></span>
                            Submit<i className="bi bi-send ms-2"></i>
                          </Button>
                        </div>
                        {isCreateLoading ? (
                          <div className="position-relative ms-3">
                            <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default CreateTfRelease;
