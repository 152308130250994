import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { fmDataContext } from "../../Context/FmData";
import Joi from "joi";
import { useParams } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { authenticationContext } from "../../Context/AuthContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";
import jwtDecode from "jwt-decode";

const UpdateTeamMember = () => {
  let [mounted, setMounted] = useState(false);

  let { teamMemberId } = useParams();
  let { baseUrl, navigate, showData, globalCrumbs, setGlobalCrumbs } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  let [currentTeamMember, setCurrentTeamMember] = useState({});
  let [isTeamMemberLoading, setIsTeamMemberLoading] = useState(true);

  let [selectedImage, setSelectedImage] = useState({});

  let [joiErrorsList, setJoiErrorsList] = useState([]);
  let [imageInputError, setImageInputError] = useState(null);

  let [teamMemberData, setTeamMemberData] = useState({
    member_name: "",
    job_title: "",
    image: selectedImage,
    modify_user_id: null,
    facebook_url: null,
    instagram_url: null,
    threads_url: null,
    x_url: null,
    linkedin_url: null,
  });

  let [isUpdateLoading, setIsUpdateLoading] = useState(false);

  let [updateResponse, setUpdateResponse] = useState({});

  let [navigateTimeOut, setNavigateTimeOut] = useState("");

  let userToken = jwtDecode(localStorage.getItem("userToken")).accessToken;

  const getTeamMember = (reqMethod, pathName, setLoading = setIsTeamMemberLoading, headers) => {
    showData(reqMethod, pathName, setLoading, headers)
      .then((response) => {
        // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
        // if (response.data.data.member_image.includes("via.placeholder.com")) {
        //   response.data.data.member_image = response.data.data.member_image.replace("http://127.0.0.1:8000/storage/", "");
        // }
        // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
        let teamMemberResponse = response.data.data;
        setCurrentTeamMember(teamMemberResponse);
        setTeamMemberData({
          member_name: teamMemberResponse.member_name ? teamMemberResponse.member_name : "",
          job_title: teamMemberResponse.member_job_title ? teamMemberResponse.member_job_title : "",
          image: selectedImage,
          modify_user_id: null,
          facebook_url: teamMemberResponse && teamMemberResponse.member_social_media_links.facebook_url ? teamMemberResponse.member_social_media_links.facebook_url : null,
          instagram_url: teamMemberResponse && teamMemberResponse.member_social_media_links.instagram_url ? teamMemberResponse.member_social_media_links.instagram_url : null,
          threads_url: teamMemberResponse && teamMemberResponse.member_social_media_links.threads_url ? teamMemberResponse.member_social_media_links.threads_url : null,
          x_url: teamMemberResponse && teamMemberResponse.member_social_media_links.x_url ? teamMemberResponse.member_social_media_links.x_url : null,
          linkedin_url: teamMemberResponse && teamMemberResponse.member_social_media_links.linkedin_url ? teamMemberResponse.member_social_media_links.linkedin_url : null,
        });
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const getTeamMemberData = (e) => {
    const teamMember = { ...teamMemberData };
    teamMember[e.target.name] = e.target.value;
    setTeamMemberData(teamMember);
  };

  const handleImageSelect = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const updateItem = async (e) => {
    e.preventDefault();
    if (userCan("fm_team_member_update")) {
      teamMemberData.modify_user_id = user.currentUser._id;

      const validationResult = validateUpdateTeamMemberForm();
      let imageError = null;
      setUpdateResponse({});

      if (selectedImage && selectedImage.name) {
        teamMemberData.image = selectedImage;
        if (!["image/png", "image/jpg", "image/jpeg", "image/gif"].includes(teamMemberData.image.type)) {
          imageError = "File must be an Image and from These extensions (png, jpg, jpeg, gif)";
        } else if (teamMemberData.image.size > 5 * 1024 * 1024) {
          imageError = "Image Size Must Be Less Than 5 MB";
        }
      } else {
        teamMemberData.image = null;
      }

      if (validationResult.error || imageError) {
        validationResult.error ? setJoiErrorsList(validationResult.error.details) : setJoiErrorsList([]);
        setImageInputError(imageError);
      } else {
        setIsUpdateLoading(true);
        setImageInputError(null);
        setJoiErrorsList([]);

        await axios({
          method: "POST",
          url: `${baseUrl}fm-team-members/update/${currentTeamMember.key}`,
          data: teamMemberData,
          params: {
            _method: "PUT",
          },
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
            "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
            Authorization: `Bearer ${user.accessToken}`,
          },
        })
          .then((response) => {
            setUpdateResponse(response.data);
            setIsUpdateLoading(false);

            if (response.data.msg && response.data.msg.includes("successfully")) {
              let timeOut = setTimeout(() => {
                setUpdateResponse({});
                navigate(`/fm-team-members/${currentTeamMember.key}?archived=0`);
              }, 4000);
              setNavigateTimeOut(timeOut);

              setTeamMemberData({
                member_name: "",
                job_title: "",
                image: {},
                modify_user_id: null,
                facebook_url: null,
                instagram_url: null,
                threads_url: null,
                x_url: null,
                linkedin_url: null,
              });
              teamMemberData.member_name = "";
              teamMemberData.job_title = "";
              teamMemberData.image = {};
              teamMemberData.facebook_url = null;
              teamMemberData.instagram_url = null;
              teamMemberData.threads_url = null;
              teamMemberData.x_url = null;
              teamMemberData.linkedin_url = null;

              setSelectedImage(null);
              e.target.reset();
              toast(`${response.data.msg}, navigating...`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                containerId: "update_done_toast",
              });
            } else if (response.data.msg && response.data.msg.includes("failed")) {
              toast(response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          })
          .catch((errors) => {
            navigate("/not-found");
          });
      }
    } else {
      navigate("/not-found");
    }
  };

  const validateUpdateTeamMemberForm = () => {
    const scheme = Joi.object({
      member_name: Joi.string().min(1).max(255).required(),
      job_title: Joi.string().min(1).max(1500).required(),
      image: Joi.any(),
      modify_user_id: Joi.number(),
      facebook_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Facebook URL is not valid").allow(null, "").optional(),
      instagram_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Instagram URL is not valid").allow(null, "").optional(),
      threads_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("threads URL is not valid").allow(null, "").optional(),
      x_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("X URL is not valid").allow(null, "").optional(),
      linkedin_url: Joi.string().pattern(new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|(www\\.)?){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?")).message("Linkedin URL is not valid").allow(null, "").optional(),
    });
    return scheme.validate(teamMemberData, { abortEarly: false });
  };

  useEffect(() => {
    getTeamMember("GET", `${baseUrl}fm-team-member/${teamMemberId}`, setIsTeamMemberLoading, {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
      Authorization: `Bearer ${userToken}`,
    });
    setMounted(true);
  }, []);

  useEffect(() => {
    return () => {
      if (mounted) {
        clearTimeout(navigateTimeOut);
      }
    };
  }, [navigateTimeOut]);

  useEffect(() => {
    if (mounted && currentTeamMember) {
      let globalCrumbsUpdated = globalCrumbs.map((crumb) => {
        if (crumb.name === teamMemberId.toUpperCase()) {
          crumb.name = currentTeamMember.member_name.toUpperCase();
        }
        return crumb;
      });
      setGlobalCrumbs(globalCrumbsUpdated);
    }
  }, [currentTeamMember]);

  return (
    <>
      {isTeamMemberLoading ? (
        <LoadingScreen />
      ) : (
        <div className="container position-relative pt-3 pt-md-4">
          <div className="mb-3 mb-md-4">
            <h1 className="fu-text-3-rem fu-text-3-rem-md fu-text-md-2-rem fu-text-sm-2-rem fu-text-1-8-rem-xs fu-text-18-px-xxs fu-text-16-px-xxs fu-fw-800 fu-text-white mb-0">Update Team Member</h1>
          </div>
          <div className="py-2 mb-4 iu-row-heading create-submit-row">
            <h2 className="mb-0 row-title">Edit ( {currentTeamMember.member_name} ) data</h2>
          </div>
          <div className="row">
            <div>
              <div>
                <Form onSubmit={updateItem}>
                  <Row>
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="teamMemberName">
                        <Form.Label className="fu-text-light-gray" data-title="required">
                          Team Member Name
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Team Member Name" className="fu-input" name="member_name" value={teamMemberData.member_name ? teamMemberData.member_name : ""} onInput={getTeamMemberData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "mamber_name" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("member_name", "Team Member name")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col xl={6}>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <Form.Group className="mb-3" controlId="teamMemberImage">
                            <Form.Label className="fu-text-light-gray" data-title="required">
                              Team Member Image
                              <span className="required-input mx-1" data-title="required">
                                *
                              </span>
                            </Form.Label>
                            <Form.Control type="file" placeholder="choose Team Member Image" className="fu-input" name="image" onInput={handleImageSelect} />

                            {imageInputError !== null ? <div className="alert alert-danger py-1 px-2 mt-2 mb-0">{imageInputError}</div> : ""}
                          </Form.Group>
                        </div>
                        <div className="ms-2 align-self-center align-self-lg-center align-self-xl-baseline">
                          <div className="table-item-img-container">
                            <img src={currentTeamMember.member_image} alt="artist" className="w-100 rounded-circle" />
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="teamMemberJobTitle">
                        <Form.Label className="fu-text-light-gray" data-title="required">
                          Team Member Job Title
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" className="fu-input" placeholder="Enter Team Member Job Title" name="job_title" value={teamMemberData.job_title ? teamMemberData.job_title : ""} onInput={getTeamMemberData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "job_title" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("job_title", "Team Member Job")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>

                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="facebookUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          FaceBook URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Team Member FaceBook URL" className="fu-input" name="facebook_url" value={teamMemberData.facebook_url ? teamMemberData.facebook_url : ""} onInput={getTeamMemberData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "facebook_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="instagramUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Instagram URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter TeamMember Instagram URL" className="fu-input" name="instagram_url" value={teamMemberData.instagram_url ? teamMemberData.instagram_url : ""} onInput={getTeamMemberData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "instagram_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="threadsUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Threads URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Team Member Threads URL" className="fu-input" name="threads_url" value={teamMemberData.threads_url ? teamMemberData.threads_url : ""} onInput={getTeamMemberData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "threads_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="xUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          X URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Team Member X URL" className="fu-input" name="x_url" value={teamMemberData.x_url ? teamMemberData.x_url : ""} onInput={getTeamMemberData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "x_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="linkedInUrl">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          LinkedIn URL
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Team Member LinkedIn URL" className="fu-input" name="linkedin_url" value={teamMemberData.linkedin_url ? teamMemberData.linkedin_url : ""} onInput={getTeamMemberData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "linkedin_url" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replaceAll("_", " ")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  {updateResponse.errors
                    ? Object.keys(updateResponse.errors).map((key) =>
                        updateResponse.errors[key].map((error, index) => (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error}
                          </div>
                        ))
                      )
                    : ""}

                  <div className="d-flex align-items-center mt-4">
                    <div>
                      <Button className={`fu-btn fu-btn-gray ${isUpdateLoading ? "disabled" : ""}`} type="submit" disabled={isUpdateLoading ? true : false}>
                        <span className="btn-bg"></span>
                        Submit<i className="bi bi-send ms-2"></i>
                      </Button>
                    </div>
                    {isUpdateLoading ? (
                      <div className="position-relative ms-3">
                        <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateTeamMember;
