import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { fmDataContext } from "../../Context/FmData";
import Joi from "joi";
import { useParams } from "react-router-dom";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { authenticationContext } from "../../Context/AuthContext";
import userCan from "../../helpers/userCan";
import { toast } from "react-toastify";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import jwtDecode from "jwt-decode";

const UpdateTfRelease = () => {
  let [mounted, setMounted] = useState(false);

  let { tfReleaseId } = useParams();
  let { baseUrl, navigate, showData, globalCrumbs, setGlobalCrumbs, getAllDataWithoutPaginate } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  let [currentTfRelease, setCurrentTfRelease] = useState({});
  let [isTfReleaseLoading, setIsTfReleaseLoading] = useState(true);

  let [joiErrorsList, setJoiErrorsList] = useState([]);

  let [tfReleaseData, setTfReleaseData] = useState({
    title: "",
    order: null,
    release_id: null,
    modify_user_id: null,
  });

  let [isUpdateLoading, setIsUpdateLoading] = useState(false);

  let [updateResponse, setUpdateResponse] = useState({});

  const animatedComponents = makeAnimated();

  let releaseRef = useRef();
  let releaseOrderRef = useRef();

  let [selectedRelease, setSelectedRelease] = useState(null);
  let [selectedReleaseOrder, setSelectedReleaseOrder] = useState(null);

  let [isGetReleasesLoading, setIsGetReleasesLoading] = useState(true);
  let [releases, setReleases] = useState([]);

  let [navigateTimeOut, setNavigateTimeOut] = useState("");

  let userToken = jwtDecode(localStorage.getItem("userToken")).accessToken;

  const getTfRelease = (reqMethod, pathName, setLoading = setIsTfReleaseLoading, headers) => {
    showData(reqMethod, pathName, setLoading, headers)
      .then((response) => {
        let tfReleaseResponse = response.data.data;

        setCurrentTfRelease(tfReleaseResponse);
        setTfReleaseData({
          title: tfReleaseResponse.additional_release_title ? tfReleaseResponse.additional_release_title : "",
          order: tfReleaseResponse.release_order ? tfReleaseResponse.release_order : null,
          release_id: tfReleaseResponse.release_data._id ? tfReleaseResponse.release_data._id : null,
          modify_user_id: null,
        });
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const getTfReleaseData = (e) => {
    const tfRelease = { ...tfReleaseData };
    tfRelease[e.target.name] = e.target.value;
    setTfReleaseData(tfRelease);
  };

  const handleReleaseOrder = () => {
    const tfRelease = { ...tfReleaseData };
    tfRelease.order = selectedReleaseOrder !== null && selectedReleaseOrder.value !== null ? selectedReleaseOrder.value : null;
    setTfReleaseData(tfRelease);
  };

  const handleReleaseSelect = () => {
    const tfRelease = { ...tfReleaseData };
    tfRelease.release_id = selectedRelease !== null && selectedRelease.value !== null ? selectedRelease.value : null;
    setTfReleaseData(tfRelease);
  };

  const getAllReleases = (reqMethod, pathName, params, setLoading = setIsGetReleasesLoading) => {
    getAllDataWithoutPaginate(reqMethod, pathName, params, setLoading)
      .then((response) => {
        setReleases(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  const updateItem = async (e) => {
    e.preventDefault();
    if (userCan("fm_top_five_releases_update")) {
      tfReleaseData.modify_user_id = user.currentUser._id;
      const validationResult = validateUpdateTfReleaseForm();
      setUpdateResponse({});
      if (validationResult.error) {
        validationResult.error ? setJoiErrorsList(validationResult.error.details) : setJoiErrorsList([]);
      } else {
        setIsUpdateLoading(true);
        setJoiErrorsList([]);

        await axios({
          method: "POST",
          url: `${baseUrl}fm-top-five-releases/update/${currentTfRelease.key}`,
          data: tfReleaseData,
          params: {
            _method: "PUT",
          },
          headers: {
            Accept: "application/json",
            "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
            Authorization: `Bearer ${user.accessToken}`,
          },
        })
          .then((response) => {
            setUpdateResponse(response.data);
            setIsUpdateLoading(false);
            if (response.data.msg && response.data.msg.includes("successfully")) {
              let timeOut = setTimeout(() => {
                setUpdateResponse({});
                navigate(`/fm-top-five-releases/${currentTfRelease.key}`);
              }, 4000);
              setNavigateTimeOut(timeOut);
              setTfReleaseData({
                title: "",
                order: null,
                release_id: null,
                modify_user_id: null,
              });
              e.target.reset();
              releaseRef.current.clearValue();
              releaseOrderRef.current.clearValue();
              toast(`${response.data.msg}, navigating...`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
                containerId: "update_done_toast",
              });
            } else if (response.data.msg && response.data.msg.includes("failed")) {
              toast(response.data.msg, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                progressStyle: { backgroundColor: "#fdb915" },
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
            }
          })
          .catch((errors) => {
            navigate("/not-found");
          });
      }
    } else {
      navigate("/not-found");
    }
  };

  const validateUpdateTfReleaseForm = () => {
    const scheme = Joi.object({
      title: Joi.string().min(1).max(255).allow(null, "").optional(),
      order: Joi.number().min(1).max(5).required().messages({
        "number.base": `"Release Order" is a required field`,
        "number.empty": `"Release Order" is a required field`,
        "any.required": `"Release Order" is a required field`,
      }),
      release_id: Joi.number().required().messages({
        "number.base": `"Release" is a required field`,
        "number.empty": `"Release" is a required field`,
        "any.required": `"Release" is a required field`,
      }),
      modify_user_id: Joi.number(),
    });
    return scheme.validate(tfReleaseData, { abortEarly: false });
  };

  useEffect(() => {
    getTfRelease("GET", `${baseUrl}fm-one-from-top-five-releases/${tfReleaseId}`, setIsTfReleaseLoading, {
      Accept: "application/json",
      "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
      Authorization: `Bearer ${userToken}`,
    });
    getAllReleases("GET", `${baseUrl}fm-all-releases`, null, setIsGetReleasesLoading);
    setMounted(true);
  }, []);

  useEffect(() => {
    return () => {
      if (mounted) {
        clearTimeout(navigateTimeOut);
      }
    };
  }, [navigateTimeOut]);

  useEffect(() => {
    if (mounted && currentTfRelease) {
      let globalCrumbsUpdated = globalCrumbs.map((crumb) => {
        if (crumb.name === tfReleaseId.toUpperCase()) {
          crumb.name = currentTfRelease.additional_release_title ? currentTfRelease.additional_release_title.toUpperCase() : currentTfRelease.release_data.release_title.toUpperCase();
        }
        return crumb;
      });
      setGlobalCrumbs(globalCrumbsUpdated);
      if (releaseRef.current && currentTfRelease.release_data) {
        releaseRef.current.setValue({ value: currentTfRelease.release_data._id, label: currentTfRelease.release_data.release_title });
      }
      if (releaseOrderRef.current && currentTfRelease.release_order) {
        releaseOrderRef.current.setValue({ value: currentTfRelease.release_order, label: currentTfRelease.release_order });
      }
    }
  }, [currentTfRelease]);

  useEffect(() => {
    handleReleaseSelect();
  }, [selectedRelease]);

  useEffect(() => {
    handleReleaseOrder();
  }, [selectedReleaseOrder]);

  return (
    <>
      {isTfReleaseLoading || isGetReleasesLoading ? (
        <LoadingScreen />
      ) : (
        <div className="container position-relative pt-3 pt-md-4">
          <div className="mb-3 mb-md-4">
            <h1 className="fu-text-3-rem page-heading-title-sm-2-rem fu-text-white fu-fw-800 mb-0">Update Release</h1>
          </div>
          <div className="py-2 mb-4 iu-row-heading create-submit-row">
            <h2 className="mb-0 row-title">Edit ( {currentTfRelease.additional_release_title ? currentTfRelease.additional_release_title : currentTfRelease.release_data.release_title} ) record in top 5 records</h2>
          </div>
          <div className="row">
            <div>
              <div>
                <Form onSubmit={updateItem}>
                  <Row>
                    <Col xl={6}>
                      <Form.Group className="mb-3" controlId="additionalReleaseName">
                        <Form.Label className="fu-text-light-gray" data-title="optional">
                          Additional Release Title
                          <span className="optional-input mx-1" data-title="optional">
                            *
                          </span>
                        </Form.Label>
                        <Form.Control type="text" placeholder="Enter Additional Release Title" className="fu-input" name="title" value={tfReleaseData.title ? tfReleaseData.title : ""} onInput={getTfReleaseData} />
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "title" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("title", "Additional Release title")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </Form.Group>
                    </Col>

                    <Col xl={6}>
                      <div className="mb-3">
                        <div className="fu-text-light-gray w-fit-content mb-2" data-title="required">
                          Select Release
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </div>
                        <div className="select-options">
                          <Select
                            ref={releaseRef}
                            classNames={{
                              control: () => "select-filtration",
                              option: ({ isSelected, innerProps }) => (isSelected ? (innerProps.style = { backgroundColor: "#fdb915", color: "#191919" }) : (innerProps.style = { backgroundColor: "#ffffff" })),
                            }}
                            placeholder="Select Release...."
                            name="release"
                            onChange={setSelectedRelease}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            isClearable
                            options={releases.map((release) => ({ value: release.id, label: release.title }))}
                          />
                        </div>
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "release_id" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("release_id", "Release")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </div>
                    </Col>
                    <Col xl={6}>
                      <div className="mb-3">
                        <div className="fu-text-light-gray w-fit-content mb-2" data-title="required">
                          Release Order
                          <span className="required-input mx-1" data-title="required">
                            *
                          </span>
                        </div>
                        <div className="select-options">
                          <Select
                            ref={releaseOrderRef}
                            classNames={{
                              control: () => "select-filtration",
                              option: ({ isSelected, innerProps }) => (isSelected ? (innerProps.style = { backgroundColor: "#fdb915", color: "#191919" }) : (innerProps.style = { backgroundColor: "#ffffff" })),
                            }}
                            placeholder="Select Release Order...."
                            name="releaseOrder"
                            onChange={setSelectedReleaseOrder}
                            closeMenuOnSelect={true}
                            components={animatedComponents}
                            isClearable
                            options={[
                              { value: 1, label: 1 },
                              { value: 2, label: 2 },
                              { value: 3, label: 3 },
                              { value: 4, label: 4 },
                              { value: 5, label: 5 },
                            ]}
                          />
                        </div>
                        {joiErrorsList.map((error, index) =>
                          error.path[0] === "order" ? (
                            <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                              {error.message.replace("order", "Release Order")}
                            </div>
                          ) : (
                            ""
                          )
                        )}
                      </div>
                    </Col>
                  </Row>
                  {/* ------------------------------------ */}

                  {updateResponse.errors
                    ? Object.keys(updateResponse.errors).map((key) =>
                        updateResponse.errors[key].map((error, index) => (
                          <div key={index} className="alert alert-danger py-1 px-2 mt-2 mb-0">
                            {error}
                          </div>
                        ))
                      )
                    : ""}

                  <div className="d-flex align-items-center mt-4">
                    <div>
                      <Button className={`fu-btn fu-btn-gray ${isUpdateLoading ? "disabled" : ""}`} type="submit" disabled={isUpdateLoading ? true : false}>
                        <span className="btn-bg"></span>
                        Submit<i className="bi bi-send ms-2"></i>
                      </Button>
                    </div>
                    {isUpdateLoading ? (
                      <div className="position-relative ms-3">
                        <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateTfRelease;
