import React from "react";
import ReactPlayer from "react-player";
import Reveal from "../Reveal/Reveal";
import { Link } from "react-router-dom";

const VideoCard = ({ video }) => {
  return (
    <div className="h-100">
      <Reveal>
        <div className="p-2 p-sm-4 video-container h-100">
          <div className="release-bg" style={{ backgroundImage: `url(https://img.youtube.com/vi/${video.youtube_video_id}/sddefault.jpg)` }}></div>
          <div className="yt-iframe-box">
            <div className="i-frame-container mb-2">
              <div className="i-frame-release-poster">
                <img src={`https://img.youtube.com/vi/${video.youtube_video_id}/sddefault.jpg`} alt="release poster" className="w-100" />
              </div>
              <ReactPlayer
                url={`https://www.youtube-nocookie.com/embed/${video.youtube_video_id}?autoplay=0&disablekb=1&playsinline=1&cc_load_policy=0&cc_lang_pref=auto&widget_referrer=https%3A%2F%2Ffmcp.futurisingmusic.com%2F&&rel=0&showinfo=0&iv_load_policy=3&modestbranding=1&customControls=true&noCookie=true&origin=https%3A%2F%2Ffmcp.futurisingmusic.com%2F&enablejsapi=1&widgetid=1`}
                playIcon={
                  <div className="i-frame-play-icon">
                    <i className="bi bi-play-fill play-icon"></i>
                  </div>
                }
                light={true}
                controls
                playing={true}
                width={"100%"}
                height={"100%"}
              />
            </div>
            <div className="fu-video-caption">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <h3 className="mb-0 fu-release-video-name">{video.video_title}</h3>
                  {video.video_label !== null ? <p className="mb-0 fu-channel-video-name">{video.video_label.label_name}</p> : ""}
                  {video.video_artists !== null ? <p className="mb-0 release-artist">{video.video_artists.map((artist, index) => `${artist.artist_name}${index < video.video_artists.length - 1 ? "," : ""} `)} </p> : ""}
                </div>
                <div className="ms-3">
                  <Link to={`/fm-videos/${video.video_key}`} className="fu-btn fu-btn-gray">
                    <span className="btn-bg"></span>
                    Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Reveal>
    </div>
  );
};

export default VideoCard;
