import React, { createContext, useState } from "react";

export let fmTfReleasesContext = createContext(0);

const TfReleasesContext = ({ children }) => {
  let [tfReleaseMsg, setTfReleaseMsg] = useState("");

  let fmTfReleasesLifting = {
    tfReleaseMsg,
    setTfReleaseMsg,
  };

  return (
    <>
      <fmTfReleasesContext.Provider value={{ ...fmTfReleasesLifting }}>{children}</fmTfReleasesContext.Provider>
    </>
  );
};

export default TfReleasesContext;
