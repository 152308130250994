import jwtDecode from "jwt-decode";
import React, { createContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

export let authenticationContext = createContext(0);

const AuthContext = ({ children }) => {
  let navigate = useNavigate();
  let authApiBaseUrl = process.env.REACT_APP_FM_API_AUTH_BASE_URL;

  let [user, setUser] = useState(null);
  let [userPermissions, setUserPermissions] = useState([]);

  let saveUserData = () => {
    let encodedUserData = localStorage.getItem("userToken");
    try {
      let decodedUserData = jwtDecode(encodedUserData);
      if (!decodedUserData.accessToken || !decodedUserData.currentUser || !decodedUserData.currentUser._id || !decodedUserData.currentUser.key || !decodedUserData.currentUser.name || !decodedUserData.currentUser.email || !decodedUserData.currentUser.role || !decodedUserData.currentUser.role_name || !decodedUserData.currentUser.permissions || !decodedUserData.currentUser.status) {
        localStorage.removeItem("userToken");
        return <Navigate to="/login" />;
      }
      setUser(decodedUserData);
      let permissions = decodedUserData.currentUser.permissions.map((permission) => permission.name);
      setUserPermissions(permissions);
      return <Navigate to="/" />;
    } catch (error) {
      localStorage.removeItem("userToken");
      return <Navigate to="/login" />;
    }
  };

  useEffect(() => {
    if (localStorage.getItem("userToken")) {
      saveUserData();
    }
  }, []);

  let authLifting = {
    navigate,
    saveUserData,
    user,
    setUser,
    authApiBaseUrl,
    userPermissions,
    setUserPermissions,
  };

  return (
    <>
      <authenticationContext.Provider value={{ ...authLifting }}>{children}</authenticationContext.Provider>
    </>
  );
};

export default AuthContext;
