import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { fmDataContext } from "../../Context/FmData";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import LoadingCircle from "../LoadingCircle/LoadingCircle";
import { fmNewsContext } from "../../Context/NewsContext";
import userCan from "../../helpers/userCan";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import Swal from "sweetalert2/dist/sweetalert2.all.min";
import "@sweetalert2/theme-dark/dark.min.css";
import jwtDecode from "jwt-decode";
import styles from "./NewDetails.module.scss";
import { authenticationContext } from "../../Context/AuthContext";
import NewBlogCard from "../NewBlogCard/NewBlogCard";

const NewDetails = () => {
  let [mounted, setMounted] = useState(false);

  let { newBlogId } = useParams();
  let [params, setParams] = useSearchParams();

  let { baseUrl, navigate, showData, editItemExistence, getData, globalCrumbs, setGlobalCrumbs } = useContext(fmDataContext);
  let { user } = useContext(authenticationContext);

  let [currentNewBlog, setCurrentNewBlog] = useState({});
  let onlyTrashed = params.get("archived");
  let [isCurrentNewBlogLoading, setIsCurrentNewBlogLoading] = useState(true);

  let [isSoftDeleteLoading, setIsSoftDeleteLoading] = useState(false);
  let [isForceDeleteLoading, setIsForceDeleteLoading] = useState(false);
  let [isRestoreLoading, setIsRestoreLoading] = useState(false);

  let { setNewBlogMsg } = useContext(fmNewsContext);

  let userToken = jwtDecode(localStorage.getItem("userToken")).accessToken;

  let newsSearchInput = useRef();

  let [newsSwiperCount, setNewsSwiperCount] = useState(2);

  const setNewsSlidesCount = () => {
    if (window.innerWidth > 1400) {
      setNewsSwiperCount(2);
    } else if (window.innerWidth < 1400 && window.innerWidth >= 1200) {
      setNewsSwiperCount(2);
    } else if (window.innerWidth < 1200 && window.innerWidth > 991.9) {
      setNewsSwiperCount(1);
    } else if (window.innerWidth < 992 && window.innerWidth > 767.9) {
      setNewsSwiperCount(1);
    } else if (window.innerWidth < 768 && window.innerWidth > 575.9) {
      setNewsSwiperCount(1);
    } else if (window.innerWidth < 576 && window.innerWidth > 0) {
      setNewsSwiperCount(1);
    }
  };

  const softDeleteNewBlog = (reqMethod, pathName, headers, setLoading) => {
    if (userCan("fm_new_blog_soft_delete")) {
      Swal.fire({
        title: "Are you sure?",
        html: `<p class="mb-0 line-clamp-2">You will delete <span class="fu-text-light-gray">${currentNewBlog.data.new_title}</span></p>`,
        icon: "warning",
        showCancelButton: true,
        customClass: {
          cancelButton: "popup-btn",
          confirmButton: "popup-btn",
        },
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              setNewBlogMsg(`${currentNewBlog.data.new_title.slice(0, currentNewBlog.data.new_title.length > 20 ? 20 : currentNewBlog.data.new_title.length)}... Deleted Successfully`);
              navigate("/fm-news");
            })
            .catch((error) => {
              navigate("/not-found");
            });
        }
      });
    } else {
      navigate("/not-found");
    }
  };

  const restoreNewBlog = (reqMethod, pathName, headers, setLoading) => {
    if (userCan("fm_new_blog_restore_trashed")) {
      Swal.fire({
        title: "Are you sure?",
        html: `<p class="mb-0 line-clamp-2">You will restore <span class="fu-text-light-gray">${currentNewBlog.data.new_title}</span></p>`,
        icon: "question",
        showCancelButton: true,
        customClass: {
          cancelButton: "popup-btn",
          confirmButton: "popup-btn",
        },
        confirmButtonText: "Yes, restore it!",
      }).then((result) => {
        if (result.isConfirmed) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              setNewBlogMsg(`${currentNewBlog.data.new_title.slice(0, currentNewBlog.data.new_title.length > 20 ? 20 : currentNewBlog.data.new_title.length)}... Restored Successfully`);
              navigate("/fm-news");
            })
            .catch((error) => {
              navigate("/not-found");
            });
        }
      });
    } else {
      navigate("/not-found");
    }
  };

  const forceDeleteNewBlog = (reqMethod, pathName, headers, setLoading) => {
    Swal.fire({
      title: "Are you sure?",
      html: `<p class="mb-0 line-clamp-2">You will delete <span class="fu-text-light-gray">${currentNewBlog.data.new_title}</span> permanently</p><p class="mb-0">You won't be able to revert this!</p>`,
      icon: "warning",
      showCancelButton: true,
      customClass: {
        cancelButton: "popup-btn",
        confirmButton: "popup-btn",
      },
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        if (userCan("fm_new_blog_force_delete")) {
          editItemExistence(reqMethod, pathName, headers, setLoading)
            .then((response) => {
              setNewBlogMsg(`${currentNewBlog.data.new_title.slice(0, currentNewBlog.data.new_title.length > 20 ? 20 : currentNewBlog.data.new_title.length)}... Deleted Permanently Successfully`);
              navigate("/fm-news");
            })
            .catch((error) => {
              navigate("/not-found");
            });
        } else {
          navigate("/not-found");
        }
      }
    });
  };

  const getNewBlog = (reqMethod, pathName, setLoading = setIsCurrentNewBlogLoading, headers, params) => {
    showData(reqMethod, pathName, setLoading, headers, params)
      .then((response) => {
        // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
        // if (response.data.data.new_image.includes("via.placeholder.com")) {
        //   response.data.data.new_image = response.data.data.new_image.replace("http://127.0.0.1:8000/storage/", "");
        // }
        // if (response.data.data.related_news !== null) {
        //   response.data.data.related_news.forEach((newBlog) => {
        //     if (newBlog.new_image.includes("via.placeholder.com")) {
        //       newBlog.new_image = newBlog.new_image.replace("http://127.0.0.1:8000/storage/", "");
        //     }
        //   });
        // }
        // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
        setCurrentNewBlog(response.data);
        setLoading(false);
      })
      .catch((error) => {
        navigate("/not-found");
      });
  };

  useEffect(() => {
    getNewBlog(
      "GET",
      `${baseUrl}fm-new/${newBlogId}`,
      setIsCurrentNewBlogLoading,
      {
        Accept: "application/json",
        "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
        Authorization: `Bearer ${userToken}`,
      },
      {
        only_trashed: onlyTrashed,
      }
    );
    setMounted(true);

    setNewsSlidesCount();
    window.addEventListener("resize", function () {
      setNewsSlidesCount();
    });
  }, []);

  useEffect(() => {
    if (mounted) {
      getNewBlog(
        "GET",
        `${baseUrl}fm-new/${newBlogId}`,
        setIsCurrentNewBlogLoading,
        {
          Accept: "application/json",
          "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
          Authorization: `Bearer ${userToken}`,
        },
        {
          only_trashed: onlyTrashed,
        }
      );
    }
  }, [newBlogId]);

  useEffect(() => {
    if (mounted && currentNewBlog.data) {
      let globalCrumbsUpdated = globalCrumbs.map((crumb) => {
        if (crumb.name === newBlogId.toUpperCase()) {
          crumb.name = `${currentNewBlog.data.new_title
            .split(" ")
            .slice(0, currentNewBlog.data.new_title.split(" ").length > 5 ? 5 : currentNewBlog.data.new_title.split(" ").length - 1)
            .join(" ")
            .toUpperCase()}...`;
        }
        return crumb;
      });
      setGlobalCrumbs(globalCrumbsUpdated);
    }
  }, [currentNewBlog]);

  let [newsSearchTimeOut, setNewsSearchTimeOut] = useState("");
  let [newsSearchText, setNewsSearchText] = useState("");
  let [isNewsSearchLoading, setIsNewsSearchLoading] = useState(false);

  const getSearchNews = () => {
    setIsNewsSearchLoading(true);
    if (newsSearchTimeOut) {
      clearTimeout(newsSearchTimeOut);
    }
    let getNewsSearchTimeOut = setTimeout(() => {
      getData(
        "GET",
        `${baseUrl}fm-related-news`,
        {
          search_query: newsSearchText,
          new_key: currentNewBlog.data.new_key,
        },
        setIsNewsSearchLoading
      )
        .then((response) => {
          // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
          // if (response.data.data !== null && response.data.data.length > 0) {
          //   response.data.data.forEach((newBlog) => {
          //     if (newBlog.new_image.includes("via.placeholder.com")) {
          //       newBlog.new_image = newBlog.new_image.replace("http://127.0.0.1:8000/storage/", "");
          //     }
          //   });
          // }
          // ------------------ REMOVE BEFORE PRODUCTION --------------------------------
          currentNewBlog.data.related_news = response.data.data;
          setIsNewsSearchLoading(false);
        })
        .catch((error) => {
          navigate("/not-found");
        });
    }, 1500);
    setNewsSearchTimeOut(getNewsSearchTimeOut);
  };

  useEffect(() => {
    if (mounted) {
      getSearchNews();
    }
  }, [newsSearchText]);

  return (
    <>
      {isCurrentNewBlogLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <div className={styles.blog_heading_container}>
            <div className={styles.blog_img_section}>
              <div className="container">
                <div className="pt-4">
                  <div className={styles.blog_pic_container}>
                    <img src={currentNewBlog.data.new_image} alt="blog" className="w-100 h-100" />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.bg_blur} style={{ backgroundImage: `url(${currentNewBlog.data.new_image})` }}></div>
            <div className={styles.gradient}></div>
          </div>

          <div className="position-relative mt-4 z-index-high">
            <div className="container">
              <div className="d-flex flex-wrap align-items-center justify-content-center mb-4">
                {currentNewBlog.data.new_deleted_at === null ? (
                  userCan("fm_new_blog_update") ? (
                    <div className="mx-2 my-1 my-sm-0">
                      <Link to={`/fm-news/update/${currentNewBlog.data.new_key}`} className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`} disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                        <span className="btn-bg"></span>
                        Edit
                      </Link>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {currentNewBlog.data.new_deleted_at === null ? (
                  userCan("fm_new_blog_soft_delete") ? (
                    <div className="mx-2 my-1 my-sm-0">
                      <button
                        className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`}
                        onClick={() =>
                          softDeleteNewBlog(
                            "DELETE",
                            `${baseUrl}fm-news/delete/${currentNewBlog.data.new_key}`,
                            {
                              Accept: "application/json",
                              "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                              Authorization: `Bearer ${user.accessToken}`,
                            },
                            setIsSoftDeleteLoading
                          )
                        }
                        disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                        <span className="btn-bg"></span>
                        Delete
                      </button>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}

                {currentNewBlog.data.new_deleted_at !== null ? (
                  userCan("fm_new_blog_restore_trashed") ? (
                    <div className="mx-2 my-1 my-sm-0">
                      <button
                        className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`}
                        onClick={() =>
                          restoreNewBlog(
                            "PATCH",
                            `${baseUrl}fm-news/restore/${currentNewBlog.data.new_key}`,
                            {
                              Accept: "application/json",
                              "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                              Authorization: `Bearer ${user.accessToken}`,
                            },
                            setIsRestoreLoading
                          )
                        }
                        disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                        <span className="btn-bg"></span>
                        Restore
                      </button>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                {userCan("fm_new_blog_force_delete") ? (
                  <div className="ms-2 my-1 my-sm-0">
                    <button
                      className={`btn fu-btn-sm fu-btn-gray ${isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? "disabled" : ""}`}
                      onClick={() =>
                        forceDeleteNewBlog(
                          "DELETE",
                          `${baseUrl}fm-news/force-delete/${currentNewBlog.data.new_key}`,
                          {
                            Accept: "application/json",
                            "fm-api-secret-key": process.env.REACT_APP_FM_API_SECRET_KEY,
                            Authorization: `Bearer ${user.accessToken}`,
                          },
                          setIsForceDeleteLoading
                        )
                      }
                      disabled={isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? true : false}>
                      <span className="btn-bg"></span>
                      Force Delete
                    </button>
                  </div>
                ) : (
                  ""
                )}

                {isForceDeleteLoading || isRestoreLoading || isSoftDeleteLoading ? (
                  <div className="position-relative ms-4">
                    <LoadingCircle circleCont={35} icon={30} loader={35} dot={35} />
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="new-blog-tags">
                <div className="mb-4 info-tags-container">
                  {currentNewBlog.data._id ? (
                    <>
                      <div className="mx-1 my-1 info-tag">
                        <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">#ID: </span>
                        <span className="d-inline-flex justify-content-center align-items-center">{currentNewBlog.data._id}</span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {currentNewBlog.data.new_key ? (
                    <>
                      <div className="mx-1 my-1 info-tag">
                        <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">#KEY: </span>
                        <span className="d-inline-flex justify-content-center align-items-center">{currentNewBlog.data.new_key}</span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {currentNewBlog.data.related_news_count !== null ? (
                    <>
                      <div className="mx-1 my-1 info-tag">
                        <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">RELATED NEW COUNT: </span>
                        <span className="d-inline-flex justify-content-center align-items-center">{currentNewBlog.data.related_news_count}</span>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {currentNewBlog.data.new_created_at ? (
                    userCan("created_at_show") ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED AT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{new Date(currentNewBlog.data.new_created_at).toLocaleString("en-US", { timeZone: "Africa/Cairo", hour12: true })}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {currentNewBlog.data.new_updated_at ? (
                    userCan("updated_at_show") ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED AT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{new Date(currentNewBlog.data.new_updated_at).toLocaleString("en-US", { timeZone: "Africa/Cairo", hour12: true })}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {currentNewBlog.data.new_deleted_at ? (
                    userCan("deleted_at_show") ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">DELETED AT: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{new Date(currentNewBlog.data.new_deleted_at).toLocaleString("en-US", { timeZone: "Africa/Cairo", hour12: true })}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {currentNewBlog.data.new_created_by ? (
                    userCan("created_by_show") ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED BY, NAME: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentNewBlog.data.new_created_by.name}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {currentNewBlog.data.new_created_by ? (
                    userCan("created_by_show") ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED BY, #ID: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentNewBlog.data.new_created_by._id}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {currentNewBlog.data.new_created_by ? (
                    userCan("created_by_show") ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">CREATED BY, KEY: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentNewBlog.data.new_created_by.key}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {currentNewBlog.data.new_updated_by ? (
                    userCan("updated_by_show") ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED BY, NAME: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentNewBlog.data.new_updated_by.name}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {currentNewBlog.data.new_updated_by ? (
                    userCan("updated_by_show") ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED BY, #ID: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentNewBlog.data.new_updated_by._id}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                  {currentNewBlog.data.new_updated_by ? (
                    userCan("updated_by_show") ? (
                      <>
                        <div className="mx-1 my-1 info-tag">
                          <span className="d-inline-flex justify-content-center align-items-center me-2 fu-text-yellow">UPDATED BY, KEY: </span>
                          <span className="d-inline-flex justify-content-center align-items-center">{currentNewBlog.data.new_updated_by.key}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className={styles.blog_title_container}>
                <h1 className={styles.blog_title}>{currentNewBlog.data.new_title}</h1>
              </div>
              <div className={styles.blog_description_container}>
                <p className={styles.blog_description} dangerouslySetInnerHTML={{ __html: currentNewBlog.data.new_description }} />
                {(currentNewBlog.data.new_btn_text_outside || currentNewBlog.data.new_btn_text_inside) && (
                  <div className="my-4">
                    {currentNewBlog.data.new_btn_text_outside && (
                      <a href={currentNewBlog.data.new_btn_path_outside} className="fu-btn-sm fu-btn-gray" target="_blank" rel="noreferrer">
                        <span className="btn-bg"></span>
                        {currentNewBlog.data.new_btn_text_outside}
                      </a>
                    )}
                    {currentNewBlog.data.new_btn_text_inside && (
                      <a href={`http://localhost:3001/${currentNewBlog.data.new_btn_path_inside}`} className="fu-btn-sm fu-btn-gray ms-2" target="_blank" rel="noreferrer">
                        <span className="btn-bg"></span>
                        {currentNewBlog.data.new_btn_text_inside}
                      </a>
                    )}
                  </div>
                )}
              </div>
              {/* related news */}
              {currentNewBlog.data.related_news !== null ? (
                <div className="mb-4">
                  <div className="d-flex flex-column flex-lg-row align-items-center mb-4">
                    <div className="flex-grow-1 iu-row-heading mb-2 mb-lg-0">
                      <h2 className="mb-0 row-title">RELATED NEWS</h2>
                    </div>
                    <div className="d-flex justify-content-between align-items-center row-search-container ms-0 ms-lg-2">
                      <div className="item-search-box-container flex-grow-1">
                        <div className="loading-fixed-dimensions item-search-box-loading">
                          {isNewsSearchLoading ? (
                            <div className="position-relative">
                              <LoadingCircle circleCont={20} icon={15} loader={20} dot={20} />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="item-search-box">
                          <input
                            type="text"
                            className="form-control shadow-none"
                            id="related_news_search_query"
                            placeholder="Relateds News Search..."
                            onInput={(e) => {
                              setNewsSearchText(e.target.value);
                              clearTimeout(newsSearchTimeOut);
                            }}
                            ref={newsSearchInput}
                          />
                        </div>
                      </div>
                      <div className="ms-2">
                        <button
                          className="fu-btn fu-btn-gray"
                          onClick={() => {
                            setNewsSearchText("");
                            clearTimeout(newsSearchTimeOut);
                            newsSearchInput.current.value = "";
                          }}>
                          <span className="btn-bg"></span>
                          Clear Search
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="item-news-search-row-height">
                    {newsSearchText.length > 0 && !isNewsSearchLoading && currentNewBlog.data.related_news.length === 0 ? (
                      <div className="d-flex flex-column justify-content-center align-items-center item-news-search-msg-height">
                        <div>
                          <h2 className="fu-text-3-rem fu-text-yellow">
                            <i className="bi bi-search"></i>
                          </h2>
                        </div>
                        <div>
                          <h3 className="h5 fu-text-light-gray line-clamp-2">
                            There Are No News Related To This New Blog With This Text<span className="d-inline-flex fu-text-yellow mx-2">"{newsSearchText}"</span>
                          </h3>
                        </div>
                      </div>
                    ) : (
                      <div className="row gx-3">
                        <Swiper className="swiper px-2 h-100" slidesPerView={newsSwiperCount} spaceBetween={10} keyboard={true} navigation={true} modules={[Navigation, Keyboard]}>
                          {currentNewBlog.data.related_news.map((newBlog, index) => (
                            <SwiperSlide key={index}>
                              <NewBlogCard newBlog={newBlog} />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default NewDetails;
