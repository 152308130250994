import React, { useContext } from "react";
import Reveal from "../Reveal/Reveal";
import { fmDataContext } from "../../Context/FmData";

const NewBlogCard = ({ newBlog }) => {
  let { goToBlogDetails } = useContext(fmDataContext);

  return (
    <div className="h-100">
      <Reveal>
        <div className="p-2 p-sm-3 p-md-4 new-blog-container h-100 " onClick={() => goToBlogDetails(`/fm-news/${newBlog.new_key}`, newBlog.new_deleted_at ? "1" : "0")}>
          <div className="new-blog-bg" style={{ backgroundImage: `url(${newBlog.new_image})` }}></div>
          <div className="blog-box">
            <div className="blog-img-container mb-2">
              <img src={newBlog.new_image} alt="blog one" className="w-100 h-100 blog-img" />
            </div>
            <div className="fu-blog-caption">
              <div>
                <h3 className="mb-2 fu-blog-title">{newBlog.new_title}</h3>
                <span className="mb-0 fu-blog-read">Read more +</span>
              </div>
            </div>
          </div>
        </div>
      </Reveal>
    </div>
  );
};

export default NewBlogCard;
